import { java } from "j4ts"

import HashMap = java.util.HashMap

/**
 * Abstract class for HTML start and end elements (tags).
 */
export abstract class MyElement {
    /**
     * Element's name
     */
    _element: string

    /**
     * Returns element's name.
     * @return element's name
     */
    getElementName(): string {
        return this._element
    }

    constructor() {}
}

/**
 * Class representing HTML start element.
 */
export class ElementStart extends MyElement {
    /**
     * Map containing all element's attributtes with their values.
     */
    private _attributes: HashMap<string, string>

    /**
     * Cstr.
     * @param element element's name
     * @param attributes element's attributes
     */
    public constructor(element: string, attributes: HashMap<string, string>) {
        super()
        this._element = element
        this._attributes = attributes
    }

    /**
     * Returns element's attributes.
     * @return element's attributes
     */
    getAttributes(): HashMap<string, string> {
        return this._attributes
    }
}

/**
 * Class representing HTML end element.
 */
export class ElementEnd extends MyElement {
    /**
     * Cstr.
     * @param element element's name
     */
    constructor(element: string) {
        super()
        this._element = element
    }
}
