import { Validators } from "@angular/forms"
import { BaseForm, Validations } from "@puntaje/shared/core"
import { MaterialTipos } from "./material_tipos.service"

export class AyudaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            // profesor_id: 		{label: "Profesor", type: "select", visible: true, options: {class: Personas}, validations: [Validators.required]},
            material_tipo_id: {
                label: "Tipo de Material",
                type: "select",
                visible: true,
                options: { class: MaterialTipos },
                validations: [Validators.required]
            },
            ayuda: { label: "Nombre ayuda", type: "text", visible: true, validations: [Validators.required] },
            visible: { label: "Visible", type: "checkbox", visible: true },
            target_app: { label: "Target App (profesores/alumnos/o vacío)", type: "text", visible: true },
            contenido: { label: "Contenido", type: "ckeditor", visible: true },
            comentarios: { label: "Comentarios", type: "textarea", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            // asignatura_id: {label: "Asignatura principal", type: "select", visible: true, options: {class: Asignaturas}, validations: [Validators.required]},
            file: { label: "Archivo", type: "file", visible: true, validations: [Validations.validateFile] },
            url: { label: "Enlace a video", type: "text", visible: true, validations: [Validations.validateYoutubeURI] }
        }
    }
}
