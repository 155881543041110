import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    LotePregunta,
    LotePreguntas,
    Plataforma,
    Plataformas,
    Pregunta,
    Preguntas,
    PreguntaView,
    RevisionRecursos,
    RevisionRecurso,
    PreguntaCambiadas,
    PreguntaCambiada
} from "@puntaje/nebulosa/api-services"
import { PdfView, AuthService, GrupoPreguntaTextoDirective, GrupoPreguntaTextoService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { HttpClient } from "@angular/common/http"

@Component({
    templateUrl: "pregunta.component.html",
    styleUrls: ["pregunta.component.scss"]
})
export class PreguntaComponent implements OnInit, OnDestroy {
    show_params = PreguntaView.viewParams
    pregunta: Pregunta
    plataformas: Plataforma[]
    estadistica: any[]
    total: any
    private sub: Subscription
    distractores: boolean[] = []
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("pdfLoadingLayout", { static: true }) pdfLoadingLayout: LoadingLayoutComponent

    paramsPreviewLatex: any
    featuringInLotes: LotePregunta[]
    changelog: any
    showMarcarComoRevisada: boolean
    showDesmarcarComoRevisada: boolean

    savingCambio: boolean = false

    openVariacionModal: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        private preguntasService: Preguntas,
        private plataformasService: Plataformas,
        private lotePreguntasService: LotePreguntas,
        private authService: AuthService,
        private revisionRecursosService: RevisionRecursos,
        private router: Router,
        private route: ActivatedRoute,
        protected preguntaCambiadasService: PreguntaCambiadas,
        public grupoPreguntaTextoService: GrupoPreguntaTextoService,
        protected http: HttpClient
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.loadPregunta(+params["id"])
        })
    }

    generarVariacion() {
        this.openVariacionModal.emit()
    }

    loadPregunta(preguntaId) {
        this.loadingLayout.standby()

        const param = {
            include: "[revision_recursos,persona_externa:plataforma]"
        }
        this.preguntasService.find(preguntaId, param).then((pregunta: Pregunta) => {
            this.pregunta = pregunta
            this.updatePreviewLatex()
            this.plataformasService.where({ without_old: 1 }).then((plataformas: Plataforma[]) => {
                this.plataformas = plataformas
                this.preguntasService.getEstadisticas(this.pregunta.id).then(estadisticas => {
                    this.estadistica = estadisticas as any
                    this.total = this.sumup(this.estadistica)
                    this.loadingLayout.ready()
                })
            })
            this.lotePreguntasService
                .where({ lote_pregunta: { pregunta_id: this.pregunta.id }, include: "[lote]" })
                .then((response: LotePregunta[]) => {
                    this.featuringInLotes = response
                })

            this.pregunta.contestables[0].alternativas.forEach(alternativa => {
                if (alternativa.explicacion_distractor != undefined && alternativa.explicacion_distractor != "") {
                    this.distractores.push(false)
                }
            })

            this.showDesmarcarComoRevisada = !!this.pregunta.revision_recursos?.find(
                rr => rr.usuario_id == this.authService.getUserData().id
            )
            this.showMarcarComoRevisada = !this.showDesmarcarComoRevisada
        })
    }

    getDificultad(correctas, incorrectas, omitidas) {
        if (correctas || incorrectas || omitidas) {
            const numeroRespuestas = correctas + incorrectas + omitidas
            const dificultad = correctas / numeroRespuestas

            let dificultadString = ""
            if (dificultad <= 0.3) {
                dificultadString = "Alta"
            } else if (dificultad <= 0.6) {
                dificultadString = "Media"
            } else {
                dificultadString = "Baja"
            }
            return dificultadString
        } else {
            return "-"
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    getChangelog(page: number, per: number) {
        let pregunta_id
        this.route.params.subscribe(params => {
            pregunta_id = +params["id"]
        })

        return this.preguntasService.getChangeLog(pregunta_id, page, per).then(response => {
            this.changelog = response["changes"] as any[]
            this.changelog.forEach(function (element) {
                element.change_time = new Date(element.change_time).toLocaleString()
            })

            return response["total"]
        })
    }

    keys(asignatura: Pregunta): Array<string> {
        return Object.keys(asignatura)
    }

    toggleDistractor(index: any) {
        this.distractores[index] = !this.distractores[index]
    }

    sum(estadistica) {
        if (estadistica && estadistica.correctas) {
            return estadistica.correctas + estadistica.incorrectas + estadistica.omitidas
        } else {
            return 0
        }
    }

    sumup(estadisticas) {
        let correctas = 0
        let incorrectas = 0
        let omitidas = 0
        Object.keys(estadisticas).forEach(e => {
            if (estadisticas[e]) {
                correctas = correctas + estadisticas[e].correctas
                incorrectas = incorrectas + estadisticas[e].incorrectas
                omitidas = omitidas + estadisticas[e].omitidas
            }
        })

        return { correctas: correctas, incorrectas: incorrectas, omitidas: omitidas }
    }

    marcarCambioImportante() {
        this.savingCambio = true

        const preguntaCambiada = new PreguntaCambiada()
        preguntaCambiada.pregunta_id = this.pregunta.id

        this.preguntaCambiadasService.save(preguntaCambiada).then(pc => {
            this.savingCambio = false
        })
    }

    marcarComoRevisada() {
        this.loadingLayout.standby()
        const revisionRecurso = new RevisionRecurso()
        revisionRecurso.usuario_id = +this.authService.getUserData().id
        revisionRecurso.recurso_type = "Pregunta"
        revisionRecurso.recurso_id = this.pregunta.id

        this.revisionRecursosService.save(revisionRecurso).then(rr => {
            this.loadPregunta(this.pregunta.id)
        })
    }

    desmarcarComoRevisada() {
        this.loadingLayout.standby()
        this.revisionRecursosService
            .remove(this.pregunta.revision_recursos.find(rr => rr.usuario_id == this.authService.getUserData().id).id)
            .then(() => {
                this.loadPregunta(this.pregunta.id)
            })
    }

    updatePreviewLatex() {
        let params: any = {
            pregunta: {
                ...this.pregunta,
                taxativo: { ...this.pregunta.taxativos[0] },
                contestable: {
                    ...this.pregunta.contestables[0],
                    alternativas: [...this.pregunta.contestables[0].alternativas.map(a => ({ ...a }))]
                }
            }
        }

        delete params.clasificacion_preguntas
        delete params.clasificaciones
        delete params.grupo_pregunta

        this.paramsPreviewLatex = params
    }
}
