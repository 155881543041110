import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Usuario, UsuarioForm, Usuarios } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "usuarios.form.component.html"
})
export class UsuariosNewComponent implements OnInit {
    usuario: Usuario = new Usuario()
    params = UsuarioForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar usuario"
    display_buttons_options = "all"
    save_button_text: string
    clear_button_text: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private usuariosService: Usuarios, private router: Router) {}

    ngOnInit() {
        this.form = UsuarioForm.getForm(this.usuario)
        this.loadingLayout.ready()
    }

    save() {
        UsuarioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.usuario.persona_id = this.usuario.persona && this.usuario.persona.id
            this.usuariosService.save(this.usuario).then(response => {
                this.router.navigate(["usuarios"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.usuario = new Usuario()
            UsuarioForm.markFormControlsAsPristine(this.form)
            UsuarioForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
