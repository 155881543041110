import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Personas } from "./personas.service"

export class PersonaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Personas, key: "id" } },
            nombre: { label: "Nombre", type: TextView, tableVisible: true },
            apellido_paterno: { label: "Apellido paterno", type: TextView, tableVisible: true },
            apellido_materno: { label: "Apellido materno", type: TextView, tableVisible: true },
            email: { label: "Email", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
