<table class="table table-bordered">
    <thead>
        <tr>
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <th *ngIf="show_params[key].tableVisible">{{ show_params[key].label }}</th>
            </ng-template>
            <th>Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let pregunta of preguntas; let i = index; trackBy: id">
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <td *ngIf="show_params[key].tableVisible && key != 'pregunta'">
                    <model-table-cell [params]="show_params[key]" [value]="pregunta" [key]="key"></model-table-cell>
                </td>
                <td *ngIf="show_params[key].tableVisible && key == 'pregunta'">
                    <pregunta-small [pregunta]="pregunta" [preguntaXS]="true"></pregunta-small>
                </td>
            </ng-template>
            <td>
                <table-with-actions-buttons
                    [tableButtonsArray]="tableButtonsArray"
                    [functionParameter]="pregunta"
                ></table-with-actions-buttons>
            </td>
        </tr>
    </tbody>
</table>
