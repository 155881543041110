import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class ReportesBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(params) {
        this.searchParams.next(params)
    }
}
