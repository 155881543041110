<logged-layout>
    <logged-layout-toolnavbar>
        <lotes-toolnavbar *ngIf="lote" [lote]="lote" [displayButtons]="'all new edit'"></lotes-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Lote</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of keys(show_params)"
                    [params]="show_params[key]"
                    [value]="lote"
                    [key]="key"
                ></model-simple-view>
            </div>
            <lote-preguntas *ngIf="!loadingLayout.loading" [lote_preguntas]="lote.lote_preguntas"></lote-preguntas>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
