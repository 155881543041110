import { BooleanView, DateView, HtmlView, LinkView, TextView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { Personas } from "./personas.service"
import { Preguntas } from "./preguntas.service"

export class PreguntaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Preguntas, key: "id" } },
            nombre_asignatura: {
                label: "Asignatura",
                type: LinkView,
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id" }
            },
            grupo_pregunta_id: { label: "Grupo Pregunta", type: TextView, tableVisible: false },
            pregunta: { label: "Pregunta", type: TextView, tableVisible: true },
            taxativo: { label: "Enunciado", type: HtmlView, tableVisible: false },
            contestable: { label: "Contestable", type: TextView, tableVisible: false },
            solucion: { label: "Solución", type: TextView, tableVisible: false },
            solucion_video: { label: "Solución Video", type: TextView, tableVisible: false },
            solucion_latex: { label: "Solución Latex", type: TextView, tableVisible: false },
            estado: { label: "Estado", type: TextView, tableVisible: true },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            presencial: { label: "Presencial", type: BooleanView, tableVisible: true },
            comentarios: { label: "Comentarios", type: TextView, tableVisible: false },
            profesor_id: {
                label: "Profesor",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "profesor_id" }
            },
            cantidad_reportes: { label: "Numero Reportes", type: TextView, tableVisible: true },
            encargado_reporte_id: {
                label: "Encargado Reporte",
                type: LinkView,
                tableVisible: false,
                options: { class: Personas, key: "encargado_reporte_id" }
            },
            autor_id: {
                label: "Autor",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "autor_id" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
