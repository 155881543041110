<logged-layout>
    <logged-layout-toolnavbar>
        <tipo-instrumento-plataformas-toolnavbar [displayButtons]="'new'"></tipo-instrumento-plataformas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Tipo Instrumento Plataformas</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <tipo-instrumento-plataformas-buscador></tipo-instrumento-plataformas-buscador>
            <tipo-instrumento-plataformas
                [tipoInstrumentoPlataformas]="tipoInstrumentoPlataformas"
                (onDelete)="onDelete()"
            ></tipo-instrumento-plataformas>
            <paginator #paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
