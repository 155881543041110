<logged-layout>
    <logged-layout-toolnavbar>
        <generador-instrumentos-toolnavbar
            *ngIf="generador"
            [generador]="generador"
            [displayButtons]="'all new edit'"
        ></generador-instrumentos-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Generador Instrumento</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading && generador">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="generador"
                    [key]="key"
                ></model-simple-view>

                <h3>Clasificaciones</h3>
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Orden</th>
                            <th>Mención</th>
                            <th>Suficiencia</th>
                            <th>Porcentaje</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let gic of generador.generador_instrumento_clasificaciones">
                            <td>{{ gic.clasificacion.id }}</td>
                            <td>{{ gic.clasificacion.clasificacion }}</td>
                            <td>{{ gic.clasificacion.clasificacion_tipo.clasificacion_tipo }}</td>
                            <td>{{ gic.orden }}</td>
                            <td>{{ gic.clasificacion_mencion?.clasificacion }}</td>
                            <td>{{ gic.clasificacion_suficiencia?.clasificacion }}</td>
                            <td>{{ gic.porcentaje * 100 | number: "1.1" }}%</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Instrucciones</h3>
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Clasificación</th>
                            <th>Instrucción</th>
                            <th>Orden</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let gii of generador.generador_instrumento_instrucciones">
                            <td>{{ gii.id }}</td>
                            <td>{{ gii.clasificacion?.clasificacion }}</td>
                            <td [innerHTML]="gii.instruccion?.instruccion"></td>
                            <td>{{ gii.orden }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-md-6">
                        <h3>Clasificaciones Filtro</h3>
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let gif of generador.generador_instrumento_filtro_clasificaciones">
                                    <td>{{ gif.clasificacion.id }}</td>
                                    <td>{{ gif.clasificacion.clasificacion }}</td>
                                    <td>{{ gif.clasificacion.clasificacion_tipo.clasificacion_tipo }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <h3>Clasificacion Tipos Filtro</h3>
                        <table
                            id="table-clasificacion-tipos-filtro"
                            class="table table-striped table-bordered table-hover"
                        >
                            <thead>
                                <tr>
                                    <th>Tipo Clasificacion</th>
                                    <th>Tipo Clasificacion Hija</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let gifct of generador.generador_instrumento_filtro_clasificacion_tipos">
                                    <td>{{ gifct.clasificacion_tipo.clasificacion_tipo }}</td>
                                    <td>{{ gifct.clasificacion_tipo_hija.clasificacion_tipo }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <h3>Categorías</h3>
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let gic of generador.generador_instrumento_categorias">
                                    <td>{{ gic.categoria.id }}</td>
                                    <td>{{ gic.categoria.clasificacion }}</td>
                                    <td>{{ gic.categoria.clasificacion_tipo.clasificacion_tipo }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
