import { RouterModule } from "@angular/router"
import { MaterialGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { AyudaComponent } from "./ayuda.component"
import { AyudasComponent } from "./ayudas.component"
import { AyudasDeleteComponent } from "./ayudas.delete.component"
import { AyudasEditComponent } from "./ayudas.edit.component"
import { AyudasNewComponent } from "./ayudas.new.component"

export const ayudasRouting = RouterModule.forChild([
    {
        path: "ayudas",
        component: AyudasComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Ayudas#index" }
    },
    {
        path: "ayudas/new",
        component: AyudasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Ayudas#create" }
    },
    {
        path: "ayudas/:id",
        component: AyudaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Ayudas#show" }
    },
    {
        path: "ayudas/:id/edit",
        component: AyudasEditComponent,
        canActivate: [AuthGuard, MaterialGuard, PermisosGuard],
        data: { permiso: "Ayudas#update" }
    },
    {
        path: "ayudas/:id/delete",
        component: AyudasDeleteComponent,
        canActivate: [AuthGuard, MaterialGuard, PermisosGuard],
        data: { permiso: "Ayudas#destroy" }
    }
])
