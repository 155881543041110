<logged-layout>
    <logged-layout-toolnavbar>
        <generador-instrumentos-toolnavbar
            [displayButtons]="display_buttons_options"
        ></generador-instrumentos-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Formulario clasificaciones" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="ready" class="row">
                <div class="col-md-8 col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            [form]="form"
                            [params]="params['generador_instrumento']"
                            [(value)]="generador['generador_instrumento']"
                            key="generador_instrumento"
                        ></form-input>

                        <div class="form-group" *ngIf="plataformas">
                            <label>Plataforma</label>
                            <select (input)="plataformaSelected($any($event).target.value)" class="form-control">
                                <option value="">Seleccione Plataforma</option>
                                <option
                                    [selected]="plataforma.id == plataformaId"
                                    [value]="plataforma.id"
                                    *ngFor="let plataforma of plataformas"
                                >
                                    {{ plataforma.plataforma }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group" *ngIf="asignaturaPlataformas">
                            <label>Asignatura</label>
                            <select
                                (input)="asignaturaPlataformaSelected($any($event).target.value)"
                                class="form-control"
                            >
                                <option
                                    [selected]="ap.id == asignaturaPlataformaId"
                                    [value]="ap.id"
                                    *ngFor="let ap of asignaturaPlataformas"
                                >
                                    {{ ap.asignatura.asignatura }}
                                </option>
                            </select>
                        </div>

                        <form-input
                            [form]="form"
                            [params]="params['tipo_instrumento_id']"
                            [(value)]="generador['tipo_instrumento_id']"
                            key="tipo_instrumento_id"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['valido']"
                            [(value)]="generador['valido']"
                            key="valido"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['numero_preguntas']"
                            [(value)]="generador['numero_preguntas']"
                            key="numero_preguntas"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['tiempo']"
                            [(value)]="generador['tiempo']"
                            key="tiempo"
                        ></form-input>

                        <form-input
                            [form]="form"
                            [params]="params['alias']"
                            [(value)]="generador['alias']"
                            key="alias"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['orden']"
                            [(value)]="generador['orden']"
                            key="orden"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['grupal']"
                            [(value)]="generador['grupal']"
                            key="grupal"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['multiple']"
                            [(value)]="generador['multiple']"
                            key="multiple"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['visible']"
                            [(value)]="generador['visible']"
                            key="visible"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['numero_preguntas_piloto']"
                            [(value)]="generador['numero_preguntas_piloto']"
                            key="numero_preguntas_piloto"
                        ></form-input>
                        <form-input
                            id="maximo_alternativas"
                            [form]="form"
                            [params]="params['maximo_alternativas']"
                            [(value)]="generador['maximo_alternativas']"
                            key="maximo_alternativas"
                        ></form-input>
                    </form>

                    <form [formGroup]="extrasForm" class="form-inline">
                        <br />
                        <br />
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">Clasificaciones</h3>
                            </div>

                            <div class="panel-body">
                                <div formArrayName="clasificaciones">
                                    <button (click)="agregarClasificacion()" class="btn btn-default">
                                        Agregar Clasificación
                                    </button>
                                    <div
                                        *ngFor="let clasificacion of clasificacionForms.controls; let i = index"
                                        [formGroupName]="i"
                                    >
                                        <div class="form-group">
                                            <label>Orden:</label>
                                            <input class="form-control" type="number" min="0" formControlName="orden" />
                                        </div>
                                        <div class="form-group">
                                            <label>Porcentaje:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                max="1"
                                                formControlName="porcentaje"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>Mención</label>
                                            <select class="form-control" formControlName="clasificacion_mencion_id">
                                                <option *ngFor="let m of menciones" [value]="m.id">
                                                    {{ m.clasificacion }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Suficiencia</label>
                                            <select class="form-control" formControlName="clasificacion_suficiencia_id">
                                                <option *ngFor="let s of suficiencias" [value]="s.id">
                                                    {{ s.clasificacion }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Id clasificación:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="clasificacion_id"
                                            />
                                        </div>
                                        <button (click)="removerClasificacion(i)" class="btn btn-default">x</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">Filtro Clasificaciones</h3>
                            </div>

                            <div class="panel-body">
                                <div formArrayName="clasificacionFiltros">
                                    <button (click)="agregarClasificacionFiltro()" class="btn btn-default">
                                        Agregar Clasificación Filtro
                                    </button>
                                    <div
                                        *ngFor="let clasificacion of clasificacionFiltroForms.controls; let i = index"
                                        [formGroupName]="i"
                                    >
                                        <div class="form-group">
                                            <label>Id clasificación:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="clasificacion_id"
                                            />
                                        </div>
                                        <button (click)="removerClasificacionFiltro(i)" class="btn btn-default">
                                            x
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">Relación Filtro Clasificación Tipos</h3>
                            </div>

                            <div class="panel-body">
                                <div>
                                    <p>
                                        Sección para definir los tipos de clasificación que se usan para agrupar las
                                        clasificaciones filtro en la vista generar evaluación.
                                    </p>

                                    <p>
                                        Ej: Se quiere que las clasificaciones de tipo "objetivo de aprendizaje" vengan
                                        agrupadas por "eje temático LGE", entonces:
                                        <br />
                                        Tipo Clasificación: "eje temático LGE" - Tipo Clasificación Hija: "objetivo de
                                        aprendizaje"
                                    </p>
                                </div>
                                <div id="clasificacionTipoFiltros" formArrayName="clasificacionTipoFiltros">
                                    <button
                                        id="btnClasificacionTipoFiltro"
                                        type="button"
                                        (click)="agregarRelacionClasificacionTipoFiltro()"
                                        class="btn btn-default"
                                    >
                                        Agregar Agrupación por Tipo de Clasificación
                                    </button>
                                    <div
                                        *ngFor="
                                            let clasificacionTipoFiltro of clasificacionTipoFiltroForms.controls;
                                            let i = index
                                        "
                                        [formGroupName]="i"
                                        class="rowInputs"
                                    >
                                        <div class="form-group">
                                            <label>Tipo Clasificación ID:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="clasificacion_tipo_id"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>Tipo Clasificación Hija ID:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="clasificacion_tipo_hija_id"
                                            />
                                        </div>
                                        <button (click)="removerClasificacionTipoFiltro(i)" class="btn btn-default">
                                            x
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">Categorías</h3>
                            </div>

                            <div class="panel-body">
                                <div formArrayName="categorias">
                                    <button (click)="agregarCategoria()" class="btn btn-default">
                                        Agregar Categoría
                                    </button>
                                    <div
                                        *ngFor="let clasificacion of categoriaForms.controls; let i = index"
                                        [formGroupName]="i"
                                    >
                                        <div class="form-group">
                                            <label>Id clasificación:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="categoria_id"
                                            />
                                        </div>
                                        <button (click)="removerCategoria(i)" class="btn btn-default">x</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">Instruccione</h3>
                            </div>

                            <div class="panel-body">
                                <div formArrayName="instrucciones">
                                    <button (click)="agregarInstruccion()" class="btn btn-default">
                                        Agregar Instrucción
                                    </button>
                                    <div
                                        *ngFor="let instruccion of instruccionForms.controls; let i = index"
                                        [formGroupName]="i"
                                    >
                                        <div class="form-group">
                                            <label>Id instrucción:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="instruccion_id"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>Id clasificación:</label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                formControlName="clasificacion_id"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>Orden:</label>
                                            <input class="form-control" type="number" min="0" formControlName="orden" />
                                        </div>
                                        <button (click)="removerInstruccion(i)" class="btn btn-default">x</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <button (click)="save()" type="button" class="btn btn-default">
                        {{ save_button_text | easyplaceholder: "Guardar" }}
                    </button>
                    <button (click)="back()" type="button" class="btn btn-default">Volver</button>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
