import { DateView, TextView } from "@puntaje/shared/core"

export class ClasificacionTipoView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            clasificacion_tipo: { label: "Tipo de Clasificación", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
