import { Injectable, Injector } from "@angular/core"
import { PermisosAsociadosService } from "./permisos-asociados.service"
import { BasePolicyService, Opts } from "./base-policy.service"
import { PermisosService } from "./permisos.service"
import { Materiales } from "@puntaje/nebulosa/api-services"

@Injectable({
    providedIn: "root"
})
export class MaterialesPolicyService extends PermisosAsociadosService {
    resourceName: string = "Materiales"

    update({ resource, resourceId, ...rest }: Opts): boolean | Promise<boolean> {
        if (
            resource &&
            (resource as any).certificacion_recursos &&
            (resource as any).certificacion_recursos.length > 0 &&
            !this.testPermiso("Materiales_update_certificado")
        )
            return false
        if (resourceId) {
            const params = {
                material: {
                    id: resourceId
                },
                include: "[certificacion_recursos]"
            }
            return this.injector
                .get(Materiales)
                .where(params)
                .then(materiales => {
                    if (materiales.length == 0) return false
                    let p = materiales[0]
                    return !(
                        p.certificacion_recursos &&
                        p.certificacion_recursos.length == 0 &&
                        !this.testPermiso("Materiales_update_certificado")
                    )
                })
        }
        return (
            super.update({ resource, resourceId, ...rest }) ||
            this.permisoAsociado("update", { resource, resourceId, ...rest })
        )
    }
}
