import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoteGuard } from "@puntaje/nebulosa/api-services"
import { LoteComponent } from "./lote.component"
import { LotesComponent } from "./lotes.component"
import { LotesDeleteComponent } from "./lotes.delete.component"
import { LotesEditComponent } from "./lotes.edit.component"
import { LotesNewComponent } from "./lotes.new.component"
import { LotesUsuarioComponent } from "./lotes_usuario.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { LotesListComponent } from "./lotes-list/lotes-list.component"

export const lotesRoutes: Routes = [
    {
        path: "lotes",
        component: LotesListComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Lotes#index" }
    },
    {
        path: "lotes/new",
        component: LotesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Lotes#create" }
    },
    {
        path: "lotes/:id",
        component: LoteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Lotes#show" }
    },
    {
        path: "lotes/:id/edit",
        component: LotesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Lotes#update" }
    },
    {
        path: "lotes/:id/delete",
        component: LotesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Lotes#destroy" }
    },
    {
        path: "mis_lotes",
        component: LotesUsuarioComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Lotes#index" }
    }
]
export const lotesProviders = [AuthGuard]

export const lotesRouting = RouterModule.forChild(lotesRoutes)
