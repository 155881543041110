<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-tipo-toolnavbar
            *ngIf="clasificacionTipo"
            [clasificacionTipo]="clasificacionTipo"
            [displayButtons]="'all new'"
        ></clasificacion-tipo-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Tipo de Clasificación</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="clasificacionTipo"
                    [key]="key"
                ></model-simple-view>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
