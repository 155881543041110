import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { AuthGuard, FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { PerfilComponent } from "./perfil.component"
import { PerfilesComponent } from "./perfiles.component"
import { PerfilesDeleteComponent } from "./perfiles.delete.component"
import { PerfilesEditComponent } from "./perfiles.edit.component"
import { PerfilesNewComponent } from "./perfiles.new.component"
import { perfilesRouting } from "./perfiles.routing"
import { PerfilesToolNavbarComponent } from "./perfiles.toolnavbar.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        NebuModule,
        PaginatorModule,
        perfilesRouting
    ],
    declarations: [
        PerfilesComponent,
        PerfilComponent,
        PerfilesNewComponent,
        PerfilesEditComponent,
        PerfilesDeleteComponent,
        PerfilesToolNavbarComponent
    ],
    exports: [],
    providers: [AuthGuard]
})
export class PerfilesModule {}
