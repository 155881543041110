import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { ClasificacionTipoAliasComponent } from "./clasificacion_tipo_alias.component"
import { ClasificacionTipoAliasesComponent } from "./clasificacion_tipo_aliases.component"
import { ClasificacionTipoAliasesDeleteComponent } from "./clasificacion_tipo_aliases.delete.component"
import { ClasificacionTipoAliasesEditComponent } from "./clasificacion_tipo_aliases.edit.component"
import { ClasificacionTipoAliasesNewComponent } from "./clasificacion_tipo_aliases.new.component"
import { PermisosGuard } from "../../../shared/guards/permisos.guard"

export const ClasificacionTipoAliasesRoutes: Routes = [
    {
        path: "clasificacion_tipo_aliases",
        component: ClasificacionTipoAliasesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipoAliases#index" }
    },
    {
        path: "clasificacion_tipo_aliases/new",
        component: ClasificacionTipoAliasesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipoAliases#create" }
    },
    {
        path: "clasificacion_tipo_aliases/:id",
        component: ClasificacionTipoAliasComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipoAliases#show" }
    },
    {
        path: "clasificacion_tipo_aliases/:id/edit",
        component: ClasificacionTipoAliasesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipoAliases#update" }
    },
    {
        path: "clasificacion_tipo_aliases/:id/delete",
        component: ClasificacionTipoAliasesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipoAliases#destroy" }
    }
]

export const ClasificacionTipoAliasesRouting = RouterModule.forChild(ClasificacionTipoAliasesRoutes)
