<logged-layout>
    <logged-layout-titulo>Asociar Generador de diagnóstico</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout>
            <div class="form-group">
                <label>Generador Instrumento de Diagnostico</label>
                <autocomplete
                    (filterChange)="onInputText($event)"
                    [(ngModel)]="generadorInstrumentoDiagnostico"
                    showTextFun="toStringWithTipoInstrumentoWithAsignaturaPlataforma"
                    [options]="generadorInstrumentosDiagnosticoFiltered"
                ></autocomplete>
            </div>
            <button class="btn btn-default margin-right" (click)="save()">Guardar</button>
            <button class="btn btn-default" (click)="back()">Volver</button>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
