<div class="grupo_pregunta_container">
    <div class="right_title">
        <a
            [routerLink]="['/grupo_preguntas', grupoPregunta?.id]"
            *ngIf="grupoPregunta"
            [class.disableLink]="disableLinkOnCorner"
        >
            Grupo pregunta #{{ grupoPregunta?.id }}
        </a>
    </div>
    <loading-layout #loadingLayout [loaderType]="'nebu-small'">
        <div class="top-padding-space"></div>
        <div *ngIf="grupoPregunta && !loadingLayout.loading">
            <div
                [grupoPreguntaTexto]="grupoPregunta.texto | easyplaceholder: 'Grupo pregunta sin contenido de texto.'"
                [grupoPreguntaId]="grupoPregunta.id"
            ></div>
        </div>
    </loading-layout>
</div>
