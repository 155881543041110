import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { PlataformaComponent } from "./plataforma.component"
import { PlataformasComponent } from "./plataformas.component"
import { PlataformasDeleteComponent } from "./plataformas.delete.component"
import { PlataformasEditComponent } from "./plataformas.edit.component"
import { PlataformasNewComponent } from "./plataformas.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const plataformasRoutes: Routes = [
    {
        path: "plataformas",
        component: PlataformasComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Plataformas#index" }
    },
    {
        path: "plataformas/new",
        component: PlataformasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Plataformas#create" }
    },
    {
        path: "plataformas/:id",
        component: PlataformaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Plataformas#show" }
    },
    {
        path: "plataformas/:id/edit",
        component: PlataformasEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Plataformas#update" }
    },
    {
        path: "plataformas/:id/delete",
        component: PlataformasDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Plataformas#destroy" }
    }
]

export const plataformasRouting = RouterModule.forChild(plataformasRoutes)
