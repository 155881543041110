import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core"

/*#################################

Tabla con botones de acción en la columna derecha

#################################*/

@Component({
    templateUrl: "table_with_actions.component.html",
    selector: "table-with-actions",
    styleUrls: ["table_with_actions.component.css"]
})
export class TableWithActionsComponent implements OnInit {
    @Input("contentArray") contentArray: any[]
    @Input("showParams") show_params: any[]
    @Input() tableButtonsArray: any[]
    @Input() resourceName: string

    @Input() selectable = false
    @Input() hideSelect: (y: any) => boolean = elem => false
    selected: number[] = []
    @Output() sendSelected = new EventEmitter()

    firsLimit: number = 850
    showSmallTable: boolean = false

    ngOnInit() {
        this.showSmallTable = window.innerWidth <= this.firsLimit
        this.selected = []
    }

    onResize(event) {
        this.showSmallTable = event.target.innerWidth <= this.firsLimit
    }

    id(index, content) {
        let track
        if (!content) track = undefined
        else if (content.id && content.updated_at) track = content.id + content.updated_at.toString()
        else if (content.id) track = content.id
        else track = undefined
        return track
    }

    addUp(id: number) {
        if (this.isChecked(id)) {
            this.selected.splice(this.selected.indexOf(id), 1)
        } else {
            this.selected.push(id)
        }
        this.sendSelected.emit(this.selected)
    }

    isChecked(id: number) {
        return this.selected.includes(id)
    }
}
