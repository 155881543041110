import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { ReporteComponent } from "./reporte.component"
import { ReportesComponent } from "./reportes.component"
import { ReportesDeleteComponent } from "./reportes.delete.component"
import { ReportesEditComponent } from "./reportes.edit.component"
import { ReportesNewComponent } from "./reportes.new.component"
import { ReportesAgrupadosComponent } from "./reportes_agrupados.component"
import { ReportesResueltosComponent } from "./reportes_resueltos.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const reportesRoutes: Routes = [
    {
        path: "reportes",
        component: ReportesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#index" }
    },
    {
        path: "reportes/resueltos",
        component: ReportesResueltosComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#index" }
    },
    {
        path: "reportes/agrupados",
        component: ReportesAgrupadosComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#index" }
    },
    {
        path: "reportes/new",
        component: ReportesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#create" }
    },
    {
        path: "reportes/:id",
        component: ReporteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#show" }
    },
    {
        path: "reportes/:id/edit",
        component: ReportesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#update" }
    },
    {
        path: "reportes/:id/delete",
        component: ReportesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Reportes#destroy" }
    }
]

export const reportesRouting = RouterModule.forChild(reportesRoutes)
