<logged-layout>
    <logged-layout-toolnavbar>
        <materiales-toolnavbar
            *ngIf="material"
            [material]="material"
            [displayButtons]="'all new edit'"
        ></materiales-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        Material
        <button
            *ngIf="showMarcarComoRevisada"
            [disabled]="loadingLayout.loading"
            class="btn btn-default pull-right"
            (click)="marcarComoRevisada()"
        >
            Marcar como revisada
        </button>
        <button
            *ngIf="showDesmarcarComoRevisada"
            [disabled]="loadingLayout.loading"
            class="btn btn-default pull-right"
            (click)="desmarcarComoRevisada()"
        >
            Desmarcar como revisada
        </button>
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="material"
                    [key]="key"
                ></model-simple-view>
                <h4>Persona externa</h4>
                <div *ngIf="!material.persona_externa">
                    <div>----------</div>
                </div>
                <div *ngIf="material.persona_externa">
                    <div>{{ material.persona_externa.toString() }}</div>
                    <div>{{ material.persona_externa.plataforma.plataforma }}</div>
                </div>
                <h4 *ngIf="material.clasificaciones">Clasificaciones</h4>
                <ng-template ngFor let-clasificacion [ngForOf]="material.clasificaciones">
                    <div class="clasificacion">{{ clasificacion.clasificacion }}</div>
                </ng-template>
                <p *ngIf="material.clasificaciones.length == 0" class="empty-list">Material sin clasificaciones.</p>
                <br />
                <h4>Revisiones</h4>
                <revisiones-recurso *ngIf="material" [recurso]="material" recursoTipo="Material"></revisiones-recurso>
                <br />
                <h4>Certificación</h4>
                <certificacion-recurso [recursoId]="material.id" [tipoRecurso]="'Material'"></certificacion-recurso>
                <br />
                <h4 *ngIf="material.plataformas">Plataformas</h4>
                <ng-template ngFor let-plataforma [ngForOf]="material.plataformas">
                    <div class="plataforma">{{ plataforma.plataforma }}</div>
                </ng-template>
                <p *ngIf="material.plataformas.length == 0" class="empty-list">
                    Material no ha sido asignado a plataforma(s).
                </p>
                <h4>Vista previa</h4>
                <div class="clearfix">
                    <material-preview [material]="material"></material-preview>
                </div>
                <br />
                <h4>Thumbnail</h4>
                <p *ngIf="!material.thumbnail">No tiene / automático</p>
                <img *ngIf="material.thumbnail" [src]="material.thumbnail" />
                <br />
                <h4>Uso en Plataformas</h4>
                <table *ngIf="estadistica" class="table table-striped table-bordered tabla-uso-plataforma">
                    <thead>
                        <tr>
                            <th>Plataforma</th>
                            <th>Vistas</th>
                            <th>Tiempo</th>
                            <th>Likes</th>
                            <th>Dislike</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let plataforma of plataformas; let i = index">
                            <td>{{ plataforma.plataforma }}</td>
                            <td>{{ estadistica[plataforma.id]?.total_vista || 0 }}</td>
                            <td>{{ estadistica[plataforma.id]?.tiempo_acumulado | secondsToTime: "s" }}</td>
                            <td>{{ estadistica[plataforma.id]?.total_like || 0 }}</td>
                            <td>{{ estadistica[plataforma.id]?.total_dislike || 0 }}</td>
                        </tr>
                        <tr class="info">
                            <td>Total</td>
                            <td>{{ total.total_vista }}</td>
                            <td>{{ total.tiempo_acumulado | secondsToTime: "s" }}</td>
                            <td>{{ total.total_like }}</td>
                            <td>{{ total.total_dislike }}</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h4>Reportes</h4>
                <embed-reportes *ngIf="material" [tabla]="'Material'" [entidadId]="material.id" [enableAdvancedOptions]="true"
                                [selectable]="true"></embed-reportes>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
