import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Plataforma,
    Plataformas,
    TipoInstrumentos,
    TipoInstrumento,
    TipoInstrumentoPlataforma,
    TipoInstrumentoPlataformas,
    TipoInstrumentoPlataformaForm
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "tipo_instrumento_plataformas.form.component.html"
})
export class TipoInstrumentoPlataformasNewComponent implements OnInit, OnDestroy {
    tipoInstrumentoPlataforma: TipoInstrumentoPlataforma = new TipoInstrumentoPlataforma()
    params = TipoInstrumentoPlataformaForm.formParams
    form: UntypedFormGroup
    displayTipoEdit: boolean = true
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Agregar alias de tipo de instrumento por plataforma"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    plataformas: Plataforma[]
    plataformaId: number
    tipoInstrumentos: TipoInstrumento[]
    tipoInstrumentoId: number

    constructor(
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = TipoInstrumentoPlataformaForm.getForm(this.tipoInstrumentoPlataforma)
        this.loadingLayout.ready()
    }

    ngOnDestroy() {}

    save() {
        TipoInstrumentoPlataformaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.tipoInstrumentoPlataformasService.save(this.tipoInstrumentoPlataforma).then(response => {
                this.router.navigate(["tipo_instrumento_plataformas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.tipoInstrumentoPlataforma = new TipoInstrumentoPlataforma()
            TipoInstrumentoPlataformaForm.markFormControlsAsPristine(this.form)
            TipoInstrumentoPlataformaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
