<!-- <nav class="navbar navbar-default" *ngIf = 'displayNavbar'>
    <button *ngIf='displayBtnAll' (click)="all()" class="btn btn-sm btn-default pull-left">
        <span class="glyphicon glyphicon-list" aria-hidden="true"></span> Planes de Estudio
    </button>

    <button *ngIf='displayBtnNew' (click)="new()" class="btn btn-sm btn-default pull-right">
        <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span> Agregar Plan
    </button>

    <button *ngIf='displayBtnEdit' (click)="edit(planEstudio.id)" class="btn btn-sm btn-default pull-right margin-right">
        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span> Editar Plan
    </button>

    <button *ngIf='displayBtnSesiones' (click)="sesiones(planEstudio.id)" class="btn btn-sm btn-default pull-right margin-right">
        <span class="glyphicon glyphicon-list" aria-hidden="true"></span> Editar Sesiones
    </button>
</nav> -->

<toolnavbar
    resourceName="plan_estudio"
    [resource]="planEstudio"
    [displayButtons]="displayButtons"
    [displayNavbar]="displayNavbar"
>
    <button *ngIf="displayBtnSesiones" (click)="sesiones()" class="btn btn-sm btn-default pull-right margin-right">
        <span class="glyphicon glyphicon-list" aria-hidden="true"></span>
        Editar Sesiones
    </button>
</toolnavbar>
