import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { PlanEstudios } from "./plan_estudios.service"
import { PlanEstudioLoteForm } from "./plan_estudio_lotes.form"
import { PlanEstudioMaterialForm } from "./plan_estudio_materiales.form"

export class PlanEstudioSesionForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            estado: { label: "Estado", type: "hidden", visible: false },
            orden: { label: "Orden", type: "hidden", visible: false },
            sesion: { label: "Nombre", type: "text", visible: true },
            descripcion: { label: "Descripción", type: "textarea", visible: true },
            plan_estudio_id: {
                label: "Plan de Estudio",
                type: "select",
                visible: true,
                options: { class: PlanEstudios },
                validations: [Validators.required]
            },
            plan_estudio_lotes: { type: "models", class: PlanEstudioLoteForm },
            plan_estudio_materiales: { type: "models", class: PlanEstudioMaterialForm }
        }
    }
}
