import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Pregunta, PreguntaForm, Preguntas, PreguntaView } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    templateUrl: "preguntas_usuario.component.html"
})
export class PreguntasUsuarioComponent implements OnInit {
    labels = PreguntaForm.formParams
    show_params = PreguntaView.viewParams
    preguntas = []
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    persona_id: string

    constructor(
        private preguntasService: Preguntas,
        private router: Router,
        private authService: AuthService,
        private simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        //Hay que cambiar el servicio de login, para que AuthService reciba el id del usuario y lo puedo entregar aqui
        let usuario = this.authService.getUserData()
        this.persona_id = usuario.persona.id ? usuario.persona.id + "" : "1"
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", callModal: false, glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    showAll(page: number, per: number) {
        this.loadingLayout.standby()

        //Aqui la idea sería que al mandar el usuario_id, el servicio de 'all' mandara solo las preguntas de
        //ese usuario
        const params = {
            page: page,
            per: per,
            order: { by: "id", sort: "desc" },
            profesor: { id: this.persona_id },
            include: "[certificacion_recursos]"
        }
        return this.preguntasService.where(params).then((preguntas: Pregunta[], total: number) => {
            this.preguntas = preguntas
            this.loadingLayout.ready()
            return total
        })
    }

    view(pregunta: Pregunta) {
        this.router.navigate(["preguntas/" + pregunta.id])
    }

    edit(pregunta: Pregunta) {
        this.router.navigate(["preguntas/" + pregunta.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["preguntas/" + id + "/delete"])
    }

    showModalDelete(pregunta: Pregunta) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta pregunta?")
        this.simpleModalService.setModalCallback(() => this.delete(pregunta.id))
    }

    id(index, value) {
        return value.id
    }
}
