<toolnavbar
    resourceName="pregunta"
    [resource]="pregunta"
    [displayButtons]="displayButtons"
    [displayNavbar]="displayNavbar"
>
    <a *ngIf="displayBtnMine" [routerLink]="urlMisPreguntas" class="btn btn-sm btn-default margin-right pull-left">
        <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
        Mis Preguntas
    </a>
    <a [routerLink]="['/preguntas/buscar']" class="btn btn-sm btn-default pull-left">
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        Búsqueda
    </a>
</toolnavbar>
