import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"

export class PersonaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(5)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
