<logged-layout>
    <logged-layout-titulo>Bienvenid@</logged-layout-titulo>
    <logged-layout-contenido>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <quick-search-showcasebox></quick-search-showcasebox>
            </div>
        </div>
    </logged-layout-contenido>
</logged-layout>
