import { DateView, TextView } from "@puntaje/shared/core"
import { Pregunta } from "./preguntas.model"

export class LotePreguntaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            orden: { label: "Orden", type: TextView, tableVisible: true },
            pregunta: { label: "Pregunta", type: TextView, class: Pregunta },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
