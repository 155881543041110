<form class="formulario" *ngIf="true && plataformas && tipoInstrumentos">
    <div class="row">
        <div class="form-group col-md-4">
            <label>Nombre</label>
            <input
                class="form-control"
                name="generador_instrumento"
                type="text"
                [(ngModel)]="searchParams.generador_instrumento.like.generador_instrumento"
                (ngModelChange)="deleteIfUndefined(searchParams.generador_instrumento.like, 'generador_instrumento')"
            />
        </div>
        <div class="form-group col-md-4">
            <label>Alias</label>
            <input
                class="form-control"
                name="alias"
                type="text"
                [(ngModel)]="searchParams.generador_instrumento.like.alias"
                (ngModelChange)="deleteIfUndefined(searchParams.generador_instrumento.like, 'alias')"
            />
        </div>
        <div class="form-group col-md-4">
            <label>Plataforma</label>
            <select
                class="form-control"
                name="plataforma"
                [(ngModel)]="searchParams.asignatura_plataforma.plataforma_id"
                (input)="onSelectedPlataforma($any($event).target.value)"
                (ngModelChange)="deleteIfUndefined(searchParams.asignatura_plataforma, 'plataforma_id')"
            >
                <option [ngValue]="undefined">Todas</option>
                <option [value]="p.id" *ngFor="let p of plataformas">{{ p.plataforma }}</option>
            </select>
        </div>

        <div class="form-group col-md-4" *ngIf="asignaturas.length > 0">
            <label>Asignatura</label>
            <select
                class="form-control"
                name="asignatura"
                [(ngModel)]="searchParams.asignatura_plataforma.asignatura_id"
                (ngModelChange)="deleteIfUndefined(searchParams.asignatura_plataforma, 'asignatura_id')"
            >
                <option [ngValue]="undefined">Todas</option>
                <option *ngFor="let a of asignaturas" [value]="a.id">{{ a.asignatura }}</option>
            </select>
        </div>

        <div class="form-group col-md-4">
            <label for="valido">Válido</label>
            <select
                class="form-control"
                name="valido"
                [(ngModel)]="searchParams.generador_instrumento.valido"
                (ngModelChange)="deleteIfUndefined(searchParams.generador_instrumento, 'valido')"
            >
                <option [ngValue]="undefined">Todos</option>
                <option [ngValue]="true">Sí</option>
                <option [ngValue]="false">No</option>
            </select>
        </div>

        <div class="form-group col-md-4">
            <label for="multiple">Múltiple</label>
            <select
                class="form-control"
                name="multiple"
                [(ngModel)]="searchParams.generador_instrumento.multiple"
                (ngModelChange)="deleteIfUndefined(searchParams.generador_instrumento, 'multiple')"
            >
                <option [ngValue]="undefined">Todos</option>
                <option [ngValue]="true">Sí</option>
                <option [ngValue]="false">No</option>
            </select>
        </div>

        <div class="form-group col-md-4">
            <label for="visible">Visible</label>
            <select
                class="form-control"
                name="visible"
                [(ngModel)]="searchParams.generador_instrumento.visible"
                (ngModelChange)="deleteIfUndefined(searchParams.generador_instrumento, 'visible')"
            >
                <option [ngValue]="undefined">Todos</option>
                <option [ngValue]="true">Sí</option>
                <option [ngValue]="false">No</option>
            </select>
        </div>

        <div class="form-group col-md-4">
            <label for="grupal">Grupal</label>
            <select
                class="form-control"
                name="grupal"
                [(ngModel)]="searchParams.generador_instrumento.grupal"
                (ngModelChange)="deleteIfUndefined(searchParams.generador_instrumento, 'grupal')"
            >
                <option [ngValue]="undefined">Todos</option>
                <option [ngValue]="true">Sí</option>
                <option [ngValue]="false">No</option>
            </select>
        </div>

        <div class="form-group col-md-4">
            <label>Tipo Instrumento</label>
            <select
                class="form-control"
                name="tipo_instrumento"
                [(ngModel)]="searchParams.tipo_instrumento.id"
                (ngModelChange)="deleteIfUndefined(searchParams.tipo_instrumento, 'id')"
            >
                <option [ngValue]="undefined">Todas</option>
                <option [value]="ti.id" *ngFor="let ti of tipoInstrumentos">{{ ti.tipo_instrumento }}</option>
            </select>
        </div>
    </div>
    <button class="btn btn-default" (click)="search()">Buscar</button>
</form>
