import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { generadorInstrumentosRouting } from "./generador-instrumentos.routing"
import { GeneradorInstrumentosComponent } from "./generador-instrumentos/generador-instrumentos.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { UtilModule, FormModule, PaginatorModule, ModelViewModule } from "@puntaje/shared/core"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { GeneradorInstrumentosBuscadorService } from "./generador_instrumentos_buscador.service"
import { GeneradorInstrumentoComponent } from "./generador-instrumento/generador-instrumento.component"
import { GeneradorInstrumentosNewComponent } from "./generador-instrumentos-new/generador-instrumentos-new.component"
import { GeneradorInstrumentosToolNavbarComponent } from "./generador-instrumentos-toolnavbar/generador-instrumentos-toolnavbar.component"
import { GeneradorInstrumentosEditComponent } from "./generador-instrumentos-edit/generador-instrumentos-edit.component"
import { GeneradorInstrumentosCopyComponent } from "./generador-instrumentos-copy/generador-instrumentos-copy.component"
import { GeneradorInstrumentosBuscadorComponent } from "./generador-instrumentos-buscador/generador-instrumentos-buscador.component"
import { AsociarGeneradorDiagnosticoComponent } from "./asociar-generador-diagnostico/asociar-generador-diagnostico.component"

@NgModule({
    declarations: [
        GeneradorInstrumentosComponent,
        GeneradorInstrumentoComponent,
        GeneradorInstrumentosNewComponent,
        GeneradorInstrumentosToolNavbarComponent,
        GeneradorInstrumentosEditComponent,
        GeneradorInstrumentosCopyComponent,
        GeneradorInstrumentosBuscadorComponent,
        AsociarGeneradorDiagnosticoComponent
    ],
    imports: [
        CommonModule,
        generadorInstrumentosRouting,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        PaginatorModule,
        ModelViewModule,
        NebuModule
    ],
    providers: [GeneradorInstrumentosBuscadorService]
})
export class GeneradorInstrumentosModule {}
