import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Ayuda,
    AyudaForm,
    AyudaPlataforma,
    Ayudas,
    Plataforma,
    Plataformas,
    TipoMaterial
} from "@puntaje/nebulosa/api-services"
import { AuthService, S3, S3Service } from "@puntaje/shared/core"

@Component({
    templateUrl: "ayudas.form.component.html",
    styleUrls: ["ayudas.form.component.scss"]
})
export class AyudasNewComponent implements OnInit {
    params = AyudaForm.formParams
    ayuda = new Ayuda()
    s3_model = new S3()
    form: UntypedFormGroup
    logged_layout_title = "Agregar ayuda"
    display_buttons_options = "all"
    clear_button_text = "Borrar todo"
    policy: String
    s3signature: String
    file: File
    tipoMaterial = TipoMaterial
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    plataformas: Plataforma[]
    enablePreview: boolean = true
    saving: boolean = false
    plataformas_seleccionadas: number[]

    constructor(
        private ayudaService: Ayudas,
        public s3Service: S3Service,
        private router: Router,
        private plataformasService: Plataformas,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.form = AyudaForm.getForm(this.ayuda)
        this.loadingLayout.ready()
        this.plataformas_seleccionadas = []
    }

    save() {
        AyudaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.saving = true
            //Borrando enlace o contenido según tipo
            if (this.ayuda.material_tipo_id == 4) {
                delete this.ayuda.url
            } else {
                delete this.ayuda.contenido
            }

            if (this.ayuda.material_tipo_id == 1) {
                this.s3Service.where(this.ayuda.getS3Params()).then((policies: S3[]) => {
                    let policy = policies as any as S3
                    this.ayuda.url = policy["key"]
                    this.s3Service.uploadToS3(policy, this.ayuda.file, this.ayudaService.tableName, this.saveAyuda)
                })
            } else {
                this.saveAyuda()
            }
        }
    }

    clear() {
        this.enablePreview = false
        setTimeout(() => {
            this.ayuda = new Ayuda()
            AyudaForm.markFormControlsAsPristine(this.form)
            AyudaForm.markFormControlsAsUntouched(this.form)
            this.enablePreview = true
        }, 150)
    }

    checkTipoAyuda() {
        let tipo = this.form.controls["material_tipo_id"].value
        if (tipo !== "" && tipo !== undefined) {
            return tipo
        }
        return this.tipoMaterial.FILE
    }

    saveAyuda = () => {
        this.ayudaService.save(this.ayuda).then((response: Ayuda) => {
            this.saving = false
            this.router.navigate(["ayudas/" + response.id])
        })
    }

    actualizarPlataformas(event) {
        this.ayuda.ayuda_plataformas = []
        if (event && Array.isArray(event) && event.length > 0) {
            let list = []
            for (let plataforma of event) {
                let ayuda_plataforma = new AyudaPlataforma()
                ayuda_plataforma.plataforma_id = plataforma
                this.ayuda.ayuda_plataformas.push(ayuda_plataforma)
            }
        }
    }

    updateTipoAyuda() {}

    reloadPreview() {
        this.enablePreview = false
        setTimeout(() => {
            this.enablePreview = true
        }, 150)
    }

    isSaving() {
        return this.saving
    }
}
