import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { LotePregunta, LotePreguntas, LotePreguntaView } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "lote_preguntas.component.html",
    styleUrls: ["lote_preguntas.component.scss"],
    selector: "lote-preguntas"
})
export class LotePreguntasComponent implements OnInit {
    @Input("lote_preguntas") lote_preguntas: LotePregunta[]
    show_params = LotePreguntaView.viewParams
    labels: any[]
    constructor(private lotePreguntasService: LotePreguntas, private router: Router) {}

    ngOnInit() {
        //this.labels = LotePregunta.recursiveLabels(this.show_params);
    }

    keys(lote_pregunta: LotePregunta): Array<string> {
        return Object.keys(lote_pregunta)
    }

    id(index, value) {
        return value.id
    }
}
