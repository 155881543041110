<logged-layout>
    <logged-layout-toolnavbar>
        <reporte-toolnavbar [displayButtons]="'search'"></reporte-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Reportes Agrupados</logged-layout-titulo>
    <logged-layout-contenido>
        <reportes-buscador [withAsignatura]=true></reportes-buscador>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="reportes"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>

            <paginator [getList]="showAll" [useQueryParams]="true"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
