import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { HomeComponent } from "./home.component"

export const landingRoutes: Routes = [
    { path: "landing", component: HomeComponent, canActivate: [AuthGuard] },
    { path: "landing/home", component: HomeComponent, canActivate: [AuthGuard] },
    { path: "home", redirectTo: "landing/home", pathMatch: "full" }
]

export const landingRouting = RouterModule.forChild(landingRoutes)
