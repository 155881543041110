import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Reporte, ReporteRazon, ReporteRazones, Reportes, ReporteView } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    selector: "embed-reportes",
    templateUrl: "embed_reportes.component.html",
    styleUrls: ["embed_reportes.component.scss"]
})
export class EmbedReportesComponent implements OnInit, OnDestroy {
    @Input() entidadId: number
    @Input() tabla: string
    reportes: Reporte[]
    show_params = ReporteView.entidadParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("paginator", { static: true }) paginator: PaginatorComponent
    reporteRazon: ReporteRazon
    @Input() enableAdvancedOptions: boolean = false
    toggleAdvancedOptions: boolean = false
    sub: Subscription
    razones: ReporteRazon[]
    reporteRazonSelected: ReporteRazon
    @Input() selectable = false
    selectedReportes: number[]

    constructor(
        private reportesService: Reportes,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected reporteRazonesService: ReporteRazones
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            resolve: {
                function: this.showModalResolve.bind(this),
                text: "Resolver",
                callModal: true,
                glyphicon: "glyphicon-ok",
                permisoResolver: _ => "Reportes#update",
                showIf: reporte => !this.checkIfResolved(reporte)
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        //this.showAll(1,10);
        if (this.enableAdvancedOptions) this.setAdvancedOptions()
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }

    showAll = (page: number, count: number) => {
        this.loadingLayout.standby()
        return this.reportesService
            .where({ reporte: { entidad_id: this.entidadId, tabla: this.tabla }, page: page, per: count })
            .then((reportes: Reporte[], total: number) => {
                const reportes_nuevo: Reporte[] = []
                reportes.forEach(reporte => {
                    const estado = reporte.estado_reporte.estado
                    if (estado === "Pendiente" || estado === "Resuelto") {
                        reportes_nuevo.push(reporte)
                    }
                })
                this.reportes = reportes_nuevo
                this.loadingLayout.ready()
                return total
            })
    }

    view(reporte: Reporte) {
        this.router.navigate(["reportes/" + reporte.id])
    }

    resolve(reporte: Reporte) {
        reporte.estado_reporte_id = 2 //Resuelto
        this.reportesService.update(reporte.id, reporte).then((response: Reporte) => {
            this.paginator.reload()
        })
    }

    delete(id: number) {
        this.reportesService.remove(id).then(response => {
            this.paginator.reload()
        })
    }

    showModalResolve(reporte: Reporte) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea marcar el reporte como resuelto?")
        this.simpleModalService.setModalCallback(() => this.resolve(reporte))
    }

    showModalDelete(reporte: Reporte) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar el reporte?")
        this.simpleModalService.setModalCallback(() => this.delete(reporte.id))
    }

    setAdvancedOptions() {
        this.sub = this.reporteRazonesService.reporteRazonesSubject.subscribe(reporteRazonesByTabla => {
            this.razones = reporteRazonesByTabla[this.tabla]
            if (!this.razones || this.razones.length == 0) {
                this.reporteRazonesService.loadReporteRazones()
            }
        })
    }

    showModalResolveAll() {
        if (this.reporteRazonSelected) {
            this.simpleModalService.setModalHeader("Confirmación")
            this.simpleModalService.setModalStringContent(
                "¿Está seguro que desea marcar todos los reportes de tipo '" +
                    this.reporteRazonSelected.razon +
                    "'' como resueltos?"
            )
            this.simpleModalService.setModalCallback(() => this.resolveAllWithType())
            this.simpleModalService.showSimpleModal()
        }
    }

    resolveAllWithType() {
        if (this.reporteRazonSelected) {
            let params = {
                reporte_target: {
                    entidad_id: this.entidadId,
                    tabla: this.tabla,
                    reporte_razon_id: this.reporteRazonSelected.id,
                    estado_reporte_id: 1
                },
                reporte: {
                    estado_reporte_id: 2
                }
            }
            this.reportesService.updateMany(params).then((response: Reporte[]) => {
                this.paginator.reload()
            })
        }
    }

    setSelected(selected) {
        this.selectedReportes = selected
    }

    solveSelected() {
        const params = {
            reporte_target: {
                id: this.selectedReportes
            },
            reporte: {
                estado_reporte_id: 2
            }
        }
        this.reportesService.updateMany(params).then((response: Reporte[]) => {
            this.paginator.reload()
        })
    }

    checkIfResolved(elem) {
        return elem?.estado_reporte_id === 2
    }
}
