<logged-layout>
    <logged-layout-titulo>Sub Administradores</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngFor="let usuario of usuarios">
                <sub-administrador-row [usuario]="usuario"></sub-administrador-row>
            </div>
            <div *ngIf="usuarios && usuarios.length == 0">
                No hay sub administradores asociados.
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
