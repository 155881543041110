import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Plataforma } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plataformas.toolnavbar.component.html",
    selector: "plataforma-toolnavbar"
})
export class PlataformasToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() plataforma: Plataforma
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/plataformas"
        this.urlNew = "/plataformas/new"
        if (this.plataforma != null) {
            this.urlEdit = "/plataformas/" + this.plataforma.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.plataforma != null) {
            this.urlEdit = "/plataformas/" + this.plataforma.id + "/edit"
        }
        super.edit()
    }
}
