import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { GeneradorInstrumentosComponent } from "./generador-instrumentos/generador-instrumentos.component"
import { GeneradorInstrumentoComponent } from "./generador-instrumento/generador-instrumento.component"
import { GeneradorInstrumentosNewComponent } from "./generador-instrumentos-new/generador-instrumentos-new.component"
import { GeneradorInstrumentosEditComponent } from "./generador-instrumentos-edit/generador-instrumentos-edit.component"
import { GeneradorInstrumentosCopyComponent } from "./generador-instrumentos-copy/generador-instrumentos-copy.component"
import { AsociarGeneradorDiagnosticoComponent } from "./asociar-generador-diagnostico/asociar-generador-diagnostico.component"

export const generadorInstrumentosRoutes: Routes = [
    {
        path: "generador_instrumentos",
        component: GeneradorInstrumentosComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GeneradorInstrumentos#index" }
    },
    {
        path: "generador_instrumentos/new",
        component: GeneradorInstrumentosNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GeneradorInstrumentos#create" }
    },
    {
        path: "generador_instrumentos/:id",
        component: GeneradorInstrumentoComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GeneradorInstrumentos#show" }
    },
    {
        path: "generador_instrumentos/:id/edit",
        component: GeneradorInstrumentosEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GeneradorInstrumentos#update" }
    },
    {
        path: "generador_instrumentos/:id/copy",
        component: GeneradorInstrumentosCopyComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GeneradorInstrumentos#create" }
    },
    {
        path: "generador_instrumentos/:id/asociar_generador_diagnostico",
        component: AsociarGeneradorDiagnosticoComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GeneradorInstrumentoConversiones#create" }
    }
]

export const generadorInstrumentosRouting = RouterModule.forChild(generadorInstrumentosRoutes)
