import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Usuario, UsuarioUsuarios } from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { SimpleModalService } from "../../../layouts/simple_modal/simple_modal.service"

@Component({
    selector: "usuarios-sub-administrador",
    templateUrl: "./usuarios-sub-administrador.component.html",
    styleUrls: ["./usuarios-sub-administrador.component.scss"]
})
export class UsuariosSubAdministradorComponent implements OnInit {
    @Input() usuarios: Usuario[]
    @Input() usuarioPadre: Usuario
    @Output() desasociado = new EventEmitter()
    usuariosSliced: Usuario[] = []

    constructor(
        protected route: Router,
        protected usuarioUsuariosService: UsuarioUsuarios,
        private simpleModalService: SimpleModalService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {}

    setData = (page: number, per: number) => {
        return new Promise<number>((resolve, reject) => {
            this.usuariosSliced = this.usuarios.slice((page - 1) * per, page * per)

            this.cdr.detectChanges()

            resolve(this.usuarios.length)
        })
    }

    ver(usuario) {
        this.route.navigate(["/usuarios", usuario.id])
    }

    desasociar(usuario) {
        const params = {
            usuario_usuario: {
                usuario_ref_id: this.usuarioPadre.id,
                usuario_id: usuario.id
            }
        }

        this.usuarioUsuariosService.where(params).then(usuarioUsuarios => {
            const promises = usuarioUsuarios.map(uu => {
                return this.usuarioUsuariosService.remove(uu.id)
            })

            Promise.all(promises).then(_ => {
                this.desasociado.emit()
            })
        })
    }

    showModalDesasociar(usuario: Usuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea desasociar al usuario " + usuario.persona + "?"
        )
        this.simpleModalService.setModalCallback(() => this.desasociar(usuario))
        this.simpleModalService.showSimpleModal()
    }
}
