<logged-layout>
    <logged-layout-titulo>Mis usuarios asociados</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <usuarios-sub-administrador
                *ngIf="usuario"
                [usuarios]="usuario.usuarios_hijos"
                [usuarioPadre]="usuario"
                (desasociado)="reloadUsuarios()"
            ></usuarios-sub-administrador>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
