import { Directive, Input, TemplateRef, ViewContainerRef, Injector } from "@angular/core"
import { BasePolicyService } from "./base-policy.service"
import { servicios } from "./tabla-servicios"

@Directive({
    selector: "[permiso]"
})
export class PermisoDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private injector: Injector
    ) {}

    private hasView = false

    toggleView(hasPermiso: boolean) {
        if (hasPermiso && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef)
            this.hasView = true
        } else if (!hasPermiso && this.hasView) {
            this.viewContainer.clear()
            this.hasView = false
        }
    }

    @Input() resource
    @Input() resourceId

    // permisoStro de la forma 'service#method' ej Asignaturas#index
    @Input() set permiso(permisoStr: string) {
        let [resource, method] = permisoStr.split("#")
        let service, resourceName
        if (servicios[resource]) {
            service = this.injector.get(servicios[resource].policy)
            resourceName = service.resourceName
        } else {
            service = this.injector.get(BasePolicyService)
            resourceName = resource
        }
        const boolOrPromise = service[method]({ resourceName, resource: this.resource, resourceId: this.resourceId })
        if (boolOrPromise instanceof Promise) {
            boolOrPromise.then(hasPermiso => {
                // console.log(permisoStr, boolOrPromise)
                this.toggleView(hasPermiso)
            })
        } else {
            // console.log(permisoStr, boolOrPromise)
            this.toggleView(boolOrPromise)
        }
    }
}
