<div class="row">
    <h3>Recursos Asociados</h3>
    <ul class="nav nav-tabs">
        <li class="active">
            <a href="#tab1" role="tab" data-toggle="tab" (click)="reset('Pregunta')">
                <span class="tab_text">Preguntas</span>
            </a>
        </li>
        <li>
            <a href="#tab2" role="tab" data-toggle="tab" (click)="reset('GrupoPregunta')">
                <span class="tab_text">Grupo de Preguntas</span>
            </a>
        </li>
        <li>
            <a href="#tab3" role="tab" data-toggle="tab" (click)="reset('Lote')">
                <span class="tab_text">Lotes</span>
            </a>
        </li>
        <li>
            <a href="#tab4" role="tab" data-toggle="tab" (click)="reset('PlanEstudio')">
                <span class="tab_text">Planes de estudio</span>
            </a>
        </li>
        <li>
            <a href="#tab5" role="tab" data-toggle="tab" (click)="reset('Material')">
                <span class="tab_text">Materiales</span>
            </a>
        </li>
    </ul>
</div>

<div class="tab-content">
    <!-- Preguntas -->
    <div id="tab1" class="active tab-pane clearfix" #tabContent>
        <div *ngIf="showRecursosAsociados.Pregunta">
            <div class="row">
                <button class="btn buttonAsociarRecursos" (click)="goAsociar('Pregunta', 'a', 'preguntas')">
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar preguntas
                </button>
                <button
                    class="btn buttonAsociarRecursos"
                    (click)="goAsociarClasificaciones('Pregunta', 'a', 'preguntas')"
                >
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Clasificaciones
                </button>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="usuario">
                    <recursos-asociados [usuario]="usuario" [recurso]="'Pregunta'"></recursos-asociados>
                </div>
            </div>
        </div>
        <div *ngIf="showAsociarRecursos.Pregunta">
            <asociar-recursos-ids
                [usuario]="usuario"
                [recurso]="recurso"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-recursos-ids>
        </div>
        <div *ngIf="showAsociarClasificaciones.Pregunta">
            <asociar-clasificaciones
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-clasificaciones>
        </div>
    </div>

    <!-- Grupo de Preguntas -->
    <div id="tab2" class="tab-pane" #tabContent>
        <div *ngIf="showRecursosAsociados.GrupoPregunta">
            <div class="row">
                <button
                    class="btn buttonAsociarRecursos"
                    (click)="goAsociar('GrupoPregunta', 'a', 'Grupo de preguntas')"
                >
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Grupo de Preguntas
                </button>
                <button
                    class="btn buttonAsociarRecursos"
                    (click)="goAsociarClasificaciones('GrupoPregunta', 'a', 'Grupo de preguntas')"
                >
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Clasificaciones
                </button>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="usuario">
                    <recursos-asociados [usuario]="usuario" [recurso]="'GrupoPregunta'"></recursos-asociados>
                </div>
            </div>
        </div>
        <div *ngIf="showAsociarRecursos.GrupoPregunta">
            <asociar-recursos-ids
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-recursos-ids>
        </div>
        <div *ngIf="showAsociarClasificaciones.GrupoPregunta">
            <asociar-clasificaciones
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-clasificaciones>
        </div>
    </div>

    <!-- Lotes -->
    <div id="tab3" class="tab-pane" #tabContent>
        <div *ngIf="showRecursosAsociados.Lote">
            <div class="row">
                <button class="btn buttonAsociarRecursos" (click)="goAsociar('Lote', 'o', 'Lotes')">
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Lotes
                </button>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="usuario">
                    <recursos-asociados [usuario]="usuario" [recurso]="'Lote'"></recursos-asociados>
                </div>
            </div>
        </div>
        <div *ngIf="showAsociarRecursos.Lote">
            <asociar-recursos-ids
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-recursos-ids>
        </div>
        <div *ngIf="showAsociarClasificaciones.Lote">
            <asociar-clasificaciones
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-clasificaciones>
        </div>
    </div>

    <!-- Planes de estudio -->
    <div id="tab4" class="tab-pane" #tabContent>
        <div *ngIf="showRecursosAsociados.PlanEstudio">
            <div class="row">
                <button class="btn buttonAsociarRecursos" (click)="goAsociar('PlanEstudio', 'o', 'Planes de estudio')">
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Planes de estudio
                </button>
            </div>
            <div class="col-md-12" *ngIf="usuario">
                <recursos-asociados [usuario]="usuario" [recurso]="'PlanEstudio'"></recursos-asociados>
            </div>
        </div>
        <div *ngIf="showAsociarRecursos.PlanEstudio">
            <asociar-recursos-ids
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-recursos-ids>
        </div>
    </div>

    <!-- Materiales -->
    <div id="tab5" class="tab-pane" #tabContent>
        <div *ngIf="showRecursosAsociados.Material">
            <div class="row">
                <button class="btn buttonAsociarRecursos" (click)="goAsociar('Material', 'o', 'Materiales')">
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Material
                </button>
                <button
                    class="btn buttonAsociarRecursos"
                    (click)="goAsociarClasificaciones('Material', 'o', 'materiales')"
                >
                    <fa name="plus-circle" class="icon"></fa>
                    Asociar Clasificaciones
                </button>
            </div>
            <div class="col-md-12" *ngIf="usuario">
                <recursos-asociados [usuario]="usuario" [recurso]="'Material'"></recursos-asociados>
            </div>
        </div>
        <div *ngIf="showAsociarRecursos.Material">
            <asociar-recursos-ids
                [recurso]="recurso"
                [usuario]="usuario"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-recursos-ids>
        </div>
        <div *ngIf="showAsociarClasificaciones.Material">
            <asociar-clasificaciones
                [usuario]="usuario"
                [recurso]="recurso"
                (changeView)="goToShowRecursosAsociados($event)"
            ></asociar-clasificaciones>
        </div>
    </div>
</div>
