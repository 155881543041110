import { BooleanView, DateView, LinkOrDownloadView, LinkView, TextView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { Materiales } from "./materiales.service"
import { MaterialTipos } from "./material_tipos.service"
import { Personas } from "./personas.service"

export class MaterialView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            material_tipo_nombre: {
                label: "Tipo de Material",
                type: TextView,
                tableVisible: true,
                options: { class: MaterialTipos, key: "material_tipo_id" }
            },
            nombre_asignatura: {
                label: "Asignatura",
                type: LinkView,
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id" }
            },
            material: { label: "Nombre material", type: TextView, tableVisible: true },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            comentarios: { label: "Comentarios", type: TextView, tableVisible: true },
            download: { label: "Link", type: LinkOrDownloadView, tableVisible: true, options: { class: Materiales } },
            profesor_id: {
                label: "Profesor",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "profesor_id" }
            },
            cantidad_reportes: { label: "Cantidad Reportes", type: LinkView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
