import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { GrupoPregunta, GrupoPreguntas, GrupoPreguntaView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    selector: "grupo_preguntas",

    templateUrl: "grupo_preguntas.component.html"
})
export class GrupoPreguntasComponent implements OnInit {
    /*  grupo_preguntas: GrupoPregunta[]; */
    show_params = GrupoPreguntaView.viewParams
    tableButtonsArray: any
    @Input() contentArray: GrupoPregunta[]
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        private grupo_preguntasService: GrupoPreguntas,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    /*   showAll(page: number, per: number) {
    return this.grupo_preguntasService.where({"page":page, "per":per}).then((grupo_preguntas: GrupoPregunta[], total: number) => {
      this.grupo_preguntas = grupo_preguntas;
      this.loadingLayout.ready();
      return total;
    });
  }
 */
    view(grupo_pregunta: GrupoPregunta) {
        this.router.navigate(["grupo_preguntas/" + grupo_pregunta.id])
    }

    edit(grupo_pregunta: GrupoPregunta) {
        this.router.navigate(["grupo_preguntas/" + grupo_pregunta.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["grupo_preguntas/" + id + "/delete"])
    }

    showModalDelete(grupo_pregunta: GrupoPregunta) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar la plataforma '" + grupo_pregunta.id + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(grupo_pregunta.id))
    }
}
