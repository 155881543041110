import { BaseForm, Validations } from "@puntaje/shared/core"

export class ArchivoForm extends BaseForm {
    public static get formParams(): any {
        return {
            file: {
                label: "Archivo",
                type: "file",
                visible: true,
                validations: [Validations.validateEditarMasivamente]
            }
        }
    }
}
