import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { MyDatePickerModule } from "mydatepicker"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FacebookService } from "@ogr-sa/ngx-facebook"
import {
    AuthModule,
    BaseModule,
    ErroresModule,
    FlashMessageModule,
    FormModule,
    Loader,
    LoaderModule,
    TranslationsModule,
    UtilModule
} from "@puntaje/shared/core"
import { AppComponent } from "./app.component"
import { routing } from "./app.routing"
import { DragulaModule } from "ng2-dragula"
import { LayoutsModule } from "./layouts/layouts.module"
import { AsignaturasModule } from "./modules/asignaturas/asignaturas.module"
import { AyudasModule } from "./modules/ayudas/ayudas.module"
import { ClasificacionesModule } from "./modules/clasificaciones/clasificaciones.module"
import { ClasificacionTiposModule } from "./modules/clasificacion_tipos/clasificacion_tipos.module"
import { ClasificacionTipoAliasesModule } from "./modules/clasificacion_tipos/clasificacion_tipo_aliases/clasificacion_tipo_aliases.module"
import { GrupoPreguntasModule } from "./modules/grupo_preguntas/grupo_preguntas.module"
import { LandingModule } from "./modules/landing/landing.module"
import { LotesModule } from "./modules/lotes/lotes.module"
import { MaterialesModule } from "./modules/materiales/materiales.module"
import { MonitorRecursosModule } from "./modules/monitor_recursos/monitor_recursos.module"
import { PerfilesModule } from "./modules/perfiles/perfiles.module"
import { PersonasModule } from "./modules/personas/personas.module"
import { PiedraRosettaModule } from "./modules/piedra_rosetta/piedra_rosetta.module"
import { PlanEstudiosModule } from "./modules/plan_estudios/plan_estudios.module"
import { PlataformasModule } from "./modules/plataformas/plataformas.module"
import { PreguntasModule } from "./modules/preguntas/preguntas.module"
import { ReportesModule } from "./modules/reportes/reportes.module"
import { ReporteRazonesModule } from "./modules/reporte_razones/reporte_razones.module"
import { ReporteSubRazonesModule } from "./modules/reporte_sub_razones/reporte_sub_razones.module"
import { UsuariosModule } from "./modules/usuarios/usuarios.module"
import { UsuarioRecursosModule } from "./modules/usuario_recursos/usuario_recursos.module"
import { CertificacionRecursosModule } from "./modules/certificacion_recursos/certificacion_recursos.module"
import { RevisionesRecursoModule } from "./modules/revisiones-recurso/revisiones-recurso.module"

// ngx-bootstrap
import {
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDaterangepickerConfig,
    DatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    ProgressbarModule,
    RatingModule,
    SortableModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule
} from "ngx-bootstrap"
import { NebuLoader } from "./modules/nebu-loader/nebu-loader.service"
import { SharedModule } from "./shared/shared.module"
import { GeneradorInstrumentosModule } from "./modules/generador-instrumentos/generador-instrumentos.module"
import { getProviders } from "@puntaje/nebulosa/core"
import { config } from "../config/config"
import { environment } from "../environments/environment"
import { TipoInstrumentoPlataformasModule } from "./modules/tipo_instrumento_plataformas/tipo_instrumento_plataformas.module"

export function configFactory(loader: NebuLoader) {
    return () => loader.loadConfig()
}

@NgModule({
    imports: [
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        SortableModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),

        DragulaModule.forRoot(),

        BrowserModule,
        Angular2FontawesomeModule,
        LandingModule,
        UsuariosModule.forRoot(),
        PersonasModule.forRoot(),
        PreguntasModule,
        MaterialesModule.forRoot(),
        LotesModule,
        AsignaturasModule,
        AyudasModule.forRoot(),
        LoaderModule.forRoot(),
        AuthModule.forRoot({}),
        BaseModule.forRoot(),
        ErroresModule.forRoot(),
        NebuModule.forRoot(),
        FormModule,
        ReporteRazonesModule,
        ReporteSubRazonesModule,
        ReportesModule.forRoot(),
        ClasificacionTipoAliasesModule.forRoot(),
        ClasificacionTiposModule.forRoot(),
        ClasificacionesModule.forRoot(),
        GrupoPreguntasModule,
        PlanEstudiosModule,
        PerfilesModule,
        PlataformasModule,
        LayoutsModule.forRoot(),
        UtilModule.forRoot(),
        FlashMessageModule.forRoot(),
        DatepickerModule,
        TimepickerModule,
        MyDatePickerModule,
        PiedraRosettaModule,
        MonitorRecursosModule,
        SharedModule,
        UsuarioRecursosModule,
        CertificacionRecursosModule,
        RevisionesRecursoModule,
        GeneradorInstrumentosModule,
        TipoInstrumentoPlataformasModule.forRoot(),
        TranslationsModule.forRoot(() => ({})),
        routing
    ],
    declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [NebuLoader],
            multi: true
        },
        getProviders(config, environment),
        FacebookService
    ]
})
export class AppModule {}
