import { Injectable } from "@angular/core"

@Injectable({
    providedIn: "root"
})
export class PermisosService {
    permisos: string[]

    constructor() {}

    setPermisos(permisos: string[]) {
        this.permisos = permisos
    }

    getPermisosFromStorage() {
        const permisos = localStorage.getItem("permisos")
        let parsedPermisos
        try {
            parsedPermisos = permisos ? JSON.parse(permisos) : null
        } catch (e) {
            // Si hubo un error de parse por otras versiones de los permisos, entonces no hay permisos
        }

        return parsedPermisos
    }

    setPermisosToStorage(permisos: string[]) {
        localStorage.setItem("permisos", JSON.stringify(permisos))
    }
}
