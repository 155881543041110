/** @format */

import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PopoverModule } from "ngx-bootstrap"
import { FilterModule, FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { routing } from "./tipo_instrumento_plataformas.routing"
import { TipoInstrumentoPlataformasComponent } from "./tipo_instrumento_plataformas.component"
import { TipoInstrumentoPlataformasListComponent } from "./tipo-instrumeto-plataformas-list/tipo-instrumento-plataformas-list.component"
import { TipoInstrumentoPlataformasToolNavbarComponent } from "./tipo_instrumento_plataformas.toolnavbar.component"
import { TipoInstrumentoPlataformasBuscadorComponent } from "./tipo_instrumento_plataformas_buscador.component"
import { TipoInstrumentoPlataformasBuscadorService } from "./tipo_instrumento_plataformas_buscador.service"
import { TipoInstrumentoPlataformaComponent } from "./tipo_instrumento_plataforma.component"
import { TipoInstrumentoPlataformasNewComponent } from "./tipo_instrumento_plataformas.new.component"
import { TipoInstrumentoPlataformasEditComponent } from "./tipo_instrumento_plataformas.edit.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        FilterModule,
        PaginatorModule,
        Angular2FontawesomeModule,
        PopoverModule,
        NebuModule,
        routing
    ],
    declarations: [
        TipoInstrumentoPlataformasListComponent,
        TipoInstrumentoPlataformasComponent,
        TipoInstrumentoPlataformasToolNavbarComponent,
        TipoInstrumentoPlataformasBuscadorComponent,
        TipoInstrumentoPlataformaComponent,
        TipoInstrumentoPlataformasNewComponent,
        TipoInstrumentoPlataformasEditComponent
    ],
    exports: [
        TipoInstrumentoPlataformasListComponent,
        TipoInstrumentoPlataformasToolNavbarComponent,
        TipoInstrumentoPlataformasBuscadorComponent,
        TipoInstrumentoPlataformaComponent,
        TipoInstrumentoPlataformasNewComponent,
        TipoInstrumentoPlataformasEditComponent
    ]
})
export class TipoInstrumentoPlataformasModule {
    static forRoot(): ModuleWithProviders<TipoInstrumentoPlataformasModule> {
        return {
            ngModule: TipoInstrumentoPlataformasModule,
            providers: [TipoInstrumentoPlataformasBuscadorService]
        }
    }
}
