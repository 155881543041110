import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { PlanEstudio, PlanEstudioForm, PlanEstudios } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "plan_estudios.form.component.html"
})
export class PlanEstudiosEditComponent implements OnInit, OnDestroy {
    oPlanEstudio: PlanEstudio = new PlanEstudio()
    planEstudio: PlanEstudio = new PlanEstudio()
    params = PlanEstudioForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar plan de estudio"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private planEstudiosService: PlanEstudios,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = PlanEstudioForm.getForm(this.planEstudio)
        this.sub = this.route.params.subscribe(params => {
            this.planEstudiosService.find(+params["id"]).then((response: PlanEstudio) => {
                this.planEstudio = response
                this.oPlanEstudio = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        PlanEstudioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.planEstudiosService.update(this.planEstudio.id, this.planEstudio).then(response => {
                this.router.navigate(["plan_estudios"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.planEstudio = this.oPlanEstudio.clone()
            PlanEstudioForm.markFormControlsAsPristine(this.form)
            PlanEstudioForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
