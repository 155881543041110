<form *ngIf="open">
    <div class="flex-container">
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Nombre"
                (keyup.enter)="search()"
                name="nombre"
                type="text"
                [(ngModel)]="searchParams.persona.like.nombre"
                (ngModelChange)="deleteIfUndefined(searchParams.persona.like, 'nombre')"
            />
        </div>
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Apellido paterno"
                (keyup.enter)="search()"
                name="apellido_paterno"
                type="text"
                [(ngModel)]="searchParams.persona.like.apellido_paterno"
                (ngModelChange)="deleteIfUndefined(searchParams.persona.like, 'apellido_paterno')"
            />
        </div>
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Apellido materno"
                (keyup.enter)="search()"
                name="apellido_materno"
                type="text"
                [(ngModel)]="searchParams.persona.like.apellido_materno"
                (ngModelChange)="deleteIfUndefined(searchParams.persona.like, 'apellido_materno')"
            />
        </div>
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Email"
                (keyup.enter)="search()"
                name="email"
                type="text"
                [(ngModel)]="searchParams.usuario.email"
                (ngModelChange)="deleteIfUndefined(searchParams.usuario, 'email')"
            />
        </div>
        <div class="form-group">
            <select
                class="form-control"
                name="perfil"
                [(ngModel)]="searchParams.perfil.id"
                (ngModelChange)="deleteIfUndefined(searchParams.perfil, 'id')"
            >
                <option [ngValue]="undefined">-- Seleccione Perfil --</option>
                <option *ngFor="let perfil of perfiles" [ngValue]="perfil.id">{{ perfil.perfil }}</option>
            </select>
        </div>
        <div class="form-group">
            <select
                class="form-control"
                name="status"
                [(ngModel)]="searchParams.usuario.status"
                (ngModelChange)="deleteIfUndefined(searchParams.usuario, 'status')"
            >
                <option [ngValue]="undefined">-- Seleccione Estado--</option>
                <option *ngFor="let s of status" [ngValue]="s.value">{{ s.label }}</option>
            </select>
        </div>
        <button type="button" class="btn btn-default" (click)="search()">
            <fa name="search"></fa>
            &nbsp;Buscar
        </button>
    </div>
</form>
