import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { GrupoPreguntas } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    template: ""
})
export class GrupoPreguntasDeleteComponent implements OnInit, OnDestroy {
    private sub: Subscription

    constructor(
        private grupo_preguntasService: GrupoPreguntas,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.grupo_preguntasService.remove(+params["id"]).then(response => {
                this.router.navigate(["grupo_preguntas"])
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
