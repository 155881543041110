import { BooleanView, DateView, HtmlView, LinkView, TextView, GrupoPreguntaTextoView } from "@puntaje/shared/core"
import { Personas } from "./personas.service"

export class GrupoPreguntaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            texto: { label: "Texto", type: GrupoPreguntaTextoView, tableVisible: true },
            imagen: { label: "Imagen", type: TextView, tableVisible: false },
            estado: { label: "Estado", type: TextView, tableVisible: false },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            texto_latex: { label: "Texto Latex", type: TextView, tableVisible: false },
            profesor: {
                label: "Profesor",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "id" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
