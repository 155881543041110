import { DateView, LinkView, TextView, BooleanView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { Clasificaciones } from "./clasificaciones.service"
import { ClasificacionTipos } from "./clasificacion_tipos.service"

export class ClasificacionView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            clasificacion: {
                label: "Clasificación",
                type: LinkView,
                tableVisible: true,
                options: { class: Clasificaciones, key: "id" }
            },
            descripcion: { label: "Descripción", type: TextView, tableVisible: false },
            clasificacion_tipo: {
                label: "Tipo",
                type: LinkView,
                tableVisible: true,
                options: { class: ClasificacionTipos, key: "clasificacion_tipo_id", modelKey: "clasificacion_tipo" }
            },
            asignatura: {
                label: "Asignatura",
                type: LinkView,
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id", modelKey: "asignatura" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true },
            medible: { label: "Medible", type: BooleanView, tableVisible: true },
            priorizado: { label: "Priorización Curricular", type: BooleanView, tableVisible: false }
        }
    }
}
