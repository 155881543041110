import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { ClasificacionTiposBuscadorService } from "../clasificacion-tipos-buscador.service"

@Component({
    selector: "clasificacion-tipos-buscador",
    templateUrl: "./clasificacion-tipos-buscador.component.html",
    styleUrls: ["./clasificacion-tipos-buscador.component.scss"],
})
export class ClasificacionTiposBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false
    searchParams: any = { clasificacionTipo: { like: {} } }

    constructor(protected clasificacionTiposBuscadorService: ClasificacionTiposBuscadorService) {}

    ngOnInit() {
        this.sub = this.clasificacionTiposBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    search() {
        this.clasificacionTiposBuscadorService.search(this.searchParams)
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
