import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import {
    GeneradorInstrumento,
    GeneradorInstrumentoConversiones,
    GeneradorInstrumentoConversion,
    GeneradorInstrumentos,
    TipoInstrumento,
    TipoInstrumentos
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    selector: "app-asociar-generador-diagnostico",
    templateUrl: "./asociar-generador-diagnostico.component.html",
    styleUrls: ["./asociar-generador-diagnostico.component.scss"]
})
export class AsociarGeneradorDiagnosticoComponent implements OnInit {
    @ViewChild(LoadingLayoutComponent) loadingLayout: LoadingLayoutComponent

    generadorInstrumentosDiagnostico: GeneradorInstrumento[]
    tipoInstrumentosDiagnostico: TipoInstrumento[]

    generadorInstrumentosDiagnosticoFiltered: GeneradorInstrumento[] = []
    generadorInstrumentoDiagnostico: GeneradorInstrumento

    generadorInstrumentoConversion: GeneradorInstrumentoConversion

    sub: Subscription
    generadorInstrumentoId: number

    mensaje: string
    count: number = 0

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected tipoInstrumentosService: TipoInstrumentos,
        protected generadorInstrumentoConversiones: GeneradorInstrumentoConversiones
    ) {}

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(routeParams => {
            this.generadorInstrumentoId = +routeParams["id"]

            const params = {
                generador_instrumento_conversion: {
                    generador_instrumento_id: this.generadorInstrumentoId
                }
            }

            this.generadorInstrumentoConversiones.where(params).then(generadorInstrumentoConversiones => {
                this.generadorInstrumentoConversion = generadorInstrumentoConversiones[0]

                this.setSavedConversiones()
            })
        })

        this.tipoInstrumentosService.where().then(tipoInstrumentos => {
            this.tipoInstrumentosDiagnostico = tipoInstrumentos.filter(
                ti => ti.tipo_instrumento.indexOf("diagnostico") > -1 || ti.tipo_instrumento.indexOf("diagnóstico") > -1
            )

            const params = {
                tipo_instrumento: {
                    id: this.tipoInstrumentosDiagnostico.map(ti => ti.id)
                },
                include: "[tipo_instrumento,asignatura_plataforma:[asignatura,plataforma]]"
            }

            this.generadorInstrumentosService.disableIgnoreModel()
            this.generadorInstrumentosService.where(params).then(generadorInstrumentos => {
                this.generadorInstrumentosDiagnostico = generadorInstrumentos
                this.generadorInstrumentosService.enableIgnoreModel()

                this.setSavedConversiones()
            })
        })
    }

    onInputText(text) {
        this.generadorInstrumentosDiagnosticoFiltered = this.generadorInstrumentosDiagnostico.filter(
            gi =>
                gi.toStringWithTipoInstrumentoWithAsignaturaPlataforma().toLowerCase().indexOf(text.toLowerCase()) > -1
        )
    }

    setSavedConversiones() {
        this.count++

        if (this.count == 2) {
            if (this.generadorInstrumentoConversion) {
                this.generadorInstrumentoDiagnostico = this.generadorInstrumentosDiagnostico.find(
                    gi => gi.id == this.generadorInstrumentoConversion.generador_instrumento_ref_id
                )
            }

            this.loadingLayout.ready()
        }
    }

    back() {
        this.router.navigate([`/generador_instrumentos`])
    }

    save() {
        if (!this.generadorInstrumentoDiagnostico) {
            this.mensaje = "Debe seleccionar un generador instrumento."

            return
        }

        const generadorInstrumentoConversion =
            this.generadorInstrumentoConversion ||
            Object.assign(new GeneradorInstrumentoConversion(), {
                generador_instrumento_id: this.generadorInstrumentoId
            })

        generadorInstrumentoConversion.generador_instrumento_ref_id = this.generadorInstrumentoDiagnostico.id

        if (generadorInstrumentoConversion.id) {
            this.generadorInstrumentoConversiones
                .update(generadorInstrumentoConversion.id, generadorInstrumentoConversion)
                .then(_ => {
                    this.back()
                })
        } else {
            this.generadorInstrumentoConversiones.save(generadorInstrumentoConversion).then(_ => {
                this.back()
            })
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
