import { Injectable } from "@angular/core"
import { PermisosAsociadosService } from "./permisos-asociados.service"
import { Opts } from "./base-policy.service"
import { GrupoPreguntas } from "@puntaje/nebulosa/api-services"

@Injectable({
    providedIn: "root"
})
export class GrupoPreguntasPolicyService extends PermisosAsociadosService {
    resourceName: string = "GrupoPreguntas"

    update({ resource, resourceId, ...rest }: Opts): boolean | Promise<boolean> {
        if (
            resource &&
            (resource as any).certificacion_recurso &&
            !this.testPermiso("GrupoPreguntas_update_certificado")
        )
            return false
        if (resourceId) {
            const params = {
                grupo_pregunta: {
                    id: resourceId
                },
                include: "[certificacion_recurso]"
            }
            return this.injector
                .get(GrupoPreguntas)
                .where(params)
                .then(grupo_preguntas => {
                    if (grupo_preguntas.length == 0) return false
                    let p = grupo_preguntas[0]
                    return !(p.certificacion_recurso && !this.testPermiso("GrupoPreguntas_update_certificado"))
                })
        }
        return (
            super.update({ resource, resourceId, ...rest }) ||
            this.permisoAsociado("update", { resource, resourceId, ...rest })
        )
    }
}
