import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { pluralize, capitalize } from "@puntaje/shared/core"
import { PermisosService } from "../../modules/policies/permisos.service"

@Component({
    selector: "toolnavbar",
    templateUrl: "./toolnavbar.component.html",
    styleUrls: ["./toolnavbar.component.scss"]
})
export class ToolnavbarComponent implements OnInit {
    displayBtnAll = false
    displayBtnNew = false
    displayBtnEdit = false

    permisoAll = "#"
    permisoNew = "#"
    permisoEdit = "#"

    urlNew = ""
    urlAll = ""
    urlEdit = ""

    @Input() displayNavbar: boolean = false
    @Input() resource: any
    @Input() resourceName: string
    @Input() displayButtons: string

    constructor(protected router: Router, protected route: ActivatedRoute, protected permisos: PermisosService) {}

    ngOnInit() {
        this.checkDisplayButtons()
        this.setPermisos()
        this.buildRoutes()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
        }
        this.displayNavbar = this.displayNavbar || this.displayBtnAll || this.displayBtnNew || this.displayBtnEdit
    }

    setPermisos() {
        //TODO mover a alguna parte global
        const camelize = (text: string, separator = "_") => {
            return text
                .split(separator)
                .map(w => w.replace(/./, m => m.toUpperCase()))
                .join("")
        }
        const baseName = camelize(capitalize(pluralize(this.resourceName)))
        this.permisoAll = `${baseName}#index`
        this.permisoNew = `${baseName}#create`
        this.permisoEdit = `${baseName}#update`
    }

    buildRoutes() {
        this.urlNew = `/${pluralize(this.resourceName)}/new`
        this.urlAll = `/${pluralize(this.resourceName)}`
        if (this.resource) this.urlEdit = `/${pluralize(this.resourceName)}/${this.resource.id}/edit`
    }
}
