<loading-layout #loadingLayout>
    <ng-container *ngIf="clasificaciones.length > 0">
        <clasificaciones-asociadas
            (grupoEliminado)="grupoEliminado($event)"
            [recurso]="recurso"
            [clasificaciones]="clasificaciones"
        ></clasificaciones-asociadas>
    </ng-container>
    <ng-container *ngIf="recurso == 'Pregunta'">
        <br />
        <div *ngIf="preguntas">
            <preguntas [preguntas]="preguntas"></preguntas>
        </div>
        <paginator [getList]="showAll.bind(this)" [useQueryParams]="true"></paginator>
    </ng-container>

    <ng-container *ngIf="recurso == 'GrupoPregunta'">
        <br />
        <div *ngIf="grupo_preguntas">
            <grupo_preguntas [contentArray]="grupo_preguntas"></grupo_preguntas>
        </div>
        <paginator [getList]="showAllGrupoPreguntas.bind(this)" [useQueryParams]="true"></paginator>
    </ng-container>

    <ng-container *ngIf="recurso == 'Lote'">
        <br />
        <div *ngIf="lotes">
            <lotes [lotes]="lotes"></lotes>
        </div>
        <paginator [getList]="showAllLote.bind(this)" [useQueryParams]="true"></paginator>
    </ng-container>

    <ng-container *ngIf="recurso == 'PlanEstudio'">
        <br />
        <div *ngIf="planEstudios">
            <plan_estudios [planEstudios]="planEstudios"></plan_estudios>
        </div>
        <paginator [getList]="showAllPlanEstudio.bind(this)" [useQueryParams]="true"></paginator>
    </ng-container>

    <ng-container *ngIf="recurso == 'Material'">
        <br />
        <div *ngIf="materiales">
            <materiales [materiales]="materiales"></materiales>
        </div>
        <paginator [getList]="showAllMaterial.bind(this)" [useQueryParams]="true"></paginator>
    </ng-container>
</loading-layout>
