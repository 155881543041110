import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    ClasificacionMaterial,
    Material,
    Materiales,
    MaterialForm,
    MaterialPlataforma,
    Plataforma,
    Plataformas,
    TipoMaterial
} from "@puntaje/nebulosa/api-services"
import { AuthService, S3, S3Service } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales.form.component.html",
    styleUrls: ["materiales.form.component.css"]
})
export class MaterialesNewComponent implements OnInit {
    params = MaterialForm.formParams
    material = new Material()
    s3_model = new S3()
    form: UntypedFormGroup
    logged_layout_title = "Agregar material"
    display_buttons_options = "all"
    clear_button_text = "Borrar todo"
    policy: String
    s3signature: String
    file: File
    tipoMaterial = TipoMaterial
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    plataformas: Plataforma[]
    enablePreview: boolean = true
    saving: boolean = false
    plataformas_seleccionadas: number[]
    canEdit: boolean = true

    constructor(
        private materialService: Materiales,
        public s3Service: S3Service,
        private router: Router,
        private plataformasService: Plataformas,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.form = MaterialForm.getForm(this.material)
        this.loadingLayout.ready()
        this.plataformas_seleccionadas = []

        const usuario = this.authService.getUserData()
        if (usuario) {
            this.material.profesor = {
                ...usuario.persona,
                toString() {
                    return usuario.persona.nombre + " " + usuario.persona.apellido_paterno
                }
            }

            this.material.profesor_id = usuario.persona.id
        }
    }

    save() {
        MaterialForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.saving = true
            //Borrando enlace o contenido según tipo
            if (this.material.material_tipo_id == 4) {
                delete this.material.url
            } else {
                delete this.material.contenido
            }
            let promiseThumbnail: Promise<any> = Promise.resolve()
            let promiseUploadable: Promise<any> = Promise.resolve()

            if (this.material.thumbnail_file) {
                promiseThumbnail = new Promise((resolve, reject) => {
                    this.s3Service.where(this.material.getS3ParamsThumb()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.material.thumbnail = policy["key"]
                        this.s3Service.uploadToS3(
                            policy,
                            this.material.thumbnail_file,
                            this.materialService.tableName,
                            resolve
                        )
                    })
                })
            }

            if (Material.uploadableToS3.includes(+this.material.material_tipo_id)) {
                promiseUploadable = new Promise((resolve, reject) => {
                    this.s3Service
                        .where(this.material.getS3Params(this.material.material_tipo_id))
                        .then((policies: S3[]) => {
                            const policy = policies as any as S3
                            this.material.url = policy["key"]
                            this.s3Service.uploadToS3(
                                policy,
                                this.material.file,
                                this.materialService.tableName,
                                resolve
                            )
                        })
                })
            }

            Promise.all([promiseThumbnail, promiseUploadable]).then(() => {
                this.saveMaterial()
            })
        }
    }

    clear() {
        this.enablePreview = false
        setTimeout(() => {
            this.material = new Material()
            MaterialForm.markFormControlsAsPristine(this.form)
            MaterialForm.markFormControlsAsUntouched(this.form)
            this.enablePreview = true
        }, 150)
    }

    checkTipoMaterial() {
        let tipo = this.form.controls["material_tipo_id"].value
        if (tipo !== "" && tipo !== undefined) {
            return tipo
        }
        return this.tipoMaterial.FILE
    }

    saveMaterial = () => {
        if (this.material.profesor) this.material.profesor_id = this.material.profesor.id

        this.materialService.save(this.material).then((response: Material) => {
            this.saving = false
            this.router.navigate(["materiales/" + response.id])
        })
    }

    actualizarClasificaciones(event) {
        this.material.clasificacion_materiales = []
        if (event && Array.isArray(event) && event.length > 0) {
            let list = []
            for (let c of event) {
                let cp = new ClasificacionMaterial()
                cp.clasificacion_id = c.id
                this.material.clasificacion_materiales.push(cp)
            }
        }
    }

    actualizarPlataformas(event) {
        this.material.material_plataformas = []
        if (event && Array.isArray(event) && event.length > 0) {
            let list = []
            for (let plataforma of event) {
                let material_plataforma = new MaterialPlataforma()
                material_plataforma.plataforma_id = plataforma
                this.material.material_plataformas.push(material_plataforma)
            }
        }
    }

    updateTipoMaterial() {}

    reloadPreview() {
        this.enablePreview = false
        setTimeout(() => {
            this.enablePreview = true
        }, 150)
    }

    isSaving() {
        return this.saving
    }
}
