import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { Subject, Subscription } from "rxjs"

@Component({
    selector: "monitor-clasificacion",
    templateUrl: "monitor_clasificacion.component.html",
    styleUrls: ["monitor_clasificacion.component.scss"]
})
export class MonitorClasificacionComponent implements OnInit, OnDestroy {
    value: boolean = true
    showHijas: boolean = false
    hijas: any[] = []
    subjectParent: Subject<any> = new Subject<any>()
    sub: Subscription
    show = false

    @Input() clasificacion: any
    @Input() subject: Subject<any>

    @Output() hijaChecked: EventEmitter<any> = new EventEmitter<any>()
    @Output() padreChange: EventEmitter<any> = new EventEmitter<any>()

    checkboxChanged() {
        this.value = !this.value
        this.subjectParent.next(this.value)
        this.hijaChecked.emit(this.clasificacionObj)
    }

    toggleHijas() {
        this.showHijas = !this.showHijas
    }

    hijaCheckedListener(value: any) {
        let pos: number = this.hijas.findIndex(h => {
            return h.id === value.id
        })
        if (pos >= 0) {
            this.hijas[pos] = value
        } else {
            this.hijas.push(value)
        }
        if (this.hijas.find(h => h.value)) {
            this.value = true
        } else {
            this.value = false
        }
        this.hijaChecked.emit(this.clasificacionObj)
    }

    ngOnInit() {
        if (this.subject) {
            this.sub = this.subject.subscribe((v: any) => {
                this.updateValue(v)
                this.subjectParent.next(v)
            })
        }
        if (this.clasificacion.clasificaciones_hijas) {
            this.hijas = this.markAll(this.clasificacion.clasificaciones_hijas, this.value)
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe()
        }
    }

    updateValue(value: boolean) {
        this.value = value
    }

    get clasificacionObj() {
        return {
            id: this.clasificacion.id,
            value: this.value,
            hijas: this.hijas
        }
    }

    markAll(array: any[], value: boolean) {
        return array.map(e => {
            let obj = {}
            obj["id"] = e.id
            obj["value"] = value
            if (e.clasificaciones_hijas) {
                obj["hijas"] = this.markAll(e.clasificaciones_hijas, value)
            } else {
                obj["hijas"] = []
            }
            return obj
        })
    }
}
