import { Injectable, Injector } from "@angular/core"
import { BasePolicyService, Opts } from "./base-policy.service"
import { PermisosService } from "./permisos.service"
import { servicios } from "./tabla-servicios"
import { pluralize, capitalize } from "@puntaje/shared/core"

@Injectable({
    providedIn: "root"
})
export class PermisosAsociadosService extends BasePolicyService {
    constructor(permisosService: PermisosService, public injector: Injector) {
        super(permisosService)
    }

    asociadoPromise(resourceName: string, id: number) {
        return this.injector
            .get(servicios[capitalize(pluralize(this.getResourceName(resourceName)))].api)
            .where({ [this.getResourceName(resourceName)]: { id } })
            .then(_ => _.length == 1)
    }

    permisoAsociado(
        accion: string,
        { resourceId, resource, resourceName, onlyPermisos }: Opts
    ): boolean | Promise<boolean> {
        if (this.testPermiso(`${this.getResourceName(resourceName)}_${accion}_asociados`)) {
            if (onlyPermisos) return true
            const id = resourceId || resource.id
            return this.asociadoPromise(resourceName, id)
        }
        return false
    }

    index(opts: Opts): boolean | Promise<boolean> {
        return super.index(opts) || this.permisoAsociado("index", { ...opts, onlyPermisos: true })
    }

    update(opts: Opts): boolean | Promise<boolean> {
        return super.update(opts) || this.permisoAsociado("update", { ...opts, onlyPermisos: true })
    }

    show(opts: Opts): boolean | Promise<boolean> {
        return super.show(opts) || this.permisoAsociado("show", { ...opts, onlyPermisos: true })
    }

    create(opts: Opts): boolean | Promise<boolean> {
        return super.create(opts) || this.permisoAsociado("create", { ...opts, onlyPermisos: true })
    }

    destroy(opts: Opts): boolean | Promise<boolean> {
        return super.destroy(opts) || this.permisoAsociado("destroy", { ...opts, onlyPermisos: true })
    }
}
