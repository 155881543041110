<toolnavbar
    resourceName="tipo_instrumento_plataforma"
    [resource]="tipoInstrumentoPlataforma"
    [displayButtons]="displayButtons"
    [displayNavbar]="displayNavbar"
>
    <a class="btn btn-sm btn-default pull-left" (click)="search()">
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        Búsqueda
    </a>
</toolnavbar>
