import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import {
    Asignatura,
    Asignaturas,
    EstadoReporte,
    EstadoReportes,
    Plataforma,
    Plataformas,
    ReporteRazon,
    ReporteRazones,
    ReporteSubRazon,
    ReporteSubRazones
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { ReportesBuscadorService } from "./reportes_buscador.service"

@Component({
    selector: "reportes-buscador",
    templateUrl: "reportes_buscador.component.html"
})
export class ReportesBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false
    @Input() withAsignatura = false

    asignaturas: Asignatura[]
    plataformas: Plataforma[]
    tablas = ["Material", "Pregunta"]
    roles = ["Estudiante", "Docente", "Administrador"]
    reporteRazones: ReporteRazon[]
    reporteSubRazones: ReporteSubRazon[]
    estadoReportes: EstadoReporte[]

    searchParams: any = { reporte: {} }

    constructor(
        protected reportesBuscadorService: ReportesBuscadorService,
        protected asignaturasService: Asignaturas,
        protected plataformasService: Plataformas,
        protected reporteRazonesService: ReporteRazones,
        protected reporteSubRazonesService: ReporteSubRazones,
        protected estadoReportesService: EstadoReportes
    ) {}

    ngOnInit() {
        this.sub = this.reportesBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.asignaturasService.where().then((asignaturas: Asignatura[]) => {
            this.asignaturas = asignaturas
        })

        this.plataformasService.where().then((plataformas: Plataforma[]) => {
            this.plataformas = plataformas
        })

        this.reporteRazonesService.where().then((reporteRazones: ReporteRazon[]) => {
            this.reporteRazones = reporteRazones
        })

        this.estadoReportesService.where().then((estadoReportes: EstadoReporte[]) => {
            this.estadoReportes = estadoReportes
        })
    }

    buscarReporteSubRazones(reporteRazonId: number) {
        this.reporteSubRazones = undefined
        delete this.searchParams.reporte.reporte_sub_razon_id
        this.reporteSubRazonesService
            .where({ reporte_sub_razon: { reporte_razon_id: reporteRazonId } })
            .then((reporteSubRazones: ReporteSubRazon[]) => {
                this.reporteSubRazones = reporteSubRazones
            })
    }

    deleteIfUndefined(field: string) {
        if (!this.searchParams.reporte[field]) {
            delete this.searchParams.reporte[field]
        }
    }

    search() {
        this.reportesBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
