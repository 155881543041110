<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div class="subadmin-row">
            <fa name="user" class="user-icon"></fa>
            <div class="usuario-wrap">
                <h4>
                    <a routerLink="/usuarios/{{ usuario.id }}">{{ usuario.persona }}</a>
                </h4>
                <p>{{ usuario.perfiles[0].perfil }}</p>
            </div>
            <span class="usuario-count">
                <fa name="user" class="icono"></fa>
                ({{ usuario.usuarios_hijos.length }})
            </span>
            <div class="asociar-wrap">
                <autocomplete
                    class="autocomplete"
                    [(ngModel)]="usuarioParaAsociar"
                    [options]="usuariosAsociar"
                    (filterChange)="usuariosFiltro($event)"
                    [showTextFun]="'getPersona'"
                ></autocomplete>
                <button (click)="asociarUsuario()" class="btn btn-default btn-xs">
                    <fa name="user-plus" class="icono"></fa>
                    Asociar
                </button>
            </div>
        </div>
        <usuarios-sub-administrador
            class="subtabla"
            [usuarios]="usuario.usuarios_hijos"
            [usuarioPadre]="usuario"
            (desasociado)="reloadUsuario()"
        ></usuarios-sub-administrador>
    </ng-container>
</loading-layout>
