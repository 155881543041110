import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import {
    Asignatura,
    Asignaturas,
    Clasificacion,
    Clasificaciones,
    Persona,
    Personas,
    MaterialTipos,
    MaterialTipo
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { MaterialesBuscadorService } from "./materiales_buscador.service"
import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"

@Component({
    selector: "materiales-buscador",
    templateUrl: "materiales_buscador.component.html",
    styleUrls: ["materiales_buscador.component.scss"]
})
export class MaterialesBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false

    asignaturas: Asignatura[]
    clasificaciones: Clasificacion[]
    profesores: Persona[]
    materialTipos: MaterialTipo[]

    buscadorClasificaciones: UntypedFormControl = new UntypedFormControl()
    buscadorProfesor: UntypedFormControl = new UntypedFormControl()

    searchParams: any = { material: { like: {} } }
    clasificacionesMaterial: Clasificacion[] = []

    showClasificaciones: boolean = false
    showProfesores: boolean = false

    constructor(
        protected materialesBuscadorService: MaterialesBuscadorService,
        protected clasificacionesService: Clasificaciones,
        protected asignaturasService: Asignaturas,
        protected personasService: Personas,
        protected materialTiposService: MaterialTipos
    ) {}

    ngOnInit() {
        this.sub = this.materialesBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.buscadorClasificaciones.valueChanges
            .pipe(
                filter(v => v == "" || v.length >= 3),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(value => {
                if (value != "") {
                    this.getClasificaciones(value)
                } else {
                    this.clasificaciones = []
                }
            })

        this.buscadorProfesor.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
            if (value != "") {
                this.getProfesores(value)
            } else {
                delete this.searchParams.material.profesor_id
                this.profesores = []
            }
        })

        this.asignaturasService.where().then((asignaturas: Asignatura[]) => {
            this.asignaturas = asignaturas
        })

        this.materialTiposService.where().then(materialTipos => {
            this.materialTipos = materialTipos
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    getProfesores(value) {
        this.personasService.where({ persona: { nombre_completo: value } }).then((personas: Persona[]) => {
            this.profesores = personas
            this.showProfesores = true
        })
    }

    clickProfesor(persona) {
        this.searchParams.material.profesor_id = persona.id
        this.showProfesores = false
        this.buscadorProfesor.setValue(persona.toString(), { emitEvent: false })
    }

    getClasificaciones(value) {
        this.clasificacionesService
            .where({ with_nombre_jerarquico: 1, clasificacion: { full_nombre_jerarquico: value } })
            .then((clasificaciones: Clasificacion[]) => {
                this.clasificaciones = clasificaciones
                this.showClasificaciones = true
            })
    }

    clickClasificacion(clasificacion: Clasificacion) {
        if (!this.clasificacionesMaterial.find(c => c.id == clasificacion.id)) {
            this.clasificacionesMaterial = this.clasificacionesMaterial.concat(clasificacion)
        }

        this.showClasificaciones = false
        this.buscadorClasificaciones.setValue("")
    }

    deleteClasificacion(clasificacion: Clasificacion) {
        let i = this.clasificacionesMaterial.findIndex(c => c.id == clasificacion.id)
        i >= 0 && this.clasificacionesMaterial.splice(i, 1)
    }

    search() {
        if (this.clasificacionesMaterial.length != 0) {
            this.searchParams.clasificacion = { id: this.clasificacionesMaterial.map(cm => cm.id) }
            this.searchParams.and_clasificacion = 1
        } else {
            delete this.searchParams.clasificacion
            delete this.searchParams.and_clasificacion
        }
        this.materialesBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
