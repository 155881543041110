import { Configuration, ElementConfigItem, LinksConversion } from "./Configuration"
import { ElementStart, ElementEnd } from "./MyElement"
import { CSSStyle } from "./CSSStyle"

import { java, org, javaemul } from "j4ts"

import ArrayUtils = org.apache.commons.lang3.ArrayUtils

import MessageDigest = java.security.MessageDigest

import StringEscapeUtils = org.apache.commons.lang3.StringEscapeUtils

import StringWriter = java.io.StringWriter

import BufferedWriter = java.io.BufferedWriter

import IOException = java.io.IOException

import HashMap = java.util.HashMap

import Iterator = java.util.Iterator

import Map = java.util.Map

/**
 * Class which converts HTML into LaTeX format.
 * Plain HTML elements are converted using
 * {@link Convertor#commonElementStart(ElementStart) commonElementStart()} and
 * {@link Convertor#commonElementEnd(ElementEnd, ElementStart) commonElementEnd()}
 * methods. Elements requiring special care during
 * the conversion are converted by calling special methods like
 * {@link Convertor#tableRowStart(ElementStart) tableRowStart() }.
 */
export class Convertor {
    /**
     * Program configuration.
     */
    private _config: Configuration

    /**
     * Output file.
     */
    private _fw: StringWriter

    /**
     * Output file.
     */
    private _writer: BufferedWriter

    /**
     * Counter telling in how many elements with
     * &quot;leaveText&quot; attribute the parser is.
     */
    private _countLeaveTextElements: number = 0

    /**
     * Counter telling in how many elements with
     * &quot;ignoreContent&quot; attribute the parser is.
     */
    private _countIgnoreContentElements: number = 0

    /**
     * If table cell is reached is it first table cell?
     */
    private _firstCell: boolean = true

    /**
     * If table row is reached is it first table row?
     */
    private _firstRow: boolean = true

    /**
     * Shall border be printed in current table.
     */
    private _printBorder: boolean = false

    /**
     * Document's bibliography. <br />
     * key :  bibitem name <br />
     * value : bibitem description
     */
    private _biblio: HashMap<string, string> = new HashMap<string, string>(10)

    /**
     * Opens the output file.
     * @param outputFile output LaTeX file
     * @throws FatalErrorException when output file can't be opened
     */
    constructor(outputFile: StringWriter) {
        this._config = new Configuration()
        this._fw = outputFile
        this._writer = new BufferedWriter(this._fw)
    }

    /**
     * Closes the output file.
     */
    public destroy() {
        try {
            this._writer.close()
        } catch (e) {
            console.error("Can't close the output string: ")
        }
    }

    /**
     * Called when HTML start element is reached and special method for
     * the element doesn't exist.
     * @param element HTML start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public commonElementStart(element: ElementStart) {
        var item: ElementConfigItem = this._config.getElement(element.getElementName())
        if (item.leaveText()) ++this._countLeaveTextElements
        if (item.ignoreContent()) ++this._countIgnoreContentElements
        var str: string = item.getStart()
        this._writer.write(str)
        var styles: string[] = element.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        for (var index121 = 0; index121 < styles.length; index121++) {
            var css = styles[index121]
            {
                var cssPair: string[] = css.split(/:\s*/)
                if (cssPair.length > 1)
                    this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getStart())
            }
        }
    }

    /**
     * Called when HTML end element is reached and special method for
     * the element doesn't exist.
     * @param element corresponding end tag
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public commonElementEnd(element: ElementEnd, es: ElementStart) {
        var styles: string[] = es.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        styles.reverse()
        for (var index122 = 0; index122 < styles.length; index122++) {
            var css = styles[index122]
            {
                var cssPair: string[] = css.split(/:\s*/)
                if (cssPair.length > 1)
                    this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getEnd())
            }
        }
        var item: ElementConfigItem = this._config.getElement(element.getElementName())
        if (item.leaveText()) --this._countLeaveTextElements
        if (item.ignoreContent()) --this._countIgnoreContentElements
        var str: string = item.getEnd()
        if (str === "") return
        this._writer.write(str)
        this.processAttributes(es)
    }

    /**
     * Called when text content is reached in the input HTML document.
     * @param str text content reached
     * @throws IOException when output error occurs
     */
    public characters(str: string) {
        if (this._countLeaveTextElements === 0) str = str.replace(/\n/g, " ").replace(/\t/g, " ")
        if (str === "" || str.trim() === "") return
        if (this._countIgnoreContentElements > 0) return
        if (!new RegExp("[^\\\\]*\\\\f\\[.*\\\\f\\][^\\\\]*").test(str)) {
            if (this._countLeaveTextElements === 0) str = this.convertCharEntitites(this.convertLaTeXSpecialChars(str))
            else str = this.convertCharEntitites(str)
        } else {
            str = str.replace(/\\f\[/g, "").replace(/\\f\]/g, "")
            str = this.convertCharEntititesLatex(str)
        }
        this._writer.write(str)
    }

    /**
     * Called when comment is reached in the input HTML document.
     * @param comment comment (without &lt;!-- and --&gt;)
     * @throws IOException when output error occurs
     */
    public comment(comment: string) {
        if (
            /* startsWith */ ((str, searchString, position = 0) =>
                str.substr(position, searchString.length) === searchString)(comment.trim().toLowerCase(), "latex:")
        ) {
            comment = comment.trim()
            comment = comment.substring(6, comment.length)
            this._writer.write(comment + "\n")
            return
        }
        comment = "% " + comment
        comment = "\n" + comment.replace(/\n/g, "\n% ")
        comment += "\n"
        this._writer.write(comment)
    }

    /**
     * Converts LaTeX special characters (ie. '{') to LaTeX commands.
     * @param str input string
     * @return converted string
     */
    private convertLaTeXSpecialChars(str: string): string {
        return str
    }

    /**
     * Converts HTML character entities to LaTeX commands.
     * @param str input string
     * @return converted string
     */
    private convertCharEntitites(str: string): string {
        var entity: java.lang.StringBuffer = new java.lang.StringBuffer("")
        var entityStr: string = ""
        var len: number = str.length
        var addToBuffer: boolean = false
        for (var i: number = 0; i < len; ++i) {
            if (str.charAt(i) === "&") {
                addToBuffer = true
                entity.delete(0, entity.length())
                continue
            }
            if (addToBuffer && str.charAt(i) === ";") {
                try {
                    var repl: string = ""
                    var ok: boolean = true
                    if (entity.charAt(0) === "#") {
                        try {
                            var entityNum: number
                            if (entity.charAt(1) === "x" || entity.charAt(1) === "X") {
                                entityNum = javaemul.internal.IntegerHelper.valueOf(
                                    entity.substring(2, entity.length()),
                                    16
                                )
                            } else {
                                entityNum = javaemul.internal.IntegerHelper.valueOf(
                                    entity.substring(1, entity.length())
                                )
                            }
                            repl = this._config.getChar(entityNum)
                        } catch (ex) {
                            console.info("Not a number in entity.")
                            ok = false
                        }
                    } else {
                        repl = this._config.getChar(entity.toString())
                    }
                    if (ok) {
                        let reg = new RegExp("&" + entity.toString() + ";", "g")
                        str = str.replace(reg, repl)
                        len = str.length
                        i += repl.length - (entity.length() + 2)
                    }
                } catch (e) {
                    console.info(e.toString())
                }
                addToBuffer = false
                entity.delete(0, entity.length())
                continue
            }
            if (addToBuffer) {
                entity.append(str.charAt(i))
            }
        }
        return str
    }

    private convertCharEntititesLatex(str: string): string {
        var entity: java.lang.StringBuffer = new java.lang.StringBuffer("")
        var entityStr: string = ""
        var len: number = str.length
        var addToBuffer: boolean = false
        for (var i: number = 0; i < len; ++i) {
            if (str.charAt(i) === "&") {
                addToBuffer = true
                entity.delete(0, entity.length())
                continue
            }
            if (addToBuffer && str.charAt(i) === ";") {
                try {
                    var repl: string = ""
                    var ok: boolean = true
                    if (entity.charAt(0) === "#") {
                        try {
                            var entityNum: number
                            if (entity.charAt(1) === "x" || entity.charAt(1) === "X") {
                                entityNum = javaemul.internal.IntegerHelper.valueOf(
                                    entity.substring(2, entity.length()),
                                    16
                                )
                            } else {
                                entityNum = javaemul.internal.IntegerHelper.valueOf(
                                    entity.substring(1, entity.length())
                                )
                            }
                            repl = this._config.getChar(entityNum)
                        } catch (ex) {
                            console.info("Not a number in entity.")
                            ok = false
                        }
                    } else {
                        repl = this._config.getChar(entity.toString())
                    }
                    if (ok) {
                        repl = repl.replace(/\$/, "")
                        let reg = new RegExp("&" + entity.toString() + ";", "g")
                        str = str.replace(reg, repl)
                        len = str.length
                        i += repl.length - (entity.length() + 2)
                    }
                } catch (e) {
                    console.info(e.toString())
                }
                addToBuffer = false
                entity.delete(0, entity.length())
                continue
            }
            if (addToBuffer) {
                entity.append(str.charAt(i))
            }
        }
        return str
    }

    /**
     * Processes HTML elements' attributes. "Title" and "cite" attributes
     * are converted to footnotes.
     * @param element HTML start tag
     * @throws IOException when output error occurs
     */
    private processAttributes(element: ElementStart) {
        var map: HashMap<string, string> = element.getAttributes()
        if (element.getElementName() === "a") return
        if (map.get("title") != null) this._writer.write("\\footnote{" + map.get("title") + "}")
        if (map.get("cite") != null) this._writer.write("\\footnote{" + map.get("cite") + "}")
    }

    /**
     * Prints CSS style converted to LaTeX command.
     * Called when HTML start element is reached.
     * @param e HTML start element
     * @throws IOException when output error occurs
     */
    public cssStyleStart(e: ElementStart) {
        var styles: CSSStyle[] = this.findStyles(e)
        for (var i: number = 0; i < styles.length; ++i) {
            if (styles[i] == null) continue
            if (this._config.getMakeCmdsFromCSS())
                this._writer.write(this._config.getCmdStyleName(styles[i].getName()) + "{")
            else this._writer.write(styles[i].getStart())
        }
    }

    /**
     * Prints CSS style converted to LaTeX command.
     * Called when HTML end element is reached.
     * @param e corresponding HTML start element
     * @throws IOException when output error occurs
     */
    public cssStyleEnd(e: ElementStart) {
        var styles: CSSStyle[] = this.findStyles(e)
        for (var i: number = styles.length - 1; i >= 0; --i) {
            if (styles[i] == null) continue
            if (this._config.getMakeCmdsFromCSS()) this._writer.write("}")
            else this._writer.write(styles[i].getEnd())
        }
    }

    /**
     * Finds styles for the specified element.
     * @param e HTML element
     * @return array with styles in this order: element name style, 'class' style,
     * 'id' style (if style not found null is stored in the array)
     */
    private findStyles(e: ElementStart): CSSStyle[] {
        try {
            if (this._config.getElement(e.getElementName()).ignoreStyles()) return null
        } catch (ex) {}
        var styleNames: string[] = [e.getElementName(), "", ""]
        var styles: CSSStyle[] = [null, null, null]
        var style: CSSStyle
        if (e.getAttributes().get("class") != null) styleNames[1] = e.getAttributes().get("class")
        if (e.getAttributes().get("id") != null) styleNames[2] = e.getAttributes().get("id")
        if ((style = this._config.findStyle(styleNames[0])) != null) styles[0] = style
        if ((style = this._config.findStyleClass(styleNames[1], e.getElementName())) != null) styles[1] = style
        if ((style = this._config.findStyleId(styleNames[2], e.getElementName())) != null) styles[2] = style
        return styles
    }

    /**
     * Called when A start element is reached.
     * @param e start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public anchorStart(e: ElementStart) {
        var href: string = ""
        var name: string = ""
        var title: string = ""
        if (e.getAttributes().get("href") != null) href = e.getAttributes().get("href")
        if (e.getAttributes().get("name") != null) name = e.getAttributes().get("name")
        if (e.getAttributes().get("title") != null) title = e.getAttributes().get("title")
        switch (this._config.getLinksConversionType()) {
            case LinksConversion.FOOTNOTES:
                break
            case LinksConversion.BIBLIO:
                break
            case LinksConversion.HYPERTEX:
                if (
                    /* startsWith */ ((str, searchString, position = 0) =>
                        str.substr(position, searchString.length) === searchString)(href, "#")
                ) {
                    this._writer.write("\\hyperlink{" + href.substring(1, href.length) + "}{")
                    break
                }
                if (!(name === "")) {
                    this._writer.write("\\hypertarget{" + name + "}{")
                    break
                }
                if (!(href === "")) {
                    this._writer.write("\\href{" + href + "}{")
                    break
                }
                break
            case LinksConversion.IGNORE:
                break
        }
    }

    /**
     * Called when A end element is reached.
     * @param element corresponding end tag
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public anchorEnd(element: ElementEnd, es: ElementStart) {
        var href: string = ""
        var name: string = ""
        var title: string = ""
        if (es.getAttributes().get("href") != null) href = es.getAttributes().get("href")
        if (es.getAttributes().get("name") != null) name = es.getAttributes().get("name")
        if (es.getAttributes().get("title") != null) title = es.getAttributes().get("title")
        switch (this._config.getLinksConversionType()) {
            case LinksConversion.FOOTNOTES:
                if (href === "") return
                if (
                    /* startsWith */ ((str, searchString, position = 0) =>
                        str.substr(position, searchString.length) === searchString)(href, "#")
                )
                    return
                this._writer.write("\\footnote{" + es.getAttributes().get("href") + "}")
                break
            case LinksConversion.BIBLIO:
                if (href === "") return
                if (
                    /* startsWith */ ((str, searchString, position = 0) =>
                        str.substr(position, searchString.length) === searchString)(href, "#")
                )
                    return
                var key: string = ""
                var value: string = ""
                if (es.getAttributes().get("name") != null) key = es.getAttributes().get("name")
                else key = es.getAttributes().get("href")
                value = "\\verb|" + es.getAttributes().get("href") + "|."
                if (es.getAttributes().get("title") != null) value += " " + es.getAttributes().get("title")
                this._biblio.put(key, value)
                this._writer.write("\\cite{" + key + "}")
                break
            case LinksConversion.HYPERTEX:
                if (!(name === "")) {
                    this._writer.write("}")
                    break
                }
                if (
                    /* startsWith */ ((str, searchString, position = 0) =>
                        str.substr(position, searchString.length) === searchString)(href, "#")
                ) {
                    this._writer.write("}")
                    break
                }
                if (!(href === "")) {
                    this._writer.write("}")
                    break
                }
                break
            case LinksConversion.IGNORE:
                break
        }
    }

    /**
     * Called when TR start element is reached.
     * @param e start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public tableRowStart(e: ElementStart) {
        if (!this._firstRow && !this._printBorder) this._writer.write(" \\\\ \n")
        else this._firstRow = false
        var styles: string[] = e.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        for (var index123 = 0; index123 < styles.length; index123++) {
            var css = styles[index123]
            {
                var cssPair: string[] = css.split(/:\s*/)
                this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getStart())
            }
        }
    }

    /**
     * Called when TR end element is reached.
     * @param e corresponding end tag
     * @param es start tag
     * @throws IOException output error occurs
     */
    public tableRowEnd(e: ElementEnd, es: ElementStart) {
        var styles: string[] = es.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        styles.reverse()
        for (var index124 = 0; index124 < styles.length; index124++) {
            var css = styles[index124]
            {
                var cssPair: string[] = css.split(/:\s*/)
                this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getEnd())
            }
        }
        if (this._printBorder) this._writer.write(" \\\\ \n\\hline\n")
        this._firstCell = true
    }

    /**
     * Called when TD start element is reached.
     * @param e start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public tableCellStart(e: ElementStart) {
        if (!this._firstCell) this._writer.write(" & ")
        else this._firstCell = false
        this._writer.write(this._config.getElement(e.getElementName()).getStart())
        var styles: string[] = e.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        for (var index125 = 0; index125 < styles.length; index125++) {
            var css = styles[index125]
            {
                var cssPair: string[] = css.split(/:\s*/)
                this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getStart())
            }
        }
    }

    /**
     * Called when TD end element is reached.
     * @param element corresponding end tag
     * @param e start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public tableCellEnd(element: ElementEnd, e: ElementStart) {
        var styles: string[] = e.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        styles.reverse()
        for (var index126 = 0; index126 < styles.length; index126++) {
            var css = styles[index126]
            {
                var cssPair: string[] = css.split(/:\s*/)
                this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getEnd())
            }
        }
        this._writer.write(this._config.getElement(e.getElementName()).getEnd())
    }

    /**
     * Called when TABLE start element is reached.
     * @param e start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public tableStart(e: ElementStart) {
        this._writer.write(this._config.getElement(e.getElementName()).getStart())
        var str: string
        this._writer.write("|{p{1\\linewidth}}\n|")
        if ((str = e.getAttributes().get("latexcols")) != null) this._writer.write("{" + str + "}\n")
        if ((str = e.getAttributes().get("border")) != null) if (!(str === "0")) this._printBorder = true
        if (this._printBorder) this._writer.write("\\hline \n")
        var styles: string[] = e.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        for (var index127 = 0; index127 < styles.length; index127++) {
            var css = styles[index127]
            {
                var cssPair: string[] = css.split(/:\s*/)
                if (cssPair.length > 1)
                    this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getStart())
            }
        }
    }

    /**
     * Called when TABLE end element is reached.
     * @param e corresponding end tag
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public tableEnd(e: ElementEnd, es: ElementStart) {
        var styles: string[] = es.getAttributes().getOrDefault("style", "a:b").split(/;\s*/)
        styles.reverse()
        for (var index128 = 0; index128 < styles.length; index128++) {
            var css = styles[index128]
            {
                var cssPair: string[] = css.split(/:\s*/)
                if (cssPair.length > 1)
                    this._writer.write(this._config.getPropertyConf(cssPair[0] + "-" + cssPair[1]).getEnd())
            }
        }
        this._writer.write(this._config.getElement(e.getElementName()).getEnd())
        this._firstRow = true
        this._printBorder = false
    }

    /**
     * Called when BODY start element is reached.
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public bodyStart(es: ElementStart) {
        if (this._config.getLinksConversionType() === LinksConversion.HYPERTEX)
            this._writer.write("\n\\usepackage{hyperref}")
        if (this._config.getMakeCmdsFromCSS()) this._writer.write(this._config.makeCmdsFromCSS())
        this._writer.write(this._config.getElement(es.getElementName()).getStart())
    }

    /**
     * Called when IMG start element is reached.
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public imgStart(es: ElementStart) {
        var src: string = es.getAttributes().get("src")
        var index: number = src.lastIndexOf("/")
        var file: string = src.substring(index + 1, src.length)
        var indexOfLastDot: number = src.lastIndexOf(".")
        var extension: string = src.substring(indexOfLastDot + 1)
        var filename: string = this.stringDigest(src) + "." + extension
        var withCharacters: string = StringEscapeUtils.unescapeHtml4(src)
        this._writer.write("\n\\remoteimagep{" + filename + "}{" + withCharacters + "}{1}")
    }

    private stringDigest(str: string): string {
        try {
            //debugger;
            var md: MessageDigest = MessageDigest.getInstance("MD5")
            md.update(/* getBytes */ str.split("").map(s => s.charCodeAt(0)))
            var digest: number[] = md.digest()
            var sb: java.lang.StringBuffer = new java.lang.StringBuffer()
            for (var index129 = 0; index129 < digest.length; index129++) {
                var b = digest[index129]
                {
                    sb.append((b & 255).toString())
                }
            }
            return sb.toString()
        } catch (e) {
            //console.log(e);
            return "stringDigestEXCEPTION"
        }
    }

    /**
     * Called when META start element is reached.
     * Recognizes basic charsets (cp1250, utf8, latin2)
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public metaStart(es: ElementStart) {}

    /**
     * Called when FONT start element is reached.
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public fontStart(es: ElementStart) {
        if (es.getAttributes().get("size") != null) {
            var command: string
            try {
                var size: number = javaemul.internal.IntegerHelper.valueOf(es.getAttributes().get("size"))
                switch (size) {
                    case 1:
                        command = "{\\tiny"
                        break
                    case 2:
                        command = "{\\footnotesize"
                        break
                    case 3:
                        command = "{\\normalsize"
                        break
                    case 4:
                        command = "{\\large"
                        break
                    case 5:
                        command = "{\\Large"
                        break
                    case 6:
                        command = "{\\LARGE"
                        break
                    case 7:
                        command = "{\\Huge"
                        break
                    default:
                        command = "{\\normalsize"
                        break
                }
            } catch (ex) {
                command = "{\\normalsize"
            }
            this._writer.write(command + " ")
        }
    }

    /**
     * Called when FONT end element is reached.
     * @param e corresponding end tag
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public fontEnd(e: ElementEnd, es: ElementStart) {
        if (es.getAttributes().get("size") != null) {
            this._writer.write("}")
        }
    }

    /**
     * Called when  end element is reached.
     * @param element corresponding end tag
     * @param es start tag
     * @throws IOException output error occurs
     * @throws NoItemException tag not found in the configuration
     */
    public bodyEnd(element: ElementEnd, es: ElementStart) {
        if (!this._biblio.isEmpty()) {
            this._writer.write("\n\n\\begin{thebibliography}{" + this._biblio.size() + "}\n")
            for (var iterator: Iterator<any> = this._biblio.entrySet().iterator(); iterator.hasNext(); ) {
                var entry: Map.Entry<any, any> = <Map.Entry<any, any>>iterator.next()
                var key: string = <string>entry.getKey()
                var value: string = <string>entry.getValue()
                this._writer.write("\t\\bibitem{" + key + "}" + value + "\n")
            }
            this._writer.write("\\end{thebibliography}")
        }
        this.commonElementEnd(element, es)
    }
}
