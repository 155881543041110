<logged-layout>
    <logged-layout-toolnavbar>
        <materiales-toolnavbar [displayButtons]="'new'"></materiales-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Mis Materiales</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="materiales"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll" [useQueryParams]="true"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
