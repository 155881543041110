import { Component, OnInit } from "@angular/core"
import { GeneradorInstrumentoBaseForm } from "../generador-instrumento-base-form"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "app-generador-instrumentos-edit",
    templateUrl: "../generador-instrumentos-form.component.html",
    styleUrls: ["./generador-instrumentos-edit.component.scss"]
})
export class GeneradorInstrumentosEditComponent extends GeneradorInstrumentoBaseForm implements OnInit {
    display_buttons_options = "all new"

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.generadorInstrumentosService.disableIgnoreModel()
            this.generadorInstrumentosService
                .find(params["id"], {
                    include:
                        "[" +
                        "asignatura_plataforma:[asignatura,plataforma],generador_instrumento_clasificaciones," +
                        "generador_instrumento_filtro_clasificaciones," +
                        "generador_instrumento_filtro_clasificacion_tipos," +
                        "generador_instrumento_instrucciones:[clasificacion,instruccion]," +
                        "generador_instrumento_categorias:categoria]"
                })
                .then(generador => {
                    this.generadorInstrumentosService.enableIgnoreModel()
                    this.setUpForm(generador)
                })
        })
    }

    apiPromise(generador: GeneradorInstrumento) {
        return this.generadorInstrumentosService.update(generador.id, generador)
    }

    back() {
        this.router.navigate(["generador_instrumentos", this.generador.id])
    }
}
