import { Component, OnDestroy, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Material, Materiales, MaterialView } from "@puntaje/nebulosa/api-services"
import { AuthService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { MaterialesBuscadorService } from "./materiales_buscador.service"

@Component({
    selector: "materiales",

    templateUrl: "materiales.component.html"
})
export class MaterialesComponent implements OnInit {
    @Input() materiales: Material[]
    show_params = MaterialView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    /*  sub: Subscription;
  searchParams: any; */

    constructor(
        private materialesService: Materiales,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected materialesBuscadorService: MaterialesBuscadorService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        /* this.sub = this.materialesBuscadorService.searchParams.subscribe((params) => {
      this.searchParams = params;
      this.paginator.changePage(1);
    }) */
    }

    /*   showAll = (page: number, per: number) => {
    let params = {
      ...this.searchParams,
      page: page,
      per: per,
      methods: "cantidad_reportes",
      include: "[certificacion_recurso]"
    }

    return this.materialesService.where(params).then((materiales: Material[], total: number) => {
      this.materiales = materiales;
      this.loadingLayout.ready();
      return total;
    });
  } */

    view(material: Material) {
        this.router.navigate(["materiales/" + material.id])
    }

    edit(material: Material) {
        this.router.navigate(["materiales/" + material.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["materiales/" + id + "/delete"])
    }

    canShow(material: Material) {
        let perfiles = this.sessionService.getPerfiles()
        return (
            perfiles.includes("Super Administrador") ||
            (perfiles.includes("Docente") && this.authService.getUserData().id == material.profesor_id)
        )
    }

    showModalDelete(material: Material) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este material?")
        this.simpleModalService.setModalCallback(() => this.delete(material.id))
    }

    /* ngOnDestroy() {
    this.sub.unsubscribe()
  } */
}
