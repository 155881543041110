<div class="row">
    <div class="col-sm-3 col-md-2 sidebar">
        <sidemenu></sidemenu>
    </div>
    <div class="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2">
        <div class="container-fluid bottom-padding">
            <flash-message></flash-message>
            <ng-content select="logged-layout-toolnavbar"></ng-content>
            <h2>
                <span #titulo><ng-content select="logged-layout-titulo"></ng-content></span>
                <ng-template [ngIf]="titulo.children.length == 0">--Título por defecto--</ng-template>
            </h2>
            <ng-content select="logged-layout-contenido"></ng-content>
        </div>
    </div>
</div>
<simple-modal></simple-modal>
