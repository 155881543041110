import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Subscription } from "rxjs"
import { Clasificacion, Clasificaciones, ClasificacionView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { ClasificacionesBuscadorService } from "./clasificaciones_buscador.service"
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    templateUrl: "clasificaciones.component.html"
})
export class ClasificacionesComponent implements OnInit, OnDestroy {
    sub: Subscription
    searchParams: any
    clasificaciones: Clasificacion[]
    show_params = ClasificacionView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        private clasificacionesService: Clasificaciones,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected clasificacionesBuscadorService: ClasificacionesBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.sub = this.clasificacionesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            ...this.searchParams,
            page: page,
            per: per
        }
        return this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[], total: number) => {
            this.clasificaciones = clasificaciones
            this.loadingLayout.ready()
            return total
        })
    }

    view(clasificacion: Clasificacion) {
        this.router.navigate(["clasificaciones/" + clasificacion.id])
    }

    edit(clasificacion: Clasificacion) {
        this.router.navigate(["clasificaciones/" + clasificacion.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["clasificaciones/" + id + "/delete"])
    }

    showModalDelete(clasificacion: Clasificacion) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar la clasifiación '" + clasificacion.clasificacion + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(clasificacion.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
