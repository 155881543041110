<logged-layout>
    <logged-layout-toolnavbar>
        <reporte-toolnavbar *ngIf="reporte" [reporte]="reporte" [displayButtons]="'all resolve'"></reporte-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Detalle del Reporte</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <ng-container *ngFor="let key of show_params | keys">
                    <model-simple-view
                        *ngIf="!show_params[key].showHidden"
                        [params]="show_params[key]"
                        [value]="reporte"
                        [key]="key"
                    ></model-simple-view>
                </ng-container>
                <div *ngIf="reporte.estado_reporte_id != 2" class="row">
                    <button class="btn btn-sm btn-default pull-left" (click)="resolve(reporte)">Marcar resuelto</button>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
