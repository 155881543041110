<!-- <form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>Recurso</label>
            <select class="form-control" [(ngModel)]="searchParams.reporte.tabla" (ngModelChange)="deleteIfUndefined('tabla')" name="tabla">
                <option [ngValue]="undefined">-- Seleccione Tipo --</option>
                <option *ngFor="let tabla of tablas" [ngValue]="tabla">{{ tabla }}</option>
            </select>
        </div>

        <div class="form-group">
            <label>ID Recurso</label>
            <input type="text" [(ngModel)]="searchParams.reporte.entidad_id" (ngModelChange)="deleteIfUndefined('entidad_id')" class="form-control" name="entidad_id" />
        </div>

        <div class="form-group">
            <label>Plataforma</label>
            <select class="form-control" [(ngModel)]="searchParams.reporte.plataforma_id" (ngModelChange)="deleteIfUndefined('plataforma_id')" name="plataforma_id" >
                <option [ngValue]="undefined">-- Seleccione Plataforma --</option>
                <option *ngFor="let plataforma of plataformas" [ngValue]="plataforma.id">{{ plataforma.plataforma }}</option>
            </select>
        </div>

        <div class="form-group">
            <label>Estado Reporte</label>
            <select class="form-control" [(ngModel)]="searchParams.reporte.estado_reporte_id" (ngModelChange)="deleteIfUndefined('estado_reporte_id')" name="estado_reporte_id" >
                <option [ngValue]="undefined">-- Seleccione Estado Reporte --</option>
                <option *ngFor="let estadoReporte of estadoReportes" [ngValue]="estadoReporte.id">{{ estadoReporte.estado }}</option>
            </select>
        </div>

        <a class="btn btn_style btn-default" (click)="search()">Buscar</a>
    </div>

    <div class="col-md-6">
        <div class="form-group">
            <label>Reporte Razon</label>
            <select class="form-control" [(ngModel)]="searchParams.reporte.reporte_razon_id" (ngModelChange)="deleteIfUndefined('reporte_razon_id'); buscarReporteSubRazones($event);" name="reporte_razon_id" >
                <option [ngValue]="undefined">-- Seleccione Reporte Razón --</option>
                <option *ngFor="let reporteRazon of reporteRazones" [ngValue]="reporteRazon.id">{{ reporteRazon.tabla }} / {{ reporteRazon.razon }}</option>
            </select>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group">
            <label>Reporte Sub Razon</label>
            <select class="form-control" [(ngModel)]="searchParams.reporte.reporte_sub_razon_id" (ngModelChange)="deleteIfUndefined('reporte_sub_razon_id');" name="reporte_sub_razon_id" >
                <option [ngValue]="undefined">-- Seleccione Reporte Sub Razón --</option>
                <option *ngFor="let reporteSubRazon of reporteSubRazones" [ngValue]="reporteSubRazon.id">{{ reporteSubRazon.sub_reporte }}</option>
            </select>
        </div>
    </div>
</form> -->
<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>Material</label>
            <input
                class="form-control"
                name="material"
                type="text"
                [(ngModel)]="searchParams.material.like.material"
                (ngModelChange)="deleteIfUndefined(searchParams.material.like, 'material')"
            />
        </div>

        <div class="form-group">
            <table class="table-visibilidad">
                <tr>
                    <th></th>
                    <th>Todos</th>
                    <th>Visible</th>
                    <th>No Visible</th>
                </tr>
                <tr>
                    <th>Visibilidad</th>
                    <td>
                        <input
                            class=""
                            name="visible"
                            type="radio"
                            [value]="undefined"
                            [(ngModel)]="searchParams.material.visible"
                            (ngModelChange)="deleteIfUndefined(searchParams.material, 'visible')"
                        />
                    </td>
                    <td>
                        <input
                            class=""
                            name="visible"
                            type="radio"
                            [value]="1"
                            [(ngModel)]="searchParams.material.visible"
                            (ngModelChange)="deleteIfUndefined(searchParams.material, 'visible')"
                        />
                    </td>
                    <td>
                        <input
                            class=""
                            name="visible"
                            type="radio"
                            [value]="0"
                            [(ngModel)]="searchParams.material.visible"
                            (ngModelChange)="deleteIfUndefined(searchParams.material, 'visible')"
                        />
                    </td>
                </tr>
            </table>
        </div>

        <div class="form-group">
            <label>Asignatura</label>
            <select
                class="form-control"
                name="asignatura_id"
                [(ngModel)]="searchParams.material.asignatura_id"
                (ngModelChange)="deleteIfUndefined(searchParams.material, 'asignatura_id')"
            >
                <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura.id">
                    {{ asignatura.asignatura }}
                </option>
            </select>
        </div>

        <div class="form-group lista-resultados-box">
            <label [attr.for]="'profesor'">Profesor</label>
            <input name="profesor" class="form-control" type="text" [formControl]="buscadorProfesor" />
            <ul *ngIf="showProfesores" class="lista-resultados-auto" #ulProfesores>
                <li *ngFor="let p of profesores" (click)="clickProfesor(p)">{{ p }}</li>
            </ul>
        </div>

        <button class="btn btn-default" (click)="search()">Buscar</button>
    </div>

    <div class="col-md-6">
        <div class="form-group lista-resultados-box">
            <label [attr.for]="'clasificaciones'">Clasificaciones</label>
            <input name="clasificaciones" class="form-control" type="text" [formControl]="buscadorClasificaciones" />
            <ul *ngIf="showClasificaciones" class="lista-resultados-auto" #ulClasificaciones>
                <li *ngFor="let c of clasificaciones" (click)="clickClasificacion(c)">
                    {{ c.full_nombre_jerarquico }}
                </li>
            </ul>

            <ul class="clasificacion-box">
                <li *ngFor="let c of clasificacionesMaterial">
                    <span (click)="deleteClasificacion(c)">x</span>
                    {{ c.full_nombre_jerarquico }}
                </li>
            </ul>
        </div>

        <div class="form-group">
            <label>Material Tipo</label>
            <select
                class="form-control"
                name="material_tipo_id"
                [(ngModel)]="searchParams.material.material_tipo_id"
                (ngModelChange)="deleteIfUndefined(searchParams.material, 'material_tipo_id')"
            >
                <option [ngValue]="undefined">-- Seleccione Material Tipo --</option>
                <option *ngFor="let materialTipo of materialTipos" [ngValue]="materialTipo.id">
                    {{ materialTipo.material_tipo }}
                </option>
            </select>
        </div>
    </div>
</form>
