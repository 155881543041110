import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Ayudas } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    template: ""
})
export class AyudasDeleteComponent implements OnInit, OnDestroy {
    private sub: Subscription

    constructor(private ayudasService: Ayudas, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.ayudasService.remove(+params["id"]).then(response => {
                this.router.navigate(["ayudas"])
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
