import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { Material, Materiales, MaterialView, MaterialTipo, TipoMaterial } from "@puntaje/nebulosa/api-services"
import { HtmlView, PdfView, YoutubeVideoView, AudioView, VideoView, DocsView } from "@puntaje/shared/core"

@Component({
    selector: "material-preview",
    templateUrl: "material.preview.component.html",
    styleUrls: ["material.preview.component.scss"]
})
export class MaterialPreviewComponent implements OnInit {
    @Input() material: Material
    show_params = MaterialView.viewParams
    enablePreview: boolean = false
    key: string = "url"
    noViewMessage: string = ""
    params: any = {}
    enableHTMLBorder: boolean = false
    enableCheck: boolean = false
    warningMessage: string = ""

    constructor(private materialesService: Materiales, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.loadPreview()
    }

    loadPreview() {
        this.enablePreview = false
        this.enableCheck = false
        this.enableHTMLBorder = false
        this.params = {}
        if (this.material && this.material.material_tipo_id) {
            const hasUrl = this.material.url && this.material.url.length > 0
            switch (+this.material.material_tipo_id) {
                case TipoMaterial.FILE:
                    this.enableCheck = hasUrl && this.material.getExtension() == "pdf"
                    this.noViewMessage =
                        "Vista previa por ahora sólo disponible para archivos de tipo <em>PDF que ya se encuentren subidos a la plataforma.</em>"
                    this.params = { label: "Previsualizar", type: PdfView, tableVisible: true }
                    this.key = "url"
                    break

                case TipoMaterial.VIDEO:
                    this.enableCheck = hasUrl
                    this.noViewMessage =
                        "Por favor incluya un <em>enlace</em> válido para este material en la pestaña de contenido."
                    this.params = { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
                    this.key = "url"
                    this.warningMessage = "Los videos pueden tomar un tiempo en cargar."
                    break

                case TipoMaterial.STREAMING:
                    this.enableCheck = hasUrl
                    this.noViewMessage =
                        "Por favor incluya un <em>enlace</em> válido para este material en la pestaña de contenido."
                    this.params = { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
                    this.key = "url"
                    this.warningMessage = "Los videos pueden tomar un tiempo en cargar."
                    break

                case TipoMaterial.HTML:
                    this.enableCheck = this.material.contenido && this.material.contenido.length > 0
                    this.noViewMessage =
                        "Por favor incluya <em>contenido</em> válido para este material en la pestaña de contenido."
                    this.params = { label: "Contenido", type: HtmlView, tableVisible: true }
                    this.key = "contenido"
                    this.enableHTMLBorder = true
                    this.warningMessage = "El marco azul sólo sirve para marcar donde empieza y termina el contenido."
                    break

                case TipoMaterial.INSTRUMENTO:
                    this.enableCheck = hasUrl && this.material.getExtension() == "pdf"
                    this.noViewMessage =
                        "Vista previa por ahora sólo disponible para archivos de tipo <em>PDF que ya se encuentren subidos a la plataforma.</em>"
                    this.params = { label: "Previsualizar", type: PdfView, tableVisible: true }
                    this.key = "url"
                    break

                case TipoMaterial.AUDIO: {
                    const extensiones = ["mp3", "mp4", "wav"]
                    this.enableCheck =
                        this.material.url &&
                        this.material.url.length > 0 &&
                        extensiones.includes(this.material.getExtension())
                    this.noViewMessage =
                        "Vista previa por ahora sólo disponible para archivos de tipo <em>MP3, MP4 y WAV que ya se encuentren subidos a la plataforma.</em>"
                    this.params = { label: "Previsualizar", type: AudioView, tableVisible: true }
                    this.key = "url"
                    break
                }

                case TipoMaterial.MP4: {
                    const extensiones = ["mp4"]
                    this.enableCheck =
                        this.material.url &&
                        this.material.url.length > 0 &&
                        extensiones.includes(this.material.getExtension())
                    this.noViewMessage =
                        "Vista previa por ahora sólo disponible para archivos de tipo MP4 que ya se encuentren subidos a la plataforma.</em>"
                    this.params = { label: "Previsualizar", type: VideoView, tableVisible: true }
                    this.key = "url"
                    this.warningMessage = "Los videos pueden tomar un tiempo en cargar."
                    break
                }

                case TipoMaterial.PPT: {
                    const extensiones = ["ppt", "pptx"]
                    this.enableCheck =
                        this.material.url &&
                        this.material.url.length > 0 &&
                        extensiones.includes(this.material.getExtension())
                    this.noViewMessage =
                        "Vista previa por ahora sólo disponible para archivos de tipo PPT, PPTX que ya se encuentren subidos a la plataforma.</em>"
                    this.params = { label: "Previsualizar", type: DocsView, tableVisible: true }
                    this.key = "url"
                    break
                }

                default:
                    break
            }
        } else {
            this.noViewMessage =
                "Para activar la vista previa, primero indique el tipo de material (Archivo, Video, etc...)."
        }

        setTimeout(() => {
            this.enablePreview = this.enableCheck
            this.enableCheck = false
        }, 10)
    }
}
