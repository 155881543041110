<loading-layout #loadingLayout>
    <ng-container *ngIf="reportes && reportes.length > 0">
        <ng-container *ngIf="enableAdvancedOptions">
            <button
                class="btn btn-default btn-sm pull-right btn-toggle-advaced"
                (click)="toggleAdvancedOptions = !toggleAdvancedOptions"
            >
                <fa name="cog"></fa>
                Más opciones
            </button>
            <div *ngIf="toggleAdvancedOptions" class="advanced-options clearfix">
                <h5>Resolver reportes por tipo</h5>
                <div class="row">
                    <div class="col-md-6 razon-select">
                        <select [(ngModel)]="reporteRazonSelected" class="form-control">
                            <option [ngValue]="undefined">Seleccione razón del reporte</option>
                            <option *ngFor="let razon of razones" [ngValue]="razon">{{ razon }}</option>
                        </select>
                    </div>
                    <button class="col-md-1 btn btn-default btn-sm" (click)="showModalResolveAll()">
                        <span class="glyphicon glyphicon-ok side-ok" aria-hidden="true"></span>
                        Resolver
                    </button>
                </div>
                <div class="row">
                    <div class ="col-md-12">
                        <button class="btn btn-default btn-sm" (click)="solveSelected()">
                            <span class="glyphicon glyphicon-ok side-ok" aria-hidden="true"></span>
                            Resolver reportes seleccionados
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <table-with-actions
            [contentArray]="reportes"
            [showParams]="show_params"
            [tableButtonsArray]="tableButtonsArray"
            [selectable]="selectable && enableAdvancedOptions"
            [hideSelect]="checkIfResolved"
            (sendSelected)="setSelected($event)"
        ></table-with-actions>
    </ng-container>
    <paginator #paginator [getList]="showAll"></paginator>
    <ng-container *ngIf="!reportes || reportes.length === 0">
        No existen reportes asociados.
    </ng-container>
</loading-layout>
