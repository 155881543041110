import { Component, OnInit, ViewChild } from "@angular/core"
import { GrupoPreguntas, GrupoPregunta } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "app-grupo-preguntas-list",
    templateUrl: "./grupo-preguntas-list.component.html",
    styleUrls: ["./grupo-preguntas-list.component.scss"]
})
export class GrupoPreguntasListComponent implements OnInit {
    grupo_preguntas: GrupoPregunta[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private grupo_preguntasService: GrupoPreguntas) {}

    ngOnInit() {}

    showAll(page: number, per: number) {
        return this.grupo_preguntasService
            .where({ page: page, per: per, order: { by: "id", sort: "desc"}})
            .then((grupo_preguntas: GrupoPregunta[], total: number) => {
                this.grupo_preguntas = grupo_preguntas
                this.loadingLayout.ready()
                return total
            })
    }
}
