<logged-layout>
    <logged-layout-toolnavbar>
        <generador-instrumentos-toolnavbar
            [displayButtons]="display_buttons_options"
        ></generador-instrumentos-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Copiar generador instrumento" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="ready" class="row">
                <div class="col-md-8 col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            [form]="form"
                            [params]="params['generador_instrumento']"
                            [(value)]="generador['generador_instrumento']"
                            key="generador_instrumento"
                        ></form-input>

                        <div class="form-group" *ngIf="plataformas">
                            <label>Plataforma</label>
                            <select (input)="plataformaSelected($any($event).target.value)" class="form-control">
                                <option value="">Seleccione Plataforma</option>
                                <option
                                    [selected]="plataforma.id == plataformaId"
                                    [value]="plataforma.id"
                                    *ngFor="let plataforma of plataformas"
                                >
                                    {{ plataforma.plataforma }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group" *ngIf="asignaturaPlataformas">
                            <label>Asignatura</label>
                            <select
                                (input)="asignaturaPlataformaSelected($any($event).target.value)"
                                class="form-control"
                            >
                                <option
                                    [selected]="ap.id == asignaturaPlataformaId"
                                    [value]="ap.id"
                                    *ngFor="let ap of asignaturaPlataformas"
                                >
                                    {{ ap.asignatura.asignatura }}
                                </option>
                            </select>
                        </div>

                        <form-input
                            [form]="form"
                            [params]="params['numero_preguntas']"
                            [(value)]="generador['numero_preguntas']"
                            key="numero_preguntas"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['tiempo']"
                            [(value)]="generador['tiempo']"
                            key="tiempo"
                        ></form-input>

                        <form-input
                            [form]="form"
                            [params]="params['alias']"
                            [(value)]="generador['alias']"
                            key="alias"
                        ></form-input>

                        <form-input
                            [form]="form"
                            [params]="params['numero_preguntas_piloto']"
                            [(value)]="generador['numero_preguntas_piloto']"
                            key="numero_preguntas_piloto"
                        ></form-input>
                    </form>

                    <button (click)="save()" type="button" class="btn btn-default">
                        {{ save_button_text | easyplaceholder: "Guardar" }}
                    </button>
                    <button (click)="back()" type="button" class="btn btn-default">Volver</button>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
