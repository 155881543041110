import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ReporteRazon, ReporteRazones, ReporteRazonView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "reporte_razon.component.html"
})
export class ReporteRazonComponent implements OnInit, OnDestroy {
    reporteRazon: ReporteRazon
    private sub: Subscription
    show_params = ReporteRazonView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private reporteRazonesService: ReporteRazones, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.reporteRazonesService.find(+params["id"]).then((response: ReporteRazon) => {
                this.reporteRazon = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
