import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { GrupoPregunta, GrupoPreguntaForm, GrupoPreguntas } from "@puntaje/nebulosa/api-services"
import { AuthService, Main, PdfView } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"

@Component({
    templateUrl: "grupo_preguntas.form.component.html"
})
export class GrupoPreguntasNewComponent implements OnInit, OnDestroy {
    grupoPregunta: GrupoPregunta = new GrupoPregunta()
    params = GrupoPreguntaForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar grupo de preguntas"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    latexSub: Subscription
    pressedButton: Boolean = false
    paramsPreviewLatex: any

    constructor(
        private grupoPreguntasService: GrupoPreguntas,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.form = GrupoPreguntaForm.getForm(this.grupoPregunta)
        let usuario = this.authService.getUserData()
        if (usuario) {
            this.grupoPregunta.profesor_id = usuario.persona.id
        }
        this.latexSub = this.form
            .get("texto_latex")
            .valueChanges.pipe(
                filter(x => x),
                distinctUntilChanged(),
                debounceTime(2000)
            )
            .subscribe(this.updatePreviewLatex.bind(this))
        this.loadingLayout.ready()
    }

    ngOnDestroy() {
        this.latexSub.unsubscribe()
    }

    save() {
        GrupoPreguntaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.pressedButton = true
            this.grupoPreguntasService.save(this.grupoPregunta).then(response => {
                this.router.navigate(["grupo_preguntas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.grupoPregunta = new GrupoPregunta()
            GrupoPreguntaForm.markFormControlsAsPristine(this.form)
            GrupoPreguntaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    toLatex(model, from, to) {
        if (model[from]) {
            model[to] = Main.html2latex(model[from])
        }
    }

    updatePreviewLatex() {
        const params: any = {
            grupo_pregunta: {
                ...this.grupoPregunta
            }
        }

        this.paramsPreviewLatex = params
    }
}
