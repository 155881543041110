import { ICSSParserHandler } from "./ICSSParserHandler"
import { Configuration, CSSPropertyConfigItem } from "./Configuration"
import { CSSStyle } from "./CSSStyle"

import { java } from "j4ts"

import HashMap = java.util.HashMap

/**
 * Handles events sent from the CSS Parser.
 */
export class CSSParserHandler implements ICSSParserHandler {
    /**
     * Html2latex program configuration.
     */
    private _config: Configuration

    /**
     * Cstr.
     * @param config program configuration
     */
    public constructor(config: Configuration) {
        Object.defineProperty(this, "__interfaces", {
            configurable: true,
            value: ["util.html2latex.ICSSParserHandler"]
        })
        this._config = config
    }

    /**
     * Called when a new style is reached in the CSS stylesheet.
     * Splits up multiple style names (ie. <code>h1, h2, h3 { ... } </code>)
     * and sets style properties for each of the style names.
     * @param styleName name of the style
     * @param properties map with all the style's properties
     */
    public newStyle(styleName: string, properties: HashMap<string, string>) {
        var split: string[] = styleName.toLowerCase().split(",")
        for (var i: number = 0; i < split.length; ++i) {
            var name: string = split[i].trim()
            var style: CSSStyle = this._config.getStyle(name)
            if (style == null) {
                style = new CSSStyle(name)
                this._config.addStyle(name, style)
            }
            style.setProperties(properties)
        }
    }
}
