import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Perfil, Perfiles, PerfilForm } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "perfiles.form.component.html"
})
export class PerfilesEditComponent implements OnInit, OnDestroy {
    oPerfil: Perfil = new Perfil()
    perfil: Perfil = new Perfil()
    model_params = PerfilForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar perfil"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private perfilesService: Perfiles,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = PerfilForm.getForm(this.perfil)
        this.sub = this.route.params.subscribe(params => {
            this.perfilesService.find(+params["id"]).then((response: Perfil) => {
                this.perfil = response
                this.oPerfil = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        PerfilForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.perfilesService.update(this.perfil.id, this.perfil).then(response => {
                this.router.navigate(["perfiles"]) // Si el navigate se hace después de apretar un boton de un formulario
                // hay que tener cuidado en poner type="button" al botón o encapsular
                // la llamada a navigate con un setTimeout como por ejemplo:
                //          setTimeout(() => this.router.navigate(['perfiles']))
                // Esto debido a que si no, se produce un redirect en el browser y se reinicia
                // la app!
                // Si el navigate está encapsulado en una promesa que viene de http, no
                // hay problema (como en este caso).
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.perfil = this.oPerfil.clone()
            PerfilForm.markFormControlsAsPristine(this.form)
            PerfilForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
