import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { UsuarioRecursoTipo, UsuarioRecursoTipos, Usuario } from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { SimpleModalService } from "../../../layouts/simple_modal/simple_modal.service"

@Component({
    selector: "clasificaciones-asociadas",
    templateUrl: "./clasificaciones-asociadas.component.html",
    styleUrls: ["./clasificaciones-asociadas.component.scss"]
})
export class ClasificacionesAsociadasComponent implements OnInit {
    @Input() clasificaciones: UsuarioRecursoTipo[]
    @Input() recurso
    @Output() grupoEliminado = new EventEmitter()

    grupoPorRemover: UsuarioRecursoTipo

    constructor(
        protected usuarioRecursoTiposService: UsuarioRecursoTipos,
        protected router: Router,
        protected sms: SimpleModalService
    ) {}

    ngOnInit() {}

    removerGrupo() {
        this.usuarioRecursoTiposService.remove(this.grupoPorRemover.id).then(r => {
            this.clasificaciones.splice(this.clasificaciones.indexOf(this.grupoPorRemover), 1)
            this.grupoPorRemover = null
            this.grupoEliminado.emit()
        })
    }

    confirmarRemover(grupo: UsuarioRecursoTipo, numero: number) {
        this.grupoPorRemover = grupo
        this.sms.setModalHeader("Eliminando Grupo")
        this.sms.setModalStringContent(`Esta seguro de eliminar el grupo ${numero} ?`)
        this.sms.setModalCallback(() => this.removerGrupo())
        this.sms.showSimpleModal()
    }
}
