import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ReporteRazon, ReporteRazones, ReporteRazonForm } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "reporte_razones.form.component.html"
})
export class ReporteRazonesEditComponent implements OnInit, OnDestroy {
    oReporteRazon: ReporteRazon = new ReporteRazon()
    reporteRazon: ReporteRazon = new ReporteRazon()
    params = ReporteRazonForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar razón de reporte"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private reporteRazonesService: ReporteRazones,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = ReporteRazonForm.getForm(this.reporteRazon)
        this.sub = this.route.params.subscribe(params => {
            this.reporteRazonesService.find(+params["id"]).then((response: ReporteRazon) => {
                this.reporteRazon = response
                this.oReporteRazon = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        ReporteRazonForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.reporteRazonesService.update(this.reporteRazon.id, this.reporteRazon).then(response => {
                this.router.navigate(["reporte_razones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.reporteRazon = this.oReporteRazon
            ReporteRazonForm.markFormControlsAsPristine(this.form)
            ReporteRazonForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
