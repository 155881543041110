import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Plataforma,
    Plataformas,
    TipoInstrumentos,
    TipoInstrumento,
    TipoInstrumentoPlataforma,
    TipoInstrumentoPlataformas,
    TipoInstrumentoPlataformaForm
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "tipo_instrumento_plataformas.form.component.html"
})
export class TipoInstrumentoPlataformasEditComponent implements OnInit, OnDestroy {
    oTipoInstrumentoPlataforma: TipoInstrumentoPlataforma = new TipoInstrumentoPlataforma()
    tipoInstrumentoPlataforma: TipoInstrumentoPlataforma = new TipoInstrumentoPlataforma()

    params = TipoInstrumentoPlataformaForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    displayTipoEdit: boolean = true
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar alias de tipo de instrumento por plataforma"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    plataformas: Plataforma[]
    plataformaId: number
    tipoInstrumentos: TipoInstrumento[]
    tipoInstrumentoId: number

    constructor(
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = TipoInstrumentoPlataformaForm.getForm(this.tipoInstrumentoPlataforma)
        this.sub = this.route.params.subscribe(params => {
            this.tipoInstrumentoPlataformasService.find(+params["id"]).then((response: TipoInstrumentoPlataforma) => {
                this.tipoInstrumentoPlataforma = response
                this.oTipoInstrumentoPlataforma = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        TipoInstrumentoPlataformaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.tipoInstrumentoPlataformasService
                .update(this.tipoInstrumentoPlataforma.id, this.tipoInstrumentoPlataforma)
                .then(response => {
                    this.router.navigate(["tipo_instrumento_plataformas"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.tipoInstrumentoPlataforma = this.oTipoInstrumentoPlataforma.clone()
            TipoInstrumentoPlataformaForm.markFormControlsAsPristine(this.form)
            TipoInstrumentoPlataformaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
