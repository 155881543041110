import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PerfilComponent } from "./perfil.component"
import { PerfilesComponent } from "./perfiles.component"
import { PerfilesDeleteComponent } from "./perfiles.delete.component"
import { PerfilesEditComponent } from "./perfiles.edit.component"
import { PerfilesNewComponent } from "./perfiles.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const perfilesRoutes: Routes = [
    {
        path: "perfiles",
        component: PerfilesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Perfiles#index" }
    },
    {
        path: "perfiles/new",
        component: PerfilesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Perfiles#create" }
    },
    {
        path: "perfiles/:id",
        component: PerfilComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Perfiles#show" }
    },
    {
        path: "perfiles/:id/edit",
        component: PerfilesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Perfiles#update" }
    },
    {
        path: "perfiles/:id/delete",
        component: PerfilesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Perfiles#destroy" }
    }
]

export const perfilesRouting = RouterModule.forChild(perfilesRoutes)
