import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { PlanEstudio, PlanEstudios, PlanEstudioView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    selector: "plan_estudios",

    templateUrl: "plan_estudios.component.html"
})
export class PlanEstudiosComponent implements OnInit {
    @Input() planEstudios: PlanEstudio[]
    show_params = PlanEstudioView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        private planEstudiosService: PlanEstudios,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            sesiones: {
                function: this.sesiones.bind(this),
                text: "Sesiones",
                glyphicon: "glyphicon-list",
                permiso: "PlanEstudioSesiones#index"
            },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        /* this.router.routerState.root.queryParams.subscribe(params => {
      var page = +params["page"];
      var per = +params["per"];
      if(isNaN(page)) {
        page = 1;
      }
      if(isNaN(per)) {
        per = 10;
      }
      this.showAll(page, per);
    }); */
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    /*  showAll(page: number = 1, per: number = 10) {
    return this.planEstudiosService.where({"page":page, "per": per}).then((response: PlanEstudio[], total: number) => {
      this.planEstudios = response;
      this.loadingLayout.ready();
      return total;
    });
  } */

    view(planEstudio: PlanEstudio) {
        this.router.navigate(["plan_estudios/" + planEstudio.id])
    }

    sesiones(planEstudio: PlanEstudio) {
        this.router.navigate(["plan_estudios/" + planEstudio.id + "/sesiones"])
    }

    edit(planEstudio: PlanEstudio) {
        this.router.navigate(["plan_estudios/" + planEstudio.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["plan_estudios/" + id + "/delete"])
    }

    showModalDelete(planEstudio: PlanEstudio) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar el plan de estudio '" + planEstudio.plan_estudio + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(planEstudio.id))
    }
}
