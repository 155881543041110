import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { BasePolicyService } from "./base-policy.service"
import { PermisoDirective } from "./permiso.directive"
import { AsignaturasPolicyService } from "./asignaturas-policy.service"
import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    declarations: [PermisoDirective],
    providers: [BasePolicyService, AsignaturasPolicyService],
    imports: [CommonModule, UtilModule],
    exports: [PermisoDirective]
})
export class PoliciesModule {}
