<form class="formulario" *ngIf="open">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>Tipo Clasificación</label>
                <input
                    class="form-control"
                    name="clasificacionTipo"
                    type="text"
                    [(ngModel)]="searchParams.clasificacionTipo.like.clasificacionTipo"
                    (ngModelChange)="deleteIfUndefined(searchParams.clasificacionTipo.like, 'clasificacionTipo')"
                />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Id</label>
                <input
                    class="form-control"
                    name="clasificacionTipo"
                    type="text"
                    [(ngModel)]="searchParams.clasificacionTipo.id"
                    (ngModelChange)="deleteIfUndefined(searchParams.clasificacionTipo, 'id')"
                />
            </div>
        </div>
    </div>
    <button class="btn btn-default" (click)="search()">Buscar</button>
</form>
