import { BaseForm } from "@puntaje/shared/core"
import { AlternativaForm } from "./alternativas.form"

export class ContestableForm extends BaseForm {
    public static get formParams(): any {
        return {
            alternativas: { type: "models", class: AlternativaForm }
        }
    }
}
