import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PlanEstudioComponent } from "./plan_estudio.component"
import { PlanEstudiosComponent } from "./plan_estudios.component"
import { PlanEstudiosDeleteComponent } from "./plan_estudios.delete.component"
import { PlanEstudiosEditComponent } from "./plan_estudios.edit.component"
import { PlanEstudiosNewComponent } from "./plan_estudios.new.component"
import { PlanEstudiosSesionesComponent } from "./plan_estudios.sesiones.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { PlanEstudiosListComponent } from "./plan-estudios-list/plan-estudios-list.component"

export const planEstudiosRoutes: Routes = [
    {
        path: "plan_estudios",
        component: PlanEstudiosListComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "PlanEstudios#index" }
    },
    {
        path: "plan_estudios/new",
        component: PlanEstudiosNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "PlanEstudios#create" }
    },
    {
        path: "plan_estudios/:id",
        component: PlanEstudioComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "PlanEstudios#show" }
    },
    {
        path: "plan_estudios/:id/edit",
        component: PlanEstudiosEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "PlanEstudios#update" }
    },
    {
        path: "plan_estudios/:id/sesiones",
        component: PlanEstudiosSesionesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "PlanEstudios#update" }
    },
    {
        path: "plan_estudios/:id/delete",
        component: PlanEstudiosDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "PlanEstudios#destroy" }
    }
]

export const planEstudiosRouting = RouterModule.forChild(planEstudiosRoutes)
