import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { ReporteSubRazonComponent } from "./reporte_sub_razon.component"
import { ReporteSubRazonesComponent } from "./reporte_sub_razones.component"
import { ReporteSubRazonesDeleteComponent } from "./reporte_sub_razones.delete.component"
import { ReporteSubRazonesEditComponent } from "./reporte_sub_razones.edit.component"
import { ReporteSubRazonesNewComponent } from "./reporte_sub_razones.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const reporteSubRazonesRoutes: Routes = [
    {
        path: "reporte_sub_razones",
        component: ReporteSubRazonesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteSubRazones#index" }
    },
    {
        path: "reporte_sub_razones/new",
        component: ReporteSubRazonesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteSubRazones#create" }
    },
    {
        path: "reporte_sub_razones/:id",
        component: ReporteSubRazonComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteSubRazones#show" }
    },
    {
        path: "reporte_sub_razones/:id/edit",
        component: ReporteSubRazonesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteSubRazones#update" }
    },
    {
        path: "reporte_sub_razones/:id/delete",
        component: ReporteSubRazonesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteSubRazones#destroy" }
    }
]

export const reporteSubRazonesRouting = RouterModule.forChild(reporteSubRazonesRoutes)
