import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Reporte, ReporteAgrupadoView, Reportes } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { ReportesBuscadorService } from "./reportes_buscador.service"

@Component({
    templateUrl: "reportes_agrupados.component.html"
})
export class ReportesAgrupadosComponent implements OnInit, OnDestroy {
    reportes: Reporte[]
    show_params = ReporteAgrupadoView.viewParams
    tableButtonsArray: any
    sub: Subscription
    searchParams: any = {}
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    tablaToRoute = { Material: "materiales", Pregunta: "preguntas" }

    constructor(
        private reportesService: Reportes,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected reportesBuscadorService: ReportesBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: {
                function: this.view.bind(this),
                text: "Ver",
                callModal: false,
                glyphicon: "glyphicon-search",
                permisoResolver: (reporte: Reporte) => {
                    if (reporte.tabla == "Material") return "Materiales#show"
                    if (reporte.tabla == "Pregunta") return "Preguntas#show"
                    return ""
                }
            }
        }

        this.sub = this.reportesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number, count: number) => {
        let params = {
            reporte: {
                ...this.searchParams.reporte
            },
            page,
            per: count
        }

        return this.reportesService.grouped(params).then((reportes: Reporte[], total: number) => {
            this.reportes = reportes
            this.loadingLayout.ready()

            return total
        })
    }

    view(reporte: Reporte) {
        this.router.navigate([this.tablaToRoute[reporte.tabla] + "/" + reporte.entidad_id])
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
