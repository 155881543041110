import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { GrupoPregunta, GrupoPreguntaForm, GrupoPreguntas } from "@puntaje/nebulosa/api-services"
import { Main, PdfView } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"

@Component({
    templateUrl: "grupo_preguntas.form.component.html"
})
export class GrupoPreguntasEditComponent implements OnInit, OnDestroy {
    oGrupoPregunta: GrupoPregunta = new GrupoPregunta()
    grupoPregunta: GrupoPregunta = new GrupoPregunta()
    params = GrupoPreguntaForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar grupo pregunta"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    latexSub: Subscription
    pressedButton: boolean = false
    paramsPreviewLatex: any

    constructor(
        private grupoPreguntasService: GrupoPreguntas,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = GrupoPreguntaForm.getForm(this.grupoPregunta)
        this.sub = this.route.params.subscribe(params => {
            this.grupoPreguntasService.find(+params["id"]).then((response: GrupoPregunta) => {
                this.grupoPregunta = response
                this.oGrupoPregunta = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
        this.latexSub = this.form
            .get("texto_latex")
            .valueChanges.pipe(
                filter(x => x),
                distinctUntilChanged(),
                debounceTime(2000)
            )
            .subscribe(this.updatePreviewLatex.bind(this))
    }

    ngOnDestroy() {
        this.latexSub.unsubscribe()
        this.sub.unsubscribe()
    }

    save() {
        GrupoPreguntaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.pressedButton = true
            this.grupoPreguntasService.update(this.grupoPregunta.id, this.grupoPregunta).then(response => {
                this.router.navigate(["grupo_preguntas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.grupoPregunta = this.oGrupoPregunta.clone()
            GrupoPreguntaForm.markFormControlsAsPristine(this.form)
            GrupoPreguntaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    toLatex(model, from, to) {
        if (model[from]) {
            model[to] = Main.html2latex(model[from])
        }
    }

    updatePreviewLatex() {
        const params: any = {
            grupo_pregunta: {
                ...this.grupoPregunta
            }
        }

        this.paramsPreviewLatex = params
    }
}
