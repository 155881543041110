<!-- Modal -->
<div id="simpleModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="cleanModal()">&times;</button>
                <h4 class="modal-title">{{ header }}</h4>
            </div>
            <div class="modal-body">
                <p *ngIf="contentString != ''">{{ contentString }}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onClickOk()">Ok</button>
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cleanModal()">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</div>
