import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import {
    Asignatura,
    Asignaturas,
    ClasificacionTipos,
    ClasificacionTipo,
    Clasificaciones,
    Clasificacion,
    Usuario,
    UsuarioRecursoTipos,
    UsuarioRecursoTipo,
    CriterioAsignacion
} from "@puntaje/nebulosa/api-services"
import { UsuariosPolicyService } from "../../../modules/policies/usuarios-policy.service"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "asociar-clasificaciones",
    templateUrl: "./asociar-clasificaciones.component.html",
    styleUrls: ["./asociar-clasificaciones.component.scss"]
})
export class AsociarClasificacionesComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    //PASO 1 ELEGIR GRUPO
    seleccionandoGrupo = true
    grupos: UsuarioRecursoTipo[]
    grupoSeleccionado: UsuarioRecursoTipo

    //PASO 2 AGREGAR CLASIFICACIONES
    arreglo: {
        asignaturas: Asignatura[]
        clasificacionTipos: ClasificacionTipo[]
        clasificaciones: Clasificacion[]
        clasificacionTipo: ClasificacionTipo
        asignatura: Asignatura
        clasificacion: Clasificacion
    }[] = []

    clasificacionTipoAgregadas = []
    asignaturasAgregadas = []
    clasificacionAgregadas = []

    criterioAsignaciones: CriterioAsignacion[] = []
    usuarioRecursoTipos: UsuarioRecursoTipo
    showFailureFeedback: boolean = false
    mensaje: string = ""
    @Output() changeView = new EventEmitter<any>()
    @Input() recurso: any
    @Input() usuario: Usuario

    constructor(
        private asignaturasService: Asignaturas,
        private clasificacionTiposService: ClasificacionTipos,
        private clasificacionesService: Clasificaciones,
        private usuarioRecursoTiposService: UsuarioRecursoTipos,
        private authService: AuthService,
        private usuariosPolicyService: UsuariosPolicyService
    ) {}

    ngOnInit() {
        if (!this.usuariosPolicyService.indexAsociados()) {
            // ADMIN
            this.seleccionandoGrupo = false
            this.getdataPrincipal()
        } else {
            this.getGrupos()
            this.loadingLayout.ready()
        }
    }

    async getGrupos() {
        const params = {
            usuario_recurso_tipo: { usuario_id: this.authService.getUserData().id },
            render_options: {
                include: {
                    criterio_asignaciones: {
                        include: { criterio: null }
                    }
                }
            }
        }
        this.usuarioRecursoTiposService.where(params).then(grupos => {
            this.grupos = grupos
        })
    }

    seleccionarGrupo(grupo: UsuarioRecursoTipo) {
        this.loadingLayout.standby()
        this.grupoSeleccionado = grupo
        this.getdataPrincipal()
        this.seleccionandoGrupo = false
    }

    deseleccionarGrupo() {
        this.grupoSeleccionado = null
        this.seleccionandoGrupo = true
    }

    async getdataPrincipal() {
        if (!this.grupoSeleccionado) {
            let asignaturas = (await this.asignaturasService.where({ plataforma: "Puntaje Nacional" })) as Asignatura[]
            let clasificacionTipos = (await this.clasificacionTiposService.where()) as ClasificacionTipo[]

            this.arreglo.push({
                asignaturas,
                clasificacionTipos,
                clasificaciones: [],
                clasificacionTipo: undefined,
                asignatura: undefined,
                clasificacion: undefined
            })
        } else {
            let asignaturas
            if (this.grupoSeleccionado.asignaturaIds().length == 0) {
                asignaturas = await this.asignaturasService.where()
            } else {
                asignaturas = await this.asignaturasService.where({
                    asignatura: { id: this.grupoSeleccionado.asignaturaIds() }
                })
            }

            let clasificacionTipos = await this.clasificacionTiposService.where({
                clasificacion_tipo: { id: this.grupoSeleccionado.clasificacionTipoIds() }
            })

            let clasificaciones = await this.clasificacionesService.where({
                clasificacion: { id: this.grupoSeleccionado.clasificacionIds() }
            })

            clasificacionTipos.forEach(ct => {
                const inner_clasificaciones = clasificaciones.filter(c => c.clasificacion_tipo_id == ct.id)
                const options = {
                    asignaturas,
                    clasificacionTipos: [ct],
                    clasificaciones: inner_clasificaciones,
                    clasificacionTipo: ct,
                    asignatura: asignaturas.length == 1 ? asignaturas[0] : undefined,
                    clasificacion: inner_clasificaciones.length == 1 ? inner_clasificaciones[0] : undefined
                }
                if (clasificaciones.length == 0) this.getClasificaciones(options)
                this.arreglo.push(options)
            })
        }

        this.loadingLayout.ready()
    }

    getClasificaciones(objeto: any) {
        objeto.clasificacion = undefined
        objeto.clasificaiones = []
        let params = {
            clasificacion_tipo: { id: objeto.clasificacionTipo.id }
        }
        if (objeto.asignatura) {
            params["asignatura_id"] = objeto.asignatura.id
            this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[]) => {
                objeto.clasificaciones = clasificaciones
            })
        }
    }

    mensajeError(mensaje: string) {
        this.mensaje = mensaje
        this.showFailureFeedback = true
        setTimeout(() => {
            this.showFailureFeedback = false
        }, 5000)
    }

    agregar() {
        //VALIDACIONES
        const asignaturas = this.arreglo.map(el => el.asignatura).filter((el, idx, self) => self.indexOf(el) === idx)
        console.table(asignaturas)
        if (asignaturas.length > 1) return this.mensajeError("No puede haber más de una asignatura asociada")
        if (asignaturas.length == 1) {
            const asignatura = asignaturas[0]
            if (this.asignaturasAgregadas.length > 0 && !this.asignaturasAgregadas.includes(asignatura))
                return this.mensajeError("No puede haber más de una asignatura asociada")
        }

        const clasificaciones = this.arreglo.map(el => el.clasificacion).filter(el => !!el)
        console.table(clasificaciones)
        if (
            clasificaciones.length > 0 &&
            (clasificaciones.length != this.arreglo.length || this.clasificacionTipoAgregadas.length > 0)
        )
            return this.mensajeError("No se pueden mezclar clasificaciones con tipos de clasificacion")

        const clasificacionTipos = this.arreglo.map(el => el.clasificacionTipo).filter(el => !!el)
        if (clasificacionTipos.length > 0 && this.clasificacionAgregadas.length > 0 && clasificaciones.length == 0) {
            return this.mensajeError("No se pueden mezclar clasificaciones con tipos de clasificacion")
        }

        //AGREGAR AL ARREGLO FINAL
        for (let i in this.arreglo) {
            const elemento = this.arreglo[i]
            if (elemento.clasificacion) {
                if (!this.clasificacionAgregadas.includes(elemento.clasificacion)) {
                    this.clasificacionAgregadas.push(elemento.clasificacion)
                }
                continue
            }

            if (elemento.asignatura) {
                if (this.asignaturasAgregadas.length == 0) this.asignaturasAgregadas.push(elemento.asignatura)
            }

            if (elemento.clasificacionTipo) {
                if (!this.clasificacionTipoAgregadas.includes(elemento.clasificacionTipo)) {
                    this.clasificacionTipoAgregadas.push(elemento.clasificacionTipo)
                }
            }
        }
    }

    quitar(id: number, tipo: string) {
        if (tipo == "clasificacionTipo") {
            for (var i = 0; i < this.clasificacionTipoAgregadas.length; i++) {
                if (this.clasificacionTipoAgregadas[i].id == id) this.clasificacionTipoAgregadas.splice(i, 1)
            }
        }

        if (tipo == "asignatura") {
            for (var i = 0; i < this.asignaturasAgregadas.length; i++) {
                if (this.asignaturasAgregadas[i].id == id) this.asignaturasAgregadas.splice(i, 1)
            }
        }

        if (tipo == "clasificacion") {
            for (var i = 0; i < this.clasificacionAgregadas.length; i++) {
                if (this.clasificacionAgregadas[i].id == id) this.clasificacionAgregadas.splice(i, 1)
            }
        }
    }

    goList(data) {
        this.changeView.emit(data)
    }

    reset() {
        this.clasificacionTipoAgregadas = []
        this.asignaturasAgregadas = []
        this.clasificacionAgregadas = []
        // this.asignatura = null
        // this.clasificacion = null
        // this.clasificacionTipo = null
    }

    asociarClasificaciones() {
        this.loadingLayout.standby()
        this.getParams()
        this.usuarioRecursoTipos = new UsuarioRecursoTipo()
        ;(this.usuarioRecursoTipos.usuario_id = this.usuario.id),
            (this.usuarioRecursoTipos.tipo_recurso = this.recurso.recurso),
            (this.usuarioRecursoTipos.criterio_asignaciones = this.criterioAsignaciones)

        this.usuarioRecursoTiposService
            .save(this.usuarioRecursoTipos)
            .then((usuarioRecursoTipo: UsuarioRecursoTipo) => {
                this.reset()
                this.loadingLayout.ready()
            })
    }

    async getParams() {
        if (this.clasificacionTipoAgregadas.length > 0) {
            let criterioAsignacion = new CriterioAsignacion()
            for (let clasificacionTipo of this.clasificacionTipoAgregadas) {
                criterioAsignacion.criterio_type = "ClasificacionTipo"
                criterioAsignacion.criterio_id = clasificacionTipo.id
                this.criterioAsignaciones.push(criterioAsignacion)
            }
        }
        if (this.clasificacionAgregadas.length > 0) {
            for (let clasificacion of this.clasificacionAgregadas) {
                let criterioAsignacion = new CriterioAsignacion()
                criterioAsignacion.criterio_type = "Clasificacion"
                criterioAsignacion.criterio_id = clasificacion.id
                this.criterioAsignaciones.push(criterioAsignacion)
            }
        }
        if (this.asignaturasAgregadas.length > 0) {
            for (let asignatura of this.asignaturasAgregadas) {
                let criterioAsignacion = new CriterioAsignacion()
                criterioAsignacion.criterio_type = "Asignatura"
                criterioAsignacion.criterio_id = asignatura.id
                this.criterioAsignaciones.push(criterioAsignacion)
            }
        }
    }
}
