import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { SimpleModalService } from "./simple_modal.service"

/*#################################

Template de modal simple

#################################*/

@Component({
    templateUrl: "simple_modal.component.html",
    selector: "simple-modal",
    styleUrls: ["simple_modal.component.css"]
})
export class SimpleModalComponent implements OnInit, OnDestroy {
    public header: string
    public contentString: string
    headerSubscription: Subscription
    contentStringSubscription: Subscription

    constructor(private simpleModalService: SimpleModalService) {}

    ngOnInit() {
        this.header = this.simpleModalService.header
        this.contentString = this.simpleModalService.contentString

        this.headerSubscription = this.simpleModalService.headerSubject.subscribe(header => (this.header = header))
        this.contentStringSubscription = this.simpleModalService.contentStringSubject.subscribe(
            contentString => (this.contentString = contentString)
        )
    }

    onClickOk() {
        this.simpleModalService.callback()
        this.cleanModal()
    }

    cleanModal() {
        this.simpleModalService.cleanModal()
    }

    ngOnDestroy() {
        this.headerSubscription.unsubscribe()
        this.contentStringSubscription.unsubscribe()
    }
}
