import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PopoverModule } from "ngx-bootstrap"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { AsociarRecursosIdsComponent } from "./asociar_recursos_ids.component"
import { TabRecursosAsociadosComponent } from "./tab-recursos-asociados/tab-recursos-asociados.component"
import { ClasificacionesAsociadasComponent } from "./clasificaciones-asociadas/clasificaciones-asociadas.component"
import { RecursosAsociadosComponent } from "./recursos-asociados/recursos-asociados.component"
import { AsociarClasificacionesComponent } from "./asociar-clasificaciones/asociar-clasificaciones.component"
import { PreguntasModule } from "../preguntas/preguntas.module"
import { GrupoPreguntasModule } from "../grupo_preguntas/grupo_preguntas.module"
import { LotesModule } from "../lotes/lotes.module"
import { PlanEstudiosModule } from "../plan_estudios/plan_estudios.module"
import { MaterialesModule } from "../materiales/materiales.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        LayoutsModule,
        ModelViewModule,
        FormModule,
        ReactiveFormsModule,
        PaginatorModule,
        PopoverModule,
        Angular2FontawesomeModule,
        NebuModule,
        PreguntasModule,
        GrupoPreguntasModule,
        LotesModule,
        PlanEstudiosModule,
        MaterialesModule
    ],
    declarations: [
        AsociarRecursosIdsComponent,
        TabRecursosAsociadosComponent,
        ClasificacionesAsociadasComponent,
        RecursosAsociadosComponent,
        AsociarClasificacionesComponent
    ],
    exports: [
        AsociarRecursosIdsComponent,
        TabRecursosAsociadosComponent,
        ClasificacionesAsociadasComponent,
        RecursosAsociadosComponent,
        AsociarClasificacionesComponent
    ]
})
export class UsuarioRecursosModule {}
