import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

declare var $: any

@Injectable()

//interface callbackType { (myArgument: string): void }
export class SimpleModalService {
    public callback: () => void
    public header: string
    public contentString: string

    public headerSubject: Subject<any> = new Subject()
    public contentStringSubject: Subject<any> = new Subject()

    setModalHeader(header: string) {
        this.header = header
        this.headerSubject.next(this.header)
    }

    setModalStringContent(contentString: string) {
        this.contentString = contentString
        this.contentStringSubject.next(this.contentString)
    }

    setModalCallback(callback: () => void) {
        this.callback = callback
    }

    toggleSimpleModal() {
        $("#simpleModal").modal("toggle")
    }

    showSimpleModal() {
        $("#simpleModal").modal("show")
    }

    closeSimpleModal() {
        $("#simpleModal").modal("hide")
    }

    cleanModal() {
        this.callback = null
        this.header = ""
        this.contentString = ""
    }
}
