<logged-layout>
    <logged-layout-toolnavbar>
        <generador-instrumentos-toolnavbar [displayButtons]="'new'"></generador-instrumentos-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Generador Instrumentos</logged-layout-titulo>
    <logged-layout-contenido>
        <generador-instrumentos-buscador></generador-instrumentos-buscador>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="generadores"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
                resourceName="GeneradorInstrumentos"
            ></table-with-actions>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
