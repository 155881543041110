import { CSSParser } from "./CSSParser"
import { CSSParserHandler } from "./CSSParserHandler"
import { CSSStyle } from "./CSSStyle"
import { Main } from "./Main"
import { FatalErrorException, NoItemException } from "./FatalErrorException"

import configXmlString from "./config"

import { java, javaemul } from "j4ts"

import HashMap = java.util.HashMap

import Iterator = java.util.Iterator

import Map = java.util.Map

//import DocumentBuilderFactory = javax.xml.parsers.DocumentBuilderFactory;

type DocumentBuilder = DOMParser

type Document = XMLDocument

//import Element = org.w3c.dom.Element;

type NodeList = NodeListOf<Element>

/**
 * Program configuration.
 * All of the configuration from the XML configuration file
 * is stored in this class.
 */
export class Configuration {
    /**
     * Mapping between HTML elements and LaTeX commands. <br />
     * key : &lt;elementName&gt;
     */
    private _elements: HashMap<string, ElementConfigItem>

    /**
     * Mapping between HTML named entities (ie. &amp;lt;) and LaTeX commands. <br />
     * key : &lt;entityName&gt; <br />
     * value : LaTeX command
     */
    private _chars: HashMap<string, string>

    /**
     * Mapping between HTML decimal decimal entities and LaTeX commands. <br />
     * key : &lt;entityDecimalNum&gt; <br />
     * value : LaTeX command
     */
    private _charsNum: HashMap<number, string>

    /**
     * CSS styles used with converted file. <br />
     * key : &lt;styleName&gt;
     */
    private _styles: HashMap<string, CSSStyle>

    /**
     * Mappings between CSS properties and LaTeX commands.<br />
     * key : &lt;propertyName&gt;-&lt;propertyValue&gt;
     */
    private _stylesConf: HashMap<string, CSSPropertyConfigItem>

    /**
     * The way of converting hyperlinks.
     */
    private _linksConversion: LinksConversion

    /**
     * Make new LaTeX commands from the CSS styles.
     */
    private _makeCmdsFromCSS: boolean = false

    /**
     * Prefix of new LaTeX commands generated by the program.
     */
    private _commandsPrefix: string = ""

    /**
     * Loads all the configuration.
     * @throws FatalErrorException when error during processing configuration occurs
     */
    public constructor() {
        this._elements = new HashMap<string, ElementConfigItem>(100)
        this._chars = new HashMap<string, string>(250)
        this._charsNum = new HashMap<number, string>(250)
        this._styles = new HashMap<string, CSSStyle>(20)
        this._stylesConf = new HashMap<string, CSSPropertyConfigItem>(50)
        try {
            //var factory : DocumentBuilderFactory = DocumentBuilderFactory.newInstance();
            // let configXmlString: string = require("./config.xml");
            // let configXmlString: string = require("./config.xml!text");
            // var xhrObj: any = new XMLHttpRequest();
            // xhrObj.open('GET','./config.xml', false);
            // xhrObj.send('');
            // let configXmlString: string = xhrObj.responseText;
            var builder: DocumentBuilder = new DOMParser()
            var document: Document = builder.parseFromString(configXmlString, "text/xml")
            var root: Element = document.documentElement
            this.loadElementsConfiguration(root)
            this.loadLinksConfiguration(root)
            this.loadCharsConfiguration(root)
            this.loadStylesConfiguration(root)
            //if(!(Main.getCSSFile() === "")) this.loadStyleSheet(new File(Main.getCSSFile()));
        } catch (e: any) {
            throw new FatalErrorException("Can't load configuration.\n" + e.message)
        }
    }

    /**
     * Loads mapping between HTML elements and LaTeX commands.
     * @param root root element of the XML configuration file
     */
    private loadElementsConfiguration(root: Element) {
        var nl: HTMLCollectionOf<Element> = root.getElementsByTagName("element")
        for (var i: number = 0; i < nl.length; i++) {
            var e: Element = <Element>nl.item(i)
            var elementName: string = e.getAttribute("name")
            var leaveText: string = e.getAttribute("leaveText")
            var ignoreContent: string = e.getAttribute("ignoreContent")
            var ignoreStyles: string = e.getAttribute("ignoreStyle")
            var nl2: HTMLCollectionOf<Element> = e.getElementsByTagName("start")
            var elementStart: string = nl2.item(0).textContent
            nl2 = e.getElementsByTagName("end")
            var elementEnd: string = nl2.item(0).textContent
            this._elements.put(
                elementName,
                new ElementConfigItem(
                    this.replaceSpecialStrings(elementStart),
                    this.replaceSpecialStrings(elementEnd),
                    leaveText,
                    ignoreContent,
                    ignoreStyles
                )
            )
        }
    }

    /**
     * Loads mapping between HTML named entities (ie. &amp;lt;) and LaTeX commands.
     * Mappings between HTML decimal decimal entities and LaTeX commands are also
     * loaded.
     * @param root root element of the XML configuration file
     */
    private loadCharsConfiguration(root: Element) {
        var nl: HTMLCollectionOf<Element> = root.getElementsByTagName("char")
        for (var i: number = 0; i < nl.length; i++) {
            var e: Element = <Element>nl.item(i)
            var charName: string = e.getAttribute("name")
            var convertTo: string = e.getAttribute("convertTo")
            var charNum: string = e.getAttribute("num")
            try {
                if (!(charNum === "")) {
                    var charNumInt: number = javaemul.internal.IntegerHelper.valueOf(charNum)
                    this._charsNum.put(charNumInt, this.replaceSpecialStrings(convertTo))
                }
            } catch (ex) {
                console.error("Error in configuration.\n" + ex.toString())
            }
            this._chars.put(charName, this.replaceSpecialStrings(convertTo))
        }
        nl = root.getElementsByTagName("charNum")
        for (var i: number = 0; i < nl.length; i++) {
            var e: Element = <Element>nl.item(i)
            var convertTo: string = e.getAttribute("convertTo")
            var charNum: string = e.getAttribute("num")
            try {
                if (!(charNum === "")) {
                    var charNumInt: number = javaemul.internal.IntegerHelper.valueOf(charNum)
                    this._charsNum.put(charNumInt, this.replaceSpecialStrings(convertTo))
                }
            } catch (ex) {
                console.error("Error in configuration.\n" + ex.toString())
            }
        }
    }

    /**
     * Loads {@link LinksConversion options} for converting hyperlinks.
     * @param root root element of the XML configuration file
     */
    private loadLinksConfiguration(root: Element) {
        var nl: HTMLCollectionOf<Element> = root.getElementsByTagName("links")
        var links: Element = <Element>nl.item(0)
        var type: string = links.getAttribute("conversion")
        if (type === "footnotes") this._linksConversion = LinksConversion.FOOTNOTES
        else if (type === "biblio") this._linksConversion = LinksConversion.BIBLIO
        else if (type === "hypertex") this._linksConversion = LinksConversion.HYPERTEX
        else this._linksConversion = LinksConversion.IGNORE
    }

    /**
     * Loads mappings between CSS properties and LaTeX commands.
     * @param root root element of the XML configuration file
     */
    private loadStylesConfiguration(root: Element) {
        var nl: HTMLCollectionOf<Element> = root.getElementsByTagName("cssStyles")
        if (nl.length === 1) {
            var elem: Element = <Element>nl.item(0)
            var makeCmds: string = elem.getAttribute("makeCommands")
            this._commandsPrefix = elem.getAttribute("commandsPrefix")
            if (makeCmds === "yes") this._makeCmdsFromCSS = true
        }
        nl = root.getElementsByTagName("property")
        for (var i: number = 0; i < nl.length; i++) {
            var e: Element = <Element>nl.item(i)
            var propertyName: string = e.getAttribute("name")
            var nl2: HTMLCollectionOf<Element> = e.getElementsByTagName("value")
            for (var j: number = 0; j < nl2.length; j++) {
                var el: Element = <Element>nl2.item(j)
                var propertyValue: string = el.getAttribute("name")
                var start: string = el.getAttribute("start")
                var end: string = el.getAttribute("end")
                this._stylesConf.put(
                    propertyName + "-" + propertyValue,
                    new CSSPropertyConfigItem(this.replaceSpecialStrings(start), this.replaceSpecialStrings(end))
                )
                if (el.hasAttribute("startTable") && el.hasAttribute("endTable")) {
                    start = el.getAttribute("startTable")
                    end = el.getAttribute("endTable")
                    this._stylesConf.put(
                        propertyName + "-" + propertyValue + "-" + "table",
                        new CSSPropertyConfigItem(this.replaceSpecialStrings(start), this.replaceSpecialStrings(end))
                    )
                }
            }
        }
    }

    /**
     * Loads user style sheet.
     * @param f CSS file
     */
    private loadStyleSheet(f: File) {
        var parser: CSSParser = new CSSParser()
        parser.parse(f, new CSSParserHandler(this))
        for (var iterator: Iterator<any> = this._styles.entrySet().iterator(); iterator.hasNext(); ) {
            var entry: Map.Entry<any, any> = <Map.Entry<any, any>>iterator.next()
            var style: CSSStyle = <CSSStyle>entry.getValue()
            style.makeLaTeXCommands(this)
        }
    }

    /**
     * Replaces special @-strings with appropriate strings
     * (ie. &quot;@NL&quot; with &quot;\n&quot;).
     * @return string without special @-strings
     * @param str input string
     */
    private replaceSpecialStrings(str: string): string {
        str = str
            .replace(/@NL/g, "\n")
            .replace(/@TAB/g, "\t")
            .replace(/@QUOT/g, "'")
            .replace(/@DOUBLEQUOT/g, '"')
            .replace(/@AMP/g, "&")
            .replace(/@LT/g, "<")
            .replace(/@GT/g, ">")
        return str
    }

    public inText: boolean = false

    /**
     * Returns element's configuration.
     * @param name element's name
     * @return element's configuration
     * @throws NoItemException when element isn't found in the configuration
     */
    public getElement(name: string): ElementConfigItem {
        var item: ElementConfigItem
        //console.log(name);
        //console.log(this._elements);
        if ((item = this._elements.get(name)) != null) return item
        throw new NoItemException(name)
    }

    /**
     * Returns the way of converting hyperlinks.
     * @return the way of converting hyperlinks
     */
    public getLinksConversionType(): LinksConversion {
        return this._linksConversion
    }

    /**
     * Returns LaTeX command for the specified entity.
     * @param charName entity name
     * @return LaTeX command for the specified entity
     * @throws NoItemException when entity isn't found in the configuration
     */
    public getChar(charName?: any): any {
        if (typeof charName === "string" || charName === null) {
            return <any>(() => {
                var convertTo: string
                if ((convertTo = this._chars.get(charName)) != null) return convertTo
                throw new NoItemException(charName)
            })()
        } else if (typeof charName === "number" || charName === null) {
            return <any>this.getChar$java_lang_Integer(charName)
        } else throw new Error("invalid overload")
    }

    /**
     * Returns LaTeX command for the specified entity.
     * @param charNum entity number
     * @return LaTeX command for the specified entity
     * @throws NoItemException when entity isn't found in the configuration
     */
    public getChar$java_lang_Integer(charNum: number): string {
        var convertTo: string
        if ((convertTo = this._charsNum.get(charNum)) != null) return convertTo
        throw new NoItemException(charNum.toString())
    }

    /**
     * Returns style defined in the user stylesheet.
     * @param styleName style name
     * @return style defined in the user stylesheet
     */
    public getStyle(styleName: string): CSSStyle {
        return this._styles.get(styleName)
    }

    /**
     * Finds style for element with specified <code>class</code> attribute
     * @param className element's <code>class</code> attribute
     * @param elementName element name
     * @return CSS style
     */
    public findStyleClass(className: string, elementName: string): CSSStyle {
        var style: CSSStyle
        if ((style = this._styles.get(elementName + "." + className)) != null) return style
        else if ((style = this._styles.get("." + className)) != null) return style
        else return null
    }

    /**
     * Finds style for element with specified <code>id</code> attribute
     * @param elementId element's <code>id</code> attribute
     * @param elementName element name
     * @return CSS style
     */
    public findStyleId(elementId: string, elementName: string): CSSStyle {
        var style: CSSStyle
        if ((style = this._styles.get(elementName + "#" + elementId)) != null) return style
        else if ((style = this._styles.get("#" + elementId)) != null) return style
        else return null
    }

    /**
     * Finds style for element.
     * @param elementName element name
     * @return CSS style
     */
    public findStyle(elementName: string): CSSStyle {
        var style: CSSStyle
        if ((style = this._styles.get(elementName)) != null) return style
        else return null
    }

    public inTable: boolean = false

    /**
     * Returns CSS property configuration.
     * @param property property and value name (&lt;propertyName&gt;-&lt;valueName&gt;)
     * @return CSS property configuration
     * @throws NoItemException when property isn't found in the configuration
     */
    public getPropertyConf(property: string): CSSPropertyConfigItem {
        var ret: CSSPropertyConfigItem
        if (this.inTable && (ret = this._stylesConf.get(property + "-table")) != null) return ret
        if ((ret = this._stylesConf.get(property)) != null) return ret
        ret = new CSSPropertyConfigItem("", "")
        return ret
    }

    /**
     * Adds user style to the configuration.
     * @param name style name
     * @param style CSS style
     */
    public addStyle(name: string, style: CSSStyle) {
        this._styles.put(name, style)
    }

    /**
     * Makes new LaTeX commands from the CSS styles.
     * @return string containing new commands definitions
     */
    public makeCmdsFromCSS(): string {
        var ret: string = "\n% commands generated by html2latex"
        for (var iterator: Iterator<any> = this._styles.entrySet().iterator(); iterator.hasNext(); ) {
            var entry: Map.Entry<any, any> = <Map.Entry<any, any>>iterator.next()
            var styleName: string = <string>entry.getKey()
            var style: CSSStyle = <CSSStyle>entry.getValue()
            ret +=
                "\n\\newcommand{" +
                this.getCmdStyleName(styleName) +
                "}[1]{ " +
                style.getStart() +
                "#1" +
                style.getEnd() +
                " }"
        }
        return ret + "\n"
    }

    /**
     * style name without special chars
     * @param styleName style name
     * @return style name without special chars (ie. &quot;#&quot;) - suitable fo
     * creating new LaTeX command
     */
    public getCmdStyleName(styleName: string): string {
        return "\\" + this._commandsPrefix + styleName.replace(/\W/g, "").replace(/\d/g, "").replace("_", "")
    }

    /**
     * Returns true when new LaTeX commands are to be made from CSS styles.
     * @return true when new LaTeX commands are to be made from CSS styles
     */
    public getMakeCmdsFromCSS(): boolean {
        return this._makeCmdsFromCSS
    }
}

/**
 * Class representing configuration of each HTML element.
 */
export class ElementConfigItem {
    /**
     * Mapping between start tag and LaTeX command. (ie. &quot;\textbf{&quot;
     * for the <code>b</code> tag).
     */
    private _start: string

    /**
     * Mapping between end tag and LaTeX command. (ie. &quot;}&quot;
     * for the <code>b</code> tag).
     */
    private _end: string

    /**
     * The element's content mustn't be touched. (ie. for <code>pre</code>)
     */
    private _leaveText: boolean = false

    /**
     * The element's content will be ignored. (ie. for <code>script</code>)
     */
    private _ignoreContent: boolean = false

    /**
     * The element's CSS style will be ignored.
     */
    private _ignoreStyles: boolean = false

    /**
     * Cstr.
     * @param start mapping between start tag and LaTeX command
     * @param end mapping between end tag and LaTeX command
     * @param leaveText &quot;yes&quot; if the element's content mustn't be touched
     * @param ignoreContent &quot;yes&quot; if the element's content will be ignored
     * @param ignoreStyles &quot;yes&quot; if the element's CSS styles will be ignored
     */
    public constructor(start: string, end: string, leaveText: string, ignoreContent: string, ignoreStyles: string) {
        this._start = start
        this._end = end
        if (leaveText === "yes") {
            this._leaveText = true
        }
        if (ignoreContent === "yes") {
            this._ignoreContent = true
        }
        if (ignoreStyles === "yes") {
            this._ignoreStyles = true
        }
    }

    /**
     * Returns mapping between start tag and LaTeX command.
     * @return mapping between start tag and LaTeX command
     */
    public getStart(): string {
        return this._start
    }

    /**
     * Returns mapping between end tag and LaTeX command.
     * @return mapping between end tag and LaTeX command
     */
    public getEnd(): string {
        return this._end
    }

    /**
     * Returns leaveText property.
     * @return true if the element's content mustn't be touched
     */
    public leaveText(): boolean {
        return this._leaveText
    }

    /**
     * Returns ignoreContent property.
     * @return true if the element's content is ignored
     */
    public ignoreContent(): boolean {
        return this._ignoreContent
    }

    /**
     * Returns ignoreStyles property.
     * @return true if the element's CSS styles are ignored
     */
    public ignoreStyles(): boolean {
        return this._ignoreStyles
    }
}

/**
 * Class representing configuration of each CSS property
 * defined in the configuration file.
 */
export class CSSPropertyConfigItem {
    /**
     * Mapping between the CSS property and LaTeX (start command).
     * Example: <code>\texttt{<code> for &quot;monospace&quot; value
     * of &quot;font-family&quot; property.
     */
    private _start: string

    /**
     * Mapping between the CSS property and LaTeX (end command).
     */
    private _end: string

    /**
     * Cstr.
     * @param start start command
     * @param end end command
     */
    public constructor(start: string, end: string) {
        this._start = start
        this._end = end
    }

    /**
     * Returns start command.
     * @return start command
     */
    public getStart(): string {
        return this._start
    }

    /**
     * Returns end command.
     * @return end command
     */
    public getEnd(): string {
        return this._end
    }
}

/**
 * The way of converting hyperlinks.
 */
export enum LinksConversion {
    FOOTNOTES,
    BIBLIO,
    HYPERTEX,
    IGNORE
}
