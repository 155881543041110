import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ReporteRazon, ReporteRazones, ReporteRazonForm } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "reporte_razones.form.component.html"
})
export class ReporteRazonesNewComponent implements OnInit {
    reporteRazon: ReporteRazon = new ReporteRazon()
    params = ReporteRazonForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar razón de reporte"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private reporteRazonesService: ReporteRazones, private router: Router) {}

    ngOnInit() {
        this.form = ReporteRazonForm.getForm(this.reporteRazon)
        this.loadingLayout.ready()
    }

    save() {
        ReporteRazonForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.reporteRazonesService.save(this.reporteRazon).then(response => {
                this.router.navigate(["reporte_razones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.reporteRazon = new ReporteRazon()
            ReporteRazonForm.markFormControlsAsPristine(this.form)
            ReporteRazonForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
