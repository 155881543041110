<logged-layout>
    <logged-layout-toolnavbar>
        <tipo-instrumento-plataformas-toolnavbar 
            [displayButtons]="'new'">
        </tipo-instrumento-plataformas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Alias de Tipo de Instrumento</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="tipoInstrumentoPlataforma"
                    [key]="key"
                ></model-simple-view>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
