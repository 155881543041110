import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import {
    Asignatura,
    Asignaturas,
    Clasificacion,
    Clasificaciones,
    GrupoPregunta,
    GrupoPreguntas,
    Lote,
    Lotes,
    Persona,
    Personas,
    Pregunta,
    Preguntas,
    Taxativo
} from "@puntaje/nebulosa/api-services"
import "rxjs/add/operator/debounceTime"

@Component({
    selector: "preguntas-filter",
    templateUrl: "preguntas_filter.component.html",
    styleUrls: ["preguntas_filter.component.scss"]
})
export class PreguntasFilterComponent implements OnInit {
    @Output() preguntasChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() showPreguntasChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() isSearchingChange: EventEmitter<any> = new EventEmitter<any>()
    @Input() exportarIdsButton: boolean = false

    pregunta: Pregunta
    taxativo: Taxativo
    grupoPregunta: GrupoPregunta
    lote: Lote
    profesor: Persona
    clasificacionesPregunta: Clasificacion[]

    preguntas: Pregunta[]

    asignaturas: Asignatura[]
    lotes: Lote[]
    profesores: Persona[]
    clasificaciones: Clasificacion[]
    clasificacionId: number

    showLotes: boolean
    showProfesores: boolean
    showClasificaciones: boolean

    buscadorLote: UntypedFormControl = new UntypedFormControl()
    buscadorProfesor: UntypedFormControl = new UntypedFormControl()
    buscadorClasificaciones: UntypedFormControl = new UntypedFormControl()
    buscadorClasificacionId = new UntypedFormControl()

    @ViewChild("ulLotes") ulLotes: ElementRef
    @ViewChild("ulProfesores") ulProfesores: ElementRef
    @ViewChild("ulClasificaciones") ulClasificaciones: ElementRef
    isSearching: boolean = false

    @HostListener("document:click", ["$event"])
    handleClick(event: Event) {
        if (
            (this.ulLotes && !this.ulLotes.nativeElement.contains(event.target)) ||
            (this.ulProfesores && !this.ulProfesores.nativeElement.contains(event.target)) ||
            (this.ulClasificaciones && !this.ulClasificaciones.nativeElement.contains(event.target))
        ) {
            this.showLotes = this.showClasificaciones = this.showProfesores = false
        }
    }

    constructor(
        protected preguntasService: Preguntas,
        protected asignaturasService: Asignaturas,
        protected grupoPreguntasService: GrupoPreguntas,
        protected lotesService: Lotes,
        protected personasService: Personas,
        protected clasificacionesService: Clasificaciones,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.pregunta = new Pregunta(true)
        this.pregunta.estado = 1
        ;(this.pregunta as any).visible = 1

        this.taxativo = new Taxativo()
        this.grupoPregunta = new GrupoPregunta(true)
        this.lote = new Lote(true)
        this.profesor = new Persona()
        this.clasificacionesPregunta = []

        this.asignaturasService.where().then((asignaturas: Asignatura[]) => {
            this.asignaturas = asignaturas
        })

        this.buscadorLote.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
            if (value != "") {
                this.getLotes(value)
            } else {
                delete this.lote.id
                this.lotes = []
            }
        })

        this.buscadorProfesor.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
            if (value != "") {
                this.getProfesores(value)
            } else {
                delete this.profesor.id
                this.profesores = []
            }
        })

        this.buscadorClasificaciones.valueChanges
            .pipe(
                filter(v => v == "" || v.length >= 3),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(value => {
                if (value != "") {
                    this.getClasificaciones(value)
                } else {
                    this.clasificaciones = []
                }
            })

        this.buscadorClasificacionId.valueChanges.subscribe(val => {
            if (val != "") {
                this.clasificacionId = val
            }
        })
    }

    getLotes(value) {
        this.lotesService.where({ lote: { lote: value } }).then((lotes: Lote[]) => {
            this.lotes = lotes
            this.showLotes = true
        })
    }

    clickLote(lote) {
        this.lote.id = lote.id
        this.showLotes = false
        this.buscadorLote.setValue(lote.lote, { emitEvent: false })
    }

    getProfesores(value) {
        this.personasService.where({ persona: { nombre_completo: value } }).then((personas: Persona[]) => {
            this.profesores = personas
            this.showProfesores = true
        })
    }

    clickProfesor(persona) {
        this.profesor.id = persona.id
        this.showProfesores = false
        this.buscadorProfesor.setValue(persona.toString(), { emitEvent: false })
    }

    getClasificaciones(value) {
        this.clasificacionesService
            .where({ with_nombre_jerarquico: 1, clasificacion: { full_nombre_jerarquico: value } })
            .then((clasificaciones: Clasificacion[]) => {
                this.clasificaciones = clasificaciones
                this.showClasificaciones = true
            })
    }

    clickClasificacion(clasificacion: Clasificacion) {
        if (!this.clasificacionesPregunta.find(c => c.id == clasificacion.id)) {
            this.clasificacionesPregunta = this.clasificacionesPregunta.concat(clasificacion)
        }

        this.showClasificaciones = false
        this.buscadorClasificaciones.setValue("")
    }

    deleteClasificacion(clasificacion: Clasificacion) {
        let i = this.clasificacionesPregunta.findIndex(c => c.id == clasificacion.id)
        i >= 0 && this.clasificacionesPregunta.splice(i, 1)
    }

    // TODO: encontrar una forma de que el paginador se recargue solo.
    showPreguntas() {
        this.showPreguntasChange.emit(false)
        setTimeout(() => this.showPreguntasChange.emit(true), 1)
    }

    getParams(page: number, per: number = 10, exportar = false): any {
        this.pregunta.id &&
            !((this.pregunta.id as any) instanceof Array) &&
            (this.pregunta.id = (this.pregunta.id as any).split(","))
        this.pregunta.cleanEmpty()
        this.taxativo.cleanEmpty()
        this.grupoPregunta.cleanEmpty()
        this.lote.cleanEmpty()
        this.profesor.cleanEmpty()
        this.clasificacionesPregunta.forEach(c => c.cleanEmpty())

        let cIds = this.clasificacionesPregunta.map(c => c.id)
        let cObj = cIds.length != 0 ? { id: cIds } : {}

        if (this.clasificacionId) {
            if (cObj.id && !cObj.id.find(id => id == this.clasificacionId)) {
                cObj.id.push(this.buscadorClasificacionId.value)
            } else {
                cObj = { id: [this.clasificacionId] }
            }
        }

        let params = {
            with_grupo_pregunta: 1,
            page: page,
            per: per,
            pregunta: this.pregunta,
            taxativo: this.taxativo,
            with_clasificaciones: 1,
            grupo_pregunta: this.grupoPregunta,
            lote: this.lote,
            profesor: this.profesor,
            clasificacion: cObj,
            and_clasificacion: 1
        }

        if (page == 0 && per == 0) {
            delete params.page
            delete params.per
        }

        exportar && (params["to_csv"] = "preguntas.csv")

        return params
    }

    buscarPreguntas(page: number, per: number = 10, exportar = false) {
        this.isSearching = true
        this.isSearchingChange.emit(this.isSearching)
        let params = this.getParams(page, per, exportar)

        return this.preguntasService.where(params).then((preguntas: Pregunta[], total: number) => {
            this.preguntas = preguntas
            this.preguntasChange.emit(preguntas)
            this.isSearching = false
            this.isSearchingChange.emit(this.isSearching)
            return total
        })
    }
}
