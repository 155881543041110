<div [ngSwitch]="loaderType">
    <div *ngSwitchCase="'default'" class="loader"></div>
    <div *ngSwitchCase="'default-small'" class="loader-small"></div>
    <div *ngSwitchCase="'nebu'" class="kawaii-loader">
        <div class="c1"></div>
        <div class="c2"></div>
        <div class="c3"></div>
        <div class="c4"></div>
    </div>
    <div *ngSwitchCase="'nebu-small'" class="kawaii-loader kawaii-loader-small">
        <div class="c1"></div>
        <div class="c2"></div>
        <div class="c3"></div>
        <div class="c4"></div>
    </div>
</div>
