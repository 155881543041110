import { Component, OnInit, ViewChild } from "@angular/core"
import { PlanEstudios, PlanEstudio } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "app-plan-estudios-list",
    templateUrl: "./plan-estudios-list.component.html",
    styleUrls: ["./plan-estudios-list.component.scss"]
})
export class PlanEstudiosListComponent implements OnInit {
    planEstudios: PlanEstudio[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private planEstudiosService: PlanEstudios) {}

    ngOnInit() {}

    showAll(page: number = 1, per: number = 10) {
        return this.planEstudiosService
            .where({ page: page, per: per })
            .then((response: PlanEstudio[], total: number) => {
                this.planEstudios = response
                this.loadingLayout.ready()
                return total
            })
    }
}
