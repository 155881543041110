/** @format */

import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    Injector
} from "@angular/core"
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms"
import { Router } from "@angular/router"
import { config } from "../../../config/config"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Alternativa,
    AlternativaForm,
    ClasificacionPregunta,
    ClasificacionTipo,
    ClasificacionTipos,
    Pregunta,
    PreguntaForm,
    Preguntas,
    Persona,
    ContestableTipos,
    ContestableTipo,
    Contestable,
    PreguntaAsignatura,
    Plataforma,
    PreguntaPlataforma,
    TaxativoForm,
    Taxativo,
    ContestableForm,
    GrupoPregunta,
    GrupoPreguntas
} from "@puntaje/nebulosa/api-services"
import { DragulaService } from "ng2-dragula"
import { AuthService, FlashMessageService, Main, PdfView, ToggleClassService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { PreguntaSmallComponent } from "./pregunta.small.component"

@Component({
    templateUrl: "preguntas.form2.html",
    styleUrls: ["preguntas.form.component.scss"]
})
export class PreguntasNewComponent implements OnInit, OnDestroy {
    params = PreguntaForm.formParams
    alternativasParams = this.params.contestables.class.formParams.alternativas.class.formParams
    taxativosParams = this.params.taxativos.class.formParams
    pregunta = new Pregunta()
    oPregunta: Pregunta
    form: UntypedFormGroup
    taxativoForm: UntypedFormGroup
    taxativosForm: UntypedFormArray
    alternativasForms: UntypedFormArray[]
    contestablesForms: UntypedFormArray
    logged_layout_title = "Agregar pregunta"
    display_buttons_options = "mine all"
    save_button_text = "Guardar pregunta"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("pdfLoadingLayout") pdfLoadingLayout: LoadingLayoutComponent
    @ViewChildren("alternativaDiv") alternativaDiv: QueryList<ElementRef>
    disableAlternativas: boolean = false
    private dragularSub1: Subscription
    private dragularSub2: Subscription
    enablePistaWarning: boolean = false
    alternativasLetras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    @ViewChild(PreguntaSmallComponent) preguntaPreview: PreguntaSmallComponent

    paramsPreviewLatex: any

    subscriptions: Subscription[] = []
    isPSUValid: boolean = true
    tabWarnings: boolean[] = new Array(6).fill(false)
    @ViewChildren("tabContent") tabContent: QueryList<ElementRef>
    idClasificacionPSU: number
    disableSubmitBtn: boolean = true
    ordenOcultar: number = 0
    canEdit: boolean = true

    contestableTipos: ContestableTipo[]

    isPreguntaAlternativas: boolean = true
    isRespuestaConstruida: boolean = false
    isListaDesplegable: boolean = false
    gruposPreguntasFiltered: GrupoPregunta[]
    grupoPreguntaSelected: GrupoPregunta

    plataformas_seleccionadas = []

    waitCKeditor: boolean = false
    alternativasFormArrayInicial: UntypedFormArray

    tipoContestable: string = ""
    editMode = false

    doNotTriggerOrdenOcultarChanges = false

    constructor(
        protected preguntasService: Preguntas,
        protected router: Router,
        protected flashMessage: FlashMessageService,
        protected toggleClassService: ToggleClassService,
        protected dragulaService: DragulaService,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        private grupoPreguntasService: GrupoPreguntas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected contestableTiposService: ContestableTipos,
        protected injector: Injector
    ) {}

    ngOnInit() {
        this.form = PreguntaForm.getForm(this.pregunta, null, this.injector)
        this.taxativoForm = this.form.controls["taxativo"] as UntypedFormGroup
        this.taxativosForm = this.form.controls["taxativos"] as UntypedFormArray
        this.addTaxativo()
        this.alternativasForms = [
            (this.form.controls["contestables"] as UntypedFormGroup).controls["alternativas"] as UntypedFormArray
        ]

        this.alternativasFormArrayInicial = (this.form.controls["contestables"] as UntypedFormGroup).controls[
            "alternativas"
        ] as UntypedFormArray
        this.contestablesForms = this.form.controls["contestables"] as UntypedFormArray

        this.pregunta["contestables"].map(contestable => {
            let i = 0
            contestable.alternativas.forEach(a => {
                a["letra"] = this.alternativasLetras[i]
                i++
            })
        })
        let usuario = this.authService.getUserData()
        if (usuario) {
            let persona = new Persona()
            persona.id = usuario.persona.id
            persona.nombre = usuario.persona.nombre
            persona.apellido_paterno = usuario.persona.apellido_paterno
            this.pregunta.profesor = persona
            this.pregunta.encargado_reporte = persona
            this.pregunta.autor = persona
        }

        this.loadingLayout.ready()
        this.configureUpdateLatex()
        this.subscriptions.push(
            this.form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
                this.checkTabValidations()
            })
        )

        this.disableSubmitBtn = true
        this.clasificacionTiposService
            .where({ clasificacion_tipo: { clasificacion_tipo: "sub eje tematico" } })
            .then((response: ClasificacionTipo[]) => {
                if (response && response.length > 0) this.idClasificacionPSU = response[0].id
                this.disableSubmitBtn = false
            })

        this.contestableTiposService.where().then((contestableTipos: ContestableTipo[]) => {
            this.contestableTipos = contestableTipos
        })
        setTimeout(() => {
            this.waitCKeditor = true
        }, 1000)

        this.dragularSub1 = this.dragulaService.dragend().subscribe(value => {
            this.onDragend([value.el])
        })
        this.dragularSub2 = this.dragulaService.drag().subscribe(value => {
            this.onDrag([value.el])
        })
    }

    ngAfterViewInit() {
        this.updatePreviewLatex()
    }

    configureUpdateLatex() {
        this.subscriptions.push(
            this.form
                .get("solucion_latex")
                .valueChanges.pipe(
                    filter(x => x),
                    distinctUntilChanged(),
                    debounceTime(2000)
                )
                .subscribe(this.updatePreviewLatex.bind(this))
        )
        ;(this.form.get("taxativos") as UntypedFormArray).controls.forEach(control => {
            this.subscriptions.push(
                control
                    .get("taxativo_latex")
                    .valueChanges.pipe(
                        filter(x => x),
                        distinctUntilChanged(),
                        debounceTime(2000)
                    )
                    .subscribe(this.updatePreviewLatex.bind(this))
            )
        })
        ;(this.form.get("contestables") as UntypedFormArray).controls.forEach(contestable => {
            ;(contestable.get("alternativas") as UntypedFormArray).controls.forEach(control => {
                this.subscriptions.push(
                    control
                        .get("alternativa_latex")
                        .valueChanges.pipe(
                            filter(x => x),
                            distinctUntilChanged(),
                            debounceTime(2000)
                        )
                        .subscribe(this.updatePreviewLatex.bind(this))
                )
            })
        })
    }

    updatePreviewLatex() {
        let params: any = {
            pregunta: {
                ...this.pregunta,
                taxativo: { ...this.pregunta.taxativos },
                contestable: {
                    ...this.pregunta.contestables,
                    alternativas: [...this.pregunta.contestables.map(c => c.alternativas.map(a => ({ ...a })))]
                },
                nombre_autor: `${this.pregunta.autor.nombre} ${this.pregunta.autor.apellido_paterno}`
            }
        }

        delete params.clasificacion_preguntas
        delete params.clasificaciones
        delete params.grupo_pregunta

        this.paramsPreviewLatex = params
    }

    ngOnDestroy() {
        this.dragularSub1.unsubscribe()
        this.dragularSub2.unsubscribe()
        this.subscriptions.forEach(s => s.unsubscribe())
        //this.dragulaService.destroy('alternativas-bag');
    }

    addAlternativa(index: number) {
        let alternativaControl = AlternativaForm.getForm(new Alternativa())
        this.alternativasForms[index].push(alternativaControl)
        this.pregunta.contestables[index].agregarAlternativa()
        this.restoreAlternativasLetras()
    }

    removeAlternativa(index: number, indexAlternativasForms) {
        if (this.pregunta.contestables[indexAlternativasForms].removeAlternativa(index)) {
            this.alternativasForms[indexAlternativasForms].controls.splice(index, 1)
            this.restoreAlternativasLetras()
        }
    }

    onInputText(text) {
        this.grupoPreguntasService
            .where({
                per: 10,
                grupo_pregunta: {
                    like: {
                        id: text
                    }
                }
            })
            .then((gruposPreguntas: GrupoPregunta[]) => {
                this.gruposPreguntasFiltered = gruposPreguntas
            })
    }

    private onDragend(args) {
        let [el, target, source] = args
        this.restoreAlternativasLetras()
        this.disableAlternativas = false
        this.cdr.detectChanges()
    }

    private onDrag(args) {
        let [e, el] = args
        this.saveAlternativasLetras()
        this.disableAlternativas = true
    }

    private saveAlternativasLetras() {
        let i = 0
        this.pregunta.contestables.forEach(contestable =>
            contestable.alternativas.forEach(a => (a["letra"] = this.alternativasLetras[i]), i++)
        )
    }

    private restoreAlternativasLetras() {
        let i = 0
        this.pregunta.contestables.map(contestable => {
            contestable.alternativas.forEach(a => {
                ;(a["letra"] = this.alternativasLetras[i]), i++
            })
        })
    }

    private setOrden() {
        this.restoreAlternativasLetras()
        let letras = "abcdefghijklmnopqrstuvwxyz"
        this.pregunta.contestables.forEach(contestable => {
            contestable.alternativas.map((alternativa: Alternativa) => {
                alternativa.orden = letras.indexOf(alternativa["letra"].toLowerCase())
            })
        })
    }

    disableOnClick(event) {
        event.stopPropagation()
    }

    save() {
        PreguntaForm.markFormControlsAsTouched(this.form)

        this.customPSUcheck()
        this.checkPistas()
        setTimeout(() => {
            this.checkTabValidations()
        }, 800)
        if (this.form.valid && this.customPSUcheck() && this.checkPistas()) {
            this.disableSubmitBtn = true
            this.setOrden()
            if (this.tipoContestable == "lista desplegable") {
                this.pregunta.taxativos.map(taxativo =>
                    !taxativo.taxativo_latex ? (taxativo.taxativo_latex = Main.html2latex(taxativo.taxativo)) : null
                )
            }

            if (!this.pregunta.solucion_latex) {
                this.pregunta.solucion_latex = Main.html2latex(this.pregunta.solucion)
            }

            this.pregunta.contestables.map(contestable =>
                contestable.alternativas.map(a =>
                    !a.alternativa_latex ? (a.alternativa_latex = Main.html2latex(a.alternativa)) : null
                )
            )

            if (this.pregunta.profesor) this.pregunta.profesor_id = this.pregunta.profesor.id
            if (this.pregunta.encargado_reporte) this.pregunta.encargado_reporte_id = this.pregunta.encargado_reporte.id
            if (this.pregunta.autor) this.pregunta.autor_id = this.pregunta.autor.id
            if (this.grupoPreguntaSelected) this.pregunta.grupo_pregunta_id = this.grupoPreguntaSelected.id

            this.preguntasService.save(this.pregunta).then((pregunta: Pregunta) => {
                this.flashMessage.setParams("success", "Se ha creado exitosamente la pregunta #" + pregunta.id, 2)
                this.router.navigate(["mis_preguntas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.pregunta = new Pregunta()
            PreguntaForm.markFormControlsAsPristine(this.form)
            PreguntaForm.markFormControlsAsUntouched(this.form)
        }, 150) // ??
    }

    toLatex(model, from, to) {
        if (model[from]) {
            model[to] = Main.html2latex(model[from])
        }
        this.cdr.detectChanges()
    }

    toggleAlternativa(elem, clase) {
        this.toggleClassService.toggleClass(
            this.alternativaDiv.find((e, i) => {
                return i == elem
            }).nativeElement,
            clase
        )
    }

    toggleDistractor(index: number, elem, clase) {
        if (this.pregunta.contestables[0].alternativas[index].correcta) {
            this.toggleClassService.removeClass(elem, clase)
        } else {
            this.toggleClassService.addClass(elem, clase)
        }
    }

    correctaChange(index: number, value: boolean) {
        let ctrlOcultable = this.alternativasForms[0]["controls"][index].get("ocultable")
        if (value) {
            ctrlOcultable.disable()
            ctrlOcultable.reset()
        } else {
            ctrlOcultable.enable()
        }
    }

    public ordenOcultarChange(index: number, value: number) {
        value = +value
        const ctrlAlternativas = this.alternativasForms[0]["controls"]
        const ctrlOrdenOcultar = this.alternativasForms[0]["controls"][index].get("orden_ocultar")

        const maxValue = ctrlAlternativas.filter(ctrl => ctrl.get("ocultable").value).length - 1
        // Saco el valor antiguo basandome el valor que falta dentro de los posibles ordenes
        const oldValue =
            new Array(maxValue + 1)
                .fill(0)
                .map((_, i) => i)
                .find(v => ctrlAlternativas.every(ctrl => +ctrl.get("orden_ocultar").value != v)) ?? value

        const delta = Math.abs(oldValue - value) / (oldValue - value)
        const betweenMin = delta > 0 ? value : oldValue + 1
        const betweenMax = delta > 0 ? oldValue - 1 : value

        const ctrlAlternativasBetween = ctrlAlternativas
            .map((ctrl, i) => [ctrl, i] as [UntypedFormControl, number])
            .filter(
                ([c, i]) =>
                    +c.get("orden_ocultar").value >= betweenMin &&
                    +c.get("orden_ocultar").value <= betweenMax &&
                    i != index
            )
        ctrlAlternativasBetween.forEach(([c, i]) => {
            const newValue = +c.get("orden_ocultar").value + delta

            c.get("orden_ocultar").setValue(newValue, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[i].orden_ocultar = newValue
        })

        if (value > maxValue) {
            ctrlOrdenOcultar.setValue(maxValue, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[index].orden_ocultar = maxValue
        }
    }

    ocultableChange(index: number, value: boolean) {
        this.doNotTriggerOrdenOcultarChanges = true

        let ctrlAlternativas = this.alternativasForms[0]["controls"]
        let indexOrdenOcultarCtrl = ctrlAlternativas[index].get("orden_ocultar")
        let otherAlternativas = ctrlAlternativas
            .map((c, i) => [c, i] as [UntypedFormControl, number])
            .filter(([x, i]) => i !== index && x.get("ocultable").value === true)
        let maxValue = otherAlternativas.reduce((x, [y]) => {
            const ordenOcultar = y.get("orden_ocultar").value

            return Math.max(x, Number.isInteger(ordenOcultar) ? ordenOcultar : -1)
        }, -1)
        let minValue = otherAlternativas.reduce((x, [y]) => {
            const ordenOcultar = y.get("orden_ocultar").value

            return Math.min(x, Number.isInteger(ordenOcultar) ? ordenOcultar : 0)
        }, 0)
        if (!value) {
            let currentOrdenOcultar = indexOrdenOcultarCtrl.value
            indexOrdenOcultarCtrl.setValue(null, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[index].orden_ocultar = null

            if (currentOrdenOcultar === minValue) {
                otherAlternativas.forEach(([x, i]) => {
                    let ordenOcultarCtrl = x.get("orden_ocultar")
                    const newOrdenOcultar = +ordenOcultarCtrl.value - 1

                    ordenOcultarCtrl.setValue(newOrdenOcultar, { emitEvent: false })
                    this.pregunta.contestables[0].alternativas[i].orden_ocultar = newOrdenOcultar
                })
            } else if (currentOrdenOcultar > minValue && currentOrdenOcultar < maxValue) {
                otherAlternativas.forEach(([x, i]) => {
                    let ordenOcultarCtrl = x.get("orden_ocultar")

                    if (ordenOcultarCtrl.value > currentOrdenOcultar) {
                        const newOrdenOcultar = +ordenOcultarCtrl.value - 1

                        ordenOcultarCtrl.setValue(newOrdenOcultar, { emitEvent: false })
                        this.pregunta.contestables[0].alternativas[i].orden_ocultar = newOrdenOcultar
                    }
                })
            }
        } else {
            indexOrdenOcultarCtrl.setValue(maxValue + 1, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[index].orden_ocultar = maxValue + 1
        }
        this.cdr.detectChanges()

        this.doNotTriggerOrdenOcultarChanges = false
    }

    actualizarClasificaciones(event) {
        this.pregunta.clasificacion_preguntas = []
        if (event && Array.isArray(event) && event.length > 0) {
            let list = []
            for (let c of event) {
                let cp = new ClasificacionPregunta()
                cp.clasificacion_id = c.id
                this.pregunta.clasificacion_preguntas.push(cp)
            }
        }
    }

    actualizarPlataformas(plataforma_ids: number[]) {
        this.pregunta.pregunta_plataformas = plataforma_ids.map(id => {
            let cp = new PreguntaPlataforma()
            cp.plataforma_id = id
            return cp
        })
    }

    public checkSession() {
        if (!this.authService.isLoggedIn()) {
            this.authService.logout()
            // Redirect the user
            this.router.navigate([config.app.paths.landing])
        }
    }

    reloadPreview() {
        this.updatePreviewLatex()
    }

    customPSUcheck() {
        // reviso si tiene clasificación psu, osea si tiene clasificación tipo sub eje
        let isPSU = false
        this.pregunta.clasificaciones.forEach(c => {
            isPSU = isPSU || c.clasificacion_tipo_id == this.idClasificacionPSU
        })
        this.isPSUValid =
            (this.pregunta.contestables.find(contestable => contestable.alternativas.length == 5) && isPSU) || !isPSU
        return this.isPSUValid
    }

    checkTabValidations() {
        let i = 0
        this.tabContent.forEach(tc => {
            this.tabWarnings[i] = tc.nativeElement.getElementsByClassName("error-list").length > 0
            i++
        })
    }

    copiarPista(pista: string) {
        this.pregunta.contestables.forEach(contestable =>
            contestable.alternativas.forEach(alternativa => {
                if (!alternativa.correcta) alternativa.pista = pista
                else alternativa.pista = ""
            })
        )
    }

    checkPistas() {
        const alternativasConPista = this.pregunta.contestables
            .reduce(
                (alternativasConPista, contestable) =>
                    alternativasConPista.concat(contestable.alternativas.map(a => a.pista)),
                []
            )
            .filter(a => a).length
        this.enablePistaWarning = !(
            alternativasConPista == 0 || alternativasConPista >= this.pregunta.contestables[0]?.alternativas.length - 1
        )

        return !this.enablePistaWarning
    }

    toggleTipoContestable(tipo: string) {
        this.tipoContestable = tipo
        switch (tipo) {
            case "alternativas": {
                const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Pregunta de alternativas")
                this.isPreguntaAlternativas = true
                this.isRespuestaConstruida = false
                this.isListaDesplegable = false
                this.addContestable(tipo)
                this.alternativasForms = (this.form.controls["contestables"] as UntypedFormArray).controls.map(
                    formContestable =>
                        (formContestable as UntypedFormGroup).controls["alternativas"] as UntypedFormArray
                )
                var i = 0
                for (let a of this.pregunta["contestables"][0]["alternativas"]) {
                    a["letra"] = this.alternativasLetras[i]
                    i++
                }

                this.cdr.detectChanges()
                this.configureUpdateLatex()
                break
            }

            case "respuesta construida": {
                const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Respuesta construida")
                this.isPreguntaAlternativas = false
                this.isRespuestaConstruida = true
                this.isListaDesplegable = false
                this.pregunta.contestables = [new Contestable(tipo.id, 1)]
                this.cdr.detectChanges()
                break
            }

            case "lista desplegable": {
                const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Lista Desplegable")
                this.isPreguntaAlternativas = false
                this.isRespuestaConstruida = false
                this.isListaDesplegable = true
                this.pregunta.contestables = []
                this.contestablesForms = this.form.controls["contestables"] as UntypedFormArray
                this.alternativasForms = []
                this.cdr.detectChanges()
                break
            }

            default:
                break
        }
    }

    onUpdateAsignaturas(ids: number[]) {
        this.pregunta.asignatura_ids = ids
    }

    public addTaxativo() {
        const taxativoControl = TaxativoForm.getForm(new Taxativo())
        this.taxativosForm.push(taxativoControl)
        this.pregunta.taxativos.push(new Taxativo())
        this.cdr.detectChanges()
    }

    public addContestable = (tipo: ContestableTipo): void => {
        const newContestable = new Contestable(tipo.id)
        const contestableControl = ContestableForm.getForm(newContestable)
        this.contestablesForms.push(contestableControl)
        this.pregunta.contestables.push(newContestable)
    }
}
