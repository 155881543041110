import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Perfil, Perfiles, PerfilView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "perfiles.component.html"
})
export class PerfilesComponent implements OnInit {
    perfiles: Perfil[]
    show_params = PerfilView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private perfilesService: Perfiles,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll = (page: number = 1, per: number = 10) => {
        return this.perfilesService.where({ page: page, per: per }).then((response: Perfil[], total: number) => {
            this.perfiles = response
            this.loadingLayout.ready()
            return total
        })
    }

    view(perfil: Perfil) {
        this.router.navigate(["perfiles/" + perfil.id])
    }

    edit(perfil: Perfil) {
        this.router.navigate(["perfiles/" + perfil.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["perfiles/" + id + "/delete"])
    }

    showModalDelete(perfil: Perfil) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este perfil?")
        this.simpleModalService.setModalCallback(() => this.delete(perfil.id))
    }
}
