import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Material, Materiales, MaterialView } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales_usuario.component.html"
})
export class MaterialesUsuarioComponent implements OnInit {
    materiales: Material[]
    show_params = MaterialView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected materialesService: Materiales,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    showAll = (page: number, per: number) => {
        let params = {
            page: page,
            per: per,
            material: {
                profesor_id: this.authService.getUserData().persona.id
            },
            include: "[certificacion_recursos]"
        }

        return this.materialesService.where(params).then((materiales: Material[], total: number) => {
            this.materiales = materiales
            this.loadingLayout.ready()
            return total
        })
    }

    view(material: Material) {
        this.router.navigate(["materiales/" + material.id])
    }

    edit(material: Material) {
        this.router.navigate(["materiales/" + material.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["materiales/" + id + "/delete"])
    }

    showModalDelete(material: Material) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este material?")
        this.simpleModalService.setModalCallback(() => this.delete(material.id))
    }
}
