import { Injectable, Injector } from "@angular/core"
import { PermisosAsociadosService } from "./permisos-asociados.service"
import { BasePolicyService, Opts } from "./base-policy.service"
import { PermisosService } from "./permisos.service"
import { Preguntas } from "@puntaje/nebulosa/api-services"
import { preguntasProviders } from "../preguntas/preguntas.routing"

@Injectable({
    providedIn: "root"
})
export class PreguntasPolicyService extends PermisosAsociadosService {
    resourceName: string = "Preguntas"

    update({ resource, resourceId, ...rest }: Opts): boolean | Promise<boolean> {
        if (
            resource &&
            (resource as any).certificacion_recursos &&
            (resource as any).certificacion_recursos.length > 0 &&
            !this.testPermiso("Preguntas_update_certificado")
        )
            return false
        if (resourceId) {
            const usuario = JSON.parse(localStorage.getItem("usuario"))
            const params = {
                pregunta: {
                    id: resourceId
                },
                include: "[certificacion_recursos,usuario_recursos]"
            }
            return this.injector
                .get(Preguntas)
                .where(params)
                .then(preguntas => {
                    if (preguntas.length == 0) return false
                    let p = preguntas[0]
                    return !(
                        p.certificacion_recursos &&
                        p.certificacion_recursos.length == 0 &&
                        !this.testPermiso("Preguntas_update_certificado") &&
                        !p.usuario_recursos.find(usuario_recurso => usuario_recurso.usuario_id == usuario.id)
                    )
                })
        }
        return (
            super.update({ resource, resourceId, ...rest }) ||
            this.permisoAsociado("update", { resource, resourceId, ...rest })
        )
    }

    clonar({ resourceName }: Opts): boolean | Promise<boolean> {
        return this.testPermiso(`${this.getResourceName(resourceName)}_clonar`)
    }
}
