import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"

export class PlanEstudioForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            plan_estudio: { label: "Nombre", type: "text", visible: true },
            descripcion: { label: "Descripción", type: "textarea", visible: true },
            asignatura_id: {
                label: "Asignatura",
                type: "select",
                visible: true,
                options: { class: Asignaturas },
                validations: [Validators.required]
            },
            estado: { label: "Estado", type: "checkbox", visible: true }
        }
    }
}
