<logged-layout>
    <logged-layout-toolnavbar>
        <plan-estudio-toolnavbar [displayButtons]="'new'"></plan-estudio-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Planes de Estudio</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <plan_estudios [planEstudios]="planEstudios"></plan_estudios>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
