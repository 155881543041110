import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Materiales } from "./materiales.service"
import { Preguntas } from "./preguntas.service"
import { Reportes } from "./reportes.service"

export class ReporteView {
    public static get viewParams(): any {
        let tablas = (tabla: string) => {
            if (tabla == "Material") {
                return Materiales
            } else if (tabla == "Pregunta") {
                return Preguntas
            } else {
                return null
            }
        }
        return {
            id: { label: "Reporte", type: LinkView, tableVisible: true, options: { class: Reportes, key: "id" } },
            tabla: { label: "Tabla", type: TextView, tableVisible: true },
            entidad_id: {
                label: "Id",
                type: LinkView,
                tableVisible: true,
                options: { fromFunction: tablas, from: "tabla", key: "entidad_id" }
            },
            plataforma_id: { label: "Plataforma", type: TextView, tableVisible: false, showHidden: true },
            plataforma: { label: "Plataforma", type: TextView, tableVisible: true },
            usuario_reporte: { label: "Reportado por", type: TextView, tableVisible: false },
            usuario_reporte_id: { label: "Usuario Id", type: TextView, tableVisible: true },
            usuario_reporte_nombre: { label: "Nombre", type: TextView, tableVisible: false, showHidden: true },
            usuario_reporte_apellido_paterno: {
                label: "Apellido paterno",
                type: TextView,
                tableVisible: false,
                showHidden: true
            },
            usuario_reporte_apellido_materno: {
                label: "Apellido materno",
                type: TextView,
                tableVisible: false,
                showHidden: true
            },
            usuario_reporte_email: { label: "Email", type: TextView, tableVisible: false },
            usuario_reporte_rol_id: { label: "Rol ID", type: TextView, tableVisible: false },
            usuario_reporte_rol_rol: { label: "Rol", type: TextView, tableVisible: true },
            reporte: { label: "Reporte", type: TextView, tableVisible: false },
            reporte_razon_id: { label: "Razón de Reporte", type: TextView, tableVisible: false, showHidden: true },
            reporte_razon: { label: "Razón de Reporte", type: TextView, tableVisible: true },
            created_at: { label: "Fecha", type: DateView, tableVisible: true },
            estado_reporte: { label: "Estado reporte", type: TextView, tableVisible: true }
        }
    }

    public static get entidadParams(): any {
        return {
            id: { label: "Reporte", type: LinkView, tableVisible: true, options: { class: Reportes, key: "id" } },
            plataforma: { label: "Plataforma", type: TextView, tableVisible: true },
            reporte: { label: "Reporte", type: TextView, tableVisible: true },
            created_at: { label: "Fecha", type: DateView, tableVisible: true },
            usuario_reporte_rol_rol: { label: "Rol", type: TextView, tableVisible: true },
            reporte_razon: { label: "Razón de Reporte", type: TextView, tableVisible: true },
            estado_reporte: { label: "Estado reporte", type: TextView, tableVisible: true }
        }
    }
}
