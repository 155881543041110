import { Component, OnInit, ViewChild } from "@angular/core"
import { TipoInstrumentoPlataforma, TipoInstrumentoPlataformas } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { TipoInstrumentoPlataformasBuscadorService } from "../tipo_instrumento_plataformas_buscador.service"

@Component({
    selector: "puntaje-tipo-instrumento-plataformas-list",
    templateUrl: "./tipo-instrumento-plataformas-list.component.html",
    styleUrls: ["./tipo-instrumento-plataformas-list.component.scss"]
})
export class TipoInstrumentoPlataformasListComponent implements OnInit {
    tipoInstrumentoPlataformas: TipoInstrumentoPlataforma[]
    searchParams: any
    sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("paginator", { static: false }) paginator: PaginatorComponent

    constructor(
        protected tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        protected tipoInstrumentoPlataformasBuscadorService: TipoInstrumentoPlataformasBuscadorService
    ) {}

    ngOnInit() {
        this.sub = this.tipoInstrumentoPlataformasBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    onDelete() {
        this.paginator.changePage(1)
    }

    showAll(page: number = 1, per: number = 10) {
        const params = {
            ...this.searchParams,
            page: page,
            per: per,
            include: "[tipo_instrumento,plataforma]",
            order: "created_at desc"
        }
        return this.tipoInstrumentoPlataformasService
            .where(params)
            .then((response: TipoInstrumentoPlataforma[], total: number) => {
                this.tipoInstrumentoPlataformas = response
                this.loadingLayout.ready()
                return total
            })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
