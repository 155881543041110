import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { ReporteRazones } from "./reporte_razones.service"

export class ReporteSubRazonForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            reporte_razon_id: { label: "Razón", type: "select", visible: true, options: { class: ReporteRazones } },
            sub_reporte: {
                label: "Reporte",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            explicacion: {
                label: "Explicación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            gatillante: { label: "Gatillante", type: "checkbox", visible: true },
            explicado: { label: "Requiere Detalle", type: "checkbox", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
