import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"

/*#################################

Barra de navegación que va en la parte superior.

#################################*/

@Component({
    templateUrl: "navbar.component.html",
    selector: "navbar",
    styleUrls: ["navbar.css"]
})
export class NavbarComponent {
    constructor(public authService: AuthService, private router: Router) {}

    logout() {
        this.authService.logout()
        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/"
        // Redirect the user
        this.router.navigate([redirect])
    }
}
