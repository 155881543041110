import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Lote, LotePregunta, LotePreguntas, Lotes, Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"
import { BaseModel } from "@puntaje/shared/core"
import { PreguntasFilterComponent } from "../preguntas/preguntas_filter.component"

@Component({
    templateUrl: "lote_preguntas.add.component.html",
    styleUrls: ["lote_preguntas.component.scss"],
    selector: "add-lote-preguntas"
})
export class LotePreguntasAddComponent implements OnInit {
    preguntas = []
    showPreguntas: boolean = false
    lote_pregunta: LotePregunta = new LotePregunta()
    @Output() loteUpdating = new EventEmitter()
    @Output() loteUpdated = new EventEmitter()
    @Input("lote_id") lote_id: number
    @Input("lote_preguntas") lote_preguntas: LotePregunta[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("preguntasFilter", { static: true }) preguntasFilter: PreguntasFilterComponent
    preguntaQuery: string

    constructor(
        protected lotePreguntasService: LotePreguntas,
        protected lotesService: Lotes,
        protected preguntasService: Preguntas,
        protected router: Router
    ) {}

    ngOnInit() {
        this.showAll()
    }

    showAll() {
        this.loadingLayout.ready()
    }

    searchPregunta() {
        this.preguntasService.where({ pregunta: { id: +this.preguntaQuery } }).then((preguntas: Pregunta[]) => {
            this.preguntas = preguntas
        })
    }

    agregarTodas() {
        let params = this.preguntasFilter.getParams(0, 0)
        params.only_id = 1

        this.preguntasService.where(params).then((preguntas: Pregunta[]) => {
            let lote = new BaseModel() as Lote
            lote.lote_preguntas = []
            lote.id = this.lote_id
            let lastLote = this.lote_preguntas.sort((a, b) => b.orden - a.orden)[0]
            let nextOrden
            if (lastLote) {
                nextOrden = lastLote.orden + 1
            } else {
                nextOrden = 0
            }

            preguntas.forEach(p => {
                if (!this.checkIfAlreadyAdded(p.id)) {
                    let lotePregunta = new LotePregunta()
                    lotePregunta.pregunta_id = p.id
                    lotePregunta.orden = nextOrden
                    lote.lote_preguntas.push(lotePregunta)
                    nextOrden++
                }
            })

            this.lotesService.update(this.lote_id, lote).then(l => {
                this.loteUpdated.emit()
                this.showPreguntas = false
            })
        })
    }

    onPreguntasChange(preguntas: Pregunta[]) {
        this.preguntas = preguntas
    }

    onShowPreguntasChange(show: boolean) {
        this.showPreguntas = show
    }

    checkIfAlreadyAdded(id_pregunta: number) {
        let alreadyAdded: boolean = false
        if (this.lote_preguntas) {
            for (let lote_pregunta of this.lote_preguntas) {
                if (lote_pregunta.pregunta.id == id_pregunta) {
                    alreadyAdded = true
                }
            }
        }
        return alreadyAdded
    }

    save(id: number) {
        if (this.lote_id != undefined) {
            this.lote_pregunta.lote_id = this.lote_id
        } else {
            return
        }
        this.lote_pregunta.pregunta_id = id
        this.loadingLayout.standby()
        this.lotePreguntasService.save(this.lote_pregunta).then(response => {
            this.loteUpdated.emit()
        })
    }

    id(pregunta, index) {
        return pregunta.id || index
    }
}
