<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-tipo-alias-toolnavbar [displayButtons]="'new search'"></clasificacion-tipo-alias-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Aliases de Tipo de Clasificación</logged-layout-titulo>
    <logged-layout-contenido>
        <!-- <clasificacion-tipo-aliases-buscador></clasificacion-tipo-aliases-buscador> -->
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="clasificacionTipoAliases"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
