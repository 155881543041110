import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import {
    ClasificacionTipoAlias,
    ClasificacionTipoAliasForm,
    ClasificacionTipoAliases,
    Plataforma,
    AsignaturaPlataforma,
    Plataformas,
    AsignaturaPlataformas
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion_tipo_aliases.form.component.html"
})
export class ClasificacionTipoAliasesNewComponent implements OnInit {
    clasificacionTipoAlias: ClasificacionTipoAlias = new ClasificacionTipoAlias()
    params = ClasificacionTipoAliasForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar alias de tipo de clasificación"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    plataformas: Plataforma[]
    plataformaId: number
    asignaturaPlataformas: AsignaturaPlataforma[]
    asignaturaPlataformaId: number
    clasificacionTipoId: number
    displayTipoEdit: boolean = true
    sub: Subscription

    constructor(
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        private router: Router,
        private route: ActivatedRoute,
        protected plataformasService: Plataformas,
        protected asignaturaPlataformasService: AsignaturaPlataformas
    ) {}

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            console.log(params)
            this.clasificacionTipoId = params["clasificacion_tipo_id"]
        })

        Promise.all([this.plataformasService.where()]).then(([plataformas]) => {
            this.plataformas = plataformas

            this.form = ClasificacionTipoAliasForm.getForm(this.clasificacionTipoAlias)

            if (this.clasificacionTipoAlias.asignatura_plataforma_id) {
                this.plataformaId = this.clasificacionTipoAlias.asignatura_plataforma.plataforma_id
                this.asignaturaPlataformasService
                    .where({ include: "asignatura", asignatura_plataforma: { plataforma_id: this.plataformaId } })
                    .then(asignaturaPlataformas => {
                        this.asignaturaPlataformas = asignaturaPlataformas
                        this.asignaturaPlataformaId = this.clasificacionTipoAlias.asignatura_plataforma_id
                        this.loadingLayout.ready()
                    })
            } else {
                this.loadingLayout.ready()
            }
        })
    }

    save() {
        ClasificacionTipoAliasForm.markFormControlsAsTouched(this.form)
        if (this.form.valid && this.asignaturaPlataformaId) {
            this.clasificacionTipoAlias.asignatura_plataforma_id = this.asignaturaPlataformaId
            this.clasificacionTipoAlias.clasificacion_tipo_id = this.clasificacionTipoId
            this.clasificacionTipoAliasesService.save(this.clasificacionTipoAlias).then(response => {
                this.router.navigate(["clasificacion_tipo_aliases"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.clasificacionTipoAlias = new ClasificacionTipoAlias()
            ClasificacionTipoAliasForm.markFormControlsAsPristine(this.form)
            ClasificacionTipoAliasForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    plataformaSelected(plataformaId) {
        this.asignaturaPlataformas = []
        this.plataformaId = plataformaId
        this.asignaturaPlataformasService
            .where({ include: "asignatura", asignatura_plataforma: { plataforma_id: plataformaId } })
            .then(asignaturaPlataformas => {
                this.asignaturaPlataformas = asignaturaPlataformas

                if (this.asignaturaPlataformas.length > 0) {
                    this.asignaturaPlataformaId = this.asignaturaPlataformas[0].id
                }
            })
    }

    asignaturaPlataformaSelected(apId) {
        this.asignaturaPlataformaId = apId
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }
}
