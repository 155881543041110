import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, UtilModule, PaginatorModule } from "@puntaje/shared/core"
import { EmbedPlataformasComponent } from "./embed_plataformas.component"
import { PlataformaComponent } from "./plataforma.component"
import { PlataformasComponent } from "./plataformas.component"
import { PlataformasDeleteComponent } from "./plataformas.delete.component"
import { PlataformasEditComponent } from "./plataformas.edit.component"
import { PlataformasNewComponent } from "./plataformas.new.component"
import { plataformasRouting } from "./plataformas.routing"
import { PlataformasToolNavbarComponent } from "./plataformas.toolnavbar.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        plataformasRouting,
        ModelViewModule,
        NebuModule,
        PaginatorModule
    ],
    declarations: [
        EmbedPlataformasComponent,
        PlataformasComponent,
        PlataformaComponent,
        PlataformasNewComponent,
        PlataformasEditComponent,
        PlataformasDeleteComponent,
        PlataformasToolNavbarComponent
    ],
    exports: [EmbedPlataformasComponent]
})
export class PlataformasModule {}
