import { BaseForm } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { LotePreguntaForm } from "./lote_preguntas.form"

export class LoteForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            lote: { label: "Lote", type: "text", visible: true },
            asignatura_id: { label: "Asignatura", type: "select", visible: true, options: { class: Asignaturas } },
            estado: { label: "Estado", type: "hidden", visible: false },
            visible: { label: "Visible", type: "checkbox", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            lote_preguntas: { type: "models", class: LotePreguntaForm }
        }
    }
}
