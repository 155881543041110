<div *ngIf="pregunta" [class.pregunta-xs]="preguntaXS" [class.pregunta-xl]="preguntaXL">
    <div class="enunciado-container" (click)="toggleEnunciado()">
        <div
            class="contenido-enunciado"
            [innerHTML]="
                pregunta.taxativos[0]?.taxativo
                    | clearLatex
                    | katex
                    | trustedhtml
                    | easyplaceholder: 'Enunciado no disponible'
            "
            [class.contenido-enunciado-contracted]="!enunciadoExpanded"
            [class.contenido-enunciado-expanded]="enunciadoExpanded"
        ></div>
        <div
            *ngIf="!disableGoToPregunta"
            class="detailsButton"
            (click)="goToDetails()"
            data-toggle="tooltip"
            data-placement="top"
            title="Ver pregunta"
        >
            <span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span>
        </div>
        <div *ngIf="!disableExpand" class="expandButton">
            <span *ngIf="!enunciadoExpanded" class="glyphicon glyphicon-plus" aria-hidden="true"></span>
            <span *ngIf="enunciadoExpanded" class="glyphicon glyphicon-minus" aria-hidden="true"></span>
        </div>
    </div>
    <div class="alternativas-container">
        <div *ngFor="let alternativa of pregunta.contestables[0]?.alternativas; let i = index">
            <div class="alternativa-container" [class.alternativa-correcta]="alternativa.correcta">
                <div class="alternativa-row">
                    <div *ngIf="alternativa.letra" class="alternativa-letra">{{ alternativa.letra }}</div>
                    <div
                        class="alternativa-contenido"
                        [innerHTML]="alternativa.alternativa | clearLatex | katex | trustedhtml"
                    ></div>
                    <span *ngIf="alternativa.correcta" class="glyphicon glyphicon-ok side-ok" aria-hidden="true"></span>
                    <button
                        *ngIf="!alternativa.correcta && alternativa.explicacion_distractor"
                        type="button"
                        class="btn btn-xs btn-default dist-button"
                        (click)="toggleDistractor(i)"
                    >
                        <span
                            class="glyphicon"
                            [class.glyphicon-chevron-up]="distractores[i]"
                            [class.glyphicon-chevron-down]="!distractores[i]"
                            aria-hidden="true"
                        ></span>
                        Distractor
                    </button>
                </div>
            </div>
            <div
                *ngIf="alternativa.explicacion_distractor"
                class="distractor-contenido"
                [class.distractor-show]="distractores[i]"
            >
                {{ alternativa.explicacion_distractor }}
            </div>
        </div>
    </div>

    <div *ngIf="viewSolution">
        <h4 class="solucion-titulo">Solución</h4>
        <div
            class="solucion-contenido"
            [innerHTML]="
                pregunta.solucion | clearLatex | katex | trustedhtml | easyplaceholder: 'Solución no disponible'
            "
        ></div>

        <h4 class="solucion-titulo">Solución en video</h4>
        <div class="solucion-contenido">
            {{
                pregunta.solucion_video
                    | clearLatex
                    | katex
                    | trustedhtml
                    | easyplaceholder: "Solución en video no disponible"
            }}
        </div>
    </div>
</div>
