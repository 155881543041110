import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { config } from "../../../config/config"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Asignatura,
    AsignaturaPlataforma,
    Clasificacion,
    ClasificacionPreguntas,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    Plataforma,
    Plataformas,
    Preguntas,
    TipoInstrumento
} from "@puntaje/nebulosa/api-services"

declare var $: any

@Component({
    templateUrl: "monitor_preguntas.component.html",
    selector: "app-monitor-preguntas",
    styleUrls: ["monitor_recursos.scss"]
})
export class MonitorPreguntasComponent implements OnInit {
    config: typeof config
    form: UntypedFormGroup = new UntypedFormGroup({
        plataforma: new UntypedFormControl(),
        tipoInstrumento: new UntypedFormControl(),
        asignatura: new UntypedFormControl(),
        generador: new UntypedFormControl()
    })
    plataformas: Plataforma[]
    asignaturaPlataformas: AsignaturaPlataforma[]
    tipoInstrumentos: TipoInstrumento[]
    asignaturas: Asignatura[]
    generadores: GeneradorInstrumento[]
    clasificaciones: Clasificacion[]
    clasificacionesIds: number[]

    plataformaSelected: Plataforma
    tipoInstrumentoSelected: number
    asignaturaSelected: number
    generadorInstrumentoSelected: number
    cantidadPreguntas: number = 0

    showData: boolean = false
    showGeneradores: boolean = false
    contentAvailable: boolean = false
    sinTipoInstrumentos: boolean = false
    sinAsignaturas: boolean = false
    sinGeneradores: boolean = false
    sinClasificaciones: boolean = false
    loadingCantidad: boolean = false
    arte: boolean = false
    visible: boolean = true
    presencial: boolean = false
    estados: number[]
    estadosSelected: number[] = []

    plataformaParams = {
        label: "Plataforma",
        type: "select",
        visible: true,
        options: {
            class: Plataformas,
            params: {
                with_tipo_instrumentos: 1,
                with_asignatura_plataformas: 1
            }
        }
    }

    tipoInstrumentoParams = {
        label: "Tipo Instrumento",
        type: "select",
        visible: true
    }

    asignaturaParams = {
        label: "Asignatura",
        type: "select",
        visible: true
    }

    generadorParams = {
        label: "Instrumento",
        type: "select",
        visible: true
    }

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingClasificaciones", { static: true }) loadingClasificaciones: LoadingLayoutComponent

    constructor(
        protected cdr: ChangeDetectorRef,
        private generadorInstrumentoService: GeneradorInstrumentos,
        private clasificacionPreguntasService: ClasificacionPreguntas,
        private preguntasService: Preguntas
    ) {
        this.config = config
    }

    estadoChanged(value: HTMLInputElement) {
        value.checked
            ? this.estadosSelected.push(parseInt(value.value))
            : (this.estadosSelected = this.estadosSelected.filter(v => v !== parseInt(value.value)))
        this.updateClasificacionesId(this.clasificacionesIds)
    }

    ngOnInit() {
        this.preguntasService.enableIgnoreModel()
        this.preguntasService.estados().then((response: any) => {
            console.log(response)
            this.estados = response
            this.preguntasService.disableIgnoreModel()
            this.loadingLayout.ready()
            this.loadingClasificaciones.ready()
        })
    }

    setPlataformas(plataformas: Plataforma[]) {
        this.plataformas = plataformas
    }

    setTipoInstrumentosAndAsignaturas(plataformaId: string) {
        this.tipoInstrumentos = null
        this.asignaturas = null
        this.asignaturaPlataformas = null
        this.plataformaSelected = null
        this.tipoInstrumentoSelected = null
        this.asignaturaSelected = null
        this.clasificaciones = null
        this.sinTipoInstrumentos = false
        this.sinAsignaturas = false
        this.sinGeneradores = false
        if (this.plataformas) {
            let p = this.plataformas.find((plataforma: Plataforma) => {
                return plataforma.id === parseInt(plataformaId)
            })
            if (p) {
                this.plataformaSelected = p
                this.asignaturaPlataformas = p.asignatura_plataformas ? p.asignatura_plataformas.slice() : null
                this.tipoInstrumentos = p ? p.tipo_instrumentos.slice() : null
                this.asignaturas = p ? p.asignatura_plataformas.map(ap => ap.asignatura).slice() : null
                if (!this.tipoInstrumentos || this.tipoInstrumentos.length <= 0) {
                    this.sinTipoInstrumentos = true
                }
                if (!this.asignaturas || this.asignaturas.length <= 0) {
                    this.sinAsignaturas = true
                }
            }
        }
        this.shouldShowData()
        this.shouldShowGeneradores()
    }

    shouldShowData() {
        this.showData =
            this.tipoInstrumentos && this.tipoInstrumentos.length > 0 && this.asignaturas && this.asignaturas.length > 0
    }

    shouldShowGeneradores() {
        this.showGeneradores =
            this.tipoInstrumentoSelected !== null &&
            this.tipoInstrumentoSelected !== undefined &&
            this.tipoInstrumentoSelected !== 0 &&
            this.asignaturaSelected !== null &&
            this.asignaturaSelected !== undefined &&
            this.tipoInstrumentoSelected !== 0
        this.cdr.detectChanges()
    }

    tipoInstrumentoChange(value: string) {
        this.tipoInstrumentoSelected = parseInt(value)
        this.updateGeneradores()
        this.shouldShowGeneradores()
    }

    asignaturaChange(value: string) {
        this.asignaturaSelected = parseInt(value)
        this.updateGeneradores()
        this.shouldShowGeneradores()
    }

    updateGeneradores() {
        this.generadores = null
        this.generadorInstrumentoSelected = null
        this.clasificaciones = null
        this.clasificacionesIds = null
        this.sinGeneradores = false
        this.arte = false
        this.cantidadPreguntas = 0
        if (this.showGeneradores) {
            let asignaturaPlartaforma = this.plataformaSelected.asignatura_plataformas.find((ap: any) => {
                return ap.asignatura_id === this.asignaturaSelected
            })
            if (asignaturaPlartaforma) {
                let generadores = asignaturaPlartaforma.generador_instrumentos.filter(gi => {
                    return gi.tipo_instrumento_id === this.tipoInstrumentoSelected
                })
                this.generadores = generadores ? (generadores.length > 0 ? generadores.slice() : null) : null
                if (!this.generadores || this.generadores.length <= 0) {
                    this.sinGeneradores = true
                }
            }
        }
    }

    getClasificaciones(value: any) {
        if (value !== undefined && value !== null && value !== "") {
            this.sinClasificaciones = false
            this.clasificaciones = null
            this.clasificacionesIds = null
            this.loadingClasificaciones.standby()
            let tipo = this.tipoInstrumentos.find(ti => ti.id === this.tipoInstrumentoSelected)
            let params: any = null
            if (this.config.monitorPreguntas[tipo.tipo_instrumento]) {
                params = { group_by: this.config.monitorPreguntas[tipo.tipo_instrumento].groupBy }
            }
            if (tipo && value !== "" && value !== undefined && value !== null && params && params.group_by !== null) {
                this.generadorInstrumentoService.filtroClasificaciones(value, params).then((resp: any) => {
                    let clasificaciones = resp as Clasificacion[]
                    this.clasificaciones = clasificaciones
                    this.generadorInstrumentoSelected = value
                    this.arte = true
                    this.loadingClasificaciones.ready()
                })
            } else {
                this.sinClasificaciones = true
                this.clasificaciones = null
                this.clasificacionesIds = null
                this.generadorInstrumentoSelected = null
                this.loadingClasificaciones.ready()
            }
        }
    }

    updateClasificacionesId(value: any) {
        this.loadingCantidad = true
        this.clasificacionesIds = value
        if (this.clasificacionesIds && this.clasificacionesIds.length > 0) {
            let str = this.clasificacionesIds.join(",")
            let params: any = {
                clasificacion_ids: str,
                count: 1,
                uniq: "pregunta_id",
                pregunta: {
                    visible: this.visible ? 1 : 0,
                    presencial: this.presencial ? 1 : 0
                }
            }
            if (this.estadosSelected && this.estadosSelected.length > 0) {
                params.pregunta["estado"] = this.estadosSelected
            }
            this.clasificacionPreguntasService.enableIgnoreModel()
            this.clasificacionPreguntasService.where(params).then((response: any, total: number) => {
                this.loadingCantidad = false
                this.cantidadPreguntas = response
            })
        } else {
            this.cantidadPreguntas = 0
            this.loadingCantidad = false
        }
    }
}
