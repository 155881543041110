import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    PlataformaType
} from "@puntaje/shared/core"

export class NebulosaConfig implements AppConfiguration {
    app = {
        assets: {
            logoLarge: "assets/img/logo_angular.png"
        },
        name: "nebulosa",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/usuarios/login"
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "",
        authPath: ""
    } as ApiType

    plataforma = {
        name: "Nebulosa",
        id: null,
        info: {
            companyName: "Ogr.cl"
        }
    } as PlataformaType

    monitorPreguntas = {
        ensayo: {
            groupBy: ["eje temático,sub eje tematico", "curso", "habilidad cognitiva"]
        },
        curricular: {
            groupBy: ["eje temático LGE,objetivo de aprendizaje"]
        },
        saber11: {
            groupBy: ["competencia"]
        },
        saber: {
            groupBy: ["componente"]
        },
        simce: {
            groupBy: ["eje temático LGE,objetivo de aprendizaje"]
        }
    }

    asignaturas = []

    evaluaciones = {}

    mensajesLogin = null
}
