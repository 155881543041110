import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Persona, PersonaForm, Personas } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "personas.form.component.html"
})
export class PersonasEditComponent implements OnInit, OnDestroy {
    oPersona: Persona = new Persona()
    persona: Persona = new Persona()
    params = PersonaForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar persona"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private personasService: Personas,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = PersonaForm.getForm(this.persona)
        this.sub = this.route.params.subscribe(params => {
            this.personasService.find(+params["id"]).then((persona: Persona) => {
                this.persona = persona
                this.oPersona = persona.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        PersonaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.personasService.update(this.persona.id, this.persona).then(response => {
                this.router.navigate(["personas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.persona = this.oPersona.clone()
            PersonaForm.markFormControlsAsPristine(this.form)
            PersonaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
