/** @format */

import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    Injector
} from "@angular/core"
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { config } from "../../../config/config"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Alternativa,
    AlternativaForm,
    ClasificacionPregunta,
    ClasificacionTipo,
    ClasificacionTipos,
    Pregunta,
    PreguntaForm,
    Preguntas,
    ContestableTipos,
    ContestableTipo,
    PreguntaAsignatura,
    PreguntaPlataforma,
    ContestableForm,
    Contestable,
    TaxativoForm,
    Taxativo,
    GrupoPregunta,
    GrupoPreguntas
} from "@puntaje/nebulosa/api-services"
import { DragulaService } from "ng2-dragula"
import { AuthService, FlashMessageService, Main, PdfView, ToggleClassService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { PreguntaSmallComponent } from "./pregunta.small.component"
import { HttpClient } from "@angular/common/http"

@Component({
    templateUrl: "preguntas.form2.html",
    styleUrls: ["preguntas.form.component.scss"]
})
export class PreguntasEditComponent implements OnInit, OnDestroy {
    params = PreguntaForm.formParams
    alternativasParams = this.params.contestables.class.formParams.alternativas.class.formParams
    taxativosParams = this.params.taxativos.class.formParams

    pregunta = new Pregunta()
    oPregunta = new Pregunta()
    form: UntypedFormGroup
    taxativoForm: UntypedFormGroup
    taxativosForm: UntypedFormArray
    alternativasForms: UntypedFormArray[]
    contestablesForms: UntypedFormArray
    logged_layout_title = "Editar pregunta"
    display_buttons_options = "mine all new"
    save_button_text = "Guardar cambios"
    private sub: Subscription
    private dragularSub1: Subscription
    private dragularSub2: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("pdfLoadingLayout") pdfLoadingLayout: LoadingLayoutComponent
    @ViewChild("#alternativaDiv") alternativaDiv: ElementRef
    disableAlternativas: boolean = false
    alternativasLetras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    alternativas_to_remove = []
    @ViewChild(PreguntaSmallComponent) preguntaPreview: PreguntaSmallComponent

    paramsPreviewLatex: any

    enableCustomPSUwarning: boolean = false
    enablePistaWarning: boolean = false
    isPSUValid: boolean = true
    tabWarnings: boolean[] = new Array(6).fill(false)
    @ViewChildren("tabContent") tabContent: QueryList<ElementRef>
    idClasificacionPSU: number
    disableSubmitBtn: boolean = true

    subscriptions: Subscription[] = []

    contestableTipos: ContestableTipo[]

    isPreguntaAlternativas: boolean = true
    isRespuestaConstruida: boolean = false
    isListaDesplegable: boolean = false
    gruposPreguntasFiltered: GrupoPregunta[] = []
    grupoPreguntaSelected: GrupoPregunta

    editMode: boolean = true
    waitCKeditor: boolean = false
    canEdit: boolean = false
    tipoContestable: string

    plataformas_seleccionadas: number[]

    doNotTriggerOrdenOcultarChanges = false

    constructor(
        private preguntasService: Preguntas,
        private router: Router,
        private route: ActivatedRoute,
        private flashMessage: FlashMessageService,
        private toggleClassService: ToggleClassService,
        private cdr: ChangeDetectorRef,
        private dragulaService: DragulaService,
        private authService: AuthService,
        private grupoPreguntasService: GrupoPreguntas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected contestableTiposService: ContestableTipos,
        protected injector: Injector
    ) {}

    ngOnInit() {
        this.preguntasService.disableIgnoreModel()
        this.form = PreguntaForm.getForm(this.pregunta, null, this.injector)
        this.sub = this.route.params.subscribe(params => {
            this.preguntasService
                .find(+params["id"], { include: "[certificacion_recursos,grupo_pregunta]" })
                .then((pregunta: Pregunta) => {
                    this.canEdit = pregunta.certificacion_recursos ? pregunta.certificacion_recursos.length == 0 : false
                    this.plataformas_seleccionadas = pregunta.pregunta_plataformas.map(p => p.plataforma_id)
                    this.pregunta = pregunta
                    this.form = PreguntaForm.getForm(this.pregunta, null, this.injector)
                    this.contestablesForms = this.form.controls["contestables"] as UntypedFormArray
                    this.grupoPreguntaSelected = pregunta.grupo_pregunta
                    this.taxativosForm = this.form.controls["taxativos"] as UntypedFormArray
                    // this.alternativasForms = [
                    //     (this.form.controls["contestables"] as FormGroup).controls["alternativas"] as FormArray
                    // ]
                    this.alternativasForms = (this.form.controls["contestables"] as UntypedFormArray).controls.map(
                        formContestable =>
                            (formContestable as UntypedFormGroup).controls["alternativas"] as UntypedFormArray
                    )
                    const cantAlternativas = this.pregunta.contestables
                        .reduce(
                            (alternativas, contestable) => alternativas.concat(contestable.alternativas.map(a => a)),
                            []
                        )
                        .filter(a => a).length

                    this.alternativasForms[0]["controls"].forEach(control => {
                        const ctrlOcultable = control.get("ocultable")
                        const correcta = control.get("correcta").value

                        if (correcta) {
                            ctrlOcultable.disable()
                            ctrlOcultable.reset()
                        }
                    })

                    this.oPregunta = pregunta.clone()
                    this.setTipoContestable()
                    // this.generateAlternativaControls(cantAlternativas)
                    this.actualizarClasificaciones(pregunta.clasificaciones)
                    this.loadingLayout.ready()
                    this.cdr.detectChanges()
                    this.configureUpdateLatex()
                    this.updatePreviewLatex()
                    this.subscriptions.push(
                        this.form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
                            this.checkTabValidations()
                        })
                    )
                    this.disableSubmitBtn = true
                    this.clasificacionTiposService
                        .where({ clasificacion_tipo: { clasificacion_tipo: "sub eje tematico" } })
                        .then((response: ClasificacionTipo[]) => {
                            if (response && response.length > 0) this.idClasificacionPSU = response[0].id
                            this.disableSubmitBtn = false
                        })
                })
                .then(() => {
                    this.dragularSub1 = this.dragulaService.dragend().subscribe(value => {
                        this.onDragend([value.el])
                    })
                    this.dragularSub2 = this.dragulaService.drag().subscribe(value => {
                        this.onDragend([value.el])
                    })
                })
        })
        setTimeout(() => {
            this.waitCKeditor = true
        }, 1000)
    }

    setTipoContestable() {
        const tipoContestable = this.pregunta.contestables[0].contestable_tipo
        if (tipoContestable && tipoContestable.contestable_tipo === "Pregunta de alternativas") {
            this.isPreguntaAlternativas = true
            this.isRespuestaConstruida = false
            this.isListaDesplegable = false
        } else if (tipoContestable && tipoContestable.contestable_tipo === "Respuesta construida") {
            this.isPreguntaAlternativas = false
            this.isRespuestaConstruida = true
            this.isListaDesplegable = false
        } else if (tipoContestable && tipoContestable.contestable_tipo === "Lista Desplegable") {
            this.isPreguntaAlternativas = false
            this.isRespuestaConstruida = false
            this.isListaDesplegable = true
        }
    }

    onInputText(text) {
        this.grupoPreguntasService
            .where({
                per: 10,
                grupo_pregunta: {
                    like: {
                        id: text
                    }
                }
            })
            .then((gruposPreguntas: GrupoPregunta[]) => {
                this.gruposPreguntasFiltered = gruposPreguntas
            })
    }

    configureUpdateLatex() {
        const isContestables = this.pregunta.contestables.length > 0
        const isTaxativos = this.pregunta.taxativos.length > 0

        this.subscriptions.push(
            this.form
                .get("solucion_latex")
                .valueChanges.pipe(
                    filter(x => x as any),
                    distinctUntilChanged(),
                    debounceTime(2000)
                )
                .subscribe(this.updatePreviewLatex.bind(this))
        )
        if (isTaxativos) {
            ;(this.form.get("taxativos") as UntypedFormArray).controls.forEach(control => {
                this.subscriptions.push(
                    control
                        .get("taxativo_latex")
                        .valueChanges.pipe(
                            filter(x => x as any),
                            distinctUntilChanged(),
                            debounceTime(2000)
                        )
                        .subscribe(this.updatePreviewLatex.bind(this))
                )
            })
        } else {
            this.subscriptions.push(
                this.form
                    .get("taxativo")
                    .get("taxativo_latex")
                    .valueChanges.pipe(
                        filter(x => x as any),
                        distinctUntilChanged(),
                        debounceTime(2000)
                    )
                    .subscribe(this.updatePreviewLatex.bind(this))
            )
        }
        if (isContestables) {
            ;(this.form.get("contestables") as UntypedFormArray).controls.forEach(contestable => {
                ;(contestable.get("alternativas") as UntypedFormArray).controls.forEach(control => {
                    this.subscriptions.push(
                        control
                            .get("alternativa_latex")
                            .valueChanges.pipe(
                                filter(x => x as any),
                                distinctUntilChanged(),
                                debounceTime(2000)
                            )
                            .subscribe(this.updatePreviewLatex.bind(this))
                    )
                })
            })
        } else {
            ;(this.form.get("contestable").get("alternativas") as UntypedFormArray).controls.forEach(control => {
                this.subscriptions.push(
                    control
                        .get("alternativa_latex")
                        .valueChanges.pipe(
                            filter(x => x as any),
                            distinctUntilChanged(),
                            debounceTime(2000)
                        )
                        .subscribe(this.updatePreviewLatex.bind(this))
                )
            })
        }
    }

    private generateAlternativaControls(numAlternativas: number) {
        this.alternativasForms = [
            (this.form.controls["contestables"] as UntypedFormGroup).controls["alternativas"] as UntypedFormArray
        ]
        let i = 0
        this.pregunta.contestables.forEach((contestable, index) => {
            while (i < numAlternativas - 2) {
                const alternativaControl = AlternativaForm.getForm(new Alternativa())
                this.alternativasForms[index].push(alternativaControl)
                i++
            }
        })
    }

    public addAlternativa(index: number) {
        if (this.pregunta.contestables[index].alternativas.length == 10) {
            return
        }
        const alternativaControl = AlternativaForm.getForm(new Alternativa())
        this.contestablesForms.controls[index]["controls"].alternativas.push(alternativaControl)
        this.pregunta.contestables[index].agregarAlternativa()
    }

    public removeAlternativa(index: number, indexContestable: number) {
        let alternativa_id = this.pregunta.contestables[indexContestable].removeAlternativa(index)
        if (alternativa_id) {
            this.alternativasForms[indexContestable].controls.splice(index, 1)
            this.alternativas_to_remove.push(alternativa_id)
            this.restoreAlternativasLetras()
        }
    }

    private restoreAlternativasLetras() {
        var i = 0
        for (let a of this.pregunta["contestables"][0]?.["alternativas"]) {
            a["letra"] = this.alternativasLetras[i]
            i++
        }
    }

    private onDragend(args) {
        let [el, target, source] = args
        this.restoreAlternativasLetras()
        this.disableAlternativas = false
        this.cdr.detectChanges()
    }

    private onDrag(args) {
        let [e, el] = args
        this.saveAlternativasLetras()
        this.disableAlternativas = true
    }

    private saveAlternativasLetras() {
        let i = 0
        this.pregunta.contestables.forEach(contestable =>
            contestable.alternativas.forEach(a => (a["letra"] = this.alternativasLetras[i]), i++)
        )
    }

    disableOnClick(event) {
        event.stopPropagation()
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        if (this.dragularSub1) this.dragularSub1.unsubscribe()
        if (this.dragularSub2) this.dragularSub2.unsubscribe()
        this.subscriptions.forEach(s => s.unsubscribe())
        // this.dragulaService.destroy('alternativas-bag');
    }

    updatePreviewLatex() {
        let params: any = {
            pregunta: {
                ...this.pregunta,
                taxativo: { ...this.pregunta.taxativos },
                contestable: {
                    ...this.pregunta.contestables,
                    alternativas: [...this.pregunta.contestables.map(c => c.alternativas.map(a => ({ ...a })))]
                }
            }
        }

        delete params.clasificacion_preguntas
        delete params.clasificaciones
        delete params.grupo_pregunta

        this.paramsPreviewLatex = params
    }

    save() {
        PreguntaForm.markFormControlsAsTouched(this.form)
        this.customPSUcheck()
        this.checkPistas()
        setTimeout(() => {
            this.checkTabValidations()
        }, 800)
        if (this.form.valid && this.customPSUcheck() && this.checkPistas()) {
            this.disableSubmitBtn = true
            this.setOrden()
            let pregunta_update = this.pregunta.clone()
            for (let index of this.alternativas_to_remove) {
                let alternativa = new Alternativa()
                alternativa.id = index
                alternativa["_destroy"] = true
                pregunta_update.contestables.map(contestable => contestable.alternativas.push(alternativa))
            }

            if (!pregunta_update.taxativos.filter(t => t.taxativo_latex)) {
                pregunta_update.taxativos.map(
                    taxativo => (taxativo.taxativo_latex = Main.html2latex(pregunta_update.taxativos[0]?.taxativo))
                )
            }

            if (!pregunta_update.solucion_latex) {
                pregunta_update.solucion_latex = Main.html2latex(pregunta_update.solucion)
            }

            pregunta_update.contestables.forEach(contestable =>
                contestable.alternativas.forEach(a => {
                    if (!a.alternativa_latex && a.alternativa) {
                        a.alternativa_latex = Main.html2latex(a.alternativa)
                    }
                })
            )

            if (this.pregunta.profesor) pregunta_update.profesor_id = this.pregunta.profesor.id
            if (this.pregunta.encargado_reporte)
                pregunta_update.encargado_reporte_id = this.pregunta.encargado_reporte.id
            if (this.pregunta.autor) pregunta_update.autor_id = this.pregunta.autor.id

            pregunta_update.grupo_pregunta_id = this.grupoPreguntaSelected ? this.grupoPreguntaSelected.id : null

            this.preguntasService.update(pregunta_update.id, pregunta_update).then((pregunta: Pregunta) => {
                this.flashMessage.status = "success"
                this.flashMessage.message = "La pregunta #" + pregunta.id + " ha sido editada exitosamente."
                this.router.navigate(["/preguntas/" + this.pregunta.id])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.pregunta = this.oPregunta.clone()
            const cantAlternativas = this.pregunta.contestables
                .reduce((alternativas, contestable) => alternativas.concat(contestable.alternativas.map(a => a)), [])
                .filter(a => a).length
            //this.generateAlternativaControls(cantAlternativas)
            this.alternativas_to_remove = []
            PreguntaForm.markFormControlsAsPristine(this.form)
            PreguntaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    toLatex(model, from, to) {
        if (model[from]) {
            model[to] = Main.html2latex(model[from])
        }
    }

    toggleAlternativa(elem, clase) {
        this.toggleClassService.toggleClass(elem, clase)
    }

    toggleDistractor(index: number, elem, clase) {
        if (this.pregunta.contestables[0]?.alternativas[index].correcta) {
            this.toggleClassService.removeClass(elem, clase)
        } else {
            this.toggleClassService.addClass(elem, clase)
        }
    }

    actualizarClasificaciones(event) {
        this.pregunta.clasificacion_preguntas = []
        if (event && Array.isArray(event) && event.length > 0) {
            let list = []
            for (let c of event) {
                let cp = new ClasificacionPregunta()
                cp.clasificacion_id = c.id
                this.pregunta.clasificacion_preguntas.push(cp)
            }
        }
    }

    public checkSession() {
        if (!this.authService.isLoggedIn()) {
            this.authService.logout()
            // Redirect the user
            this.router.navigate([config.app.paths.landing])
        }
    }

    customPSUcheck() {
        // reviso si tiene clasificación psu, osea si tiene clasificación tipo sub eje
        let isPSU = false
        this.pregunta.clasificaciones.forEach(c => {
            isPSU = isPSU || c.clasificacion_tipo_id == this.idClasificacionPSU
        })
        this.isPSUValid = (this.pregunta.contestables[0]?.alternativas.length == 5 && isPSU) || !isPSU
        return this.isPSUValid
    }

    checkPistas() {
        const alternativasConPista = this.pregunta.contestables[0]?.alternativas.map(a => a.pista).filter(a => a).length
        this.enablePistaWarning = !(
            alternativasConPista == 0 || alternativasConPista >= this.pregunta.contestables[0]?.alternativas.length - 1
        )

        return !this.enablePistaWarning
    }

    checkTabValidations() {
        let i = 0
        this.tabContent.forEach(tc => {
            this.tabWarnings[i] = tc.nativeElement.getElementsByClassName("error-list").length > 0
            i++
        })
    }

    copiarPista(pista: string) {
        this.pregunta.contestables.forEach(contestable =>
            contestable.alternativas.forEach(alternativa => {
                if (!alternativa.correcta) alternativa.pista = pista
                else alternativa.pista = ""
            })
        )
    }

    correctaChange(index: number, value: boolean) {
        let ctrlOcultable = this.alternativasForms[0]["controls"][index].get("ocultable")
        if (value) {
            ctrlOcultable.disable()
            ctrlOcultable.reset()
        } else {
            ctrlOcultable.enable()
        }
    }

    public ordenOcultarChange(index: number, value: number) {
        value = +value
        const ctrlAlternativas = this.alternativasForms[0]["controls"]
        const ctrlOrdenOcultar = this.alternativasForms[0]["controls"][index].get("orden_ocultar")

        const maxValue = ctrlAlternativas.filter(ctrl => ctrl.get("ocultable").value).length - 1
        // Saco el valor antiguo basandome el valor que falta dentro de los posibles ordenes
        const oldValue =
            new Array(maxValue + 1)
                .fill(0)
                .map((_, i) => i)
                .find(v => ctrlAlternativas.every(ctrl => +ctrl.get("orden_ocultar").value != v)) ?? value

        const delta = Math.abs(oldValue - value) / (oldValue - value)
        const betweenMin = delta > 0 ? value : oldValue + 1
        const betweenMax = delta > 0 ? oldValue - 1 : value

        const ctrlAlternativasBetween = ctrlAlternativas
            .map((ctrl, i) => [ctrl, i] as [UntypedFormControl, number])
            .filter(
                ([c, i]) =>
                    +c.get("orden_ocultar").value >= betweenMin &&
                    +c.get("orden_ocultar").value <= betweenMax &&
                    i != index
            )
        ctrlAlternativasBetween.forEach(([c, i]) => {
            const newValue = +c.get("orden_ocultar").value + delta

            c.get("orden_ocultar").setValue(newValue, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[i].orden_ocultar = newValue
        })

        if (value > maxValue) {
            ctrlOrdenOcultar.setValue(maxValue, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[index].orden_ocultar = maxValue
        }
    }

    ocultableChange(index: number, value: boolean) {
        this.doNotTriggerOrdenOcultarChanges = true

        let ctrlAlternativas = this.alternativasForms[0]["controls"]
        let indexOrdenOcultarCtrl = ctrlAlternativas[index].get("orden_ocultar")
        let otherAlternativas = ctrlAlternativas
            .map((c, i) => [c, i] as [UntypedFormControl, number])
            .filter(([x, i]) => i !== index && x.get("ocultable").value === true)
        let maxValue = otherAlternativas.reduce((x, [y]) => {
            const ordenOcultar = y.get("orden_ocultar").value

            return Math.max(x, Number.isInteger(ordenOcultar) ? ordenOcultar : -1)
        }, -1)
        let minValue = otherAlternativas.reduce((x, [y]) => {
            const ordenOcultar = y.get("orden_ocultar").value

            return Math.min(x, Number.isInteger(ordenOcultar) ? ordenOcultar : 0)
        }, 0)
        if (!value) {
            let currentOrdenOcultar = indexOrdenOcultarCtrl.value
            indexOrdenOcultarCtrl.setValue(null, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[index].orden_ocultar = null

            if (currentOrdenOcultar === minValue) {
                otherAlternativas.forEach(([x, i]) => {
                    let ordenOcultarCtrl = x.get("orden_ocultar")
                    const newOrdenOcultar = +ordenOcultarCtrl.value - 1

                    ordenOcultarCtrl.setValue(newOrdenOcultar, { emitEvent: false })
                    this.pregunta.contestables[0].alternativas[i].orden_ocultar = newOrdenOcultar
                })
            } else if (currentOrdenOcultar > minValue && currentOrdenOcultar < maxValue) {
                otherAlternativas.forEach(([x, i]) => {
                    let ordenOcultarCtrl = x.get("orden_ocultar")

                    if (ordenOcultarCtrl.value > currentOrdenOcultar) {
                        const newOrdenOcultar = +ordenOcultarCtrl.value - 1

                        ordenOcultarCtrl.setValue(newOrdenOcultar, { emitEvent: false })
                        this.pregunta.contestables[0].alternativas[i].orden_ocultar = newOrdenOcultar
                    }
                })
            }
        } else {
            indexOrdenOcultarCtrl.setValue(maxValue + 1, { emitEvent: false })
            this.pregunta.contestables[0].alternativas[index].orden_ocultar = maxValue + 1
        }
        this.cdr.detectChanges()

        this.doNotTriggerOrdenOcultarChanges = false
    }

    private setOrden() {
        this.restoreAlternativasLetras()
        let letras = "abcdefghijklmnopqrstuvwxyz"
        this.pregunta.contestables[0]?.alternativas.forEach((alternativa: Alternativa, index: number) => {
            if (alternativa["letra"]) {
                alternativa.orden = letras.indexOf(alternativa["letra"].toLowerCase())
            } else {
                alternativa.orden = index
            }
        })
    }

    onUpdateAsignaturas(ids: number[]) {
        this.pregunta.asignatura_ids = ids
    }

    actualizarPlataformas(plataforma_ids: number[]) {
        this.pregunta.pregunta_plataformas = plataforma_ids.map(id => {
            let cp = new PreguntaPlataforma()
            cp.plataforma_id = id
            return cp
        })
    }

    public addTaxativo() {
        const taxativoControl = TaxativoForm.getForm(new Taxativo())
        this.taxativosForm.push(taxativoControl)
        this.pregunta.taxativos.push(new Taxativo())
        this.cdr.detectChanges()
    }

    public addContestable = (tipo: ContestableTipo): void => {
        const newContestable = new Contestable(tipo.id)
        const contestableControl = ContestableForm.getForm(newContestable)
        this.contestablesForms.push(contestableControl)
        this.pregunta.contestables.push(newContestable)
    }

    toggleTipoContestable(tipo: string) {
        this.tipoContestable = tipo
        switch (tipo) {
            case "alternativas": {
                const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Pregunta de alternativas")
                this.isPreguntaAlternativas = true
                this.isRespuestaConstruida = false
                this.isListaDesplegable = false
                this.addContestable(tipo)
                this.alternativasForms = (this.form.controls["contestables"] as UntypedFormArray).controls.map(
                    formContestable =>
                        (formContestable as UntypedFormGroup).controls["alternativas"] as UntypedFormArray
                )
                var i = 0
                for (let a of this.pregunta["contestables"][0]["alternativas"]) {
                    a["letra"] = this.alternativasLetras[i]
                    i++
                }

                this.cdr.detectChanges()
                this.configureUpdateLatex()
                break
            }

            case "respuesta construida": {
                const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Respuesta construida")
                this.isPreguntaAlternativas = false
                this.isRespuestaConstruida = true
                this.isListaDesplegable = false
                this.pregunta.contestables = [new Contestable(tipo.id, 1)]
                this.cdr.detectChanges()
                break
            }

            case "lista desplegable": {
                const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Lista Desplegable")
                this.isPreguntaAlternativas = false
                this.isRespuestaConstruida = false
                this.isListaDesplegable = true
                this.pregunta.contestables = []
                this.contestablesForms = this.form.controls["contestables"] as UntypedFormArray
                this.alternativasForms = []
                this.cdr.detectChanges()
                break
            }

            default:
                break
        }
    }
}
