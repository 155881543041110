import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { PlataformasModule } from "../plataformas/plataformas.module"
import { AyudaComponent } from "./ayuda.component"
import { AyudaPreviewComponent } from "./ayuda.preview.component"
import { AyudasComponent } from "./ayudas.component"
import { AyudasDeleteComponent } from "./ayudas.delete.component"
import { AyudasEditComponent } from "./ayudas.edit.component"
import { AyudasNewComponent } from "./ayudas.new.component"
import { ayudasRouting } from "./ayudas.routing"
import { AyudasToolNavbarComponent } from "./ayudas.toolnavbar.component"
import { AyudasBuscadorComponent } from "./ayudas_buscador.component"
import { AyudasBuscadorService } from "./ayudas_buscador.service"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ayudasRouting,
        ModelViewModule,
        PaginatorModule,
        PlataformasModule,
        Angular2FontawesomeModule,
        NebuModule
    ],
    declarations: [
        AyudasComponent,
        AyudaComponent,
        AyudasNewComponent,
        AyudasEditComponent,
        AyudasDeleteComponent,
        AyudasToolNavbarComponent,
        AyudaPreviewComponent,
        AyudasBuscadorComponent
    ]
})
export class AyudasModule {
    static forRoot(): ModuleWithProviders<AyudasModule> {
        return {
            ngModule: AyudasModule,
            providers: [AyudasBuscadorService]
        }
    }
}
