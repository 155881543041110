import { RouterModule } from "@angular/router"
import { MaterialGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { MaterialComponent } from "./material.component"
import { MaterialesComponent } from "./materiales.component"
import { MaterialesDeleteComponent } from "./materiales.delete.component"
import { MaterialesEditComponent } from "./materiales.edit.component"
import { MaterialesNewComponent } from "./materiales.new.component"
import { MaterialesUsuarioComponent } from "./materiales_usuario.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { MaterialesListComponent } from "./materiales-list/materiales-list.component"
import { EditarMaterialesMasivamenteComponent } from "./editar-materiales-masivamente/editar-materiales-masivamente.component"

export const materialesRouting = RouterModule.forChild([
    {
        path: "materiales",
        component: MaterialesListComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#index" }
    },
    {
        path: "materiales/new",
        component: MaterialesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#create" }
    },

    {
        path: "materiales/:id",
        component: MaterialComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#show" }
    },
    {
        path: "materiales/:id/edit",
        component: MaterialesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#update" }
    },
    {
        path: "materiales/:id/delete",
        component: MaterialesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#destroy" }
    },
    {
        path: "mis_materiales",
        component: MaterialesUsuarioComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#index" }
    },
    {
        path: "editar_materiales_masivamente",
        component: EditarMaterialesMasivamenteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Materiales#index" }
    }
])

export const materialesProviders = [AuthGuard]
