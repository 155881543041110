import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Usuario, Usuarios, UsuarioUsuarios, UsuarioUsuario } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "sub-administrador-row",
    templateUrl: "./sub-administrador-row.component.html",
    styleUrls: ["./sub-administrador-row.component.scss"]
})
export class SubAdministradorRowComponent implements OnInit {
    @Input() usuario: Usuario
    usuariosAsociar: Usuario[]
    usuarioParaAsociar: Usuario

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected usuariosService: Usuarios, protected usuarioUsuariosService: UsuarioUsuarios) {}

    ngOnInit() {}

    ngAfterContentInit(): void {
        //Called after ngOnInit when the component's or directive's content has been initialized.
        //Add 'implements AfterContentInit' to the class.
        this.loadingLayout.ready()
    }

    usuariosFiltro(nombre) {
        this.usuariosService.where({ include: "persona", persona: { nombre_completo: nombre } }).then(usuarios => {
            this.usuariosAsociar = usuarios
        })
    }

    asociarUsuario() {
        if (this.usuarioParaAsociar) {
            const usuarioAsociado = this.usuario.usuarios_hijos.some(u => u.id == this.usuarioParaAsociar.id)
            if (usuarioAsociado) {
                return
            }

            this.loadingLayout.standby()

            const usuarioUsuario = new UsuarioUsuario()
            usuarioUsuario.usuario_ref_id = this.usuario.id
            usuarioUsuario.usuario_id = this.usuarioParaAsociar.id

            this.usuarioUsuariosService.save(usuarioUsuario).then(uu => {
                this.reloadUsuario()
            })
        }
    }

    reloadUsuario() {
        console.log("reload")
        this.loadingLayout.standby()

        const params = {
            include: "[perfiles,persona,usuarios_hijos:[perfiles,persona]]"
        }

        this.usuariosService.find(this.usuario.id, params).then(usuario => {
            this.usuario = usuario
            this.usuarioParaAsociar = undefined
            this.usuariosAsociar = undefined

            this.loadingLayout.ready()
        })
    }
}
