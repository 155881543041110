<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>Plataforma</label>
            <select
                class="form-control"
                name="plataforma_id"
                [(ngModel)]="searchParams.tipo_instrumento_plataforma.plataforma_id"
                (ngModelChange)="deleteIfUndefined(searchParams.tipo_instrumento_plataforma, 'plataforma_id')"
            >
                <option [ngValue]="undefined">-- Seleccione Plataforma --</option>
                <option *ngFor="let plataforma of plataformas" [ngValue]="plataforma.id">
                    {{ plataforma.plataforma }}
                </option>
            </select>
        </div>

        <button class="btn btn-default" (click)="search()">Buscar</button>
    </div>

    <div class="col-md-6">
        <div class="form-group">
            <label>Tipo Instrumento</label>
            <select
                class="form-control"
                name="tipo_instrumento_id"
                [(ngModel)]="searchParams.tipo_instrumento_plataforma.tipo_instrumento_id"
                (ngModelChange)="deleteIfUndefined(searchParams.tipo_instrumento_plataforma, 'tipo_instrumento_id')"
            >
                <option [ngValue]="undefined">-- Seleccione Tipo Instrumento --</option>
                <option *ngFor="let tipo_instrumento of tipoInstrumentos" [ngValue]="tipo_instrumento.id">
                    {{ tipo_instrumento.tipo_instrumento }}
                </option>
            </select>
        </div>
    </div>
</form>
