import { Component, ViewChild, OnInit } from "@angular/core"
import { Usuario, Usuarios, UsuarioUsuarios } from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "mis-usuarios-asociados",
    templateUrl: "./mis-usuarios-asociados.component.html"
})
export class MisUsuariosAsociadosComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    usuario: Usuario

    constructor(
        protected usuariosService: Usuarios,
        protected usuarioUsuariosService: UsuarioUsuarios,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.reloadUsuarios()
    }

    reloadUsuarios() {
        this.loadingLayout.standby()

        const params = {
            include: "[perfiles,persona,usuarios_hijos:[perfiles,persona]]"
        }

        this.usuariosService.find(+this.authService.getUserData().id, params).then(usuario => {
            this.usuario = usuario
            this.loadingLayout.ready()
        })
    }
}
