<logged-layout>
    <logged-layout-titulo>Editar preguntas masivamente</logged-layout-titulo>
    <logged-layout-contenido>
        <div class="row">
            <div class="col-md-8 col-sm-12">
                <form novalidate [formGroup]="form">
                    <form-input
                        [form]="form"
                        [params]="params['file']"
                        [(value)]="archivo['file']"
                        [key]="'file'"
                        [xhr_upload]="s3Service.xhr_upload"
                    ></form-input>
                    <div class="col-sm-12 buttons">
                        <button [disabled]="saving" (click)="save()" type="button" class="btn btn-default">
                            Guardar
                        </button>
                        <button (click)="clear()" type="button" class="btn btn-default">Deshacer</button>
                    </div>
                </form>
                <br />
                <br />
                Sube un archivo de tipo csv con los siguientes atributos como el header, el id es obligatorio!
                <div role="alert">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>asignatura_id</th>
                                <th>grupo_pregunta_id</th>
                                <th>profesor_id</th>
                                <th>encargado_reporte_id</th>
                                <th>autor_id</th>
                                <th>visible</th>
                                <th>estado</th>
                                <th>agregar_clasificacion</th>
                                <th>eliminar_clasificacion</th>
                                <th>agregar_plataforma</th>
                                <th>solucion_video</th>
                                <th *ngIf="response">Mensaje</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="response">
                            <tr
                                *ngFor="let resp of response.res"
                                [class.alert-danger]="resp.mensaje != ''"
                                [class.alert-success]="resp.mensaje == ''"
                            >
                                <td>{{ resp.id ? resp.id : "" }}</td>
                                <td>{{ resp.asignatura_id ? resp.asignatura_id : "" }}</td>
                                <td>{{ resp.grupo_pregunta_id ? resp.grupo_pregunta_id : "" }}</td>
                                <td>{{ resp.profesor_id ? resp.profesor_id : "" }}</td>
                                <td>{{ resp.encargado_reporte_id ? resp.encargado_reporte_id : "" }}</td>
                                <td>{{ resp.autor_id ? resp.autor_id : "" }}</td>
                                <td>{{ resp.visible ? resp.visible : "" }}</td>
                                <td>{{ resp.estado ? resp.estado : "" }}</td>
                                <td>{{ resp.agregar_clasificacion ? resp.agregar_clasificacion : "" }}</td>
                                <td>
                                    {{ resp.eliminar_clasificacion ? resp.eliminar_clasificacion : "" }}
                                </td>
                                <td>
                                    {{ resp.agregar_plataforma ? resp.agregar_plataforma : "" }}
                                </td>
                                <td>{{ resp.solucion_video ? resp.solucion_video : "" }}</td>
                                <td>{{ resp.mensaje }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="response && response['error']" class="alert alert-danger" role="alert">
                    {{ response["error"] }}
                </div>
            </div>
        </div>
    </logged-layout-contenido>
</logged-layout>
