import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { AsignaturaPlataformas } from "./asignatura_plataformas.service"
import { ClasificacionTipoAliases } from "./clasificacion_tipo_aliases.service"
import { ClasificacionTipos } from "./clasificacion_tipos.service"
import { TipoInstrumentos } from "./tipo_instrumentos.service"
import { Plataformas } from "./plataformas.service"

export class TipoInstrumentoPlataformaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            alias: {
                label: "Alias de Tipo de Instrumento",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(1)]
            },
            tipo_instrumento: {
                label: "Tipo de Instrumento",
                type: "autocomplete",
                options: { class: TipoInstrumentos }
            },
            plataforma_id: {
                label: "Plataforma",
                type: "select",
                options: { class: Plataformas }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
