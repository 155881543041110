import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"

export class ClasificacionTipoForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            clasificacion_tipo: {
                label: "Tipo de Clasificación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(5)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
