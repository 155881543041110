<div class="preview">
    <div *ngIf="material && material.material_tipo_id && enablePreview" [class.html-box]="enableHTMLBorder">
        <model-plain-value [params]="params" [value]="material" [key]="key" *ngIf="enablePreview"></model-plain-value>
    </div>
    <div *ngIf="!enablePreview && !enableCheck">
        <p class="no-preview">
            Vista previa no disponible.
            <br />
            <small [innerHTML]="noViewMessage"></small>
        </p>
    </div>

    <div *ngIf="enablePreview && warningMessage.length > 0" class="warning">
        <div class="warning-content">
            <div class="warning-icon"><fa [name]="'warning'"></fa></div>
            <div class="warning-message">
                <p>{{ warningMessage }}</p>
            </div>
        </div>
    </div>
</div>
