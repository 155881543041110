import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Asignatura, Asignaturas, AsignaturaView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "asignatura.component.html"
})
export class AsignaturaComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    show_params = AsignaturaView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private asignaturasService: Asignaturas, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.asignaturasService.unico(+params["id"]).then((asignatura: Asignatura) => {
                this.asignatura = asignatura
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
