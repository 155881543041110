import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoginComponent } from "./login/login.component"
import { UsuarioComponent } from "./usuario.component"
import { UsuariosComponent } from "./usuarios.component"
import { UsuariosDeleteComponent } from "./usuarios.delete.component"
import { UsuariosEditComponent } from "./usuarios.edit.component"
import { UsuariosNewComponent } from "./usuarios.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { SubAdministradoresComponent } from "./sub-administradores/sub-administradores.component"
import { MisUsuariosAsociadosComponent } from "./mis-usuarios-asociados/mis-usuarios-asociados.component"

export const routes: Routes = [
    {
        path: "usuarios",
        component: UsuariosComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#index" }
    },
    { path: "usuarios/login", component: LoginComponent },
    {
        path: "usuarios/sub_administradores",
        component: SubAdministradoresComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#index" }
    },
    {
        path: "usuarios/new",
        component: UsuariosNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#create" }
    },
    {
        path: "usuarios/asociados",
        component: MisUsuariosAsociadosComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#indexAsociados" }
    },
    {
        path: "usuarios/:id",
        component: UsuarioComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#show" }
    },
    {
        path: "usuarios/:id/edit",
        component: UsuariosEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#update" }
    },
    {
        path: "usuarios/:id/delete",
        component: UsuariosDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Usuarios#destroy" }
    }
]

export const routing = RouterModule.forChild(routes)
