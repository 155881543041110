import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, AbstractControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Lote,
    Lotes,
    Material,
    Materiales,
    PlanEstudio,
    PlanEstudioComponente,
    PlanEstudioEditForm,
    PlanEstudioLote,
    PlanEstudioLoteForm,
    PlanEstudioLotes,
    PlanEstudioMaterial,
    PlanEstudioMateriales,
    PlanEstudioMaterialForm,
    PlanEstudios,
    PlanEstudioSesion,
    PlanEstudioSesiones,
    PlanEstudioSesionForm
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "plan_estudios.sesiones.component.html",
    styleUrls: ["plan_estudios.sesiones.component.css"]
})
export class PlanEstudiosSesionesComponent implements OnInit, OnDestroy {
    params = PlanEstudioEditForm.formParams
    sesionesParams = this.params.plan_estudio_sesiones.class.formParams
    lotesParams = this.params.plan_estudio_sesiones.class.formParams.plan_estudio_lotes.class.formParams
    materialesParams = this.params.plan_estudio_sesiones.class.formParams.plan_estudio_materiales.class.formParams
    planEstudio: PlanEstudio = new PlanEstudio()
    lotes: Lote[] = []
    materiales: Material[] = []
    cosas: { [x: string]: UntypedFormControl } = {}
    lastValue: { [x: string]: string } = {}
    listCheck: { [x: string]: boolean } = {}
    form: UntypedFormGroup
    sesionesForm: UntypedFormArray
    mType = PlanEstudioMaterial
    lType = PlanEstudioLote
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Planes de Estudio, Sesiones"
    display_buttons_options = "all"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private lotesService: Lotes,
        private materialesService: Materiales,
        private planEstudiosService: PlanEstudios,
        private planEstudioSesionesService: PlanEstudioSesiones,
        private planEstudioMaterialesService: PlanEstudioMateriales,
        private planEstudioLotesService: PlanEstudioLotes,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.planEstudiosService.find(+params["id"], { with_sesiones: true }).then((response: PlanEstudio) => {
                this.planEstudio = response
                for (let sesion of this.planEstudio.plan_estudio_sesiones) {
                    for (let material of sesion.plan_estudio_materiales) {
                        this.cosas[sesion.orden + "_" + material.orden] = new UntypedFormControl()
                        this.cosas[sesion.orden + "_" + material.orden].setValue(material.material.material)
                        this.listCheck[sesion.orden + "_" + material.orden] = false
                        this.cosas[sesion.orden + "_" + material.orden].valueChanges
                            .pipe(debounceTime(500), distinctUntilChanged())
                            .subscribe(value => {
                                if (value != "" && value != this.lastValue[sesion.orden + "_" + material.orden]) {
                                    this.listCheck[sesion.orden + "_" + material.orden] = true
                                    this.changeMaterial(value)
                                }
                            })
                    }
                    for (let lote of sesion.plan_estudio_lotes) {
                        this.cosas[sesion.orden + "_" + lote.orden] = new UntypedFormControl()
                        this.cosas[sesion.orden + "_" + lote.orden].setValue(lote.lote.lote)
                        this.listCheck[sesion.orden + "_" + lote.orden] = false
                        this.cosas[sesion.orden + "_" + lote.orden].valueChanges
                            .pipe(debounceTime(500), distinctUntilChanged())
                            .subscribe(value => {
                                if (value != "" && value != this.lastValue[sesion.orden + "_" + lote.orden]) {
                                    this.listCheck[sesion.orden + "_" + lote.orden] = true
                                    this.changeLote(value)
                                }
                            })
                    }
                }
                this.form = PlanEstudioEditForm.getForm(this.planEstudio)
                this.generateSesionesControls()
                this.logged_layout_title = this.planEstudio.plan_estudio
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    private generateSesionesControls() {
        this.sesionesForm = this.form.controls["plan_estudio_sesiones"] as UntypedFormArray
    }

    agregarSesion() {
        let s = this.planEstudio.agregarSesion()
        let pesControl = PlanEstudioSesionForm.getForm(s)
        this.sesionesForm.push(pesControl)
    }

    quitarSesion(orden: number) {
        this.planEstudio.removerSesion(orden)
        let sin = this.sesionesForm.controls.filter(c => (c as UntypedFormGroup).controls["orden"].value != orden)
        this.sesionesForm.controls = sin
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value > orden) {
                c.controls["orden"].setValue(c.controls["orden"].value - 1)
            }
        })
    }

    agregarMaterial(sOrden: number) {
        let m = this.planEstudio.agregarMaterialSesion(sOrden)
        let pemControl = PlanEstudioMaterialForm.getForm(m)
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value == sOrden) {
                ;(c.controls["plan_estudio_materiales"] as UntypedFormArray).push(pemControl)
                this.cosas[sOrden + "_" + m.orden] = new UntypedFormControl()
                this.listCheck[sOrden + "_" + m.orden] = false
                this.cosas[sOrden + "_" + m.orden].valueChanges
                    .pipe(debounceTime(500), distinctUntilChanged())
                    .subscribe(value => {
                        if (value != "" && value != this.lastValue[sOrden + "_" + m.orden]) {
                            this.listCheck[sOrden + "_" + m.orden] = true
                            this.changeMaterial(value)
                        }
                    })
            }
        })
    }

    quitarMaterial(sOrden: number, orden: number) {
        this.planEstudio.removerMaterialSesion(sOrden, orden)
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value == sOrden) {
                let sin = (c.controls["plan_estudio_materiales"] as UntypedFormArray).controls.filter(
                    c => (c as UntypedFormGroup).controls["orden"].value != orden
                )
                ;(c.controls["plan_estudio_materiales"] as UntypedFormArray).controls = sin
                ;(c.controls["plan_estudio_materiales"] as UntypedFormArray).controls.map(mControl => {
                    const m = mControl as UntypedFormGroup
                    if (m.controls["orden"].value > orden) {
                        m.controls["orden"].setValue(m.controls["orden"].value - 1)
                    }
                })
                ;(c.controls["plan_estudio_lotes"] as UntypedFormArray).controls.map(lControl => {
                    const l = lControl as UntypedFormGroup
                    if (l.controls["orden"].value > orden) {
                        l.controls["orden"].setValue(l.controls["orden"].value - 1)
                    }
                })
            }
        })
    }

    agregarLote(sOrden: number) {
        let l = this.planEstudio.agregarLoteSesion(sOrden)
        let pelControl = PlanEstudioLoteForm.getForm(l)
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value == sOrden) {
                ;(c.controls["plan_estudio_lotes"] as UntypedFormArray).push(pelControl)
                this.cosas[sOrden + "_" + l.orden] = new UntypedFormControl()
                this.listCheck[sOrden + "_" + l.orden] = false
                this.cosas[sOrden + "_" + l.orden].valueChanges
                    .pipe(debounceTime(500), distinctUntilChanged())
                    .subscribe(value => {
                        if (value != "" && value != this.lastValue[sOrden + "_" + l.orden]) {
                            this.listCheck[sOrden + "_" + l.orden] = true
                            this.changeLote(value)
                        }
                    })
            }
        })
    }

    quitarLote(sOrden: number, orden: number) {
        this.planEstudio.removerLoteSesion(sOrden, orden)
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value == sOrden) {
                let sin = (c.controls["plan_estudio_lotes"] as UntypedFormArray).controls.filter(
                    c => (c as UntypedFormGroup).controls["orden"].value != orden
                )
                ;(c.controls["plan_estudio_lotes"] as UntypedFormArray).controls = sin
                ;(c.controls["plan_estudio_lotes"] as UntypedFormArray).controls.map(lControl => {
                    const l = lControl as UntypedFormGroup
                    if (l.controls["orden"].value > orden) {
                        l.controls["orden"].setValue(l.controls["orden"].value - 1)
                    }
                })
                ;(c.controls["plan_estudio_materiales"] as UntypedFormArray).controls.map(mControl => {
                    const m = mControl as UntypedFormGroup
                    if (m.controls["orden"].value > orden) {
                        m.controls["orden"].setValue(m.controls["orden"].value - 1)
                    }
                })
            }
        })
    }

    ordenar(list_m: PlanEstudioComponente[], list_l: PlanEstudioComponente[]) {
        return list_m
            .concat(list_l)
            .sort((a, b) => {
                return a.orden - b.orden
            })
            .filter(elem => elem["_destroy"] == undefined)
    }

    componentPosition(list: string, sesion: number, orden: number) {
        var pos = -1
        ;((this.sesionesForm.controls[sesion] as UntypedFormGroup).controls[list] as UntypedFormArray).controls.forEach(
            (c, index: number) => {
                if ((c as UntypedFormGroup).controls["orden"].value == orden) {
                    pos = index
                }
            }
        )
        return pos
    }

    changeMaterial(value: any) {
        if (!isNaN(value)) {
            this.materialesService.where({ material: { id: value } }).then((materiales: Material[]) => {
                this.materiales = materiales
            })
        } else {
            this.materialesService.where({ material: { material: value } }).then((materiales: Material[]) => {
                this.materiales = materiales
            })
        }
    }

    changeLote(value: any) {
        if (!isNaN(value)) {
            this.lotesService.where({ lote: { id: value } }).then((lotes: Lote[]) => {
                this.lotes = lotes
            })
        } else {
            this.lotesService.where({ lote: { lote: value } }).then((lotes: Lote[]) => {
                this.lotes = lotes
            })
        }
    }

    clickMaterial(sOrden: number, orden: number, material_id: number, material: string) {
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value == sOrden) {
                ;(c.controls["plan_estudio_materiales"] as UntypedFormArray).controls.map(lControl => {
                    const l = lControl as UntypedFormGroup
                    if (l.controls["orden"].value == orden) {
                        l.controls["material_id"].setValue(material_id)
                        this.cosas[sOrden + "_" + orden].setValue(material)
                        this.materiales = []
                        this.listCheck[sOrden + "_" + orden] = false
                        this.lastValue[sOrden + "_" + orden] = material
                    }
                })
            }
        })
    }

    clickLote(sOrden: number, orden: number, lote_id: number, lote: string) {
        this.sesionesForm.controls.map(control => {
            const c = control as UntypedFormGroup
            if (c.controls["orden"].value == sOrden) {
                ;(c.controls["plan_estudio_lotes"] as UntypedFormArray).controls.map(lControl => {
                    const l = lControl as UntypedFormGroup
                    if (l.controls["orden"].value == orden) {
                        l.controls["lote_id"].setValue(lote_id)
                        this.cosas[sOrden + "_" + orden].setValue(lote)
                        this.lotes = []
                        this.listCheck[sOrden + "_" + orden] = false
                        this.lastValue[sOrden + "_" + orden] = lote
                    }
                })
            }
        })
    }

    save() {
        PlanEstudioEditForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            var copyPlan = this.planEstudio.clone()
            copyPlan.plan_estudio_sesiones.map((s: PlanEstudioSesion) => {
                s.plan_estudio_lotes = s.plan_estudio_lotes.concat(s.plan_estudio_lotes_deleted)
                s.plan_estudio_materiales = s.plan_estudio_materiales.concat(s.plan_estudio_materiales_deleted)
            })
            copyPlan.plan_estudio_sesiones = copyPlan.plan_estudio_sesiones.concat(
                copyPlan.plan_estudio_sesiones_deleted
            )
            this.planEstudiosService.update(this.planEstudio.id, copyPlan).then(response => {
                this.router.navigate(["plan_estudios/" + this.planEstudio.id])
            })
        } else {
            console.log(this.form)
        }
    }

    clear() {
        setTimeout(() => {
            this.planEstudio = this.planEstudio.clone()
            this.generateSesionesControls()
            PlanEstudioEditForm.markFormControlsAsPristine(this.form)
            PlanEstudioEditForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    type(obj: any) {
        return obj.constructor
    }

    prettyType(obj: any) {
        let type = this.type(obj)
        if (type === this.mType) {
            return "Material"
        } else if (type === this.lType) {
            return "Lote"
        }
        return type
    }
}
