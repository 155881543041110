import { BaseForm } from "@puntaje/shared/core"
import { Curriculums } from "./curriculums.service"

export class RosettaStoneForm extends BaseForm {
    public static get formParams(): any {
        return {
            tipo: {
                label: "Tipo",
                type: "select",
                visible: true,
                options: [
                    {
                        id: "pregunta",
                        toString: function () {
                            return "Preguntas"
                        }
                    },
                    {
                        id: "material",
                        toString: function () {
                            return "Materiales"
                        }
                    }
                ]
            },
            curriculum_id: {
                label: "Curriculum Origen",
                type: "select",
                visible: true,
                options: { class: Curriculums }
            },
            curriculum2_id: {
                label: "Curriculum Destino",
                type: "select",
                visible: true,
                options: { class: Curriculums }
            }
        }
    }
}
