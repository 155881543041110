<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>Ayuda</label>
            <input
                class="form-control"
                name="ayuda"
                type="text"
                [(ngModel)]="searchParams.ayuda.like.ayuda"
                (ngModelChange)="deleteIfUndefined(searchParams.ayuda.like, 'ayuda')"
            />
        </div>

        <div class="form-group">
            <table class="table-visibilidad">
                <tr>
                    <th></th>
                    <th>Todos</th>
                    <th>Visible</th>
                    <th>No Visible</th>
                </tr>
                <tr>
                    <th>Visibilidad</th>
                    <td>
                        <input
                            class=""
                            name="visible"
                            type="radio"
                            [value]="undefined"
                            [(ngModel)]="searchParams.ayuda.visible"
                            (ngModelChange)="deleteIfUndefined(searchParams.ayuda, 'visible')"
                        />
                    </td>
                    <td>
                        <input
                            class=""
                            name="visible"
                            type="radio"
                            [value]="1"
                            [(ngModel)]="searchParams.ayuda.visible"
                            (ngModelChange)="deleteIfUndefined(searchParams.ayuda, 'visible')"
                        />
                    </td>
                    <td>
                        <input
                            class=""
                            name="visible"
                            type="radio"
                            [value]="0"
                            [(ngModel)]="searchParams.ayuda.visible"
                            (ngModelChange)="deleteIfUndefined(searchParams.ayuda, 'visible')"
                        />
                    </td>
                </tr>
            </table>
        </div>

        <button class="btn btn-default" (click)="search()">Buscar</button>
    </div>
</form>
