import { DateView, LinkView, TextView, BooleanView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { ClasificacionTipoAliases } from "./clasificacion_tipo_aliases.service"
import { ClasificacionTipoAlias } from "./clasificacion_tipo_aliases.model"
import { ClasificacionTipos } from "./clasificacion_tipos.service"
import { ClasificacionTipo } from "./clasificacion_tipos.model"

export class ClasificacionTipoAliasView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            alias: {
                label: "Alias de Tipo de Clasificación",
                type: LinkView,
                tableVisible: true,
                options: { class: ClasificacionTipoAliases, key: "id" }
            },
            clasificacion_tipo: {
                label: "Tipo",
                type: LinkView,
                tableVisible: true,
                options: { class: ClasificacionTipos, key: "clasificacion_tipo_id" }
            },
            asignatura: {
                label: "Asignatura",
                type: TextView,
                tableVisible: true,
                options: {
                    showFn: (clasificacion_tipo_alias: ClasificacionTipoAlias) =>
                        clasificacion_tipo_alias &&
                        clasificacion_tipo_alias.asignatura_plataforma &&
                        clasificacion_tipo_alias.asignatura_plataforma.asignatura &&
                        clasificacion_tipo_alias.asignatura_plataforma.asignatura.asignatura
                }
            },
            plataforma: {
                label: "Plataforma",
                type: TextView,
                tableVisible: true,
                options: {
                    showFn: (clasificacion_tipo_alias: ClasificacionTipoAlias) =>
                        clasificacion_tipo_alias &&
                        clasificacion_tipo_alias.asignatura_plataforma &&
                        clasificacion_tipo_alias.asignatura_plataforma.plataforma &&
                        clasificacion_tipo_alias.asignatura_plataforma.plataforma.plataforma
                }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
