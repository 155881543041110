import { Component, Input, OnInit } from "@angular/core"
import { pluralize } from "@puntaje/shared/core"

/*#################################

Botones de acción en la columna derecha

#################################*/

@Component({
    templateUrl: "table_with_actions_buttons.component.html",
    selector: "table-with-actions-buttons",
    styleUrls: ["table_with_actions_buttons.component.css"]
})
export class TableWithActionsButtonsComponent implements OnInit {
    @Input() functionParameter: any
    @Input() tableButtonsArray: any
    @Input() enableSmallButtons: boolean
    @Input() resourceName: string

    defaultPermisosKeys = ["view", "edit", "delete"]
    keysToActions = {
        view: "show",
        edit: "update",
        delete: "destroy"
    }

    buttonsOptions = {}

    ngOnInit() {
        if (!this.resourceName) this.resourceName = pluralize(this.functionParameter.constructor.className)
        Object.keys(this.tableButtonsArray).forEach(key => {
            this.buttonsOptions[key] = { ...this.tableButtonsArray[key] }

            if (!this.tableButtonsArray[key].showIf) this.tableButtonsArray[key].showIf = e => true

            if (this.defaultPermisosKeys.includes(key) && !this.tableButtonsArray[key].permiso) {
                this.buttonsOptions[key].permiso = `${this.resourceName}#${this.keysToActions[key]}`
            }

            if (this.tableButtonsArray[key].permisoResolver) {
                this.buttonsOptions[key].permiso = this.tableButtonsArray[key].permisoResolver(this.functionParameter)
            }
        })
    }
}
