import { Component, OnDestroy, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Perfil, Perfiles } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { UsuariosBuscadorService } from "./usuarios_buscador.service"
import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"

@Component({
    selector: "usuarios-buscador",
    templateUrl: "usuarios_buscador.component.html",
    styleUrls: ["usuarios_buscador.component.scss"]
})
export class UsuariosBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false
    searchParams: any = { usuario: { like: {} }, persona: { like: {} }, perfil: {} }
    perfiles: Perfil[]
    status: any = [
        { value: "habilitado", label: "Habilitado" },
        { value: "deshabilitado", label: "Deshabilitado" },
        { value: "sin_fecha", label: "Sin Fecha" }
    ]

    constructor(protected usuariosBuscadorService: UsuariosBuscadorService, protected perfilesService: Perfiles) {}

    ngOnInit() {
        this.sub = this.usuariosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
        this.perfilesService.where().then((perfiles: Perfil[]) => {
            this.perfiles = perfiles
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    search() {
        this.usuariosBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
