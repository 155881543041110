import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Lote, LotePregunta, LotePreguntas, Lotes } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "lote_preguntas.edit.component.html",
    styleUrls: ["lote_preguntas.component.scss"],
    selector: "edit-lote-preguntas"
})
export class LotePreguntasEditComponent implements OnInit {
    @Input() lote: Lote
    @Input("lote_preguntas") lote_preguntas: LotePregunta[]
    @Output() loteUpdated = new EventEmitter()
    @Output() loteUpdating = new EventEmitter()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    currentEditOrderIndex: number = -1
    editOrderValue: number
    previousEditNumberValue: number
    enableOrderChange: boolean = true
    pendingOrderChange: boolean = false

    constructor(
        private lotePreguntasService: LotePreguntas,
        private router: Router,
        private simpleModalService: SimpleModalService,
        private lotesService: Lotes
    ) {}

    ngOnInit() {
        this.loadingLayout.ready()
    }

    delete(id: number) {
        this.loadingLayout.standby()
        this.lotePreguntasService.remove(id).then(response => {
            this.loteUpdated.emit()
        })
    }

    view(id: number) {
        window.open(window.location.origin + "/preguntas/" + id)
    }

    showModalDelete(id: number) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta pregunta del lote?")
        this.simpleModalService.setModalCallback(() => this.delete(id))
    }

    id(index, value) {
        return value.id
    }

    setEditOrden(index: number) {
        this.currentEditOrderIndex = index
        this.editOrderValue = this.lote_preguntas[index].orden
        this.previousEditNumberValue = this.lote_preguntas[index].orden
    }

    clearEditOrden() {
        this.currentEditOrderIndex = -1
    }

    clearOrderValue() {
        this.editOrderValue = this.previousEditNumberValue
    }

    confirmEditOrden(index: number) {
        if (this.previousEditNumberValue != this.editOrderValue) {
            this.enableOrderChange = false
            this.lote_preguntas[index].orden = this.editOrderValue
            // reordenar
            let elem = this.lote_preguntas[index]
            let beforeElem = this.lote_preguntas.filter(lp => lp.orden <= elem.orden && lp.id != elem.id)
            let afterElem = this.lote_preguntas.filter(lp => lp.orden > elem.orden && lp.id != elem.id)
            this.lote_preguntas = beforeElem.concat(elem).concat(afterElem)
            this.clearEditOrden()
            this.enableOrderChange = true
            this.pendingOrderChange = true
        }
    }

    increaseOrden(index: number) {
        this.enableOrderChange = false
        if (index <= this.lote_preguntas.length - 2) {
            let prevValue = this.lote_preguntas[index].orden
            this.lote_preguntas[index].orden = this.lote_preguntas[index + 1].orden
            this.lote_preguntas[index + 1].orden = prevValue
            let aux = this.lote_preguntas[index]
            this.lote_preguntas[index] = this.lote_preguntas[index + 1]
            this.lote_preguntas[index + 1] = aux
        } else {
            this.lote_preguntas[index].orden++
        }
        this.enableOrderChange = true
        this.pendingOrderChange = true
    }

    decreaseOrden(index: number) {
        this.enableOrderChange = false
        if (index > 0) {
            let prevValue = this.lote_preguntas[index].orden
            this.lote_preguntas[index].orden = this.lote_preguntas[index - 1].orden
            this.lote_preguntas[index - 1].orden = prevValue
            let aux = this.lote_preguntas[index]
            this.lote_preguntas[index] = this.lote_preguntas[index - 1]
            this.lote_preguntas[index - 1] = aux
        } else {
            this.lote_preguntas[index].orden--
        }
        this.enableOrderChange = true
        this.pendingOrderChange = true
    }

    normalizeOrden() {
        let index = 0
        this.lote_preguntas.forEach((lp: LotePregunta) => {
            lp.orden = index + 1
            index++
        })
    }

    saveAllLotePreguntas() {
        if (this.lote) {
            this.loadingLayout.standby()
            let cambios_lote = this.lote.clone()
            cambios_lote.lote_preguntas = this.lote_preguntas
            delete cambios_lote.asignatura_id
            delete cambios_lote.visible
            delete cambios_lote.lote
            delete cambios_lote.estado
            cambios_lote.lote_preguntas.forEach(lp => {
                delete lp.pregunta
            })
            this.lotesService.update(this.lote.id, cambios_lote).then(() => {
                this.loteUpdated.emit()
                this.pendingOrderChange = false
            })
        }
    }
}
