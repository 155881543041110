<div *ngIf="clasificaciones && clasificaciones.length > 0">
    <div>
        <h3>Seleccione filtro de clasificaciones</h3>
    </div>
    <div>
        <monitor-clasificacion
            *ngFor="let clasificacion of clasificaciones; let i = index"
            [clasificacion]="clasificacion"
            (hijaChecked)="hijaCheckedListener($event, i)"
        ></monitor-clasificacion>
    </div>
</div>
<div *ngIf="clasificaciones && clasificaciones.length <= 0">
    No existen clasificaciones asociadas para el instrumento seleccionado.
</div>
