import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { GrupoPregunta } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "grupo_preguntas.toolnavbar.component.html",
    selector: "grupo_pregunta-toolnavbar"
})
export class GrupoPreguntasToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() grupoPregunta: GrupoPregunta
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/grupo_preguntas"
        this.urlNew = "/grupo_preguntas/new"
        if (this.grupoPregunta != null) {
            this.urlEdit = "/grupo_preguntas/" + this.grupoPregunta.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.grupoPregunta != null) {
            this.urlEdit = "/grupo_preguntas/" + this.grupoPregunta.id + "/edit"
        }
        super.edit()
    }
}
