<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-tipo-toolnavbar [displayButtons]="'new search'"></clasificacion-tipo-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Tipos de Clasificación</logged-layout-titulo>
    <logged-layout-contenido>
        <clasificacion-tipos-buscador></clasificacion-tipos-buscador>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="clasificacionTipos"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
