<logged-layout>
    <logged-layout-toolnavbar>
        <ayudas-toolnavbar [ayuda]="ayuda" [displayButtons]="'all,new,edit'"></ayudas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Ayuda</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="ayuda"
                    [key]="key"
                ></model-simple-view>
                <!-- <h4 *ngIf="ayuda.clasificaciones">Clasificaciones</h4>
				<ng-template ngFor let-clasificacion [ngForOf]="ayuda.clasificaciones">
					<div class="clasificacion">{{ clasificacion.clasificacion }}</div>
				</ng-template> -->
                <!-- <p *ngIf="ayuda.clasificaciones.length == 0" class="empty-list">Ayuda sin clasificaciones.</p> -->
                <h4 *ngIf="ayuda.plataformas">Plataformas</h4>
                <ng-template ngFor let-plataforma [ngForOf]="ayuda.plataformas">
                    <div class="plataforma">{{ plataforma.plataforma }}</div>
                </ng-template>
                <p *ngIf="ayuda.plataformas.length == 0" class="empty-list">
                    Ayuda no ha sido asignado a plataforma(s).
                </p>
                <h4>Vista previa</h4>
                <ayuda-preview [ayuda]="ayuda"></ayuda-preview>
                <!-- <h4>Reportes</h4>
				<embed-reportes *ngIf="ayuda" [tabla]="'Ayuda'" [entidadId]="ayuda.id"></embed-reportes> -->
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
