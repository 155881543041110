import { Injectable } from "@angular/core"
import { BasePolicyService, Opts } from "./base-policy.service"

@Injectable({
    providedIn: "root"
})
export class UsuariosPolicyService extends BasePolicyService {
    resourceName: string = "Preguntas"

    indexAsociados() {
        return this.testPermiso("Usuarios_index_asociados")
    }

    indexGeneral(opts: Opts) {
        return this.index(opts) || this.indexAsociados()
    }

    show(opts: Opts) {
        return super.show(opts) || this.testPermiso("Usuarios_show_asociados")
    }
}
