import { ParserHandler } from "./ParserHandler"
import { Parser } from "./Parser"

import { java } from "j4ts"

import StringWriter = java.io.StringWriter

/**
 * Program main class.
 */
export class Main {
    /**
     * Input HTML file.
     */
    private static _inputFile: string = ""

    /**
     * Output LaTeX file.
     */
    private static _outputFile: string = ""

    /**
     * Configuration file.
     */
    private static _configFile: string = "config.xml"

    /**
     * File with CSS.
     */
    private static _cssFile: string = ""

    /**
     * Creates {@link Parser Parser} instance and runs its
     * {@link Parser#parse(File, IParserHandler) parse()} method.
     * @param args command line arguments
     */
    public static main(args: string[]) {
        try {
            Main.processCmdLineArgs(args)
            if (Main._inputFile === "") {
                console.error("Input or (and) output file not specified.")
                return
            }
            var _sw: StringWriter = new StringWriter()
            var parser: Parser = new Parser()
            parser.parse(Main._inputFile, new ParserHandler(_sw))
        } catch (e: any) {
            console.error(e.message)
            e.printStackTrace()
        }
    }

    public static html2latex(inputString: string): string {
        var _sw: StringWriter = new StringWriter()
        var parser: Parser = new Parser()
        parser.parse(inputString, new ParserHandler(_sw))
        return _sw.toString()
    }

    /**
     * Processes command line arguments.
     * <ul>
     * <li>-input &lt;fileName&gt;</li>
     * <li>-output &lt;fileName&gt;</li>
     * <li>-config &lt;fileName&gt;</li>
     * <li>-css &lt;fileName&gt;</li>
     * </ul>
     * @param args command line arguments
     */
    private static processCmdLineArgs(args: string[]) {
        for (var i: number = 0; i < args.length; ++i) {
            if (args[i] === "-input") {
                if (i < args.length - 1) {
                    Main._inputFile = args[i + 1]
                    ++i
                }
            }
            if (args[i] === "-output") {
                if (i < args.length - 1) {
                    Main._outputFile = args[i + 1]
                    ++i
                }
            }
            if (args[i] === "-config") {
                if (i < args.length - 1) {
                    Main._configFile = args[i + 1]
                    ++i
                }
            }
            if (args[i] === "-css") {
                if (i < args.length - 1) {
                    Main._cssFile = args[i + 1]
                    ++i
                }
            }
        }
    }

    /**
     * Returns name of the file with CSS.
     * @return name of the file with CSS
     */
    public static getCSSFile(): string {
        return Main._cssFile
    }

    /**
     * Returns name of the file with configuration.
     * @return name of the file with configuration
     */
    public static getConfigFile(): string {
        return Main._configFile
    }
}
