import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ClasificacionTipoAlias } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"
import { ClasificacionTipoAliasesBuscadorService } from "./clasificacion-tipo-aliases-buscador.service"

@Component({
    templateUrl: "clasificacion_tipo_aliases.toolnavbar.component.html",
    selector: "clasificacion-tipo-alias-toolnavbar"
})
export class ClasificacionTipoAliasesToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() clasificacionTipoAlias: ClasificacionTipoAlias
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected clasificacionTipoAliasesBuscadorService: ClasificacionTipoAliasesBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/clasificacion_tipo_aliases"
        this.urlNew = "/clasificacion_tipo_aliases/new"
        if (this.clasificacionTipoAlias != null) {
            this.urlEdit = "/clasificacion_tipo_aliases/" + this.clasificacionTipoAlias.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    edit() {
        if (this.clasificacionTipoAlias != null) {
            this.urlEdit = "/clasificacion_tipo_aliases/" + this.clasificacionTipoAlias.id + "/edit"
        }
        super.edit()
    }

    search() {
        this.clasificacionTipoAliasesBuscadorService.openSearch.next()
    }
}
