<loading-layout #loadingLayout>
    <h4>Buscar preguntas para agregar al lote</h4>
    <!--<div class="row"> 
		<div class="col-md-6">
			<input type="text" name="busqueda_pregunta" [(ngModel)]="preguntaQuery" placeholder="Id de la Pregunta" class="form-control search-input">
			<button type="button" class = "btn btn-default search-btn" (click)="searchPregunta()">
				<span class="glyphicon glyphicon-search" aria-hidden="true"></span> Buscar
			</button>
		</div>
	</div>
	<br>-->
    <preguntas-filter
        #preguntasFilter
        (preguntasChange)="onPreguntasChange($event)"
        (showPreguntasChange)="onShowPreguntasChange($event)"
    ></preguntas-filter>
    <div *ngIf="showPreguntas">
        <h4>Resultados de la Búsqueda</h4>
        <button class="btn btn-sm btn-default btn-add-all" (click)="agregarTodas()">
            <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
            &nbsp;Agregar las {{ paginator.total }} preguntas.
        </button>
        <p *ngIf="preguntas.length == 0" class="no-results">No se han encontrado preguntas acorde a la búsqueda.</p>
        <ul class="preguntas-list">
            <li *ngFor="let pregunta of preguntas; let i = index; trackBy: id">
                <div class="pregunta-row">
                    <div class="all-width pregunta-cell">
                        <a [routerLink]="['/preguntas', pregunta.id]" target="_blank">
                            Pregunta id: #{{ pregunta.id }}
                        </a>
                        <pregunta-small [pregunta]="pregunta"></pregunta-small>
                        <ng-container *ngFor="let c of pregunta.clasificaciones">
                            <button
                                class="clasificacion with-popover"
                                popover="{{ c.descripcion }}"
                                triggers="focus"
                                *ngIf="c.descripcion && c.descripcion.length > 0"
                            >
                                {{ c.clasificacion }}&nbsp;
                                <fa name="plus-square-o" class="plus-info"></fa>
                            </button>
                            <span class="clasificacion" *ngIf="!(c.descripcion && c.descripcion.length > 0)">
                                {{ c.clasificacion }}
                            </span>
                        </ng-container>
                    </div>
                    <div class="pregunta-cell small-padding">
                        <button
                            (click)="save(pregunta.id)"
                            class="btn btn-xs btn-default action-btn"
                            *ngIf="!checkIfAlreadyAdded(pregunta.id)"
                        >
                            <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
                            Agregar al lote
                        </button>
                        <p class="already-warning" *ngIf="checkIfAlreadyAdded(pregunta.id)">
                            Pregunta ya se encuentra en el lote
                        </p>
                    </div>
                </div>
            </li>
        </ul>

        <paginator #paginator [getList]="preguntasFilter.buscarPreguntas.bind(preguntasFilter)"></paginator>
    </div>
</loading-layout>
