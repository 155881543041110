import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Material } from "@puntaje/nebulosa/api-services"
import { ToolNavbar } from "@puntaje/shared/core"
import { MaterialesBuscadorService } from "./materiales_buscador.service"

@Component({
    templateUrl: "materiales.toolnavbar.component.html",
    selector: "materiales-toolnavbar"
})
export class MaterialesToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() material: Material
    @Input() displayButtons: string

    constructor(router: Router, route: ActivatedRoute, protected materialesBuscadorService: MaterialesBuscadorService) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/materiales"
        this.urlNew = "/materiales/new"
        if (this.material != null) {
            this.urlEdit = "/materiales/" + this.material.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    edit() {
        if (this.material != null) {
            this.urlEdit = "/materiales/" + this.material.id + "/edit"
        }
        super.edit()
    }

    search() {
        this.materialesBuscadorService.openSearch.next()
    }
}
