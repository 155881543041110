import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { FlashMessageModule, ModelViewModule, UtilModule } from "@puntaje/shared/core"
import { CustomTags } from "./customtags.directive"
import { LoaderComponent } from "./loader/loader.component"
import { LoadingLayoutComponent } from "./loading_layout/loading_layout.component"
import { LoggedLayoutComponent, LoggedLayoutComponentTags } from "./logged_layout/logged_layout.component"
import { MainLayoutComponent } from "./main_layout/main_layout.component"
import { NavbarComponent } from "./navbar/navbar.component"
import { SidemenuComponent } from "./sidemenu/sidemenu.component"
import { SimpleModalComponent } from "./simple_modal/simple_modal.component"
import { SimpleModalService } from "./simple_modal/simple_modal.service"
import { TableWithActionsComponent } from "./table_with_actions/table_with_actions.component"
import { TableWithActionsButtonsComponent } from "./table_with_actions/table_with_actions_buttons.component"
import { PoliciesModule } from "../modules/policies/policies.module"
import { ToolnavbarComponent } from "./toolnavbar/toolnavbar.component"

@NgModule({
    imports: [PoliciesModule, CommonModule, FormsModule, UtilModule, ModelViewModule, FlashMessageModule, RouterModule],
    declarations: [
        MainLayoutComponent,
        LoggedLayoutComponent,
        NavbarComponent,
        SidemenuComponent,
        LoadingLayoutComponent,
        LoaderComponent,
        LoggedLayoutComponentTags,
        SimpleModalComponent,
        TableWithActionsComponent,
        TableWithActionsButtonsComponent,
        CustomTags,
        ToolnavbarComponent
    ],
    exports: [
        MainLayoutComponent,
        LoggedLayoutComponent,
        NavbarComponent,
        SidemenuComponent,
        LoadingLayoutComponent,
        LoaderComponent,
        LoggedLayoutComponentTags,
        SimpleModalComponent,
        TableWithActionsComponent,
        TableWithActionsButtonsComponent,
        ToolnavbarComponent
    ]
})
export class LayoutsModule {
    static forRoot(): ModuleWithProviders<LayoutsModule> {
        return {
            ngModule: LayoutsModule,
            providers: [SimpleModalService]
        }
    }
}
