<!-- @format -->
<div>
    <div>
        <h3>Previsualización</h3>
        <ng-container *ngIf="editMode">
            Respuesta original
            <div class="preview">
                {{ oAlternativa.alternativa }}
            </div>
            Nuevo valor
        </ng-container>
        <div class="preview">
            {{ [first, second, third, fourth].join("") || "- Por completar -" }}
        </div>
        <br />
    </div>
    <div class="info">
        <h3>Edición Respuesta Construida</h3>
        Los caracteres permitidos para cada input son
        <li>#1: Dígitos del 0 al 9 y .</li>
        <li>#2: Dígitos del 0 al 9, . , y /</li>
        <li>#3: Dígitos del 0 al 9, . , y /</li>
        <li>#4: Dígitos del 0 al 9</li>
        <br />
    </div>
    <div class="form-inline">
        <div class="form-group" [ngClass]="{ 'has-error': isFirstInvalid }">
            <div class="text-center">
                #1
            </div>
            <div>
                <input
                    type="text"
                    class="form-control"
                    maxlength="1"
                    [(ngModel)]="first"
                    (change)="validateFirst()"
                    (keyup)="validateFirst()"
                />
            </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': isSecondInvalid }">
            <div class="text-center">
                #2
            </div>
            <div>
                <input
                    type="text"
                    class="form-control"
                    maxlength="1"
                    [(ngModel)]="second"
                    (change)="validateSecond()"
                    (keyup)="validateSecond()"
                />
            </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': isThirdInvalid }">
            <div class="text-center" class="text-center">
                #3
            </div>
            <div>
                <input
                    type="text"
                    class="form-control"
                    maxlength="1"
                    [(ngModel)]="third"
                    (change)="validateThird()"
                    (keyup)="validateThird()"
                />
            </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': isFourthInvalid }">
            <div class="text-center">
                #4
            </div>
            <div>
                <input
                    type="text"
                    class="form-control"
                    maxlength="1"
                    [(ngModel)]="fourth"
                    (change)="validateFourth()"
                    (keyup)="validateFourth()"
                />
            </div>
        </div>
    </div>
    <div *ngIf="withErrors" class="error-list">
        <div class="panel panel-danger">
            <div class="panel-heading">Errores</div>
            <div class="panel-body">
                <div *ngIf="isFirstInvalid">
                    #1 - Caracter inválido o vacío
                </div>
                <div *ngIf="isSecondInvalid">
                    #2 - Caracter inválido o vacío
                </div>
                <div *ngIf="isThirdInvalid">
                    #3 - Caracter inválido o vacío
                </div>
                <div *ngIf="isFourthInvalid">
                    #4 - Caracter inválido o vacío
                </div>
            </div>
        </div>
    </div>
</div>
