import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ReporteSubRazon, ReporteSubRazones, ReporteSubRazonForm } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "reporte_sub_razones.form.component.html"
})
export class ReporteSubRazonesEditComponent implements OnInit, OnDestroy {
    oReporteSubRazon: ReporteSubRazon = new ReporteSubRazon()
    reporteSubRazon: ReporteSubRazon = new ReporteSubRazon()
    params = ReporteSubRazonForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar razón de reporte"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private reporteSubRazonesService: ReporteSubRazones,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = ReporteSubRazonForm.getForm(this.reporteSubRazon)
        this.sub = this.route.params.subscribe(params => {
            this.reporteSubRazonesService.find(+params["id"]).then((response: ReporteSubRazon) => {
                this.reporteSubRazon = response
                this.oReporteSubRazon = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        ReporteSubRazonForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.reporteSubRazonesService.update(this.reporteSubRazon.id, this.reporteSubRazon).then(response => {
                this.router.navigate(["reporte_sub_razones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.reporteSubRazon = this.oReporteSubRazon
            ReporteSubRazonForm.markFormControlsAsPristine(this.form)
            ReporteSubRazonForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
