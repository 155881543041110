<form class="formulario" *ngIf="open">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>Clasificación</label>
                <input
                    class="form-control"
                    name="clasificacion"
                    type="text"
                    [(ngModel)]="searchParams.clasificacion.like.clasificacion"
                    (ngModelChange)="deleteIfUndefined(searchParams.clasificacion.like, 'clasificacion')"
                />
            </div>

            <div class="form-group">
                <label>Id</label>
                <input
                    class="form-control"
                    name="clasificacion"
                    type="text"
                    [(ngModel)]="searchParams.clasificacion.id"
                    (ngModelChange)="deleteIfUndefined(searchParams.clasificacion, 'id')"
                />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Asignatura</label>
                <select
                    class="form-control"
                    name="asignatura_id"
                    [(ngModel)]="searchParams.clasificacion.asignatura_id"
                    (ngModelChange)="deleteIfUndefined(searchParams.clasificacion, 'asignatura_id')"
                >
                    <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                    <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura.id">
                        {{ asignatura.asignatura }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label>Tipo de clasificación</label>
                <select
                    class="form-control"
                    name="clasificacion_tipo"
                    [(ngModel)]="searchParams.clasificacion.clasificacion_tipo_id"
                    (ngModelChange)="deleteIfUndefined(searchParams.clasificacion, 'clasificacion_tipo_id')"
                >
                    <option [ngValue]="undefined">-- Seleccione tipo de clasificación --</option>
                    <option *ngFor="let clasificacionTipo of clasificacionTipos" [ngValue]="clasificacionTipo.id">
                        {{ clasificacionTipo.clasificacion_tipo }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <button class="btn btn-default" (click)="search()">Buscar</button>
</form>
