import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { ReporteRazon, ReporteRazones, ReporteRazonView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "reporte_razones.component.html"
})
export class ReporteRazonesComponent implements OnInit {
    reporteRazones: ReporteRazon[]
    show_params = ReporteRazonView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private reporteRazonesService: ReporteRazones,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll = (page: number = 1, per: number = 10) => {
        return this.reporteRazonesService
            .where({ page: page, per: per })
            .then((response: ReporteRazon[], total: number) => {
                this.reporteRazones = response
                console.log(response)
                this.loadingLayout.ready()
                return total
            })
    }

    view(reporteRazon: ReporteRazon) {
        this.router.navigate(["reporte_razones/" + reporteRazon.id])
    }

    edit(reporteRazon: ReporteRazon) {
        this.router.navigate(["reporte_razones/" + reporteRazon.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["reporte_razones/" + id + "/delete"])
    }

    showModalDelete(reporteRazon: ReporteRazon) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta razón de reporte?")
        this.simpleModalService.setModalCallback(() => this.delete(reporteRazon.id))
    }
}
