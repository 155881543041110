import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PopoverModule } from "ngx-bootstrap"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { PreguntasModule } from "../preguntas/preguntas.module"
import { LoteComponent } from "./lote.component"
import { LotesComponent } from "./lotes.component"
import { LotesDeleteComponent } from "./lotes.delete.component"
import { LotesEditComponent } from "./lotes.edit.component"
import { LotesNewComponent } from "./lotes.new.component"
import { lotesRouting } from "./lotes.routing"
import { LotesToolNavbarComponent } from "./lotes.toolnavbar.component"
import { LotePreguntasAddComponent } from "./lote_preguntas.add.component"
import { LotePreguntasComponent } from "./lote_preguntas.component"
import { LotePreguntasEditComponent } from "./lote_preguntas.edit.component"
import { LotesUsuarioComponent } from "./lotes_usuario.component"
import { LotesListComponent } from "./lotes-list/lotes-list.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        LayoutsModule,
        lotesRouting,
        ModelViewModule,
        FormModule,
        ReactiveFormsModule,
        PreguntasModule,
        PaginatorModule,
        PopoverModule,
        Angular2FontawesomeModule,
        NebuModule
    ],
    declarations: [
        LotesComponent,
        LoteComponent,
        LotesNewComponent,
        LotesEditComponent,
        LotesDeleteComponent,
        LotePreguntasComponent,
        LotePreguntasEditComponent,
        LotePreguntasAddComponent,
        LotesToolNavbarComponent,
        LotesUsuarioComponent,
        LotesListComponent
    ],
    exports: [LotesComponent]
})
export class LotesModule {}
