import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { Preguntas } from "@puntaje/nebulosa/api-services"
import { PdfView } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "pregunta-preview-latex",
    templateUrl: "./pregunta-preview-latex.component.html",
    styleUrls: ["./pregunta-preview-latex.component.scss"]
})
export class PreguntaPreviewLatexComponent implements OnInit {
    _paramsPregunta: any
    get paramsPregunta(): any {
        return this._paramsPregunta
    }
    @Input() set paramsPregunta(value: any) {
        if (value) {
            this._paramsPregunta = value
            this.update()
        }
    }

    pdfParams = { label: "Previsualización de Latex", type: PdfView }
    showPreviewErrorMessage = false
    previewErrorMessage: string = "PDF no pudo ser generado."
    pdfLatexBinaryData: Blob

    @ViewChild("pdfLoadingLayout", { static: true }) pdfLoadingLayout: LoadingLayoutComponent

    constructor(protected preguntasService: Preguntas) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["paramsPregunta"] &&
            changes["paramsPregunta"].currentValue !== null &&
            changes["paramsPregunta"].currentValue !== undefined
        ) {
            this.update()
        }
    }

    update() {
        if (!this.paramsPregunta.pregunta) {
            return
        }

        this.pdfLatexBinaryData = undefined
        this.showPreviewErrorMessage = false
        this.pdfLoadingLayout.standby()

        this.preguntasService.previewLatexBlob(this.paramsPregunta).then(response => {
            if (response.error) {
                this.showPreviewErrorMessage = true
            } else {
                this.pdfLatexBinaryData = new Blob([response.blob], { type: "application/pdf" })
                this.showPreviewErrorMessage = false
            }
            this.pdfLoadingLayout.ready()
        })
    }
}
