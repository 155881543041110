import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Usuario, Usuarios, UsuarioView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "usuario.component.html"
})
export class UsuarioComponent implements OnInit, OnDestroy {
    usuario: Usuario
    private sub: Subscription
    show_params = UsuarioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private usuariosService: Usuarios, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.usuariosService.find(+params["id"], { include: "[usuarios:persona]" }).then((usuario: Usuario) => {
                this.usuario = usuario
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
