import { DateView, TextView } from "@puntaje/shared/core"

export class ReporteRazonView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            razon: { label: "Razón", type: TextView, tableVisible: true },
            tabla: { label: "Tabla", type: TextView, tableVisible: true },
            plataforma_id: { label: "Plataforma", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
