<h3>Clasificaciones de {{ recurso }}</h3>
<div class="clasificacion-criterios">
    <div *ngFor="let clasificacion of clasificaciones; let i = index" class="clasificaciones">
        <div class="options">
            <div>Grupo {{ i + 1 }}</div>
            <div (click)="confirmarRemover(clasificacion, i + 1)" style="cursor: pointer;">
                <fa name="times-circle"></fa>
            </div>
        </div>
        <div *ngFor="let ca of clasificacion.criterio_asignaciones">
            <div *ngIf="ca.criterio_type == 'ClasificacionTipo'">
                <div class="clasificacion">
                    {{ ca.criterio.clasificacion_tipo }}
                </div>
            </div>
            <div *ngIf="ca.criterio_type == 'Asignatura'">
                <div class="clasificacion">
                    {{ ca.criterio.asignatura }}
                </div>
            </div>
            <div *ngIf="ca.criterio_type == 'Clasificacion'">
                <div class="clasificacion">
                    {{ ca.criterio.clasificacion }}
                </div>
            </div>
        </div>
    </div>
</div>
