import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { PlanEstudio, PlanEstudioForm, PlanEstudios } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "plan_estudios.form.component.html"
})
export class PlanEstudiosNewComponent implements OnInit {
    planEstudio: PlanEstudio = new PlanEstudio()
    params = PlanEstudioForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar plan de estudio"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private planEstudiosService: PlanEstudios, private router: Router) {}

    ngOnInit() {
        this.form = PlanEstudioForm.getForm(this.planEstudio)
        this.loadingLayout.ready()
    }

    save() {
        PlanEstudioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.planEstudiosService.save(this.planEstudio).then(response => {
                this.router.navigate(["plan_estudios"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.planEstudio = new PlanEstudio()
            PlanEstudioForm.markFormControlsAsPristine(this.form)
            PlanEstudioForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
