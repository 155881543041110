import { Validators } from "@angular/forms"
import { BaseForm, Validations } from "@puntaje/shared/core"
import { Perfiles } from "./perfiles.service"
import { Personas } from "./personas.service"

export class UsuarioForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            persona: {
                label: "Persona",
                type: "autocomplete",
                visible: true,
                options: { class: Personas }
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            fecha_expiracion: { label: "Fecha de expiración", type: "datepicker", visible: true },
            perfiles: { label: "Perfiles", type: "checkbox", multiple: true, options: { class: Perfiles } },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
