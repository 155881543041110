import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { EmbedReportesComponent } from "./embed_reportes.component"
import { ReporteComponent } from "./reporte.component"
import { ReportesComponent } from "./reportes.component"
import { ReportesDeleteComponent } from "./reportes.delete.component"
import { ReportesEditComponent } from "./reportes.edit.component"
import { ReportesNewComponent } from "./reportes.new.component"
import { reportesRouting } from "./reportes.routing"
import { ReportesToolNavbarComponent } from "./reportes.toolnavbar.component"
import { ReportesAgrupadosComponent } from "./reportes_agrupados.component"
import { ReportesBuscadorComponent } from "./reportes_buscador.component"
import { ReportesBuscadorService } from "./reportes_buscador.service"
import { ReportesResueltosComponent } from "./reportes_resueltos.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        PaginatorModule,
        reportesRouting,
        Angular2FontawesomeModule,
        NebuModule
    ],
    declarations: [
        ReportesComponent,
        ReporteComponent,
        ReportesNewComponent,
        ReportesEditComponent,
        ReportesDeleteComponent,
        ReportesToolNavbarComponent,
        ReportesResueltosComponent,
        ReportesBuscadorComponent,
        ReportesAgrupadosComponent,
        EmbedReportesComponent
    ],
    exports: [EmbedReportesComponent]
})
export class ReportesModule {
    static forRoot(): ModuleWithProviders<ReportesModule> {
        return {
            ngModule: ReportesModule,
            providers: [ReportesBuscadorService]
        }
    }
}
