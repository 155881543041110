import { Component, OnInit } from "@angular/core"
import { GeneradorInstrumentoBaseForm } from "../generador-instrumento-base-form"
import { GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { GeneradorInstrumentoForm } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "app-generador-instrumentos-copy",
    templateUrl: "./generador-instrumentos-copy.component.html",
    styleUrls: ["../generador-instrumentos-edit/generador-instrumentos-edit.component.scss"]
})
export class GeneradorInstrumentosCopyComponent extends GeneradorInstrumentoBaseForm implements OnInit {
    display_buttons_options = "all new"
    generadorOriginal: GeneradorInstrumento
    logged_layout_title = "Copiar generador instrumento"

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.generadorInstrumentosService.disableIgnoreModel()
            this.generadorInstrumentosService
                .find(params["id"], {
                    include: "[asignatura_plataforma:[asignatura,plataforma]]"
                })
                .then(generador => {
                    this.generadorInstrumentosService.enableIgnoreModel()
                    this.generadorOriginal = Object.assign(new GeneradorInstrumento(), generador)

                    this.setUpForm(generador)
                })
        })
    }

    apiPromise(generador: GeneradorInstrumento) {
        let newGenerador = Object.assign(new GeneradorInstrumento(), this.generador, {
            id: null,
            created_at: null,
            updated_at: null
        })
        return this.generadorInstrumentosService.copy(generador.id, newGenerador)
    }

    back() {
        this.router.navigate(["generador_instrumentos", this.generador.id])
    }

    save() {
        GeneradorInstrumentoForm.markFormControlsAsTouched(this.form)

        if (this.form.valid && this.asignaturaPlataformaId) {
            if (this.generadorOriginal == this.generador) {
            }

            this.generador.asignatura_plataforma_id = this.asignaturaPlataformaId

            this.generadorInstrumentosService.disableIgnoreModel()
            this.apiPromise(this.generador).then(response => {
                this.generadorInstrumentosService.enableIgnoreModel()
                this.router.navigate(["generador_instrumentos", response.id])
            })
        }
    }
}
