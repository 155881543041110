import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"
import { GeneradorInstrumentosBuscadorService } from "../generador_instrumentos_buscador.service"
// import { ClasificacionesBuscadorService } from "./clasificaciones_buscador.service"

@Component({
    templateUrl: "generador-instrumentos-toolnavbar.component.html",
    selector: "generador-instrumentos-toolnavbar"
})
export class GeneradorInstrumentosToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() generador: GeneradorInstrumento
    @Input() displayButtons: string

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected generadorInstrumentosBuscadorService: GeneradorInstrumentosBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/generador_instrumentos"
        this.urlNew = "/generador_instrumentos/new"
        if (this.generador != null) {
            this.urlEdit = "/generador_instrumentos/" + this.generador.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    edit() {
        if (this.generador != null) {
            this.urlEdit = "/generador_instrumentos/" + this.generador.id + "/edit"
        }
        super.edit()
    }

    search() {
        this.generadorInstrumentosBuscadorService.openSearch.next()
    }
}
