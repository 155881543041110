import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"

@Component({
    selector: "monitor-clasificaciones",
    templateUrl: "monitor_clasificaciones.component.html",
    styleUrls: ["monitor_clasificaciones.component.scss"]
})
export class MonitorClasificacionesComponent implements OnInit, OnChanges {
    clasificacionesObj: any = []

    @Input() clasificaciones: any[]
    @Output() clasificacionesId: EventEmitter<any[]> = new EventEmitter<any[]>()

    constructor() {}

    ngOnInit() {
        this.clasificacionesObj = this.markAll(this.clasificaciones, true)
        this.clasificacionesId.emit(this.flattenArray(this.clasificacionesObj))
    }

    hijaCheckedListener(value: any, index: number) {
        let pos = this.clasificacionesObj.findIndex(c => c.id === value.id)
        if (pos >= 0) {
            this.clasificacionesObj[pos] = value
        }
        this.clasificacionesId.emit(this.flattenArray(this.clasificacionesObj))
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["clasificaciones"] &&
            !changes["clasificaciones"].isFirstChange() &&
            changes["clasificaciones"].currentValue !== null &&
            changes["clasificaciones"].currentValue !== undefined
        ) {
            this.ngOnInit()
        }
    }

    markAll(array: any[], value: boolean) {
        return array.map(e => {
            let obj = {}
            obj["id"] = e.id
            obj["value"] = value
            if (e.clasificaciones_hijas) {
                obj["hijas"] = this.markAll(e.clasificaciones_hijas, value)
            } else {
                obj["hijas"] = []
            }
            return obj
        })
    }

    flattenArray(array: any[]) {
        let acumulador = []
        array.forEach(e => {
            if (e.value == true) {
                if (e.hijas && e.hijas.length > 0) {
                    acumulador = acumulador.concat(this.flattenArray(e.hijas))
                } else {
                    acumulador = acumulador.concat([e.id])
                }
            }
        })
        return acumulador
    }
}
