import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    ClasificacionMaterial,
    Material,
    Materiales,
    MaterialForm,
    MaterialPlataforma,
    TipoMaterial
} from "@puntaje/nebulosa/api-services"
import { FlashMessageService, S3, S3Service } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "materiales.form.component.html",
    styleUrls: ["materiales.form.component.css"]
})
export class MaterialesEditComponent implements OnInit, OnDestroy {
    params = MaterialForm.formParams
    oMaterial: Material = new Material()
    material = new Material()
    s3_model = new S3()
    form: UntypedFormGroup
    logged_layout_title = "Editar material"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    policy: String
    s3signature: String
    file: File
    tipoMaterial = TipoMaterial
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    plataformas_seleccionadas: number[]
    enablePreview: boolean = true
    saving: boolean = false
    canEdit: boolean = false

    constructor(
        private materialService: Materiales,
        private router: Router,
        private route: ActivatedRoute,
        public s3Service: S3Service,
        private cdr: ChangeDetectorRef,
        private flashMessage: FlashMessageService
    ) {}

    ngOnInit() {
        this.form = MaterialForm.getForm(this.material)
        this.sub = this.route.params.subscribe(params => {
            this.materialService
                .find(+params["id"], { include: "[profesor,certificacion_recursos]" })
                .then((material: Material) => {
                    this.canEdit = material.certificacion_recursos ? material.certificacion_recursos.length == 0 : false
                    this.material = material
                    this.plataformas_seleccionadas = material.plataformas.map(x => x.id)
                    this.actualizarClasificaciones(material.clasificaciones)
                    this.oMaterial = material.clone()
                    this.loadingLayout.ready()
                    this.cdr.detectChanges()
                })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        MaterialForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.saving = true

            let promiseThumbnail: Promise<any> = Promise.resolve()
            let promiseUploadable: Promise<any> = Promise.resolve()

            if (this.material.thumbnail_file) {
                promiseThumbnail = new Promise((resolve, reject) => {
                    this.s3Service.where(this.material.getS3ParamsThumb()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.material.thumbnail = policy["key"]
                        this.s3Service.uploadToS3(
                            policy,
                            this.material.thumbnail_file,
                            this.materialService.tableName,
                            resolve
                        )
                    })
                })
            }

            if (Material.uploadableToS3.includes(+this.material.material_tipo_id)) {
                promiseUploadable = new Promise((resolve, reject) => {
                    this.s3Service
                        .where(this.material.getS3Params(this.material.material_tipo_id))
                        .then((policies: S3[]) => {
                            let policy = policies as any as S3
                            if (policy["Content-Type"] === undefined) {
                                resolve(null)
                            } else {
                                this.material["url"] = policy["key"]
                                this.s3Service.uploadToS3(
                                    policy,
                                    this.material.file,
                                    this.materialService.tableName,
                                    resolve
                                )
                            }
                        })
                })
            }

            Promise.all([promiseThumbnail, promiseUploadable]).then(() => {
                this.updateMaterial()
            })
        }
    }

    checkTipoMaterial() {
        let tipo = this.form.controls["material_tipo_id"].value
        if (tipo !== "" && tipo !== undefined) {
            return tipo
        }
        return ""
    }

    clear() {
        this.enablePreview = false
        setTimeout(() => {
            this.material = this.oMaterial.clone()
            MaterialForm.markFormControlsAsPristine(this.form)
            MaterialForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
            this.enablePreview = true
        }, 150)
    }

    updateMaterial = () => {
        if (this.material.profesor) this.material.profesor_id = this.material.profesor.id

        this.materialService.update(this.material.id, this.material).then(response => {
            this.saving = false
            this.flashMessage.status = "success"
            this.flashMessage.message = "El material #" + this.material.id + " ha sido editado exitosamente."
            this.router.navigate(["materiales/" + this.material.id])
            //this.persona = response.json();
        })
    }

    actualizarClasificaciones(event) {
        this.material.clasificacion_materiales = []
        if (event && Array.isArray(event) && event.length > 0) {
            for (let c of event) {
                let cp = new ClasificacionMaterial()
                cp.clasificacion_id = c.id
                this.material.clasificacion_materiales.push(cp)
            }
        }
    }

    actualizarPlataformas(event) {
        this.material.material_plataformas = []
        if (event && Array.isArray(event) && event.length > 0) {
            let to_destroy = this.oMaterial.material_plataformas.filter(mp => event.indexOf(mp.plataforma_id) < 0)
            let to_add = event.filter(x => !this.oMaterial.material_plataformas.find(v => v.plataforma_id === x))

            to_destroy.forEach(mp => {
                var new_mp = new MaterialPlataforma()
                new_mp["_destroy"] = true
                new_mp["id"] = mp.id
                this.material.material_plataformas.push(new_mp)
            })

            to_add.forEach(p => {
                var new_mp = new MaterialPlataforma()
                new_mp["plataforma_id"] = p
                this.material.material_plataformas.push(new_mp)
            })
        }
    }

    reloadPreview() {
        this.enablePreview = false
        setTimeout(() => {
            this.enablePreview = true
        }, 150)
    }

    updateTipoMaterial() {}

    isSaving() {
        return this.saving
    }
}
