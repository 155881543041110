import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Asignatura, AsignaturaForm, Asignaturas } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "asignaturas.form.component.html"
})
export class AsignaturasEditComponent implements OnInit, OnDestroy {
    oAsignatura: Asignatura = new Asignatura()
    asignatura: Asignatura = new Asignatura()
    params = AsignaturaForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar asignatura"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private asignaturasService: Asignaturas,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = AsignaturaForm.getForm(this.asignatura)
        this.sub = this.route.params.subscribe(params => {
            this.asignaturasService.find(+params["id"]).then((asignatura: Asignatura) => {
                this.asignatura = asignatura
                this.oAsignatura = asignatura.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        AsignaturaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.asignaturasService.update(this.asignatura.id, this.asignatura).then((asignatura: Asignatura) => {
                this.router.navigate(["asignaturas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.asignatura = this.oAsignatura.clone()
            AsignaturaForm.markFormControlsAsPristine(this.form)
            AsignaturaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
