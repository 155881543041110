import { BooleanView, DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { Lotes } from "./lotes.service"
import { Personas } from "./personas.service"

export class LoteView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            lote: { label: "Lote", type: LinkView, tableVisible: true, options: { class: Lotes, key: "id" } },
            nombre_asignatura: {
                label: "Asignatura",
                type: LinkView,
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id" }
            },
            nombre_propietario: {
                label: "Propietario",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "propietario_id" }
            },
            estado: { label: "Estado", type: TextView, tableVisible: false },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
