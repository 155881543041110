import { ElementRef, Component, ChangeDetectorRef, Input } from "@angular/core"

@Component({
    selector: "word-count",
    templateUrl: "./word-count.component.html",
    styleUrls: ["./word-count.component.scss"]
})
export class WordCountComponent {
    _textToCount: string
    wordCount: number
    @Input()
    set textToCount(textToCount: string) {
        this._textToCount = textToCount
        this.countWords()
    }

    get textToCount() {
        return this._textToCount
    }
    constructor(protected elementRef: ElementRef, protected cdr: ChangeDetectorRef) {}

    countWords() {
        if (!this._textToCount) {
            this.wordCount = undefined
            return
        }

        let cleanText
        cleanText = this._textToCount
        /*
            Reemplazo cada ecuación para que cuente como 1 palabra
        */
        cleanText = cleanText.replace(new RegExp('<span class="math-tex">(.*?)</span>', "g"), "ecuacion")
        cleanText = cleanText.replace(/(\$+)(?:(?!\1)[\s\S])*\1/g, "ecuacion")
        /*
            - saltos de línea cambian por espacios para contar palabras entre saltos
            - espacios en blanco &nbsp; cambian por espacios
            - sacar tags de html
            - espacios que quedaron juntos que sobren cambian por uno solo, como cuando hay múltiples saltos de linea
        */
        cleanText = cleanText
            .replace(new RegExp("\n", "g"), " ")
            .replace(new RegExp("&nbsp;", "g"), " ")
            .replace(/<[^>]*>/g, "")
            .replace(/\s+/g, " ")
        /*
            Si queda un espacio al final, se saca.
        */
        cleanText =
            cleanText && cleanText.lastIndexOf(" ") == cleanText.length - 1
                ? cleanText.substring(0, cleanText.length - 1)
                : cleanText

        this.wordCount = cleanText ? cleanText.split(" ").length : 0
    }
}
