<logged-layout>
    <logged-layout-toolnavbar>
        <plan-estudio-toolnavbar [displayButtons]="display_buttons_options"></plan-estudio-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Planes de Estudio, Sesiones" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-md-8 col-sm-12">
                    <div>{{ planEstudio.descripcion }}</div>
                    <div><h3>Sesiones</h3></div>
                    <form novalidate [formGroup]="form">
                        <div
                            *ngFor="let sesion of planEstudio['plan_estudio_sesiones']; let i = index"
                            id="plan_estudio_sesion_{{ i }}"
                        >
                            <div>
                                <h4>
                                    Sesión {{ sesion["sesion"] }}
                                    <button (click)="quitarSesion(sesion.orden)" type="button" class="btn btn-default">
                                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                    </button>
                                </h4>
                                <form-input
                                    [(form)]="$any(sesionesForm).controls[i]"
                                    [params]="sesionesParams['sesion']"
                                    [(value)]="sesion['sesion']"
                                    key="sesion"
                                ></form-input>
                                <form-input
                                    [(form)]="$any(sesionesForm).controls[i]"
                                    [params]="sesionesParams['descripcion']"
                                    [(value)]="sesion['descripcion']"
                                    key="descripcion"
                                ></form-input>
                                <div>
                                    <h5>Componentes</h5>
                                    <button
                                        (click)="agregarMaterial(sesion.orden)"
                                        type="button"
                                        class="btn btn-default"
                                    >
                                        Agregar Material
                                    </button>
                                    <button (click)="agregarLote(sesion.orden)" type="button" class="btn btn-default">
                                        Agregar Lote
                                    </button>
                                    <div
                                        *ngIf="
                                            sesion.plan_estudio_lotes.length == 0 &&
                                            sesion.plan_estudio_materiales.length == 0
                                        "
                                    >
                                        No se ha agregado ningún componente
                                    </div>
                                    <table
                                        *ngIf="
                                            sesion.plan_estudio_lotes.length > 0 ||
                                            sesion.plan_estudio_materiales.length > 0
                                        "
                                        class="table"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Orden</th>
                                                <th>Tipo</th>
                                                <th>Nombre</th>
                                                <th>Lote/Material</th>
                                                <th>Quitar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="
                                                    let cosita of ordenar(
                                                        sesion['plan_estudio_materiales'],
                                                        sesion['plan_estudio_lotes']
                                                    );
                                                    let j = index
                                                "
                                                id="plan_estudio_cosa_{{ i }}"
                                            >
                                                <td>{{ cosita.orden }}</td>
                                                <td>{{ prettyType(cosita) }}</td>
                                                <ng-container *ngIf="type(cosita) === mType">
                                                    <td>
                                                        <div class="form-group">
                                                            <form-input
                                                                [(form)]="
                                                                    $any(sesionesForm).controls[i].controls[
                                                                        'plan_estudio_materiales'
                                                                    ].controls[
                                                                        componentPosition(
                                                                            'plan_estudio_materiales',
                                                                            i,
                                                                            cosita.orden
                                                                        )
                                                                    ]
                                                                "
                                                                [params]="materialesParams['nombre']"
                                                                [(value)]="cosita['nombre']"
                                                                key="nombre"
                                                                [withLabel]="false"
                                                            ></form-input>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <form-input
                                                            [(form)]="
                                                                $any(sesionesForm).controls[i].controls[
                                                                    'plan_estudio_materiales'
                                                                ].controls[
                                                                    componentPosition(
                                                                        'plan_estudio_materiales',
                                                                        i,
                                                                        cosita.orden
                                                                    )
                                                                ]
                                                            "
                                                            [params]="materialesParams['material_id']"
                                                            [(value)]="cosita['material_id']"
                                                            key="material_id"
                                                        ></form-input>
                                                        <div class="form-group lista-resultados-box">
                                                            <input
                                                                name="material"
                                                                class="form-control"
                                                                type="text"
                                                                [formControl]="cosas[sesion.orden + '_' + cosita.orden]"
                                                            />
                                                            <ul
                                                                *ngIf="listCheck[sesion.orden + '_' + cosita.orden]"
                                                                class="lista-resultados-auto"
                                                            >
                                                                <li
                                                                    *ngFor="let m of materiales"
                                                                    (click)="
                                                                        clickMaterial(
                                                                            sesion.orden,
                                                                            cosita.orden,
                                                                            m.id,
                                                                            m.material
                                                                        )
                                                                    "
                                                                >
                                                                    {{ m.id + " - " + m.material }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button
                                                            (click)="quitarMaterial(sesion.orden, cosita.orden)"
                                                            type="button"
                                                            class="btn btn-default"
                                                        >
                                                            <span
                                                                class="glyphicon glyphicon-remove"
                                                                aria-hidden="true"
                                                            ></span>
                                                        </button>
                                                    </td>
                                                </ng-container>
                                                <ng-container *ngIf="type(cosita) === lType">
                                                    <td>
                                                        <div class="form-group">
                                                            <form-input
                                                                [(form)]="
                                                                    $any(sesionesForm).controls[i].controls[
                                                                        'plan_estudio_lotes'
                                                                    ].controls[
                                                                        componentPosition(
                                                                            'plan_estudio_lotes',
                                                                            i,
                                                                            cosita.orden
                                                                        )
                                                                    ]
                                                                "
                                                                [params]="lotesParams['nombre']"
                                                                [(value)]="cosita['nombre']"
                                                                key="nombre"
                                                                [withLabel]="false"
                                                            ></form-input>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <form-input
                                                            [(form)]="
                                                                $any(sesionesForm).controls[i].controls[
                                                                    'plan_estudio_lotes'
                                                                ].controls[
                                                                    componentPosition(
                                                                        'plan_estudio_lotes',
                                                                        i,
                                                                        cosita.orden
                                                                    )
                                                                ]
                                                            "
                                                            [params]="lotesParams['lote_id']"
                                                            [(value)]="cosita['lote_id']"
                                                            key="lote_id"
                                                        ></form-input>
                                                        <div class="form-group lista-resultados-box">
                                                            <input
                                                                name="lote"
                                                                class="form-control"
                                                                type="text"
                                                                [formControl]="cosas[sesion.orden + '_' + cosita.orden]"
                                                            />
                                                            <ul
                                                                *ngIf="listCheck[sesion.orden + '_' + cosita.orden]"
                                                                class="lista-resultados-auto"
                                                            >
                                                                <li
                                                                    *ngFor="let l of lotes"
                                                                    (click)="
                                                                        clickLote(
                                                                            sesion.orden,
                                                                            cosita.orden,
                                                                            l.id,
                                                                            l.lote
                                                                        )
                                                                    "
                                                                >
                                                                    {{ l.id + " - " + l.lote }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button
                                                            (click)="quitarLote(sesion.orden, cosita.orden)"
                                                            type="button"
                                                            class="btn btn-default"
                                                        >
                                                            <span
                                                                class="glyphicon glyphicon-remove"
                                                                aria-hidden="true"
                                                            ></span>
                                                        </button>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <button (click)="agregarSesion()" type="button" class="btn btn-default">
                            {{ "Agregar Sesión" }}
                        </button>
                        <button (click)="save()" type="button" class="btn btn-default">
                            {{ save_button_text | easyplaceholder: "Guardar" }}
                        </button>
                        <button (click)="clear()" type="button" class="btn btn-default">
                            {{ clear_button_text | easyplaceholder: "Borrar" }}
                        </button>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
