import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { AsignaturaPlataformas } from "./asignatura_plataformas.service"
import { ClasificacionTipoAliases } from "./clasificacion_tipo_aliases.service"
import { ClasificacionTipos } from "./clasificacion_tipos.service"

export class ClasificacionTipoAliasForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            alias: {
                label: "Alias de Tipo de Clasificación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(5)]
            },
            asignatura_plataforma_id: {
                label: "Asignatura Plataforma",
                type: "select",
                options: { class: AsignaturaPlataformas }
            },
            clasificacion_tipo_id: {
                label: "Tipo de Clasificación",
                type: "select",
                options: { class: ClasificacionTipos }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
