import { Component, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "nebulosa",
    template: "<main-layout><router-outlet></router-outlet></main-layout>"
})

// @Injectable
export class AppComponent implements OnInit {
    enable_loading: boolean

    constructor(private auth: AuthService) {}

    ngOnInit() {}
}
