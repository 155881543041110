import { Component, OnInit, ViewChild } from "@angular/core"
import { Lotes, Lote } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "app-lotes-list",
    templateUrl: "./lotes-list.component.html",
    styleUrls: ["./lotes-list.component.scss"]
})
export class LotesListComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    lotes: Lote[]

    constructor(private lotesService: Lotes) {}

    ngOnInit() {}

    showAll(page: number, per: number) {
        return this.lotesService.where({ page: page, per: per }).then((lotes: Lote[], total: number) => {
            this.lotes = lotes
            this.loadingLayout.ready()
            return total
        })
    }
}
