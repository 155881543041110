import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Usuario, UsuarioForm, Usuarios } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "usuarios.form.component.html"
})
export class UsuariosEditComponent implements OnInit, OnDestroy {
    oUsuario: Usuario = new Usuario()
    usuario: Usuario = new Usuario()
    params = UsuarioForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar usuario"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private usuariosService: Usuarios,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.params.password.validations = [Validators.minLength(8)]
        this.params.password_confirmation.validations = [Validators.minLength(8)]

        this.form = UsuarioForm.getForm(this.usuario, this.params)
        this.sub = this.route.params.subscribe(params => {
            this.usuariosService.find(+params["id"], { include: "persona" }).then((usuario: Usuario) => {
                this.usuario = usuario
                this.oUsuario = usuario.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        UsuarioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.usuariosService.update(this.usuario.id, this.usuario).then(response => {
                this.router.navigate(["usuarios"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.usuario = this.oUsuario.clone()
            UsuarioForm.markFormControlsAsPristine(this.form)
            UsuarioForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
