import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import { Plataformas, TipoInstrumentos, Plataforma, TipoInstrumento } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { TipoInstrumentoPlataformasBuscadorService } from "./tipo_instrumento_plataformas_buscador.service"
import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"

@Component({
    selector: "tipo-instrumento-plataformas-buscador",
    templateUrl: "tipo_instrumento_plataformas_buscador.component.html",
    styleUrls: ["tipo_instrumento_plataformas_buscador.component.scss"]
})
export class TipoInstrumentoPlataformasBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open = false

    plataformas: Plataforma[]
    tipoInstrumentos: TipoInstrumento[]

    buscadorClasificaciones: UntypedFormControl = new UntypedFormControl()
    buscadorProfesor: UntypedFormControl = new UntypedFormControl()

    searchParams: any = { tipo_instrumento_plataforma: { plataforma_id: null, tipo_instrumento_id: null } }

    constructor(
        protected tipoInstrumentoPlataformasBuscadorService: TipoInstrumentoPlataformasBuscadorService,
        protected plataformasService: Plataformas,
        protected tipoInstrumentosService: TipoInstrumentos
    ) {}

    ngOnInit() {
        this.sub = this.tipoInstrumentoPlataformasBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.plataformasService.where().then(plataformas => {
            this.plataformas = plataformas
        })

        this.tipoInstrumentosService.where().then(tipoInstrumentos => {
            this.tipoInstrumentos = tipoInstrumentos
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    search() {
        // console.log(this.searchParams)
        this.tipoInstrumentoPlataformasBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
