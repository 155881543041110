/**
 * Fatal error - leads to program exit.
 */
export class FatalErrorException extends Error {
    /**
     * Cstr.
     * @param str error description
     */
    public constructor(str: string) {
        super("Fatal error: " + str)
        this.message = "Fatal error: " + str
        Object.defineProperty(this, "__interfaces", { configurable: true, value: ["java.io.Serializable"] })
    }
}

/**
 * Error - not so heavy as {@link FatalErrorException fatal error}.
 */
export class ErrorException extends Error {
    /**
     * Cstr.
     * @param str error description
     */
    public constructor(str: string) {
        super("Error: " + str)
        this.message = "Error: " + str
        Object.defineProperty(this, "__interfaces", { configurable: true, value: ["java.io.Serializable"] })
    }
}

/**
 * Configuration item (element, entity, CSS property) wasn't found
 * in the cofiguration.
 */
export class NoItemException extends Error {
    /**
     * Cstr.
     * @param item item name
     */
    constructor(item: string) {
        super("Can't find specified config item " + item)
        this.message = "Can't find specified config item " + item
        Object.defineProperty(this, "__interfaces", { configurable: true, value: ["java.io.Serializable"] })
    }
}
