<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-toolnavbar [displayButtons]="'new search'"></clasificacion-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Clasificaciones</logged-layout-titulo>
    <logged-layout-contenido>
        <clasificaciones-buscador></clasificaciones-buscador>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="clasificaciones"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
