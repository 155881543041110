import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Pregunta, Preguntas, PreguntaView } from "@puntaje/nebulosa/api-services"
import { GrupoPreguntaTextoService } from "@puntaje/shared/core"

@Component({
    templateUrl: "pregunta.small.component.html",
    styleUrls: ["pregunta.small.component.scss"],
    selector: "pregunta-small"
})
export class PreguntaSmallComponent implements OnInit, AfterViewInit {
    show_params = PreguntaView.viewParams
    @Input("pregunta") pregunta: Pregunta
    @Input("pregunta_id") pregunta_id: number
    @Input("preguntaXS") preguntaXS: boolean
    @Input("preguntaXL") preguntaXL: boolean
    @Input("viewSolution") viewSolution: boolean = false
    @Input("disableGoToPregunta") disableGoToPregunta: boolean
    @Input("disableExpand") disableExpand: boolean
    distractores: boolean[] = []
    enunciadoExpanded: boolean = false

    constructor(
        private preguntasService: Preguntas,
        private router: Router,
        private route: ActivatedRoute,
        public grupoPreguntaTextoService: GrupoPreguntaTextoService,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        if (this.pregunta_id != undefined) {
            this.preguntasService.find(this.pregunta_id).then((pregunta: Pregunta) => {
                this.pregunta = pregunta
                this.pregunta.contestables.map(contestable => {
                    contestable.alternativas.map(a =>
                        this.distractores.push(false)
                            ? a.explicacion_distractor != undefined && a.explicacion_distractor != ""
                            : null
                    )
                }) /* .alternativas.forEach(alternativa => {
                    if (alternativa.explicacion_distractor != undefined && alternativa.explicacion_distractor != "") {
                        this.distractores.push(false)
                    }
                }) */
            })
        } else if (this.pregunta != undefined) {
            if (this.pregunta.contestables[0] != undefined) {
                this.pregunta.contestables[0].alternativas.forEach(alternativa => {
                    if (alternativa.explicacion_distractor != undefined && alternativa.explicacion_distractor != "") {
                        this.distractores.push(false)
                    }
                })
            } else {
                this.pregunta.contestables.map(contestable => {
                    contestable.alternativas.map(a =>
                        this.distractores.push(false)
                            ? a.explicacion_distractor != undefined && a.explicacion_distractor != ""
                            : null
                    )
                })
            }
        }
    }

    ngAfterViewInit() {
        var s = document.createElement("script")
        s.type = "text/javascript"
        s.src = "assets/js/tooltipScript.js"
        this.elementRef.nativeElement.appendChild(s)
    }

    goToDetails() {
        window.open(window.location.origin + "/preguntas/" + this.pregunta.id)
        // this.router.navigate(['preguntas/'+this.pregunta.id]);
    }

    toggleDistractor(index: any) {
        this.distractores[index] = !this.distractores[index]
    }

    toggleEnunciado() {
        this.enunciadoExpanded = !this.enunciadoExpanded
    }
}
