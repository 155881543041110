import { BaseForm } from "@puntaje/shared/core"
import { PlanEstudioSesionForm } from "./plan_estudio_sesiones.form"

export class PlanEstudioEditForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            plan_estudio: { label: "Nombre", type: "hidden", visible: false },
            descripcion: { label: "Descripción", type: "hidden", visible: false },
            asignatura_id: { label: "Asignatura", type: "hidden", visible: false },
            estado: { label: "Estado", type: "hidden", visible: false },
            plan_estudio_sesiones: { type: "models", class: PlanEstudioSesionForm }
        }
    }
}
