/** @format */

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core"
import { Alternativa } from "@puntaje/nebulosa/api-services"
import { Main } from "@puntaje/shared/core"

@Component({
    selector: "app-respuesta-construida",
    templateUrl: "./respuesta-construida.component.html",
    styleUrls: ["./respuesta-construida.component.scss"]
})
export class RespuestaConstruidaComponent implements OnInit, OnChanges {
    first = ""
    second = ""
    third = ""
    fourth = ""

    isFirstInvalid: boolean = true
    isSecondInvalid: boolean = true
    isThirdInvalid: boolean = true
    isFourthInvalid: boolean = true

    withErrors: boolean = false

    oAlternativa: Alternativa

    @Input()
    editMode: boolean = false

    @Input()
    alternativa: Alternativa

    @Output() alternativaChange: EventEmitter<Alternativa> = new EventEmitter<Alternativa>()

    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.editMode) {
            this.oAlternativa = this.alternativa.clone()
            this.cdr.detectChanges()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["alternativa"] && !changes["alternativa"].isFirstChange()) {
            this.generarAlternativa()
        }
    }

    validateFirst() {
        const regex = /^(\d|\.){1}$/
        this.isFirstInvalid = !regex.test(this.first)
        this.withErrors = this.isFirstInvalid
        this.generarAlternativa()
        this.alternativaChange.emit(this.alternativa)
        this.cdr.detectChanges()
    }

    validateSecond() {
        const regex = /^(\d|\.|\/){1}$/
        this.isSecondInvalid = !regex.test(this.second)
        this.withErrors = this.isSecondInvalid
        this.generarAlternativa()
        this.alternativaChange.emit(this.alternativa)
        this.cdr.detectChanges()
    }

    validateThird() {
        const regex = /^(\d|\.|\/){1}$/
        this.isThirdInvalid = !regex.test(this.third)
        this.withErrors = this.isThirdInvalid
        this.generarAlternativa()
        this.alternativaChange.emit(this.alternativa)
        this.cdr.detectChanges()
    }

    validateFourth() {
        const regex = /^(\d){1}$/
        this.isFourthInvalid = !regex.test(this.fourth)
        this.withErrors = this.isFourthInvalid
        this.generarAlternativa()
        this.alternativaChange.emit(this.alternativa)
        this.cdr.detectChanges()
    }

    validateAll() {}

    generarAlternativa() {
        this.alternativa.letra = "A"
        this.alternativa.alternativa = [this.first, this.second, this.third, this.fourth].join("")
        this.alternativa.correcta = true
        this.alternativa.ocultable = false
        this.alternativa.alternativa_latex = this.toLatex(this.alternativa.alternativa)
    }

    toLatex(str: string) {
        return Main.html2latex(str)
    }
}
