<logged-layout>
    <logged-layout-toolnavbar>
        <grupo_pregunta-toolnavbar [displayButtons]="display_buttons_options"></grupo_pregunta-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Formulario Grupo de Preguntas" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div class="row" *ngIf="!loadingLayout.loading">
                <div class="col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            [form]="form"
                            [params]="params['texto']"
                            [(value)]="grupoPregunta['texto']"
                            [key]="'texto'"
                        ></form-input>
                        <div class="clearfix">
                            <button
                                type="button"
                                class="btn btn-default pull-right"
                                (click)="toLatex(grupoPregunta, 'texto', 'texto_latex')"
                                value=""
                            >
                                <span class="glyphicon glyphicon-retweet" aria-hidden="true"></span>
                                Texto a LaTeX
                            </button>
                            <word-count [textToCount]="grupoPregunta['texto']"></word-count>
                        </div>
                        <form-input
                            [form]="form"
                            [params]="params['texto_latex']"
                            [(value)]="grupoPregunta['texto_latex']"
                            [key]="'texto_latex'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['visible']"
                            [(value)]="grupoPregunta['visible']"
                            [key]="'visible'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['imagen']"
                            [(value)]="grupoPregunta['imagen']"
                            [key]="'imagen'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['profesor_id']"
                            [(value)]="grupoPregunta['profesor_id']"
                            [key]="'profesor_id'"
                        ></form-input>
                        <br />
                        <h4>Vista previa</h4>
                        <grupo-pregunta-preview
                            [grupoPregunta]="grupoPregunta"
                            [disableLinkOnCorner]="true"
                        ></grupo-pregunta-preview>
                        <br />
                        <grupo-pregunta-preview-latex
                            [paramsGrupoPregunta]="paramsPreviewLatex"
                            #previewLatex
                        ></grupo-pregunta-preview-latex>
                        <br />
                        <div class="clearfix">
                            <button (click)="save()" type="button" class="btn btn-default" [disabled]="pressedButton">
                                Guardar
                            </button>
                            <button (click)="clear()" type="button" class="btn btn-default">Borrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
