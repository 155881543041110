import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { PlanEstudios } from "./plan_estudios.service"

export class PlanEstudioMaterialForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            nombre: { label: "Nombre", type: "text", visible: true },
            descripcion: { label: "Descripción", type: "textarea", visible: true },
            material_id: { label: "Material", type: "hidden", visible: false },
            //material_id: {label: "Material", type: "select", visible: true, options: {class: Materiales}, validations: [Validators.required]},
            plan_estudio_id: {
                label: "Plan de Estudio",
                type: "select",
                visible: true,
                options: { class: PlanEstudios },
                validations: [Validators.required]
            },
            //plan_estudio_sesion_id: {label: "Sesión", type: "select", visible: true, options: {class: PlanEstudioSesiones}, validations: [Validators.required]},
            orden: { label: "orden", type: "number", visible: true }
        }
    }
}
