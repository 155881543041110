import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Pregunta } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "preguntas.toolnavbar.component.html",
    selector: "preguntas-toolnavbar"
})
export class PreguntasToolNavbarComponent implements OnInit {
    @Input() pregunta: Pregunta
    @Input() displayButtons: string

    displayBtnMine: boolean

    urlMisPreguntas: string
    displayNavbar: boolean

    constructor(protected router: Router) {}

    ngOnInit() {
        this.urlMisPreguntas = "/mis_preguntas"
        if (this.displayButtons != null) {
            this.displayBtnMine = this.displayButtons.indexOf("mine") !== -1
        }
        this.displayNavbar = this.displayBtnMine
    }
}
