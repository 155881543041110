<ng-container *ngIf="authService.isLoggedIn()">
    <logged-layout>
        <logged-layout-titulo>Ingreso</logged-layout-titulo>
        <logged-layout-contenido>
            <p>
                Has iniciado sesión como:&nbsp;{{ authService.getUserData().persona.nombre }}&nbsp;{{
                    authService.getUserData().persona.apellido_paterno
                }}
            </p>
            <br />
            <p class="logged-btns">
                <a routerLink="/home" class="btn btn-default">
                    <fa name="home"></fa>
                    &nbsp;Inicio
                </a>
                <button (click)="logout()" class="btn btn-default">
                    <fa name="sign-out"></fa>
                    &nbsp;Cerrar sesión
                </button>
            </p>
        </logged-layout-contenido>
    </logged-layout>
</ng-container>

<ng-container *ngIf="!authService.isLoggedIn()">
    <div class="row">
        <div class="col-md-10 col-md-offset-1">
            <div class="row">
                <div class="col-md-4 col-md-offset-4 login-box">
                    <form novalidate [formGroup]="form" (ngSubmit)="login()">
                        <h3>Ingresar</h3>
                        <input
                            type="text"
                            name="email"
                            [(ngModel)]="email"
                            placeholder="Email"
                            *ngIf="!authService.isLoggedIn()"
                            class="form-control"
                            [formControl]="$any(form).controls['email']"
                        />
                        <input
                            type="password"
                            name="password"
                            [(ngModel)]="password"
                            placeholder="Contraseña"
                            *ngIf="!authService.isLoggedIn()"
                            class="form-control"
                            [formControl]="$any(form).controls['password']"
                        />
                        <div class="error-message" *ngIf="checkEmail()">
                            <div class="align-table">
                                <div class="left-icon">
                                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                </div>
                                <div class="right-message">
                                    Debes incluir un email válido.
                                </div>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="checkPassword()">
                            <div class="align-table">
                                <div class="left-icon">
                                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                </div>
                                <div class="right-message">
                                    Debes incluir una contraseña.
                                </div>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="invalidLogin">
                            <div class="align-table">
                                <div class="left-icon">
                                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                </div>
                                <div class="right-message">
                                    Combinación de email y contraseña no válidos.
                                </div>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="usuarioDeshabilitado">
                            <div class="align-table">
                                <div class="left-icon">
                                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                </div>
                                <div class="right-message">
                                    El usuario está Deshabilitado para ingresar.
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-block btn-primary" *ngIf="!authService.isLoggedIn()">
                            Ingresar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-container>
