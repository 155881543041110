import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { ClasificacionesModule } from "../clasificaciones/clasificaciones.module"
import { PlataformasModule } from "../plataformas/plataformas.module"
import { ReportesModule } from "../reportes/reportes.module"
import { MaterialComponent } from "./material.component"
import { MaterialPreviewComponent } from "./material.preview.component"
import { MaterialesComponent } from "./materiales.component"
import { MaterialesDeleteComponent } from "./materiales.delete.component"
import { MaterialesEditComponent } from "./materiales.edit.component"
import { MaterialesNewComponent } from "./materiales.new.component"
import { materialesRouting } from "./materiales.routing"
import { MaterialesToolNavbarComponent } from "./materiales.toolnavbar.component"
import { MaterialesBuscadorComponent } from "./materiales_buscador.component"
import { MaterialesBuscadorService } from "./materiales_buscador.service"
import { MaterialesUsuarioComponent } from "./materiales_usuario.component"
import { CertificacionRecursosModule } from "../certificacion_recursos/certificacion_recursos.module"
import { RevisionesRecursoModule } from "../revisiones-recurso/revisiones-recurso.module"
import { MaterialesListComponent } from "./materiales-list/materiales-list.component"
import { EditarMaterialesMasivamenteComponent } from "./editar-materiales-masivamente/editar-materiales-masivamente.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        materialesRouting,
        ModelViewModule,
        PaginatorModule,
        ClasificacionesModule,
        PlataformasModule,
        Angular2FontawesomeModule,
        ReportesModule,
        NebuModule,
        CertificacionRecursosModule,
        RevisionesRecursoModule
    ],
    declarations: [
        MaterialesComponent,
        MaterialComponent,
        MaterialesNewComponent,
        MaterialesEditComponent,
        MaterialesDeleteComponent,
        MaterialesToolNavbarComponent,
        MaterialPreviewComponent,
        MaterialesUsuarioComponent,
        MaterialesBuscadorComponent,
        MaterialesListComponent,
        EditarMaterialesMasivamenteComponent
    ],
    exports: [MaterialesComponent]
})
export class MaterialesModule {
    static forRoot(): ModuleWithProviders<MaterialesModule> {
        return {
            ngModule: MaterialesModule,
            providers: [MaterialesBuscadorService]
        }
    }
}
