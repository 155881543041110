<logged-layout>
    <logged-layout-toolnavbar>
        <plan-estudio-toolnavbar
            *ngIf="planEstudio"
            [planEstudio]="planEstudio"
            [displayButtons]="'all sesiones new edit'"
        ></plan-estudio-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Plan de Estudio</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="planEstudio"
                    [key]="key"
                ></model-simple-view>
                <h3>Sesiones</h3>
                <ul class="list-group">
                    <li
                        *ngFor="let sesion of planEstudio['plan_estudio_sesiones']; let i = index"
                        class="list-group-item"
                    >
                        <h4 class="list-group-item-heading">{{ sesion.sesion }}</h4>
                        <p class="list-group-item-text">{{ sesion.descripcion }}</p>
                        <table
                            *ngIf="sesion.plan_estudio_lotes.length > 0 || sesion.plan_estudio_materiales.length > 0"
                            class="table"
                        >
                            <thead>
                                <tr>
                                    <th>Orden</th>
                                    <th>Tipo</th>
                                    <th>Nombre</th>
                                    <th>Lote/Material</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let cosita of ordenar(
                                            sesion['plan_estudio_materiales'],
                                            sesion['plan_estudio_lotes']
                                        );
                                        let j = index
                                    "
                                    id="plan_estudio_cosa_{{ i }}"
                                >
                                    <td>{{ cosita.orden }}</td>
                                    <td>{{ prettyType(cosita) }}</td>
                                    <td>{{ cosita.nombre }}</td>
                                    <ng-container *ngIf="type(cosita) == mType">
                                        <td>{{ cosita.material.material }}</td>
                                    </ng-container>
                                    <ng-container *ngIf="type(cosita) == lType">
                                        <td>{{ $any(cosita).lote.lote }}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>
                <br />
                <h4>Certificación</h4>
                <certificacion-recurso
                    [recursoId]="planEstudio.id"
                    [tipoRecurso]="'PlanEstudio'"
                ></certificacion-recurso>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
