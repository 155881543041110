<logged-layout>
    <logged-layout-toolnavbar>
        <grupo_pregunta-toolnavbar
            *ngIf="grupo_pregunta"
            [grupoPregunta]="grupo_pregunta"
            [displayButtons]="'all new edit'"
        ></grupo_pregunta-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        Grupo Pregunta
        <button
            *ngIf="showMarcarComoRevisada"
            [disabled]="loadingLayout.loading"
            class="btn btn-default pull-right"
            (click)="marcarComoRevisada()"
        >
            Marcar como revisada
        </button>
        <button
            *ngIf="showDesmarcarComoRevisada"
            [disabled]="loadingLayout.loading"
            class="btn btn-default pull-right"
            (click)="desmarcarComoRevisada()"
        >
            Desmarcar como revisada
        </button>
        
        
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="grupo_pregunta"
                    [key]="key"
                ></model-simple-view>
                <br />
                <h4>Revisiones</h4>
                <revisiones-recurso
                    *ngIf="grupo_pregunta"
                    [recurso]="grupo_pregunta"
                    recursoTipo="GrupoPregunta"
                ></revisiones-recurso>
                <br />
                <h4>Certificación</h4>
                <certificacion-recurso
                    [recursoId]="grupo_pregunta.id"
                    [tipoRecurso]="'GrupoPregunta'"
                ></certificacion-recurso>
                <br />
                <grupo-pregunta-preview-latex [paramsGrupoPregunta]="paramsPreviewLatex"></grupo-pregunta-preview-latex>
                <br />
                <h4>
                    Preguntas que pertenecen a este grupo:
                    <span *ngIf="preguntas">{{ preguntas.length }}</span>
                </h4>

                <loading-layout #loadingLayoutPreguntas>
                    <div *ngFor="let p of preguntas" class="resultado-pregunta">
                        <div class="pregunta-box">
                            <a [routerLink]="['/preguntas', p.id]" class="titulo">Pregunta #{{ p.id }}</a>
                            <grupo-pregunta-pregunta [pregunta]="p"></grupo-pregunta-pregunta>
                        </div>
                    </div>
                </loading-layout>
            </div>
        </loading-layout>
        
    </logged-layout-contenido>
</logged-layout>
