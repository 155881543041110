<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-toolnavbar
            *ngIf="clasificacion"
            [clasificacion]="clasificacion"
            [displayButtons]="'all new edit'"
        ></clasificacion-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Clasificación</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="clasificacion"
                    [key]="key"
                ></model-simple-view>
                <h2 *ngIf="clasificacion.clasificaciones.length > 0">Clasificaciones Asociadas</h2>
                <ul>
                    <li *ngFor="let c of clasificacion.clasificaciones">{{ c.id }} - {{ c.clasificacion }}</li>
                </ul>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
