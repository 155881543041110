import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Clasificacion, Clasificaciones, ClasificacionForm } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "clasificaciones.form.component.html"
})
export class ClasificacionesNewComponent implements OnInit {
    clasificacion: Clasificacion = new Clasificacion()
    params = ClasificacionForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar clasificación"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private clasificacionesService: Clasificaciones, private router: Router) {}

    ngOnInit() {
        this.form = ClasificacionForm.getForm(this.clasificacion)
        this.loadingLayout.ready()
    }

    save() {
        this.clasificacion.clasificacion_clasificaciones = this.clasificacion.clasificacion_clasificaciones.filter(
            cc => !!cc.clasificacion_ref_id
        )

        ClasificacionForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.clasificacionesService.save(this.clasificacion).then(response => {
                this.router.navigate(["clasificaciones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.clasificacion = new Clasificacion()
            ClasificacionForm.markFormControlsAsPristine(this.form)
            ClasificacionForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    filtrarTipo() {
        this.clasificacion.LimpiarClasificaciones()
    }

    quitarClasificacion(clasificacion) {
        if (this.clasificacion["destroy_asociados"]) this.clasificacion["destroy_asociados"].push(clasificacion.id)
        else this.clasificacion["destroy_asociados"] = [clasificacion.id]
        var index = this.clasificacion.clasificaciones.indexOf(clasificacion)
        var el = this.clasificacion.clasificaciones.splice(index, 1)
    }
}
