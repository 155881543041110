import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Ayuda } from "@puntaje/nebulosa/api-services"
import { ToolNavbar } from "@puntaje/shared/core"
import { AyudasBuscadorService } from "./ayudas_buscador.service"

@Component({
    templateUrl: "ayudas.toolnavbar.component.html",
    selector: "ayudas-toolnavbar"
})
export class AyudasToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() ayuda: Ayuda
    @Input() displayButtons: string

    constructor(router: Router, route: ActivatedRoute, protected ayudasBuscadorService: AyudasBuscadorService) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/ayudas"
        this.urlNew = "/ayudas/new"
        if (this.ayuda != null) {
            this.urlEdit = "/ayudas/" + this.ayuda.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    edit() {
        if (this.ayuda != null) {
            this.urlEdit = "/ayudas/" + this.ayuda.id + "/edit"
        }
        super.edit()
    }

    search() {
        this.ayudasBuscadorService.openSearch.next()
    }
}
