import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { ReporteSubRazon, ReporteSubRazones, ReporteSubRazonView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "reporte_sub_razones.component.html"
})
export class ReporteSubRazonesComponent implements OnInit {
    reporteSubRazones: ReporteSubRazon[]
    show_params = ReporteSubRazonView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private reporteSubRazonesService: ReporteSubRazones,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll = (page: number = 1, per: number = 20) => {
        return this.reporteSubRazonesService
            .where({ page: page, per: per })
            .then((response: ReporteSubRazon[], total: number) => {
                this.reporteSubRazones = response
                this.loadingLayout.ready()
                return total
            })
    }

    view(reporteSubRazon: ReporteSubRazon) {
        this.router.navigate(["reporte_sub_razones/" + reporteSubRazon.id])
    }

    edit(reporteSubRazon: ReporteSubRazon) {
        this.router.navigate(["reporte_sub_razones/" + reporteSubRazon.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["reporte_sub_razones/" + id + "/delete"])
    }

    showModalDelete(reporteSubRazon: ReporteSubRazon) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta razón de reporte?")
        this.simpleModalService.setModalCallback(() => this.delete(reporteSubRazon.id))
    }
}
