<loading-layout #loadingLayout>
    <div>
        <div>
            <p>Seleccione una categoria para mostrar la cantidad de recursos asociados</p>
        </div>
        <div class="col-md-3">
            <!-- <app-monitor-plataforma *ngFor="let plataforma of plataformas" [plataforma]="plataforma"></app-monitor-plataforma> -->
            <form-input
                [form]="form"
                [params]="plataformaParams"
                [key]="'plataforma'"
                (optionsChange)="setPlataformas($event)"
                (valueChange)="setTipoInstrumentosAndAsignaturas($event)"
            ></form-input>

            <form-input
                *ngIf="showData"
                [form]="form"
                [params]="tipoInstrumentoParams"
                [key]="'tipoInstrumento'"
                [preload_options]="tipoInstrumentos"
                (valueChange)="tipoInstrumentoChange($event)"
            ></form-input>

            <div *ngIf="sinTipoInstrumentos">
                No existen tipos de instrumentos asociados a la plataforma seleccionada.
            </div>

            <form-input
                *ngIf="showData"
                [form]="form"
                [params]="asignaturaParams"
                [key]="'asignatura'"
                [preload_options]="asignaturas"
                (valueChange)="asignaturaChange($event)"
            ></form-input>

            <div *ngIf="sinAsignaturas">
                No existen asignaturas asociadas a la plataforma seleccionada.
            </div>

            <form-input
                *ngIf="showGeneradores && generadores"
                [form]="form"
                [params]="generadorParams"
                [key]="'generador'"
                [preload_options]="generadores"
                (valueChange)="getClasificaciones($event)"
            ></form-input>

            <div *ngIf="sinGeneradores">
                No existen instrumentos asociados a la plataforma seleccionada que cumplan con el filtro dado.
            </div>
        </div>
        <div class="col-md-1">
            <input type="checkbox" [(ngModel)]="visible" (change)="updateClasificacionesId(clasificacionesIds)" />
            Visible
            <br />
            <input type="checkbox" [(ngModel)]="presencial" (change)="updateClasificacionesId(clasificacionesIds)" />
            Presencial
            <h5>Estados:</h5>
            <ul class="list-unstyled">
                <li *ngFor="let estado of estados">
                    <input type="checkbox" [value]="estado" (change)="estadoChanged($any($event).currentTarget)" />
                    {{ estado }}
                    <br />
                </li>
            </ul>
        </div>
        <div class="col-md-8">
            <ng-container *ngIf="!loadingCantidad">Cantidad de preguntas: {{ cantidadPreguntas }}</ng-container>
            <ng-container *ngIf="loadingCantidad">
                Cantidad de preguntas: calculando...
            </ng-container>
        </div>
        <div class="col-md-12">
            <loading-layout #loadingClasificaciones>
                <monitor-clasificaciones
                    *ngIf="clasificaciones"
                    [clasificaciones]="clasificaciones"
                    (clasificacionesId)="updateClasificacionesId($event)"
                ></monitor-clasificaciones>
            </loading-layout>

            <div *ngIf="sinClasificaciones">
                No existen clasificaciones asociadas para el instrumento seleccionado.
            </div>
        </div>
    </div>
</loading-layout>
