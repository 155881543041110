import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { PersonasBuscadorService } from "./personas_buscador.service"

@Component({
    selector: "personas-buscador",
    templateUrl: "personas_buscador.component.html",
    styleUrls: ["personas_buscador.component.scss"]
})
export class PersonasBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false
    searchParams: any = { persona: { like: {} } }

    constructor(protected personasBuscadorService: PersonasBuscadorService) {}

    ngOnInit() {
        this.sub = this.personasBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    search() {
        this.personasBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
