import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "grupopreguntatextoview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue><div [grupoPreguntaTexto]="value[key]"></div></ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class GrupoPreguntaTextoView extends View {}
