import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { PiedraRosettaPreguntasComponent } from "./piedra_rosetta.preguntas.component"
import { piedraRosettaRouting } from "./piedra_rosetta.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        LayoutsModule,
        piedraRosettaRouting,
        ModelViewModule,
        PaginatorModule,
        NebuModule
    ],
    declarations: [PiedraRosettaPreguntasComponent],
    exports: []
})
export class PiedraRosettaModule {}
