import { Component, Directive } from "@angular/core"

/*#################################

Template simple para páginas una vez que usuario se haya logeado. Incluye el menú al costado izquierdo. 
Implementa una sección de contenido, un título y un toolnavbar:

logged-layout-toolnavbar
logged-layout-titulo
logged-layout-contenido

#################################*/

@Component({
    templateUrl: "logged_layout.component.html",
    selector: "logged-layout",
    styleUrls: ["logged_layout.scss"]
})
export class LoggedLayoutComponent {}

@Directive({ selector: "logged-layout-titulo, logged-layout-contenido, logged-layout-toolnavbar" })
export class LoggedLayoutComponentTags {}
