import { BooleanView, DateView, LinkView, TextView } from "@puntaje/shared/core"
import { ReporteRazones } from "./reporte_razones.service"

export class ReporteSubRazonView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            sub_reporte: { label: "Reporte", type: TextView, tableVisible: true },
            explicacion: { label: "Explicación", type: TextView, tableVisible: true },
            gatillante: { label: "Gatillante", type: BooleanView, visible: true },
            explicado: { label: "Requiere Detalle", type: BooleanView, visible: true },
            reporte_razon_id: {
                label: "Razón",
                type: LinkView,
                tableVisible: true,
                options: { class: ReporteRazones, key: "reporte_razon_id" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
