import { IParserHandler } from "./IParserHandler"
import { FatalErrorException } from "./FatalErrorException"
import { ElementStart, ElementEnd, MyElement } from "./MyElement"

import { java } from "j4ts"

import StringReader = java.io.StringReader

import BufferedReader = java.io.BufferedReader

import IOException = java.io.IOException

import Stack = java.util.Stack

import HashMap = java.util.HashMap

/**
 * HTML parser.
 */
export class Parser {
    /**
     * Input string.
     */
    private _file: string

    /**
     * Input file.
     */
    private _fr: StringReader

    /**
     * Input file.
     */
    private _reader: BufferedReader

    /**
     * Handler which receives events from the parser.
     */
    private _handler: IParserHandler

    /**
     * Stack containing all opened and still non-closed elements.
     */
    private _openElements: Stack<ElementStart> = new Stack<ElementStart>()

    /**
     * Parses the HTML file and converts it using the particular handler.
     * The file is processed char by char and a couple of events are
     * sent to the handler. The whole process is very similar
     * to the SAX model used with XML. The list of possible events which
     * are sent to the handler follows.
     * <ul>
     * <li>startElement -- the start element was reached (ie. <code>&lt;p</code>)</li>
     * <li>endElement -- the end element was reached (ie. <code>&lt;/p&gt;)</li>
     * <li>endDocument -- the end of the document was reached</li>
     * <li>characters -- the text content of an element</li>
     * <li>comment -- the comment was reached</li>
     * </ul>
     * @param inputFile input HTML file
     * @param handler receives events such as startElement (ie. <code>&lt;html)
     * &gt;</code>, endElement, ...
     * @throws FatalErrorException fatal error (ie. input file can't be opened) occurs
     */
    public parse(inputFile: string, handler: IParserHandler) {
        this._handler = handler
        this._file = inputFile
        this.init()
        try {
            this.doParsing()
        } catch (e) {
            this._handler.endDocument()
            this.destroy()
            throw new FatalErrorException("Can't read the input string")
        }
        this._handler.endDocument()
        this.destroy()
    }

    /**
     * Opens the input file specified in the
     * {@link Parser#parse(String, IParserHandler) parse()} method.
     * @throws FatalErrorException when input file can't be opened
     */
    private init() {
        this._fr = new StringReader(this._file)
        this._reader = new BufferedReader(this._fr)
    }

    /**
     * Closes the input input file specified in the
     * {@link Parser#parse(File inputFile, IParserHandler handler) parse()} method.
     * @throws FatalErrorException when input file can't be closed
     */
    private destroy() {
        if (this._fr != null) {
            this._fr.close()
        }
    }

    /**
     * Reads the input file char by char.
     * When the <code>&quot;&lt;&quot;</code> char is reached {@link Parser#readElement()
     * readElement()} is called otherwise {@link Parser#readContent(char)
     * readContent()} is called.
     * @throws IOException when input error occurs
     */
    private doParsing() {
        var c: number
        var ch: string
        while ((c = this._reader.read()) !== -1) {
            ch = String.fromCharCode(c)
            if (ch === "<") this.readElement()
            else this.readContent(ch)
        }
    }

    /**
     * Reads elements (tags).
     * Sends <code>comment</code>, <code>startElement</code> and
     * <code>endElement</code> events to the handler.
     * @throws IOException when input error occurs
     */
    private readElement() {
        var c: number
        var ch: string
        var strb: java.lang.StringBuffer = new java.lang.StringBuffer("")
        var str: string = ""
        while ((c = this._reader.read()) !== -1) {
            ch = String.fromCharCode(c)
            if (ch === ">") {
                if (
                    /* startsWith */ ((str, searchString, position = 0) =>
                        str.substr(position, searchString.length) === searchString)(strb.toString(), "!--")
                ) {
                    if (strb.toString().endsWith("--")) {
                        str = strb.toString().substring(3, strb.length())
                        str = str.substring(0, str.length - 2)
                        this._handler.comment(str)
                        return
                    }
                    strb.append(ch)
                    continue
                }
                str = strb.toString()
                var element: MyElement = this.parseElement(str)
                if (element != null && element instanceof ElementStart) {
                    if (!str.endsWith("/")) this._openElements.push(<ElementStart>element)
                    this._handler.startElement(<ElementStart>element)
                    if (str.endsWith("/")) {
                        this._handler.endElement(new ElementEnd(element.getElementName()), <ElementStart>element)
                    }
                } else if (element != null && element instanceof ElementEnd) {
                    this.checkValidity(<ElementEnd>element)
                }
                return
            }
            strb.append(ch)
        }
    }

    /**
     * Parses element.
     * Stores element attributes in {@link ElementStart ElementStart} object
     * if it's a start element.
     * @param elementString string containing the element with its
     * attributes (but without leading &quot;&lt;&quot; and ending
     * &quot;&gt;&quot;)
     * @return {@link ElementStart ElementStart} or {@link ElementEnd
     * ElementEnd} object.
     */
    private parseElement(elementString: string): MyElement {
        var elementName: string = ""
        var attributes: HashMap<string, string> = new HashMap<string, string>(3)
        if (elementString.endsWith("/")) elementString = elementString.substring(0, elementString.length - 1)
        var aux: string[] = elementString.split(/\s+/, 2)
        if (aux.length > 1)
            aux[1] = elementString
                .split(/\s+/)
                .filter((a, i) => i > 0)
                .join(" ")
        if (aux.length !== 0) {
            elementName = aux[0]
            if (elementName.length > 1 && elementName.charAt(0) === "/") {
                var name: string = elementName.substring(1, elementName.length).toLowerCase()
                return new ElementEnd(name)
            }
            if (aux.length === 2) {
                var attr: string[] = aux[1].split(/'\s+|"\s+/)
                for (var i: number = 0; i < attr.length; ++i) {
                    attr[i] = attr[i].trim().replace(/"/g, "").replace(/'/, "")
                    var attrInstance: string[] = attr[i].split("=", 2)
                    if (attrInstance.length > 1)
                        attrInstance[1] = attr[i]
                            .split("=")
                            .filter((a, i) => i > 0)
                            .join("=")
                    if (attrInstance.length === 2) attributes.put(attrInstance[0].toLowerCase(), attrInstance[1])
                }
            }
        }
        return new ElementStart(elementName.toLowerCase(), attributes)
    }

    /**
     * Reads text content of an element.
     * Sends <code>character</code> event to the handler.
     * @param firstChar first char read in {@link Parser#doParsing doParsing()}
     * method
     * @throws IOException when input error occurs
     */
    private readContent(firstChar: string) {
        var c: number
        var ch: string
        var str: string = ""
        str += firstChar
        while ((c = this._reader.read()) !== -1) {
            ch = String.fromCharCode(c)
            if (ch === "<") {
                this._handler.characters(str)
                this.readElement()
                return
            }
            str += ch
        }
        this._handler.characters(str)
    }

    /**
     * Checks whether the document is well-formed.
     * If not it sends <code>endElement</code> events for the elements which
     * were opened but not correctly closed.
     * @param element the latest ending element which was reached
     */
    private checkValidity(element: ElementEnd) {
        if (this._openElements.empty()) return
        if (this._openElements.peek().getElementName() === element.getElementName()) {
            this._handler.endElement(element, this._openElements.pop())
            return
        }
        for (var i: number = this._openElements.size() - 1; i >= 0; --i) {
            if (this._openElements.get(i).getElementName() === element.getElementName()) {
                for (var j: number = this._openElements.size() - 1; j >= i; --j) {
                    var es: ElementStart = this._openElements.get(i)
                    var e: ElementEnd = new ElementEnd(this._openElements.pop().getElementName())
                    this._handler.endElement(e, es)
                }
                return
            }
        }
    }

    constructor() {}
}
