import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Clasificacion, Clasificaciones, ClasificacionView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion.component.html"
})
export class ClasificacionComponent implements OnInit, OnDestroy {
    clasificacion: Clasificacion
    show_params = ClasificacionView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private clasificacionesService: Clasificaciones,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            ;(this.clasificacionesService as any)
                .find(+params["id"], { with_clasificaciones: true })
                .then((response: Clasificacion) => {
                    this.clasificacion = response
                    this.loadingLayout.ready()
                })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
