import { Injectable, Injector, Optional } from "@angular/core"
import { Loader } from "@puntaje/shared/core"
import { HttpClient } from "@angular/common/http"
import { Store } from "@ngrx/store"
import { PermisosService } from "../policies/permisos.service"

@Injectable({
    providedIn: "root"
})
export class NebuLoader extends Loader {
    constructor(
        protected permisosService: PermisosService,
        http: HttpClient,
        injector: Injector,
        @Optional() store: Store<any>
    ) {
        super(http, injector, store, null)
    }

    loadConfigMethod(): Promise<any> {
        return new Promise((resolve, _) => {
            this.permisosService.setPermisos(this.permisosService.getPermisosFromStorage())
            resolve(null)
        })
    }
}
