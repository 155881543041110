import { CommonModule } from "@angular/common"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, UtilModule, PaginatorModule } from "@puntaje/shared/core"
import { ClasificacionTipoComponent } from "./clasificacion_tipo.component"
import { ClasificacionTiposComponent } from "./clasificacion_tipos.component"
import { ClasificacionTiposDeleteComponent } from "./clasificacion_tipos.delete.component"
import { ClasificacionTiposEditComponent } from "./clasificacion_tipos.edit.component"
import { ClasificacionTiposNewComponent } from "./clasificacion_tipos.new.component"
import { ClasificacionTiposRouting } from "./clasificacion_tipos.routing"
import { ClasificacionTiposToolNavbarComponent } from "./clasificacion_tipos.toolnavbar.component"
import { ClasificacionTiposBuscadorComponent } from "./clasificacion-tipos-buscador/clasificacion-tipos-buscador.component"
import { ClasificacionTiposBuscadorService } from "./clasificacion-tipos-buscador.service"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ClasificacionTiposRouting,
        ModelViewModule,
        PaginatorModule,
        NebuModule
    ],
    declarations: [
        ClasificacionTiposComponent,
        ClasificacionTipoComponent,
        ClasificacionTiposNewComponent,
        ClasificacionTiposEditComponent,
        ClasificacionTiposDeleteComponent,
        ClasificacionTiposToolNavbarComponent,
        ClasificacionTiposBuscadorComponent
    ],
    exports: []
})
export class ClasificacionTiposModule {
    static forRoot(): ModuleWithProviders<ClasificacionTiposModule> {
        return {
            ngModule: ClasificacionTiposModule,
            providers: [ClasificacionTiposBuscadorService]
        }
    }
}
