import { ICSSParserHandler } from "./ICSSParserHandler"
import { ErrorException } from "./FatalErrorException"

import { java } from "j4ts"

//import File = java.io.File;

//import FileReader = java.io.FileReader;

import BufferedReader = java.io.BufferedReader

import IOException = java.io.IOException

import HashMap = java.util.HashMap

/**
 * CSS parser.
 */
export class CSSParser {
    /**
     * Input file.
     */
    private _file: File

    /**
     * Input file.
     */
    private _fr: FileReader

    /**
     * Input file.
     */
    private _reader: BufferedReader

    /**
     * Handler which receives events from the parser.
     */
    private _handler: ICSSParserHandler

    /**
     * Parser the CSS file and sends events to the handler.
     * @param f CSS file
     * @param handler handler receiving events
     */
    public parse(f: File, handler: ICSSParserHandler) {
        this._file = f
        this._handler = handler
        try {
            this.init()
            try {
                this.doParsing()
            } catch (e) {
                console.error("Can't read the CSS file " /*+ this._file.getName()*/)
            }
            this.destroy()
        } catch (e: any) {
            console.error(e.message)
        }
    }

    /**
     * Opens the file specified in the
     * {@link CSSParser#parse(File, ICSSParserHandler) parse()} method.
     * @throws ErrorException when the file can't be opened
     */
    private init() {
        try {
            //this._fr = new FileReader(this._file);
            this._reader = new BufferedReader(this._fr)
        } catch (e) {
            throw new ErrorException("cant open css CSS")
            //throw new ErrorException("Can\'t open CSS file " + this._file.getName());
        }
    }

    /**
     * Closes the file specified in the
     * {@link CSSParser#parse(File, ICSSParserHandler) parse()} method.
     * @throws ErrorException when the file can't be closed
     */
    private destroy() {
        if (this._fr != null) {
            try {
                //this._fr.close();
            } catch (e) {
                throw new ErrorException("Can't close CSS file " /*+ this._file.getName()*/)
            }
        }
    }

    /**
     * Parses the CSS file.
     * It skips CSS comments and reads each style separately.
     * When the style is read (ie. <code>".bold { font-weight:bold; } </code>)
     * {@link CSSParser#parseStyle(String) parseStyle()} method is called.
     * @throws IOException input error occurs
     */
    private doParsing() {
        var c: number
        var ch: string
        var prevCh: string = "X"
        var str: java.lang.StringBuffer = new java.lang.StringBuffer(100)
        var inComment: boolean = false
        while ((c = this._reader.read()) !== -1) {
            ch = String.fromCharCode(c)
            if (str.toString().endsWith("/") && ch === "*") {
                inComment = true
                str.deleteCharAt(str.length() - 1)
                continue
            } else if (inComment && prevCh === "*" && ch === "/") {
                inComment = false
                continue
            } else if (inComment) {
                prevCh = ch
                continue
            } else {
                str.append(ch)
                prevCh = ch
                if (ch === "}") {
                    this.parseStyle(str.toString())
                    str.delete(0, str.length())
                }
            }
        }
    }

    /**
     * Parses single CSS style.
     * Separates style name and makes a map from the properties - pairs
     * (property name, property value).
     * @param style style definition (including style name and curly brackets)
     */
    private parseStyle(style: string) {
        var split: string[] = style.split(/\{/, 2)
        if (split.length > 1)
            split[1] = style
                .split(/\{/)
                .filter((a, i) => i > 0)
                .join("\\{")
        if (split.length !== 2) return
        var styleName: string = split[0].trim()
        var attrs: string = split[1].replace(/\\}/g, "").trim()
        split = attrs.split(";")
        var attrsMap: HashMap<string, string> = new HashMap<string, string>(5)
        for (var i: number = 0; i < split.length; ++i) {
            var property: string[] = split[i].split(":")
            if (property.length !== 2) continue
            attrsMap.put(property[0].trim().toLowerCase(), property[1].trim().toLowerCase())
        }
        this._handler.newStyle(styleName, attrsMap)
    }

    constructor() {}
}
