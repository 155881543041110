import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Reporte } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"
import { ReportesBuscadorService } from "./reportes_buscador.service"

@Component({
    templateUrl: "reportes.toolnavbar.component.html",
    selector: "reporte-toolnavbar"
})
export class ReportesToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() reporte: Reporte
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected reportesBuscadorService: ReportesBuscadorService,
        protected sessionService: SessionService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/reportes"
        this.urlNew = "/reportes/new"
        if (this.reporte != null) {
            this.urlEdit = "/reportes/" + this.reporte.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit && !this.displayBtnSearch) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.reporte != null) {
            this.urlEdit = "/reportes/" + this.reporte.id + "/edit"
        }
        super.edit()
    }

    search() {
        this.reportesBuscadorService.openSearch.next()
    }
}
