import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Materiales } from "./materiales.service"
import { Preguntas } from "./preguntas.service"

export class ReporteAgrupadoView {
    public static get viewParams(): any {
        let tablas = (tabla: string) => {
            if (tabla == "Material") {
                return Materiales
            } else if (tabla == "Pregunta") {
                return Preguntas
            } else {
                return null
            }
        }
        return {
            tabla: { label: "Recurso", type: TextView, tableVisible: true },
            asignatura: { label: "Asignatura", type: TextView, tableVisible: true },
            entidad_id: {
                label: "Id Recurso",
                type: LinkView,
                tableVisible: true,
                options: { fromFunction: tablas, from: "tabla", key: "entidad_id" }
            },
            cantidad_reportes_recurso: { label: "Cantidad Reportes", type: DateView, tableVisible: true }
        }
    }
}
