<logged-layout>
    <logged-layout-toolnavbar>
        <materiales-toolnavbar [displayButtons]="display_buttons_options"></materiales-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>{{ logged_layout_title | easyplaceholder: "Formulario materiales" }}</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-sm-12">
                    <form novalidate [formGroup]="form">
                        <div class="">
                            <button (click)="clear()" type="button" class="btn btn-default pull-right">
                                {{ clear_button_text | easyplaceholder: "Borrar" }}
                            </button>
                        </div>

                        <ul class="nav nav-tabs">
                            <li class="active">
                                <a href="#tab1" role="tab" data-toggle="tab">
                                    <span class="tab-number">1</span>
                                    <span class="tab_text">Clasificación</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    [class.disabled-tab]="!canEdit"
                                    [attr.href]="canEdit ? '#tab2' : null"
                                    [attr.role]="canEdit ? 'tab' : null"
                                    [attr.data-toggle]="canEdit ? 'tab' : null"
                                >
                                    <span class="tab-number">2</span>
                                    <span class="tab_text">Contenido</span>
                                </a>
                            </li>
                            <li>
                                <a href="#tab3" role="tab" data-toggle="tab" (click)="reloadPreview()">
                                    <span class="tab-number">3</span>
                                    <span class="tab_text">Vista previa</span>
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <!-- Clasificación -->
                            <div id="tab1" class="active tab-pane clearfix">
                                <form-input
                                    [form]="form"
                                    [params]="params['asignatura_id']"
                                    [(value)]="material['asignatura_id']"
                                    [key]="'asignatura_id'"
                                    [disabled]="!canEdit"
                                ></form-input>

                                <label class="label-visibilidad">Visibilidad del material</label>
                                <form-input
                                    [form]="form"
                                    [params]="params['visible']"
                                    [(value)]="material['visible']"
                                    [key]="'visible'"
                                    class="input-visibilidad"
                                    [disabled]="!canEdit"
                                ></form-input>
                                <h4 _ngcontent-c19="">Lista de Plataformas donde el material está habilitado</h4>
                                <div>
                                    <embed-plataformas
                                        [plataformas_seleccionadas]="plataformas_seleccionadas || []"
                                        (emit_clasificaciones)="actualizarPlataformas($event)"
                                        [disabled]="!canEdit"
                                    ></embed-plataformas>
                                </div>
                                <h4 _ngcontent-c19="">Lista de Clasificaciones</h4>
                                <div>
                                    <embed-clasificaciones
                                        [recursoEducativo]="'material'"
                                        [clasificaciones_seleccionadas]="material.clasificaciones"
                                        [material]="true"
                                        (clasificacionesChange)="actualizarClasificaciones($event)"
                                    ></embed-clasificaciones>
                                </div>
                            </div>

                            <div id="tab2" class="tab-pane clearfix">
                                <form-input
                                    [form]="form"
                                    [params]="params['material_tipo_id']"
                                    [(value)]="material['material_tipo_id']"
                                    [key]="'material_tipo_id'"
                                    [disabled]="!(material.id === undefined)"
                                    (valueChange)="updateTipoMaterial()"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['material']"
                                    [(value)]="material['material']"
                                    [key]="'material'"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['url']"
                                    [(value)]="material['url']"
                                    [key]="'url'"
                                    [hidden]="
                                        !(
                                            checkTipoMaterial() == tipoMaterial.VIDEO ||
                                            checkTipoMaterial() == tipoMaterial.STREAMING ||
                                            checkTipoMaterial() == tipoMaterial.INTERACTIVO ||
                                            checkTipoMaterial() == tipoMaterial.LINK
                                        )
                                    "
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['file']"
                                    [(value)]="material['file']"
                                    [key]="'file'"
                                    [xhr_upload]="s3Service.xhr_upload"
                                    [hidden]="
                                        !(
                                            checkTipoMaterial() == tipoMaterial.FILE ||
                                            checkTipoMaterial() == tipoMaterial.INSTRUMENTO ||
                                            checkTipoMaterial() == tipoMaterial.AUDIO ||
                                            checkTipoMaterial() == tipoMaterial.MP4 ||
                                            checkTipoMaterial() == tipoMaterial.PPT ||
                                            checkTipoMaterial() == tipoMaterial.WORD ||
                                            checkTipoMaterial() == tipoMaterial.EXCEL
                                        )
                                    "
                                ></form-input>

                                <div *ngIf="material.material_tipo_id == 4" class="contenido-warning">
                                    <div class="contenido-warning-content">
                                        <div class="contenido-warning-icon"><fa [name]="'warning'"></fa></div>
                                        <div class="contenido-warning-message">
                                            <p>
                                                El editor de contenido convierte texto normal a html por defecto. Para
                                                pegar contenido que ya está en formato html, apretar primero el botón
                                                "Source" del editor (arriba a la derecha en el editor) y luego pegar.
                                                Luego volver a apretar "Source" para volver al modo por defecto.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <form-input
                                    [form]="form"
                                    [params]="params['contenido']"
                                    [(value)]="material['contenido']"
                                    [key]="'contenido'"
                                    *ngIf="material.material_tipo_id == 4"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['thumbnail_file']"
                                    [(value)]="material['thumbnail_file']"
                                    [key]="'thumbnail_file'"
                                    id="form-thumbnail_file"
                                ></form-input>
                                <p>Dimensiones sugeridas: 640 x 360 pixeles, formato jpg o png, tamaño menor a 2MB</p>

                                <form-input
                                    [form]="form"
                                    [params]="params['comentarios']"
                                    [(value)]="material['comentarios']"
                                    [key]="'comentarios'"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['profesor']"
                                    [(value)]="material['profesor']"
                                    [key]="'profesor'"
                                ></form-input>
                            </div>

                            <div id="tab3" class="tab-pane clearfix">
                                <h4>Vista previa</h4>
                                <div class="clearfix">
                                    <material-preview
                                        [material]="material"
                                        #previewMaterial
                                        *ngIf="enablePreview"
                                    ></material-preview>
                                </div>
                                <br />
                                <h4>Thumbnail</h4>
                                <p *ngIf="!material.thumbnail">No tiene / automático</p>
                                <p *ngIf="material.thumbnail_file">
                                    Sin vista previa de archivos recién subidos por ahora.
                                </p>
                                <img *ngIf="material.thumbnail!; saving" [src]="material.thumbnail" />
                                <br />
                                <div class="clearfix">
                                    <div *ngIf="isSaving()">Guardando...</div>
                                    <button
                                        [disabled]="isSaving()"
                                        (click)="save()"
                                        type="button"
                                        class="btn btn-default pull-right"
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
