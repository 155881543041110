import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { HttpClient, HttpResponse, HttpParams, HttpHeaders, HttpRequest } from "@angular/common/http"
import { Router } from "@angular/router"
import { environment } from "../../../environments/environment"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Curriculums, RosettaStone, RosettaStoneForm } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    templateUrl: "piedra_rosetta.preguntas.component.html"
})
export class PiedraRosettaPreguntasComponent implements OnInit {
    rosettaStone: RosettaStone = new RosettaStone()
    params = RosettaStoneForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Piedra Rosetta"
    saving: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private simpleModalService: SimpleModalService,
        private curriculumsService: Curriculums
    ) {
        //this.getData();
    }

    ngOnInit() {
        this.form = RosettaStoneForm.getForm(this.rosettaStone)
        this.loadingLayout.ready()
    }

    save() {
        RosettaStoneForm.markFormControlsAsTouched(this.form)
        this.saving = true
        this.getData(this.rosettaStone.tipo, this.rosettaStone.curriculum_id, this.rosettaStone.curriculum2_id)
    }

    clear() {
        setTimeout(() => {
            this.rosettaStone = new RosettaStone()
            RosettaStoneForm.markFormControlsAsPristine(this.form)
            RosettaStoneForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    getData(tipo, cid, cid2) {
        var header = new HttpHeaders({ Authorization: this.auth.getToken() })
        var opts: { headers?: HttpHeaders; observe: "response"; responseType?: "json" } = {
            headers: header,
            observe: "response"
        }
        this.http
            .get(
                environment.endpoints.base + "/rosetta/" + tipo + "?curriculum_id=" + cid + "&curriculum2_id=" + cid2,
                opts
            )
            .toPromise()
            .then(response => {
                let json = response.body
                this.saving = false
            })
    }
}
