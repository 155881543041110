import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ClasificacionTipoAliases } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    template: ""
})
export class ClasificacionTipoAliasesDeleteComponent implements OnInit, OnDestroy {
    private sub: Subscription

    constructor(
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.clasificacionTipoAliasesService.remove(+params["id"]).then(response => {
                this.router.navigate(["clasificacion_tipo_aliases"])
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
