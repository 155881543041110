import { CommonModule } from "@angular/common"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { PersonaComponent } from "./persona.component"
import { PersonasComponent } from "./personas.component"
import { PersonasDeleteComponent } from "./personas.delete.component"
import { PersonasEditComponent } from "./personas.edit.component"
import { PersonasNewComponent } from "./personas.new.component"
import { personasRouting } from "./personas.routing"
import { PersonasToolNavbarComponent } from "./personas.toolnavbar.component"
import { PersonasBuscadorService } from "./personas-buscador/personas_buscador.service"
import { PersonasBuscadorComponent } from "./personas-buscador/personas_buscador.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        PaginatorModule,
        NebuModule,
        Angular2FontawesomeModule,
        personasRouting
    ],
    declarations: [
        PersonasComponent,
        PersonaComponent,
        PersonasNewComponent,
        PersonasEditComponent,
        PersonasDeleteComponent,
        PersonasToolNavbarComponent,
        PersonasBuscadorComponent
    ],
    exports: [],
    providers: []
})
export class PersonasModule {
    static forRoot(): ModuleWithProviders<PersonasModule> {
        return {
            ngModule: PersonasModule,
            providers: [PersonasBuscadorService]
        }
    }
}
