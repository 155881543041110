import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { Ayuda, Ayudas, AyudaView } from "@puntaje/nebulosa/api-services"
import { HtmlView, PdfView, YoutubeVideoView } from "@puntaje/shared/core"

@Component({
    selector: "ayuda-preview",
    templateUrl: "ayuda.preview.component.html",
    styleUrls: ["ayuda.preview.component.scss"]
})
export class AyudaPreviewComponent implements OnInit {
    @Input() ayuda: Ayuda
    show_params = AyudaView.viewParams
    enablePreview: boolean = false
    key: string = "url"
    noViewMessage: string = ""
    params: any = {}
    enableHTMLBorder: boolean = false
    enableCheck: boolean = false
    warningMessage: string = ""

    constructor(private ayudasService: Ayudas, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.loadPreview()
    }

    loadPreview() {
        this.enablePreview = false
        this.enableCheck = false
        this.enableHTMLBorder = false
        this.params = {}
        if (this.ayuda && this.ayuda.material_tipo_id) {
            let enableCheck: boolean = false
            switch (+this.ayuda.material_tipo_id) {
                case 1:
                    this.enableCheck = this.ayuda.url && this.ayuda.url.length > 0 && this.ayuda.getExtension() == "pdf"
                    this.noViewMessage =
                        "Vista previa por ahora sólo disponible para archivos de tipo <em>PDF que ya se encuentren subidos a la plataforma.</em>"
                    this.params = { label: "Previsualizar", type: PdfView, tableVisible: true }
                    this.key = "url"
                    break

                case 2:
                    this.enableCheck = this.ayuda.url && this.ayuda.url.length > 0
                    this.noViewMessage =
                        "Por favor incluya un <em>enlace</em> válido para esta ayuda en la pestaña de contenido."
                    this.params = { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
                    this.key = "url"
                    this.warningMessage = "Los videos pueden tomar un tiempo en cargar."
                    break

                case 3:
                    this.enableCheck = this.ayuda.url && this.ayuda.url.length > 0
                    this.noViewMessage =
                        "Por favor incluya un <em>enlace</em> válido para esta ayuda en la pestaña de contenido."
                    this.params = { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
                    this.key = "url"
                    this.warningMessage = "Los videos pueden tomar un tiempo en cargar."
                    break

                case 4:
                    this.enableCheck = this.ayuda.contenido && this.ayuda.contenido.length > 0
                    this.noViewMessage =
                        "Por favor incluya <em>contenido</em> válido para esta ayuda en la pestaña de contenido."
                    this.params = { label: "Contenido", type: HtmlView, tableVisible: true }
                    this.key = "contenido"
                    this.enableHTMLBorder = true
                    this.warningMessage = "El marco azul sólo sirve para marcar donde empieza y termina el contenido."
                    break

                default:
                    break
            }
        } else {
            this.noViewMessage =
                "Para activar la vista previa, primero indique el tipo de ayuda (Archivo, Video, etc...)."
        }

        setTimeout(() => {
            this.enablePreview = this.enableCheck
            this.enableCheck = false
        }, 10)
    }
}
