import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { ReporteSubRazonComponent } from "./reporte_sub_razon.component"
import { ReporteSubRazonesComponent } from "./reporte_sub_razones.component"
import { ReporteSubRazonesDeleteComponent } from "./reporte_sub_razones.delete.component"
import { ReporteSubRazonesEditComponent } from "./reporte_sub_razones.edit.component"
import { ReporteSubRazonesNewComponent } from "./reporte_sub_razones.new.component"
import { reporteSubRazonesRouting } from "./reporte_sub_razones.routing"
import { ReporteSubRazonesToolNavbarComponent } from "./reporte_sub_razones.toolnavbar.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        NebuModule,
        PaginatorModule,
        reporteSubRazonesRouting
    ],
    declarations: [
        ReporteSubRazonesComponent,
        ReporteSubRazonComponent,
        ReporteSubRazonesNewComponent,
        ReporteSubRazonesEditComponent,
        ReporteSubRazonesDeleteComponent,
        ReporteSubRazonesToolNavbarComponent
    ],
    exports: []
})
export class ReporteSubRazonesModule {}
