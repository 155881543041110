import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../../layouts/simple_modal/simple_modal.service"
import {
    ClasificacionTipoAlias,
    ClasificacionTipoAliases,
    ClasificacionTipoAliasView
} from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService, PaginatorComponent } from "@puntaje/shared/core"
// import { ClasificacionTiposBuscadorService } from "./clasificacion-tipos-buscador.service"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion_tipo_aliases.component.html"
})
export class ClasificacionTipoAliasesComponent implements OnInit {
    clasificacionTipoAliases: ClasificacionTipoAlias[]
    show_params = ClasificacionTipoAliasView.viewParams
    tableButtonsArray: any
    searchParams: any
    sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService // protected clasificacionTiposBuscadorService: ClasificacionTiposBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: " Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            ...this.searchParams,
            page: page,
            per: per,
            render_options: {
                include: {
                    clasificacion_tipo: null,
                    asignatura_plataforma: {
                        include: {
                            asignatura: null,
                            plataforma: null
                        }
                    }
                }
            }
        }

        return this.clasificacionTipoAliasesService
            .where(params)
            .then((clasificacionTipoAliases: ClasificacionTipoAlias[], total: number) => {
                this.clasificacionTipoAliases = clasificacionTipoAliases
                this.loadingLayout.ready()
                return total
            })
    }

    view(clasificacionTipoAlias: ClasificacionTipoAlias) {
        this.router.navigate(["clasificacion_tipo_aliases/" + clasificacionTipoAlias.id])
    }

    edit(clasificacionTipoAlias: ClasificacionTipoAlias) {
        this.router.navigate(["clasificacion_tipo_aliases/" + clasificacionTipoAlias.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["clasificacion_tipo_aliases/" + id + "/delete"])
    }

    showModalDelete(clasificacionTipoAlias: ClasificacionTipoAlias) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar el alias de clasificación?")
        this.simpleModalService.setModalCallback(() => this.delete(clasificacionTipoAlias.id))
    }
}
