import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    PlanEstudio,
    PlanEstudioComponente,
    PlanEstudioLote,
    PlanEstudioMaterial,
    PlanEstudios,
    PlanEstudioView
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "plan_estudio.component.html"
})
export class PlanEstudioComponent implements OnInit, OnDestroy {
    planEstudio: PlanEstudio
    private sub: Subscription
    show_params = PlanEstudioView.viewParams
    mType = PlanEstudioMaterial
    lType = PlanEstudioLote
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private planEstudioService: PlanEstudios, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.planEstudioService.find(+params["id"], { with_sesiones: true }).then((response: PlanEstudio) => {
                this.planEstudio = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    ordenar(list_m: PlanEstudioComponente[], list_l: PlanEstudioComponente[]) {
        return list_m.concat(list_l).sort((a, b) => {
            return a.orden - b.orden
        })
    }

    type(obj: any) {
        return obj.constructor
    }

    prettyType(obj: any) {
        let type = this.type(obj)
        if (type === this.mType) {
            return "Material"
        } else if (type === this.lType) {
            return "Lote"
        }
        return type
    }
}
