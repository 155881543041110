<logged-layout>
    <logged-layout-toolnavbar>
        <preguntas-toolnavbar
            *ngIf="pregunta"
            [pregunta]="pregunta"
            [displayButtons]="'all new edit mine'"
        ></preguntas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        Pregunta
        <span *ngIf="pregunta">#{{ pregunta.id }}</span>
        <button
            *ngIf="showMarcarComoRevisada"
            [disabled]="loadingLayout.loading"
            class="btn btn-default pull-right"
            (click)="marcarComoRevisada()"
        >
            Marcar como revisada
        </button>
        <button
            *ngIf="showDesmarcarComoRevisada"
            [disabled]="loadingLayout.loading"
            class="btn btn-default pull-right"
            (click)="desmarcarComoRevisada()"
        >
            Desmarcar como revisada
        </button>
        <button
            [disabled]="savingCambio || !pregunta"
            class="btn btn-default pull-right margin-right btn-cambio"
            (click)="marcarCambioImportante()"
        >
            Marcar cambio importante
            <loader *ngIf="savingCambio" class="loader-cambio" loaderType="default-small"></loader>
        </button>
        <button
            *permiso="'Preguntas#clonar'"
            class="btn btn-default pull-right margin-right"
            (click)="generarVariacion()"
        >
            Generar variaciones
        </button>
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <div *ngIf="pregunta?.grupo_pregunta?.id">
                    <h4>
                        Contenido
                        <a [routerLink]="['/grupo_preguntas', pregunta.grupo_pregunta?.id]">
                            Grupo Pregunta #{{ pregunta.grupo_pregunta.id }}
                        </a>
                    </h4>
                    <div class="contenido-enunciado">
                        <div class="edit-grupo-pregunta">
                            <a [routerLink]="['/grupo_preguntas/' + pregunta.grupo_pregunta?.id + '/edit']">
                                Editar grupo pregunta
                                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                            </a>
                        </div>
                        <div class="top-padding-space"></div>
                        <div
                            [grupoPreguntaTexto]="
                                pregunta?.grupo_pregunta?.texto
                                    | clearLatex
                                    | katex
                                    | easyplaceholder: 'Enunciado no disponible'
                            "
                            [grupoPreguntaId]="pregunta.grupo_pregunta.id"
                        ></div>
                    </div>
                    <h4>Contenido Pregunta #{{ pregunta.id }}</h4>
                </div>
                <div
                    class="contenido-enunciado"
                    [innerHTML]="
                        pregunta?.taxativos[0]?.taxativo
                            | taxativoAlternativas
                            | clearLatex
                            | katex
                            | taxativoNumeroTexto
                                : grupoPreguntaTextoService.sentenceToLine[pregunta?.grupo_pregunta?.id]
                            | trustedhtml
                            | easyplaceholder: 'Enunciado no disponible'
                    "
                ></div>

                <div *ngFor="let alternativa of pregunta?.contestables[0]?.alternativas; let i = index">
                    <div class="alternativa-container" [class.alternativa-correcta]="alternativa.correcta">
                        <div class="alternativa-row" [id]="alternativa.letra">
                            <div *ngIf="alternativa.letra" class="alternativa-letra">{{ alternativa.letra }}</div>
                            <div
                                class="alternativa-contenido"
                                [innerHTML]="alternativa.alternativa | clearLatex | katex | trustedhtml"
                            ></div>
                            <span
                                *ngIf="alternativa.correcta"
                                class="glyphicon glyphicon-ok side-ok"
                                aria-hidden="true"
                            ></span>
                            <span
                                *ngIf="alternativa.ocultable"
                                class="glyphicon glyphicon-ban-circle side-ocultable"
                                aria-hidden="true"
                            ></span>
                            <button
                                *ngIf="!alternativa.correcta && alternativa.explicacion_distractor"
                                type="button"
                                class="btn btn-xs btn-default dist-button"
                                (click)="toggleDistractor(i)"
                            >
                                <span
                                    class="glyphicon"
                                    [class.glyphicon-chevron-up]="distractores[i]"
                                    [class.glyphicon-chevron-down]="!distractores[i]"
                                    aria-hidden="true"
                                ></span>
                                Distractor
                            </button>
                        </div>
                    </div>
                    <div
                        *ngIf="alternativa.explicacion_distractor"
                        class="distractor-contenido"
                        [class.distractor-show]="distractores[i]"
                    >
                        {{ alternativa.explicacion_distractor }}
                    </div>
                </div>
                <h4 class="solucion-titulo">Solución</h4>
                <div
                    class="solucion-contenido"
                    [innerHTML]="
                        pregunta?.solucion
                            | clearLatex
                            | katex
                            | trustedhtml
                            | easyplaceholder: 'Solución no disponible'
                    "
                ></div>
                <h4 class="solucion-titulo">Solución en video</h4>

                <div class="solucion-contenido">
                    {{ pregunta?.solucion_video }}
                    <embedded-youtube-video [url]="pregunta?.solucion_video" class="video"></embedded-youtube-video>
                </div>
            </div>

            <h4>Persona externa</h4>
            <div *ngIf="pregunta && !pregunta.persona_externa">
                <div>----------</div>
            </div>
            <div *ngIf="pregunta && pregunta.persona_externa">
                <div>{{ pregunta.persona_externa.toString() }}</div>
                <div>{{ pregunta.persona_externa.plataforma.plataforma }}</div>
            </div>
            <br />
            <h4>Clasificaciones</h4>
            <div class="clasificaciones" *ngIf="pregunta && pregunta.clasificaciones">
                <p *ngIf="pregunta.clasificaciones.length == 0">Sin clasificaciones asociadas.</p>
                <ng-container *ngFor="let c of pregunta.clasificaciones">
                    <button
                        class="clasificacion with-popover"
                        popover="{{ c.descripcion }}"
                        triggers="focus"
                        *ngIf="c.descripcion && c.descripcion.length > 0"
                    >
                        {{ c.clasificacion }}&nbsp;
                        <fa name="plus-square-o" class="plus-info"></fa>
                    </button>
                    <span class="clasificacion" *ngIf="!(c.descripcion && c.descripcion.length > 0)">
                        {{ c.clasificacion }}
                    </span>
                </ng-container>
            </div>
            <br />
            <h4>Visible</h4>
            {{ this.pregunta?.visible ? "Sí" : "No" }}
            <br />
            <pregunta-preview-latex [paramsPregunta]="paramsPreviewLatex"></pregunta-preview-latex>
            <br />
            <h4>Revisiones</h4>
            <revisiones-recurso *ngIf="pregunta" [recurso]="pregunta" recursoTipo="Pregunta"></revisiones-recurso>
            <br />
            <h4>Certificación</h4>
            <certificacion-recurso
                *ngIf="pregunta"
                [recursoId]="pregunta.id"
                [tipoRecurso]="'Pregunta'"
            ></certificacion-recurso>
            <br />
            <h4>Reportes</h4>
            <embed-reportes
                *ngIf="pregunta"
                [tabla]="'Pregunta'"
                [entidadId]="pregunta.id"
                [enableAdvancedOptions]="true"
                [selectable]="true"
            ></embed-reportes>
            <br />
            <h4>Uso en Lotes</h4>
            <p *ngIf="!(featuringInLotes && featuringInLotes.length > 0)">
                Esta pregunta no se encuentra en ningún lote.
            </p>
            <p class="lote-info">
                <span *ngFor="let lp of featuringInLotes">
                    <a target="_blank" [routerLink]="['/lotes', lp?.lote_id]">Lote #{{ lp.lote_id }}: {{ lp.lote }}</a>
                </span>
            </p>
            <br />
            <h4>Uso en Plataformas</h4>
            <table *ngIf="estadistica" class="table table-striped table-bordered tabla-uso-plataforma">
                <thead>
                    <tr>
                        <th>Plataforma</th>
                        <th>Correctas</th>
                        <th>Incorrectas</th>
                        <th>Omitidas</th>
                        <th>Total</th>
                        <th>Dificultad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let plataforma of plataformas; let i = index">
                        <td>{{ plataforma.plataforma }}</td>
                        <td>{{ estadistica[plataforma.id]?.correctas || 0 }}</td>
                        <td>{{ estadistica[plataforma.id]?.incorrectas || 0 }}</td>
                        <td>{{ estadistica[plataforma.id]?.omitidas || 0 }}</td>
                        <td class="info">{{ sum(estadistica[plataforma.id]) }}</td>
                        <td>
                            {{
                                getDificultad(
                                    estadistica[plataforma.id]?.correctas,
                                    estadistica[plataforma.id]?.incorrectas,
                                    estadistica[plataforma.id]?.omitidas
                                )
                            }}
                        </td>
                    </tr>
                    <tr class="info">
                        <td>Total</td>
                        <td>{{ total.correctas }}</td>
                        <td>{{ total.incorrectas }}</td>
                        <td>{{ total.omitidas }}</td>
                        <td>{{ sum(total) }}</td>
                    </tr>
                </tbody>
            </table>
            <h4>Historial de Cambios</h4>
            <div class="changes">
                <div class="change" *ngFor="let change of changelog">
                    <h5>{{ change["change_time"] }}</h5>
                    <div class="attribute" *ngFor="let attribute of change['changed_fields']">
                        <p>{{ attribute }} antes</p>
                        <p>{{ change["formatted_change"][attribute][0] }}</p>
                        <p>{{ attribute }} después</p>
                        <p>{{ change["formatted_change"][attribute][1] }}</p>
                    </div>
                </div>
            </div>
            <paginator [getList]="getChangelog.bind(this)" [per]="5"></paginator>
        </loading-layout>
        <pregunta-variacion-modal
            [preguntaId]="pregunta?.id"
            [preguntaOriginal]="pregunta"
            [openVariacionModal]="openVariacionModal"
        ></pregunta-variacion-modal>
    </logged-layout-contenido>
</logged-layout>
