<logged-layout>
    <logged-layout-toolnavbar>
        <preguntas-toolnavbar [displayButtons]="display_buttons_options"></preguntas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>{{ logged_layout_title | easyplaceholder: "Formulario preguntas" }}</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-md-12">
                    <form novalidate [formGroup]="form">
                        <div class="clearfix">
                            <button (click)="clear()" type="button" class="btn btn-default pull-right">
                                <fa name="undo"></fa>
                                Deshacer todos los cambios
                            </button>
                        </div>

                        <ul class="nav nav-tabs">
                            <li class="active">
                                <a href="#tab1" role="tab" data-toggle="tab" (click)="checkSession()">
                                    <span class="tab-number" [class.tab-warning]="tabWarnings[0]">1</span>
                                    <span class="tab_text">Clasificación</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    [class.disabled-tab]="!canEdit"
                                    [attr.href]="canEdit ? '#tab2' : null"
                                    [attr.role]="canEdit ? 'tab' : null"
                                    [attr.data-toggle]="canEdit ? 'tab' : null"
                                    (click)="checkSession()"
                                >
                                    <span class="tab-number" [class.tab-warning]="tabWarnings[1]">2</span>
                                    <span class="tab_text">Enunciado</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    [class.disabled-tab]="!canEdit"
                                    [attr.href]="canEdit ? '#tab3' : null"
                                    [attr.role]="canEdit ? 'tab' : null"
                                    [attr.data-toggle]="canEdit ? 'tab' : null"
                                    (click)="checkSession()"
                                >
                                    <span class="tab-number" [class.tab-warning]="tabWarnings[2]">3</span>
                                    <span class="tab_text">Alternativas</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    [class.disabled-tab]="!canEdit"
                                    [attr.href]="canEdit ? '#tab4' : null"
                                    [attr.role]="canEdit ? 'tab' : null"
                                    [attr.data-toggle]="canEdit ? 'tab' : null"
                                    (click)="checkSession()"
                                >
                                    <span class="tab-number" [class.tab-warning]="tabWarnings[3]">4</span>
                                    <span class="tab_text">Solución</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    [class.disabled-tab]="!canEdit"
                                    [attr.href]="canEdit ? '#tab5' : null"
                                    [attr.role]="canEdit ? 'tab' : null"
                                    [attr.data-toggle]="canEdit ? 'tab' : null"
                                    (click)="checkSession()"
                                >
                                    <span class="tab-number" [class.tab-warning]="tabWarnings[4]">5</span>
                                    <span class="tab_text">Detalles</span>
                                </a>
                            </li>
                            <li>
                                <a href="#tab6" role="tab" data-toggle="tab" (click)="checkSession(); customPSUcheck()">
                                    <span class="tab-number" [class.tab-warning]="tabWarnings[5]">6</span>
                                    <span class="tab_text">Vista previa</span>
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <!-- Clasificación -->
                            <div id="tab1" class="active tab-pane clearfix" #tabContent>
                                <form-input
                                    [form]="form"
                                    [params]="params['asignatura_id']"
                                    [(value)]="pregunta['asignatura_id']"
                                    [key]="'asignatura_id'"
                                    [disabled]="!canEdit"
                                ></form-input>
                                <br />
                                <h4 _ngcontent-c19="">Lista de Plataformas donde el material está habilitado</h4>
                                <div>
                                    <embed-plataformas
                                        [plataformas_seleccionadas]="plataformas_seleccionadas || []"
                                        (emit_clasificaciones)="actualizarPlataformas($event)"
                                        [disabled]="!canEdit"
                                    ></embed-plataformas>
                                </div>
                                <h3>Asignaturas Extra</h3>
                                <embed-asignaturas
                                    (onUpdateAsignaturas)="onUpdateAsignaturas($event)"
                                    [initialAsignaturaIds]="pregunta.asignatura_ids"
                                    [disabled]="!canEdit"
                                ></embed-asignaturas>
                                <br />
                                <h3>Lista de Clasificaciones</h3>
                                <div>
                                    <embed-clasificaciones
                                        [recursoEducativo]="'pregunta'"
                                        [clasificaciones_seleccionadas]="pregunta.clasificaciones"
                                        (clasificacionesChange)="actualizarClasificaciones($event)"
                                    ></embed-clasificaciones>
                                </div>
                            </div>

                            <!-- Enunciado -->
                            <div id="tab2" class="tab-pane" #tabContent>
                                <div class="clearfix button_top_margin" *ngIf="tipoContestable == 'lista desplegable'">
                                    <button type="button" class="btn btn-default pull-right" (click)="addTaxativo()">
                                        <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span>
                                        Agregar Taxativo
                                    </button>
                                </div>
                                <!-- Taxativos-->
                                <div *ngIf="waitCKeditor && pregunta['taxativos']">
                                    <div
                                        *ngFor="
                                    let taxativo of pregunta['taxativos'];
                                    let i = index
                                "
                                    >
                                        <form-input
                                            [form]="$any(taxativosForm).controls[i]"
                                            (formChange)="taxativosForm.controls[i] = $event"
                                            [params]="taxativosParams['taxativo']"
                                            [(value)]="pregunta['taxativos'][i]['taxativo']"
                                            [key]="'taxativo'"
                                        ></form-input>
                                        <div class="clearfix">
                                            <button
                                                type="button"
                                                class="btn btn-default pull-right"
                                                (click)="toLatex(pregunta.taxativos[i],'taxativo', 'taxativo_latex')"
                                                value=""
                                            >
                                                <span class="glyphicon glyphicon-retweet" aria-hidden="true"></span>
                                                Taxativo a LaTeX
                                            </button>
                                            <word-count
                                                [textToCount]="pregunta['taxativos'][i]['taxativo']"
                                            ></word-count>
                                        </div>

                                        <form-input
                                            [form]="$any(taxativosForm).controls[i]"
                                            [params]="taxativosParams['taxativo_latex']"
                                            [(value)]="pregunta['taxativos'][i]['taxativo_latex']"
                                            [key]="'taxativo_latex'"
                                        ></form-input>
                                        <form-input
                                            [form]="$any(taxativosForm).controls[i]"
                                            [params]="taxativosParams['imagen']"
                                            [(value)]="pregunta['taxativos'][i]['imagen']"
                                            [key]="'imagen'"
                                        ></form-input>
                                    </div>
                                </div>
                            </div>

                            <!-- Alternativas -->
                            <div id="tab3" class="tab-pane" #tabContent>
                                <div *ngIf="!oPregunta">
                                    <button class="btn btn-default" (click)="toggleTipoContestable('alternativas')">
                                        Selección Múltiple
                                    </button>
                                    <button
                                        class="btn btn-default"
                                        (click)="toggleTipoContestable('respuesta construida')"
                                    >
                                        Respuesta Construida
                                    </button>
                                    <button
                                        class="btn btn-default"
                                        (click)="toggleTipoContestable('lista desplegable')"
                                    >
                                        Lista Desplegable
                                    </button>
                                </div>
                                <div
                                    *ngIf="isPreguntaAlternativas && pregunta['contestables'] && pregunta['contestables'].length > 0"
                                >
                                    <h3>Orden y previsualización</h3>
                                    <div class="drag_order_container">
                                        <div class="drag_order_container_row clearfix">
                                            <div class="drag_order_container_left">
                                                <div
                                                    class="drag_order_category"
                                                    *ngFor="
                                                        let alternativa of pregunta['contestables'][0]['alternativas'];
                                                        let i = index
                                                    "
                                                >
                                                    <div class="letra-show">{{ alternativa["letra"] }}</div>
                                                    <div class="drag_order_overlay"></div>
                                                </div>
                                            </div>
                                            <div
                                                class="dragula-container drag_order_container_right"
                                                [dragula]="'alternativas-bag2'"
                                                [dragulaModel]="pregunta['contestables'][0]['alternativas']"
                                            >
                                                <div
                                                    class="alternativa_container2 dragula-draggable"
                                                    *ngFor="
                                                        let alternativa of pregunta['contestables'][0]['alternativas'];
                                                        let i = index
                                                    "
                                                >
                                                    <div class="clearfix alternativa-header2">
                                                        <div
                                                            class="alternativa-preview"
                                                            [innerHtml]="
                                                                alternativa['alternativa']
                                                                    | easyplaceholder: '- Por completar -'
                                                            "
                                                        ></div>
                                                        <!--<p class="alternativa-title pull-left">Alternativa #{{i+1}}</p>-->
                                                        <div class="dragula-handle-container">
                                                            <span
                                                                class="glyphicon glyphicon-move dragula-handle"
                                                                aria-hidden="true"
                                                            ></span>
                                                        </div>
                                                        <!--<button type="button" class="btn btn-xs btn-default edit-btn" (click)="toggleAlternativa(i, 'expanded')">
                                                                                            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                                                                                            Completar
                                                                                        </button>-->
                                                        <button
                                                            type="button"
                                                            class="btn btn-xs btn-default edit-btn"
                                                            [easyScrollTo]="'alternativa_' + i"
                                                            [easyScrollToPadding]="-80"
                                                            [easyScrollToDuration]="800"
                                                        >
                                                            <span
                                                                class="glyphicon glyphicon-pencil"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Completar
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn btn-xs btn-default remove-btn"
                                                            (click)="removeAlternativa(i, 0)"
                                                        >
                                                            Eliminar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix button_top_margin">
                                        <button
                                            type="button"
                                            class="btn btn-default pull-right"
                                            (click)="addAlternativa(0)"
                                            value=""
                                        >
                                            <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span>
                                            Agregar Alternativa
                                        </button>
                                    </div>
                                    <br />
                                    <h3>Edición de Alternativas</h3>
                                    <div *ngIf="waitCKeditor">
                                        <div
                                            *ngFor="let contestable of pregunta['contestables']; let indexContestable = index
                                            "
                                        >
                                            <div
                                                *ngFor="
                                                    let alternativa of contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                    'controls']; let i= index"
                                                id="alternativa_{{indexContestable}}_{{ i }}"
                                            >
                                                <div *ngIf="!disableAlternativas" class="alternativa_container">
                                                    <h4>Alternativa #{{ alternativa["letra"] }}</h4>
                                                    <form-input
                                                        [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                        'controls'][i]"
                                                        [params]="alternativasParams['orden']"
                                                        [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['orden']"
                                                        [key]="'orden'"
                                                    ></form-input>
                                                    <div class="letra-form clearfix">
                                                        <form-input
                                                            [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                            'controls'][i]"
                                                            [params]="alternativasParams['letra']"
                                                            [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['letra']"
                                                            [key]="'letra'"
                                                        ></form-input>
                                                    </div>
                                                    <form-input
                                                        [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                        'controls'][i]"
                                                        [params]="alternativasParams['alternativa']"
                                                        [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['alternativa']"
                                                        [key]="'alternativa'"
                                                    ></form-input>

                                                    <div class="clearfix">
                                                        <button
                                                            type="button"
                                                            class="btn btn-default pull-right"
                                                            (click)="
                                                            toLatex(pregunta.contestables[indexContestable]['alternativas'][i], 'alternativa', 'alternativa_latex')
                                                        "
                                                            value=""
                                                        >
                                                            <span
                                                                class="glyphicon glyphicon-retweet"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Alternativa a LaTeX
                                                        </button>
                                                        <word-count
                                                            [textToCount]="pregunta.contestables[indexContestable]['alternativas'][i]['alternativa']"
                                                        ></word-count>
                                                    </div>

                                                    <form-input
                                                        [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                        'controls'][i]"
                                                        [params]="alternativasParams['alternativa_latex']"
                                                        [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['alternativa_latex']"
                                                        [key]="'alternativa_latex'"
                                                    ></form-input>
                                                    <form-input
                                                        [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                        'controls'][i]"
                                                        [params]="alternativasParams['imagen']"
                                                        [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['imagen']"
                                                        [key]="'imagen'"
                                                    ></form-input>

                                                    <div #distractorInputContainer class="paired_container">
                                                        <form-input
                                                            (click)="
                                                            toggleDistractor(
                                                                i,
                                                                distractorInputContainer,
                                                                'hide_distractor'
                                                            )
                                                        "
                                                            (valueChange)="correctaChange(i, $event)"
                                                            [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                            'controls'][i]"
                                                            [params]="alternativasParams['correcta']"
                                                            [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['correcta']"
                                                            [key]="'correcta'"
                                                        ></form-input>
                                                        <form-input
                                                            [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                            'controls'][i]"
                                                            [params]="alternativasParams['ocultable']"
                                                            [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['ocultable']"
                                                            (valueChange)="ocultableChange(i, $event)"
                                                            [key]="'ocultable'"
                                                        ></form-input>
                                                        <div class="orden-ocultar">
                                                            <form-input
                                                                [class.no-mostrar]="!pregunta.contestables[indexContestable]['alternativas'][i]['ocultable']"
                                                                [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                                'controls'][i]"
                                                                [params]="alternativasParams['orden_ocultar']"
                                                                [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['orden_ocultar']"
                                                                (valueChange)="!doNotTriggerOrdenOcultarChanges && ordenOcultarChange(i, $event)"
                                                                [key]="'orden_ocultar'"
                                                            ></form-input>
                                                        </div>
                                                        <form-input
                                                            class="distractorInput"
                                                            [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                            'controls'][i]"
                                                            [params]="alternativasParams['explicacion_distractor']"
                                                            [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['explicacion_distractor']"
                                                            [key]="'explicacion_distractor'"
                                                        ></form-input>
                                                    </div>

                                                    <form-input
                                                        [(form)]="contestablesForms.controls[indexContestable]['controls']['alternativas'][
                                                        'controls'][i]"
                                                        [params]="alternativasParams['pista']"
                                                        [(value)]="pregunta.contestables[indexContestable]['alternativas'][i]['pista']"
                                                        key="pista"
                                                    ></form-input>
                                                    <div class="clearfix">
                                                        <button
                                                            type="button"
                                                            class="btn btn-default pull-right"
                                                            (click)="copiarPista(pregunta.contestables[indexContestable]['alternativas'][i]['pista'])"
                                                            value=""
                                                        >
                                                            <span
                                                                class="glyphicon glyphicon-retweet"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Copiar pista al resto
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <app-respuesta-construida
                                    *ngIf="isRespuestaConstruida"
                                    [(alternativa)]="pregunta['contestables'][0]['alternativas'][0]"
                                    (alternativaChange)="updatePreviewLatex()"
                                    [editMode]="editMode"
                                ></app-respuesta-construida>
                                <app-lista-desplegable
                                    *ngIf="isListaDesplegable"
                                    [editMode]="editMode"
                                    [pregunta]="pregunta"
                                    [contestablesForms]="contestablesForms"
                                    [alternativasParams]="alternativasParams"
                                    [contestableTipos]="contestableTipos"
                                    [nuevoContestable]="addContestable"
                                ></app-lista-desplegable>
                            </div>

                            <!-- Solución -->
                            <div id="tab4" class="tab-pane" #tabContent>
                                <form-input
                                    [form]="form"
                                    [params]="params['solucion']"
                                    [(value)]="pregunta['solucion']"
                                    [key]="'solucion'"
                                ></form-input>

                                <div class="clearfix">
                                    <button
                                        type="button"
                                        class="btn btn-default pull-right"
                                        (click)="toLatex(pregunta, 'solucion', 'solucion_latex')"
                                        value=""
                                    >
                                        <span class="glyphicon glyphicon-retweet" aria-hidden="true"></span>
                                        Solución a LaTeX
                                    </button>
                                    <word-count [textToCount]="pregunta['solucion']"></word-count>
                                </div>

                                <form-input
                                    [form]="form"
                                    [params]="params['solucion_latex']"
                                    [(value)]="pregunta['solucion_latex']"
                                    [key]="'solucion_latex'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['solucion_video']"
                                    [(value)]="pregunta['solucion_video']"
                                    [key]="'solucion_video'"
                                ></form-input>
                            </div>

                            <!-- Detalles -->
                            <div id="tab5" class="tab-pane" #tabContent>
                                <!--<form-input [form]="form" [params]="params['estado']" [(value)]="pregunta['estado']" [key]="'estado'" >
                                </form-input>-->
                                <label>Detalles:</label>
                                <form-input
                                    [form]="form"
                                    [params]="params['visible']"
                                    [(value)]="pregunta['visible']"
                                    [key]="'visible'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['presencial']"
                                    [(value)]="pregunta['presencial']"
                                    [key]="'presencial'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['comentarios']"
                                    [(value)]="pregunta['comentarios']"
                                    [key]="'comentarios'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['profesor']"
                                    [(value)]="pregunta['profesor']"
                                    [key]="'profesor'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['encargado_reporte']"
                                    [(value)]="pregunta['encargado_reporte']"
                                    [key]="'encargado_reporte'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['autor']"
                                    [(value)]="pregunta['autor']"
                                    [key]="'autor'"
                                ></form-input>
                                <form-input
                                    [form]="form"
                                    [params]="params['pregunta_ref_id']"
                                    [(value)]="pregunta['pregunta_ref_id']"
                                    [key]="'pregunta_ref_id'"
                                ></form-input>
                            </div>

                            <!-- Vista previa -->
                            <div id="tab6" class="tab-pane" #tabContent>
                                <label>Grupo pregunta</label>
                                <autocomplete
                                    id="autocompleteGrupoPregunta"
                                    (filterChange)="onInputText($event)"
                                    [(ngModel)]="grupoPreguntaSelected"
                                    [options]="gruposPreguntasFiltered"
                                    [ngModelOptions]="{ standalone: true }"
                                    showTextFun="toStringId"
                                    [disabled]="!canEdit"
                                    [autoSelect]="false"
                                ></autocomplete>

                                <h4 class="small-top-margin">Vista previa</h4>
                                <grupo-pregunta-preview
                                    *ngIf="grupoPreguntaSelected && grupoPreguntaSelected.id"
                                    [contenidoPreguntaId]="grupoPreguntaSelected.id"
                                ></grupo-pregunta-preview>
                                <pregunta-small
                                    [pregunta]="pregunta"
                                    [preguntaXL]="true"
                                    [disableExpand]="true"
                                    [disableGoToPregunta]="true"
                                    [viewSolution]="true"
                                ></pregunta-small>
                                <br />
                                <pregunta-preview-latex [paramsPregunta]="paramsPreviewLatex"></pregunta-preview-latex>
                                <br />
                                <div *ngIf="!isPSUValid" class="warning">
                                    <div class="warning-content">
                                        <div class="warning-icon"><fa [name]="'warning'"></fa></div>
                                        <div class="warning-message">
                                            <p>Las preguntas PSU deben tener 5 alternativas.</p>
                                            <p>
                                                Ajuste el número de alternativas y/o las clasificaciones de esta
                                                pregunta antes de continuar.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="enablePistaWarning" class="warning">
                                    <div class="warning-content">
                                        <div class="warning-icon"><fa [name]="'warning'"></fa></div>
                                        <div class="warning-message">
                                            <p>
                                                Usted tiene al menos una pista. Asegurese de que todas las alternativas
                                                (salvo la correcta) tengan pista.
                                            </p>
                                            <p>
                                                Puede usar el boton "Copiar Pista" en alguna alternativa para que la
                                                pista se copia al resto de alternativas si lo desea.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix">
                                    <button
                                        (click)="save()"
                                        type="button"
                                        class="btn btn-default pull-right submit-btn"
                                        [disabled]="disableSubmitBtn"
                                    >
                                        {{ save_button_text | easyplaceholder: "Guardar" }}
                                    </button>

                                    <!-- <button (click)="previewLatex()" class="btn btn-default pull-left">
                                        Previsualización Latex
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
