<logged-layout>
    <logged-layout-toolnavbar>
        <persona-toolnavbar [persona]="persona" [displayButtons]="'all new edit'"></persona-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Persona</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="persona"
                    [key]="key"
                ></model-simple-view>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
