import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { ClasificacionComponent } from "./clasificacion.component"
import { ClasificacionesComponent } from "./clasificaciones.component"
import { ClasificacionesDeleteComponent } from "./clasificaciones.delete.component"
import { ClasificacionesEditComponent } from "./clasificaciones.edit.component"
import { ClasificacionesNewComponent } from "./clasificaciones.new.component"
import { EmbedClasificacionesComponent } from "./embed_clasificaciones.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const clasificacionesRoutes: Routes = [
    {
        path: "clasificaciones",
        component: ClasificacionesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Clasificaciones#index" }
    },
    {
        path: "clasificaciones/new",
        component: ClasificacionesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Clasificaciones#create" }
    },
    // { path: 'clasificaciones/testing', component: EmbedClasificacionesComponent, canActivate: [AuthGuard, PermisosGuard], data: {permiso: 'Clasificaciones#index'} },
    {
        path: "clasificaciones/:id",
        component: ClasificacionComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Clasificaciones#show" }
    },
    {
        path: "clasificaciones/:id/edit",
        component: ClasificacionesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Clasificaciones#update" }
    },
    {
        path: "clasificaciones/:id/delete",
        component: ClasificacionesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Clasificaciones#destroy" }
    }
]

export const clasificacionesRouting = RouterModule.forChild(clasificacionesRoutes)
