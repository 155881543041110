import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import {
    ClasificacionTipoAlias,
    ClasificacionTipoAliasForm,
    ClasificacionTipoAliases,
    Plataforma,
    AsignaturaPlataforma,
    AsignaturaPlataformas
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion_tipo_aliases.form.component.html"
})
export class ClasificacionTipoAliasesEditComponent implements OnInit, OnDestroy {
    oClasificacionTipoAlias: ClasificacionTipoAlias = new ClasificacionTipoAlias()
    clasificacionTipoAlias: ClasificacionTipoAlias = new ClasificacionTipoAlias()
    params = ClasificacionTipoAliasForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    displayTipoEdit: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar alias de tipo de clasificación"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    plataformas: Plataforma[]
    plataformaId: number
    asignaturaPlataformas: AsignaturaPlataforma[]
    asignaturaPlataformaId: number
    clasificacionTipoId: number

    constructor(
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        protected asignaturaPlataformasService: AsignaturaPlataformas
    ) {}

    ngOnInit() {
        this.form = ClasificacionTipoAliasForm.getForm(this.clasificacionTipoAlias)
        this.sub = this.route.params.subscribe(params => {
            this.clasificacionTipoAliasesService.find(+params["id"]).then((response: ClasificacionTipoAlias) => {
                this.clasificacionTipoAlias = response
                this.oClasificacionTipoAlias = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        ClasificacionTipoAliasForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.clasificacionTipoAliasesService
                .update(this.clasificacionTipoAlias.id, this.clasificacionTipoAlias)
                .then(response => {
                    this.router.navigate(["clasificacion_tipo_aliases"])
                })
        }
    }

    plataformaSelected(plataformaId) {
        this.asignaturaPlataformas = []
        this.plataformaId = plataformaId
        this.asignaturaPlataformasService
            .where({ include: "asignatura", asignatura_plataforma: { plataforma_id: plataformaId } })
            .then(asignaturaPlataformas => {
                this.asignaturaPlataformas = asignaturaPlataformas

                if (this.asignaturaPlataformas.length > 0) {
                    this.asignaturaPlataformaId = this.asignaturaPlataformas[0].id
                }
            })
    }

    asignaturaPlataformaSelected(apId) {
        this.asignaturaPlataformaId = apId
    }

    clear() {
        setTimeout(() => {
            this.clasificacionTipoAlias = this.oClasificacionTipoAlias.clone()
            ClasificacionTipoAliasForm.markFormControlsAsPristine(this.form)
            ClasificacionTipoAliasForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
