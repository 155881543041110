import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ClasificacionTipo } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"
import { ClasificacionTiposBuscadorService } from "./clasificacion-tipos-buscador.service"

@Component({
    templateUrl: "clasificacion_tipos.toolnavbar.component.html",
    selector: "clasificacion-tipo-toolnavbar"
})
export class ClasificacionTiposToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() clasificacionTipo: ClasificacionTipo
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected clasificacionTiposBuscadorService: ClasificacionTiposBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/clasificacion_tipos"
        this.urlNew = "/clasificacion_tipos/new"
        if (this.clasificacionTipo != null) {
            this.urlEdit = "/clasificacion_tipos/" + this.clasificacionTipo.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    edit() {
        if (this.clasificacionTipo != null) {
            this.urlEdit = "/clasificacion_tipos/" + this.clasificacionTipo.id + "/edit"
        }
        super.edit()
    }

    showAliases() {
        this.router.navigate(["clasificacion_tipo_aliases"])
    }

    search() {
        this.clasificacionTiposBuscadorService.openSearch.next()
    }
}
