import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Reporte, Reportes, ReporteView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "reporte.component.html"
})
export class ReporteComponent implements OnInit, OnDestroy {
    reporte: Reporte
    show_params = ReporteView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private reportesService: Reportes, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.reportesService.find(+params["id"]).then((response: Reporte) => {
                this.reporte = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    resolve(reporte: Reporte) {
        reporte.estado_reporte_id = 2 //Resuelto
        this.reportesService.update(reporte.id, reporte).then((response: Reporte) => {
            this.router.navigate(["reportes/resueltos"])
        })
    }
}
