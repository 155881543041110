<table class="table table-bordered">
    <thead>
        <tr>
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <th *ngIf="show_params[key].tableVisible">{{ show_params[key].label }}</th>
            </ng-template>
            <th>Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let tipoInstrumentoPlataforma of tipoInstrumentoPlataformas; let i = index; trackBy: id">
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <td *ngIf="show_params[key].tableVisible">
                    <model-table-cell
                        [params]="show_params[key]"
                        [value]="tipoInstrumentoPlataforma"
                        [key]="key"
                    ></model-table-cell>
                </td>
            </ng-template>
            <td>
                <!-- ??? -->
                <table-with-actions-buttons
                    [tableButtonsArray]="tableButtonsArray"
                    [functionParameter]="tipoInstrumentoPlataforma"
                ></table-with-actions-buttons>
            </td>
        </tr>
    </tbody>
</table>
