<nav class="navbar navbar-default" *ngIf="displayNavbar">
    <ng-container *permiso="permisoAll">
        <a *ngIf="displayBtnAll" [routerLink]="urlAll" class="btn btn-sm btn-default pull-left">
            <span class="glyphicon glyphicon-list" aria-hidden="true"></span>
            {{ resourceName | pluralize | capitalize }}
        </a>
    </ng-container>
    <ng-container *permiso="permisoNew">
        <a *ngIf="displayBtnNew" [routerLink]="urlNew" class="btn btn-sm btn-default pull-right">
            <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span>
            Agregar {{ resourceName }}
        </a>
    </ng-container>
    <ng-container *ngIf="resource">
        <ng-template [permiso]="permisoEdit" [resource]="resource">
            <a *ngIf="displayBtnEdit" [routerLink]="urlEdit" class="btn btn-sm btn-default pull-right">
                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                Editar {{ resourceName }}
            </a>
        </ng-template>
    </ng-container>
    <ng-content></ng-content>
</nav>
