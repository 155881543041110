<!-- @format -->
<div>
    <div>
        <h3>Lista Desplegable</h3>
        <div class="preview">
            Presiona
            <strong>Agregar Lista Desplegable</strong>
            para empezar a construir tu lista desolegable
            <button type="button" class="btn btn-default pull-right" (click)="addNewContestable()">
                <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span>
                Agregar Lista Desplegable
            </button>
        </div>
        <br />
    </div>
    <div class="form-group" *ngIf="pregunta['contestables'] && pregunta['contestables'].length > 0">
        <div *ngIf="load">
            <h3>Edición de Alternativas</h3>
            <div *ngFor="let contestable of pregunta['contestables']; let j = index">
                <div>
                    <h4>Lista desplegable {{ j + 1 }}</h4>
                    <button type="button" class="btn btn-default pull-right" (click)="addAlternativa(j)">
                        <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span>
                        Agregar Alternativa
                    </button>
                    <br />
                    <br />
                    <div
                        *ngIf="contestablesForms.controls[j]['controls']['alternativas'].length == 10"
                        [class.alert-warning]="contestablesForms.controls[j]['controls']['alternativas'].length == 10"
                    >
                        El maximo de alternativas es 10
                    </div>
                    <div
                        *ngFor="
                            let alternativa of contestablesForms.controls[j]['controls']['alternativas']['controls'];
                            let i = index
                        "
                        id="alternativa_{{ j }}_{{ i }}"
                    >
                        <div class="alternativa_container">
                            <h4>Alternativa {{ i + 1 }}</h4>
                            <form-input
                                [(form)]="contestablesForms.controls[j]['controls']['alternativas']['controls'][i]"
                                [params]="alternativasParams['orden']"
                                [(value)]="pregunta.contestables[j]['alternativas'][i]['orden']"
                                [key]="'orden'"
                            ></form-input>
                            <input
                                class="form-control"
                                [(ngModel)]="pregunta.contestables[j]['alternativas'][i]['alternativa']"
                            />
                            <form-input
                                [(form)]="contestablesForms.controls[j]['controls']['alternativas']['controls'][i]"
                                [params]="alternativasParams['correcta']"
                                [(value)]="pregunta.contestables[j]['alternativas'][i]['correcta']"
                                [key]="'correcta'"
                            ></form-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
