import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { ClasificacionClasificacionForm } from "./clasificacion_clasificaciones.form"
import { ClasificacionTipos } from "./clasificacion_tipos.service"

export class ClasificacionForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            clasificacion: {
                label: "Clasificación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(5)]
            },
            descripcion: { label: "Descripción", type: "ckeditor", visible: true },
            clasificacion_tipo_id: {
                label: "Tipo",
                type: "select",
                visible: true,
                options: { class: ClasificacionTipos }
            },
            asignatura_id: { label: "Asignatura", type: "select", visible: true, options: { class: Asignaturas } },
            nota_ia: { label: "Nota IA", type: "textarea", visible: true },
            //clasificacion_padre_id: {label: "Padre", type: "select", visible: true, options: {class: Clasificaciones}},
            clasificacion_clasificaciones: { type: "models", class: ClasificacionClasificacionForm, visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            medible: { label: "Medible", type: "checkbox", visible: true },
            priorizado: { label: "Priorización curricular", type: "checkbox", visible: true }
        }
    }
}
