import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { TipoInstrumentos } from "./tipo_instrumentos.service"
import { Plataformas } from "./plataformas.service"
import { AsignaturaPlataformas } from "./asignatura_plataformas.service"

export class GeneradorInstrumentoForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            generador_instrumento: {
                label: "Nombre",
                type: "text",
                validations: [Validators.required]
            },
            valido: {
                label: "Válido",
                type: "checkbox"
            },
            tipo_instrumento_id: {
                label: "Tipo Instrumento",
                type: "select",
                visible: true,
                options: { class: TipoInstrumentos },
                validations: [Validators.required]
            },
            asignatura_plataforma_id: {
                label: "Asignatura Plataforma",
                type: "select",
                options: { class: AsignaturaPlataformas }
            },
            numero_preguntas: { label: "N° preguntas", type: "number" },
            tiempo: { label: "Tiempo", type: "number" },
            orden: { label: "Orden", type: "number" },
            alias: { label: "Alias", type: "text" },
            grupal: { label: "Grupal", type: "checkbox" },
            numero_preguntas_piloto: { label: "N° preguntas piloto", type: "number" },
            maximo_alternativas: { label: "N° máximo de alternativas visibles por pregunta", type: "number" },
            multiple: { label: "Múltiple", type: "checkbox" },
            visible: { label: "Visible", type: "checkbox" },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
