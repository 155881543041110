import { Injectable } from "@angular/core"
import { PermisosAsociadosService } from "./permisos-asociados.service"
import { Opts } from "./base-policy.service"
import { PlanEstudios } from "@puntaje/nebulosa/api-services"

@Injectable({
    providedIn: "root"
})
export class PlanEstudiosPolicyService extends PermisosAsociadosService {
    resourceName: string = "PlanEstudios"

    update({ resource, resourceId, ...rest }: Opts): boolean | Promise<boolean> {
        if (resource && (resource as any).certificacion_recurso && !this.testPermiso("PlanEstudios_update_certificado"))
            return false
        if (resourceId) {
            const params = {
                plan_estudio: {
                    id: resourceId
                },
                include: "[certificacion_recurso]"
            }
            return this.injector
                .get(PlanEstudios)
                .where(params)
                .then(planEstudios => {
                    if (planEstudios.length == 0) return false
                    let p = planEstudios[0]
                    return !(p.certificacion_recurso && !this.testPermiso("PlanEstudios_update_certificado"))
                })
        }
        return (
            super.update({ resource, resourceId, ...rest }) ||
            this.permisoAsociado("update", { resource, resourceId, ...rest })
        )
    }
}
