import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PiedraRosettaPreguntasComponent } from "./piedra_rosetta.preguntas.component"

export const piedraRosettaRoutes: Routes = [
    { path: "piedra_rosetta", component: PiedraRosettaPreguntasComponent, canActivate: [AuthGuard, AdminGuard] }
]

export const piedraRosettaRouting = RouterModule.forChild(piedraRosettaRoutes)
