<generic-modal [buttonClicked]="openVariacionModal" [modalLarge]="true" [id]="'variacion-modal'">
    <modal-titulo>
        <div class="modal_title">
            <div class="modal_title_inner">
                <div class="modal_title_icon">
                    <div class="icon-container">
                        <ogr-icon name="cog" class="icon"></ogr-icon>
                    </div>
                </div>
                <div class="modal_title_text">Variaciones pregunta #{{ preguntaId }}</div>
            </div>
        </div>
    </modal-titulo>
    <modal-contenido class="modal-contenido">
        <div class="modal-contenido wrap">
            <div class="modal-contenido_inner margin-bottom">
                <div class="form-input">
                    <label>Prompt</label>
                    <select class="form-control" [(ngModel)]="promptId">
                        <ng-container *ngFor="let generadorInstrumento of groupedPrompts | keys">
                            <optgroup label="{{ generadorInstrumento }}">
                                <option *ngFor="let prompt of groupedPrompts[generadorInstrumento]" [value]="prompt.id">
                                    {{ prompt.descripcion }}
                                </option>
                            </optgroup>
                        </ng-container>
                    </select>
                </div>
                <div class="form-input">
                    <label>Cantidad de variaciones</label>
                    <input type="number" class="form-control" [(ngModel)]="cantidadVariaciones" />
                </div>
                <div class="form-input">
                    <label>Notas (opcional)</label>
                    <textarea class="form-control" [(ngModel)]="notas"></textarea>
                </div>
                <div *ngIf="preguntaOriginal?.grupo_pregunta?.texto" class="form-input">
                    <label>Grupo de pregunta ID</label>
                    <input type="number" class="form-control" [(ngModel)]="grupoPreguntaId" />
                </div>
                <button
                    [disabled]="generandoVariaciones"
                    class="btn btn-default margin-top"
                    (click)="generarVariaciones()"
                >
                    <span
                        *ngIf="generandoVariaciones"
                        class="glyphicon glyphicon-refresh glyphicon-refresh-animate"
                    ></span>
                    {{ generandoVariaciones ? "Generando" : variaciones ? "Generar más variaciones" : "Generar" }}
                </button>
            </div>

            <ng-container *ngIf="!generandoVariaciones && variaciones && variaciones.length > 0">
                <div class="row margin-top">
                    <div class="col-md-5 scrolleable">
                        <grupo-pregunta-preview
                            *ngIf="preguntaOriginal.grupo_pregunta?.texto"
                            [grupoPregunta]="preguntaOriginal.grupo_pregunta"
                        ></grupo-pregunta-preview>
                    </div>
                    <div
                        class="scrolleable"
                        [ngClass]="{
                            'col-md-12': !preguntaOriginal.grupo_pregunta?.texto,
                            'col-md-7': preguntaOriginal.grupo_pregunta?.texto
                        }"
                    >
                        <div class="pregunta-box margin-bottom">
                            <a [routerLink]="['/preguntas', preguntaOriginal.id]" class="titulo">
                                Pregunta original #{{ preguntaOriginal.id }}
                            </a>

                            <pregunta-small [pregunta]="preguntaOriginal"></pregunta-small>
                        </div>
                        <ng-container *ngFor="let variacion of variaciones; let i = index">
                            <div class="pregunta-box">
                                <div class="variacion-header">
                                    <a [routerLink]="['/preguntas', variacion.id]" class="titulo">
                                        Pregunta #{{ variacion.id }}
                                    </a>
                                    <button
                                        [disabled]="eliminandoVariacion"
                                        class="btn btn-default pull-right"
                                        (click)="confirmarEliminarVariacion(i)"
                                    >
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </button>
                                </div>
                                <pregunta-small [pregunta]="variacion"></pregunta-small>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </modal-contenido>
</generic-modal>
