import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Perfil, Perfiles, PerfilView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "perfil.component.html"
})
export class PerfilComponent implements OnInit, OnDestroy {
    perfil: Perfil
    show_params = PerfilView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private perfilesService: Perfiles, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.perfilesService.find(+params["id"]).then((response: Perfil) => {
                this.perfil = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
