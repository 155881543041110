import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Personas } from "./personas.service"
import { Usuarios } from "./usuarios.service"

export class UsuarioView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Usuarios, key: "id" } },
            nombre_completo: {
                label: "Persona",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "persona_id" }
            },
            email: { label: "Email", type: TextView, tableVisible: true },
            personasAsociadas: { label: "Sub Administradores", type: TextView, tableVisible: false },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true },
            fecha_expiracion: { label: "Fecha de expiración", type: DateView, tableVisible: true },
            estado: { label: "Estado", type: TextView, tableVisible: true }
        }
    }
}
