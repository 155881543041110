import {
    GeneradorInstrumento,
    TipoInstrumento,
    Clasificacion,
    GeneradorInstrumentos,
    TipoInstrumentos,
    Clasificaciones,
    GeneradorInstrumentoClasificacion,
    GeneradorInstrumentoFiltroClasificacion,
    GeneradorInstrumentoFiltroClasificacionTipo,
    GeneradorInstrumentoCategoria,
    Asignatura,
    Asignaturas,
    Plataforma,
    AsignaturaPlataforma,
    Plataformas,
    AsignaturaPlataformas,
    GeneradorInstrumentoInstruccion
} from "@puntaje/nebulosa/api-services"
import { GeneradorInstrumentoForm } from "@puntaje/nebulosa/api-services"
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from "@angular/forms"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ViewChild, Input, Directive } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"

@Directive()
export class GeneradorInstrumentoBaseForm {
    generador: GeneradorInstrumento
    params = GeneradorInstrumentoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar generador instrumento"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    save_button_text = "Guardar"
    clear_button_text = "Borrar"

    tipoInstrumentos: TipoInstrumento[]
    menciones: Clasificacion[]
    suficiencias: Clasificacion[]

    extrasForm: UntypedFormGroup

    plataformas: Plataforma[]
    plataformaId: number
    asignaturaPlataformas: AsignaturaPlataforma[]
    asignaturaPlataformaId: number

    ready = false

    @Input() withExtrasForm = true

    constructor(
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected tipoInstrumentosService: TipoInstrumentos,
        protected clasificacionesService: Clasificaciones,
        protected plataformasService: Plataformas,
        protected asignaturaPlataformasService: AsignaturaPlataformas,
        protected router: Router,
        protected fb: UntypedFormBuilder,
        protected route: ActivatedRoute
    ) {}

    setUpForm(generador: GeneradorInstrumento) {
        Promise.all([
            this.plataformasService.where(),
            this.tipoInstrumentosService.where(),
            this.clasificacionesService.where({ clasificacion_tipo: { clasificacion_tipo: "mención" } }),
            this.clasificacionesService.where({ clasificacion_tipo: { clasificacion_tipo: "suficiencia" } })
        ]).then(([plataformas, tipoInstrumentos, menciones, suficiencias]) => {
            this.plataformas = plataformas
            this.tipoInstrumentos = tipoInstrumentos
            this.menciones = menciones
            this.suficiencias = suficiencias

            this.generador = generador
            this.form = GeneradorInstrumentoForm.getForm(generador)

            generador.generador_instrumento_clasificaciones = generador.generador_instrumento_clasificaciones || []
            generador.generador_instrumento_filtro_clasificaciones =
                generador.generador_instrumento_filtro_clasificaciones || []
            generador.generador_instrumento_filtro_clasificacion_tipos =
                generador.generador_instrumento_filtro_clasificacion_tipos || []
            generador.generador_instrumento_categorias = generador.generador_instrumento_categorias || []
            generador.generador_instrumento_instrucciones = generador.generador_instrumento_instrucciones || []

            this.extrasForm = this.fb.group({
                clasificaciones: this.fb.array(
                    generador.generador_instrumento_clasificaciones
                        ? generador.generador_instrumento_clasificaciones.map(c => this.fb.group(c))
                        : []
                ),
                clasificacionFiltros: this.fb.array(
                    generador.generador_instrumento_filtro_clasificaciones
                        ? generador.generador_instrumento_filtro_clasificaciones.map(c => this.fb.group(c))
                        : []
                ),
                clasificacionTipoFiltros: this.fb.array(
                    generador.generador_instrumento_filtro_clasificacion_tipos
                        ? generador.generador_instrumento_filtro_clasificacion_tipos.map(c => this.fb.group(c))
                        : []
                ),
                categorias: this.fb.array(
                    generador.generador_instrumento_categorias
                        ? generador.generador_instrumento_categorias.map(c => this.fb.group(c))
                        : []
                ),
                instrucciones: this.fb.array(
                    generador.generador_instrumento_instrucciones
                        ? generador.generador_instrumento_instrucciones.map(c => this.fb.group(c))
                        : []
                )
            })

            if (generador.asignatura_plataforma_id) {
                this.plataformaId = generador.asignatura_plataforma.plataforma_id
                this.asignaturaPlataformasService
                    .where({ include: "asignatura", asignatura_plataforma: { plataforma_id: this.plataformaId } })
                    .then(asignaturaPlataformas => {
                        this.asignaturaPlataformas = asignaturaPlataformas
                        this.asignaturaPlataformaId = generador.asignatura_plataforma_id
                        this.loadingLayout.ready()
                        this.ready = true
                    })
            } else {
                this.loadingLayout.ready()
                this.ready = true
            }
        })
    }

    update_gi_categorias() {
        let generador_instrumento_categorias = this.categoriaForms.value.map(c => {
            let gic = new GeneradorInstrumentoCategoria()
            gic.categoria_id = c.categoria_id
            gic.id = c.id
            return gic
        })
        generador_instrumento_categorias = generador_instrumento_categorias.concat(
            this.generador.generador_instrumento_categorias
                .filter(gic => !this.categoriaForms.value.some(c => c.id == gic.id))
                .map(gic => {
                    let gicNew = gic.clone()
                    delete gicNew.generador_instrumento_id
                    ;(gicNew as any)._destroy = 1
                    return gicNew
                })
        )
        this.generador.generador_instrumento_categorias = generador_instrumento_categorias
    }

    update_gi_filtro_clasificaciones() {
        let generador_instrumento_filtro_clasificaciones = this.clasificacionFiltroForms.value.map(c => {
            let gifc = new GeneradorInstrumentoFiltroClasificacion()
            gifc.clasificacion_id = c.clasificacion_id
            gifc.id = c.id
            return gifc
        })
        generador_instrumento_filtro_clasificaciones = generador_instrumento_filtro_clasificaciones.concat(
            this.generador.generador_instrumento_filtro_clasificaciones
                .filter(gifc => !this.clasificacionFiltroForms.value.some(c => c.id == gifc.id))
                .map(gifc => {
                    let gifcNew = gifc.clone()
                    ;(gifcNew as any)._destroy = 1
                    return gifcNew
                })
        )
        this.generador.generador_instrumento_filtro_clasificaciones = generador_instrumento_filtro_clasificaciones
    }

    update_gi_filtro_clasificacion_tipos() {
        let generador_instrumento_filtro_clasificacion_tipos = this.clasificacionTipoFiltroForms.value.map(c => {
            let gifct = new GeneradorInstrumentoFiltroClasificacionTipo()
            gifct.clasificacion_tipo_id = c.clasificacion_tipo_id
            gifct.clasificacion_tipo_hija_id = c.clasificacion_tipo_hija_id
            gifct.id = c.id
            return gifct
        })
        generador_instrumento_filtro_clasificacion_tipos = generador_instrumento_filtro_clasificacion_tipos.concat(
            this.generador.generador_instrumento_filtro_clasificacion_tipos
                .filter(gifct => !this.clasificacionTipoFiltroForms.value.some(c => c.id == gifct.id))
                .map(gifct => {
                    let gifctNew = gifct.clone()
                    ;(gifctNew as any)._destroy = 1
                    return gifctNew
                })
        )
        this.generador.generador_instrumento_filtro_clasificacion_tipos =
            generador_instrumento_filtro_clasificacion_tipos
    }

    save() {
        GeneradorInstrumentoForm.markFormControlsAsTouched(this.form)
        const subForms = this.extrasForm.controls
        Object.keys(subForms).forEach(key => {
            subForms[key].markAsTouched()
        })

        if (this.form.valid && this.extrasForm.valid && this.asignaturaPlataformaId) {
            this.generador.asignatura_plataforma_id = this.asignaturaPlataformaId

            // update generador instrumento clasificaciones
            let generador_instrumento_clasificaciones = this.clasificacionForms.value.map(c => {
                let gic = new GeneradorInstrumentoClasificacion()
                gic.clasificacion_id = c.clasificacion_id
                gic.porcentaje = c.porcentaje
                gic.orden = c.orden
                gic.id = c.id
                gic.clasificacion_mencion_id = c.clasificacion_mencion_id
                gic.clasificacion_suficiencia_id = c.clasificacion_suficiencia_id
                return gic
            })
            generador_instrumento_clasificaciones = generador_instrumento_clasificaciones.concat(
                this.generador.generador_instrumento_clasificaciones
                    .filter(gic => !this.clasificacionForms.value.some(c => c.id == gic.id))
                    .map(gic => {
                        let gicNew = gic.clone()
                        delete gicNew.generador_instrumento_id
                        ;(gicNew as any)._destroy = 1
                        return gicNew
                    })
            )
            this.generador.generador_instrumento_clasificaciones = generador_instrumento_clasificaciones

            // update generador instrumento filtro clasificaciones
            this.update_gi_filtro_clasificaciones()

            // update generador instrumento categorias
            this.update_gi_categorias()

            // update generador instrumento filtro clasificacioin tipos
            this.update_gi_filtro_clasificacion_tipos()

            // update generador instrumento instrucciones ------------------------------------------------------------
            let generador_instrumento_instrucciones = this.instruccionForms.value.map(c => {
                let gii = new GeneradorInstrumentoInstruccion()
                gii.orden = c.orden
                gii.clasificacion_id = c.clasificacion_id
                gii.instruccion_id = c.instruccion_id
                gii.id = c.id
                return gii
            })
            generador_instrumento_instrucciones = generador_instrumento_instrucciones.concat(
                this.generador.generador_instrumento_instrucciones
                    .filter(gii => !this.instruccionForms.value.some(c => c.id == gii.id))
                    .map(gii => {
                        let giiNew = gii.clone()
                        delete giiNew.generador_instrumento_id
                        ;(giiNew as any)._destroy = 1
                        return giiNew
                    })
            )
            this.generador.generador_instrumento_instrucciones = generador_instrumento_instrucciones

            this.generadorInstrumentosService.disableIgnoreModel()
            this.apiPromise(this.generador).then(response => {
                this.generadorInstrumentosService.enableIgnoreModel()
                this.router.navigate(["generador_instrumentos", response.id])
            })
        }
    }

    apiPromise(generador: GeneradorInstrumento): Promise<GeneradorInstrumento> {
        return null
    }

    back() {}

    get clasificacionForms() {
        return this.extrasForm.get("clasificaciones") as UntypedFormArray
    }

    agregarClasificacion() {
        const clasificacion = this.fb.group({
            id: [""],
            porcentaje: [0.0],
            orden: [0],
            clasificacion_id: [null, Validators.required],
            clasificacion_mencion_id: [null],
            clasificacion_suficiencia_id: [null]
        })
        this.clasificacionForms.push(clasificacion)
    }

    removerClasificacion(index: number) {
        this.clasificacionForms.removeAt(index)
    }

    //generador instrumento filtro clasificaciones

    get clasificacionFiltroForms() {
        return this.extrasForm.get("clasificacionFiltros") as UntypedFormArray
    }

    agregarClasificacionFiltro() {
        const clasificacionFiltro = this.fb.group({
            id: "",
            clasificacion_id: [null, Validators.required]
        })
        this.clasificacionFiltroForms.push(clasificacionFiltro)
    }

    removerClasificacionFiltro(index: number) {
        this.clasificacionFiltroForms.removeAt(index)
    }

    //generador instrumento filtro clasificacion tipos

    get clasificacionTipoFiltroForms() {
        return this.extrasForm.get("clasificacionTipoFiltros") as UntypedFormArray
    }

    agregarRelacionClasificacionTipoFiltro() {
        const clasificacionTipoFiltro = this.fb.group({
            id: "",
            clasificacion_tipo_id: [null, Validators.required],
            clasificacion_tipo_hija_id: [null]
        })
        this.clasificacionTipoFiltroForms.push(clasificacionTipoFiltro)
    }

    removerClasificacionTipoFiltro(index: number) {
        this.clasificacionTipoFiltroForms.removeAt(index)
    }

    //generador instrumento categorias

    get categoriaForms() {
        return this.extrasForm.get("categorias") as UntypedFormArray
    }

    agregarCategoria() {
        const categoria = this.fb.group({
            id: "",
            categoria_id: [null, Validators.required]
        })
        this.categoriaForms.push(categoria)
    }

    removerCategoria(index: number) {
        this.categoriaForms.removeAt(index)
    }

    // generador instrumento instrucciones

    get instruccionForms() {
        return this.extrasForm.get("instrucciones") as UntypedFormArray
    }

    agregarInstruccion() {
        const instruccion = this.fb.group({
            id: "",
            instruccion_id: [null, Validators.required],
            clasificacion_id: [null, Validators.required],
            orden: 0
        })
        this.instruccionForms.push(instruccion)
    }

    removerInstruccion(index: number) {
        this.instruccionForms.removeAt(index)
    }

    // asignatura plataforma

    plataformaSelected(plataformaId) {
        this.asignaturaPlataformas = []
        this.plataformaId = plataformaId
        this.asignaturaPlataformasService
            .where({ include: "asignatura", asignatura_plataforma: { plataforma_id: plataformaId } })
            .then(asignaturaPlataformas => {
                this.asignaturaPlataformas = asignaturaPlataformas

                if (this.asignaturaPlataformas.length > 0) {
                    this.asignaturaPlataformaId = this.asignaturaPlataformas[0].id
                }
            })
    }

    asignaturaPlataformaSelected(apId) {
        this.asignaturaPlataformaId = apId
    }
}
