import { Configuration, CSSPropertyConfigItem } from "./Configuration"

import { java, javaemul } from "j4ts"

import Iterator = java.util.Iterator

import Map = java.util.Map

import HashMap = java.util.HashMap

/**
 * Class representing CSS style definition in program configuration.
 */
export class CSSStyle {
    /**
     * Style name.
     */
    private _name: string

    /**
     * Mapping between the style and LaTeX (start command).
     */
    private _start: string = ""

    /**
     * Mapping between the style and LaTeX (end command).
     */
    private _end: string = ""

    /**
     * Style properties.
     */
    private _properties: HashMap<string, string>

    /**
     * Cstr.
     * @param name style name
     */
    public constructor(name: string) {
        this._properties = new HashMap<string, string>(5)
        this._name = name
    }

    /**
     * Sets the style properties.
     * @param prop style properties
     */
    public setProperties(prop: HashMap<string, string>) {
        this._properties.putAll(prop)
    }

    /**
     * Sets {@link CSSStyle#_start start} and {@link CSSStyle#_end end}
     * commands for the style on the basis of style properties and
     * program configuration.
     * @param conf program configuration
     */
    public makeLaTeXCommands(conf: Configuration) {
        this.colorProperty(conf)
        this.fontFamilyProperty(conf)
        for (var iterator: Iterator<any> = this._properties.entrySet().iterator(); iterator.hasNext(); ) {
            var entry: Map.Entry<any, any> = <Map.Entry<any, any>>iterator.next()
            var property: string = <string>entry.getKey()
            var value: string = <string>entry.getValue()
            if (property === "font-family") continue
            try {
                var item: CSSPropertyConfigItem = conf.getPropertyConf(property + "-" + value)
                this._start += item.getStart()
                this._end = item.getEnd() + this._end
            } catch (e) {}
        }
    }

    /**
     * Converts &quot;color&quot; property using &quot;xcolor&quot; LaTeX package.
     * HTML notation (#xxx or #xxxxxx where &quot;x&quot; is a hexa number)
     * and rgb notation (rgb(20,180,60) or rgb(20%, 80%, 15%) are
     * supported. Also the 17 named colours defined defined in the CSS specification
     * are correctly converted.
     * @param conf program configuration
     */
    private colorProperty(conf: Configuration) {
        var color: string = this._properties.get("color")
        if (color == null) return
        if (
            /* startsWith */ ((str, searchString, position = 0) =>
                str.substr(position, searchString.length) === searchString)(color, "#")
        ) {
            color = color.replace(/#/g, "")
            if (color.length === 3) {
                var buf: java.lang.StringBuffer = new java.lang.StringBuffer(color)
                buf.insert(1, color.charAt(0))
                buf.insert(3, color.charAt(1))
                buf.insert(5, color.charAt(2))
                color = buf.toString()
            }
            this._start += "{\\color[HTML]{" + color + "}"
            this._end = "}" + this._end
        } else if (
            /* startsWith */ ((str, searchString, position = 0) =>
                str.substr(position, searchString.length) === searchString)(color, "rgb(") &&
            color.endsWith(")")
        ) {
            color = color.substring(4, color.length - 1)
            var numsStr: string[] = color.split(",")
            var nums: number[] = new Array(3)
            if (numsStr.length !== 3) return
            try {
                for (var i: number = 0; i < numsStr.length; ++i) {
                    if (numsStr[i].trim().endsWith("%")) {
                        numsStr[i] = numsStr[i].replace(/%/g, "").trim()
                        nums[i] = javaemul.internal.FloatHelper.valueOf(numsStr[i]) / 100
                    } else nums[i] = javaemul.internal.FloatHelper.valueOf(numsStr[i]) / 255
                }
            } catch (e) {
                console.info("Wrong color definition in style: " + this._name)
                return
            }
            var format: Intl.NumberFormat = new Intl.NumberFormat("en-US", { maximumFractionDigits: 3 })
            this._start +=
                "{\\color[rgb]{" +
                format.format(nums[0]) +
                "," +
                format.format(nums[1]) +
                "," +
                format.format(nums[2]) +
                "}"
            this._end = "}" + this._end
        }
    }

    /**
     * Converts &quot;font-family&quot; property.
     * Tries to find first generic font family (ie. monospace)
     * used in the definition and converts it using the configuration.
     * @param conf program configuration
     */
    public fontFamilyProperty(conf: Configuration) {
        var family: string = this._properties.get("font-family")
        if (family == null) return
        var fonts: string[] = family.split(",")
        for (var i: number = 0; i < fonts.length; ++i) {
            try {
                var item: CSSPropertyConfigItem = conf.getPropertyConf("font-family" + "-" + fonts[i].trim())
                this._start += item.getStart()
                this._end = item.getEnd() + this._end
                break
            } catch (e) {}
        }
    }

    /**
     * Returns name of the file with configuration.
     * @return mapping between the style and LaTeX (start command)
     */
    public getStart(): string {
        return this._start
    }

    /**
     * Returns mapping between the style and LaTeX (end command).
     * @return mapping between the style and LaTeX (end command)
     */
    public getEnd(): string {
        return this._end
    }

    /**
     * Returns style name.
     * @return style name
     */
    public getName(): string {
        return this._name
    }

    /**
     * Returns style properties.
     * @return style properties
     */
    public getProperties(): HashMap<string, string> {
        return this._properties
    }
}
