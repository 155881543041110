<div class="showcasebox">
    <h3>Búsqueda rápida</h3>
    <div class="search-container">
        <fa name="question-circle-o" class="type-icon"></fa>
        <input
            type="text"
            name="targetPregunta"
            [(ngModel)]="targetPregunta"
            class="form-control"
            placeholder="Pregunta ID"
            (keyup.enter)="searchPregunta()"
        />
        <button type="button" class="btn btn-default btn-search" (click)="searchPregunta()">
            <fa name="search"></fa>
        </button>
        <button type="button" class="btn-clear" (click)="clearPregunta()" *ngIf="targetPregunta">
            <fa name="times"></fa>
        </button>
        <button type="button" class="btn btn-default btn-edit" (click)="editPregunta()">
            Editar
            <fa name="pencil"></fa>
        </button>
    </div>
    <div class="search-container">
        <ogr-icon name="prueba" class="type-icon"></ogr-icon>
        <input
            type="text"
            name="targetLote"
            [(ngModel)]="targetLote"
            class="form-control"
            placeholder="Lote ID"
            (keyup.enter)="searchLote()"
        />
        <button type="button" class="btn btn-default btn-search" (click)="searchLote()"><fa name="search"></fa></button>
        <button type="button" class="btn-clear" (click)="clearLote()" *ngIf="targetLote"><fa name="times"></fa></button>
        <button type="button" class="btn btn-default btn-edit" (click)="editLote()">
            Editar
            <fa name="pencil"></fa>
        </button>
    </div>
    <div class="search-container">
        <ogr-icon name="libro2-o" class="type-icon"></ogr-icon>
        <input
            type="text"
            name="targetMaterial"
            [(ngModel)]="targetMaterial"
            class="form-control"
            placeholder="Material ID"
            (keyup.enter)="searchMaterial()"
        />
        <button type="button" class="btn btn-default btn-search" (click)="searchMaterial()">
            <fa name="search"></fa>
        </button>
        <button type="button" class="btn-clear" (click)="clearMaterial()" *ngIf="targetMaterial">
            <fa name="times"></fa>
        </button>
        <button type="button" class="btn btn-default btn-edit" (click)="editMaterial()">
            Editar
            <fa name="pencil"></fa>
        </button>
    </div>
    <div class="search-container">
        <ogr-icon name="plan-estudio" class="type-icon"></ogr-icon>
        <input
            type="text"
            name="targetPlanEstudio"
            [(ngModel)]="targetPlanEstudio"
            class="form-control"
            placeholder="Plan de estudio ID"
            (keyup.enter)="searchPlanEstudio()"
        />
        <button type="button" class="btn btn-default btn-search" (click)="searchPlanEstudio()">
            <fa name="search"></fa>
        </button>
        <button type="button" class="btn-clear" (click)="clearPlanEstudio()" *ngIf="targetPlanEstudio">
            <fa name="times"></fa>
        </button>
        <button type="button" class="btn btn-default btn-edit" (click)="editPlanEstudio()">
            Editar
            <fa name="pencil"></fa>
        </button>
    </div>
</div>
