import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Usuario, UsuarioRecurso, UsuarioRecursos } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "asociar-recursos-ids",
    templateUrl: "asociar_recursos_ids.component.html",
    styleUrls: ["asociar_recursos_ids.component.scss"]
})
export class AsociarRecursosIdsComponent implements OnInit {
    @Output() changeView = new EventEmitter<any>()
    @Input() recurso: any
    ids: string = ""

    showSuccessFeedback: boolean = false
    showExistentesFeedback: boolean = false
    showNoAsignableFeedback = false
    showNoAExistenteFeedback = false

    successIds: string
    existentesIds: string
    noAsignableIds: string
    noExistentesIds: string

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    selectedIds: string[]

    usuarioRecursos: UsuarioRecurso
    @Input() usuario: Usuario

    constructor(private usuarioRecursoService: UsuarioRecursos) {}

    goList(data) {
        this.changeView.emit(data)
    }

    ngOnInit(): void {
        this.loadingLayout.ready()
    }

    asociarIds() {
        this.loadingLayout.standby()
        this.selectedIds = this.ids.trim().split(",")

        this.usuarioRecursoService.enableIgnoreModel()
        this.usuarioRecursos = new UsuarioRecurso()
        this.usuarioRecursos.usuario_id = this.usuario.id
        this.usuarioRecursos.recurso_type = this.recurso.recurso
        this.usuarioRecursos.recurso_id = this.selectedIds

        this.usuarioRecursoService.save(this.usuarioRecursos).then((dato: any) => {
            this.usuarioRecursoService.disableIgnoreModel()
            this.loadingLayout.ready()
            this.existentesIds = dato.existentes.join()
            this.successIds = dato.exito.join()
            this.noAsignableIds = dato.no_asignables.join()
            this.noExistentesIds = dato.no_existentes.join()

            if (this.successIds.length > 0) {
                this.showSuccessFeedback = this.successIds && true
            }
            if (this.existentesIds.length > 0) {
                this.showExistentesFeedback = this.existentesIds && true
            }

            if (this.noAsignableIds.length > 0) {
                this.showNoAsignableFeedback = true
            }

            if (this.noExistentesIds.length > 0) {
                this.showNoAExistenteFeedback = true
            }
        })
    }
}
