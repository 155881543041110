import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ClasificacionTipo, ClasificacionTipoForm, ClasificacionTipos } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "clasificacion_tipos.form.component.html"
})
export class ClasificacionTiposNewComponent implements OnInit {
    clasificacionTipo: ClasificacionTipo = new ClasificacionTipo()
    params = ClasificacionTipoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar tipo de clasificación"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private clasificacionTiposService: ClasificacionTipos, private router: Router) {}

    ngOnInit() {
        this.form = ClasificacionTipoForm.getForm(this.clasificacionTipo)
        this.loadingLayout.ready()
    }

    save() {
        ClasificacionTipoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.clasificacionTiposService.save(this.clasificacionTipo).then(response => {
                this.router.navigate(["clasificacion_tipos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.clasificacionTipo = new ClasificacionTipo()
            ClasificacionTipoForm.markFormControlsAsPristine(this.form)
            ClasificacionTipoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
