<logged-layout>
    <logged-layout-toolnavbar>
        <persona-toolnavbar [displayButtons]="'new,search'"></persona-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Personas</logged-layout-titulo>
    <logged-layout-contenido>
        <personas-buscador></personas-buscador>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="personas"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>

            <paginator [getList]="showAll.bind(this)" [useQueryParams]="true"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
