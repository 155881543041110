import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { TipoInstrumentoPlataformasListComponent } from "./tipo-instrumeto-plataformas-list/tipo-instrumento-plataformas-list.component"
import { TipoInstrumentoPlataformaComponent } from "./tipo_instrumento_plataforma.component"
import { TipoInstrumentoPlataformasEditComponent } from "./tipo_instrumento_plataformas.edit.component"
import { TipoInstrumentoPlataformasNewComponent } from "./tipo_instrumento_plataformas.new.component"

export const tipoInstrumentoPlataformasRouter: Routes = [
    {
        path: "tipo_instrumento_plataformas",
        component: TipoInstrumentoPlataformasListComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "TipoInstrumentoPlataformas#index" }
    },
    {
        path: "tipo_instrumento_plataformas/new",
        component: TipoInstrumentoPlataformasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "TipoInstrumentoPlataformas#create" }
    },
    {
        path: "tipo_instrumento_plataformas/:id",
        component: TipoInstrumentoPlataformaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "TipoInstrumentoPlataformas#show" }
    },
    {
        path: "tipo_instrumento_plataformas/:id/edit",
        component: TipoInstrumentoPlataformasEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "TipoInstrumentoPlataformas#update" }
    }
]

export const routing = RouterModule.forChild(tipoInstrumentoPlataformasRouter)
