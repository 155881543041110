import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Asignatura, AsignaturaForm, Asignaturas } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "asignaturas.form.component.html"
})
export class AsignaturasNewComponent implements OnInit {
    asignatura: Asignatura = new Asignatura()
    params = AsignaturaForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar asignatura"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private asignaturasService: Asignaturas, private router: Router) {}

    ngOnInit() {
        this.form = AsignaturaForm.getForm(this.asignatura)
        this.loadingLayout.ready()
    }

    save() {
        AsignaturaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.asignaturasService.save(this.asignatura).then(response => {
                this.router.navigate(["asignaturas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.asignatura = new Asignatura()
            AsignaturaForm.markFormControlsAsPristine(this.form)
            AsignaturaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
