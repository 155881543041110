import { NgModule, Provider } from "@angular/core"
import { CommonModule } from "@angular/common"

import {
    AppConfig,
    AppEnv
} from "@puntaje/shared/core"

export function getProviders(config: AppConfig, environment: AppEnv): Provider[] {
    return [
        { provide: AppConfig, useValue: config },
        { provide: AppEnv, useValue: environment }
    ]
}

@NgModule({
    imports: [CommonModule]
})
export class NebulosaCoreModule {}
