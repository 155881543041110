import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PopoverModule } from "ngx-bootstrap"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { CertificacionRecursoComponent } from "./certificacion-recurso.component"
import { PoliciesModule } from "../../modules/policies/policies.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        LayoutsModule,
        ModelViewModule,
        FormModule,
        ReactiveFormsModule,
        PaginatorModule,
        PopoverModule,
        Angular2FontawesomeModule,
        NebuModule,
        PoliciesModule
    ],
    declarations: [CertificacionRecursoComponent],
    exports: [CertificacionRecursoComponent]
})
export class CertificacionRecursosModule {}
