import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ReporteSubRazon, ReporteSubRazones, ReporteSubRazonView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "reporte_sub_razon.component.html"
})
export class ReporteSubRazonComponent implements OnInit, OnDestroy {
    reporteSubRazon: ReporteSubRazon
    private sub: Subscription
    show_params = ReporteSubRazonView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private reporteSubRazonesService: ReporteSubRazones,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.reporteSubRazonesService.find(+params["id"]).then((response: ReporteSubRazon) => {
                this.reporteSubRazon = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
