import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { ClasificacionTipo, ClasificacionTipos, ClasificacionTipoView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService, PaginatorComponent } from "@puntaje/shared/core"
import { ClasificacionTiposBuscadorService } from "./clasificacion-tipos-buscador.service"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion_tipos.component.html"
})
export class ClasificacionTiposComponent implements OnInit {
    clasificacionTipos: ClasificacionTipo[]
    show_params = ClasificacionTipoView.viewParams
    tableButtonsArray: any
    searchParams: any
    sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        private clasificacionTiposService: ClasificacionTipos,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected clasificacionTiposBuscadorService: ClasificacionTiposBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            // edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: " Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            createAlias: {
                function: this.createAlias.bind(this),
                text: " Alias",
                callModal: false,
                glyphicon: "glyphicon-plus",
                permisoResolver: _ => "ClasificacionTipos#update"
            }
        }
        this.sub = this.clasificacionTiposBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            ...this.searchParams,
            page: page,
            per: per,
            render_options: { include: { clasificacion_tipo_aliases: null } }
        }

        return this.clasificacionTiposService
            .where(params)
            .then((clasificacionTipos: ClasificacionTipo[], total: number) => {
                this.clasificacionTipos = clasificacionTipos
                this.loadingLayout.ready()
                return total
            })
    }

    view(clasificacionTipo: ClasificacionTipo) {
        this.router.navigate(["clasificacion_tipos/" + clasificacionTipo.id])
    }

    edit(clasificacionTipo: ClasificacionTipo) {
        this.router.navigate(["clasificacion_tipos/" + clasificacionTipo.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["clasificacion_tipos/" + id + "/delete"])
    }

    showModalDelete(clasificacionTipo: ClasificacionTipo) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar la clasificación?")
        this.simpleModalService.setModalCallback(() => this.delete(clasificacionTipo.id))
    }

    createAlias(clasificacionTipo: ClasificacionTipo) {
        this.router.navigate(["clasificacion_tipo_aliases/new"], {
            queryParams: { clasificacion_tipo_id: clasificacionTipo.id }
        })
    }
}
