import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Perfil, Perfiles, PerfilForm } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "perfiles.form.component.html"
})
export class PerfilesNewComponent implements OnInit {
    perfil: Perfil = new Perfil()
    model_params = PerfilForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar perfil"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private perfilesService: Perfiles, private router: Router) {}

    ngOnInit() {
        this.form = PerfilForm.getForm(this.perfil)
        this.loadingLayout.ready()
    }

    save() {
        PerfilForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.perfilesService.save(this.perfil).then(response => {
                this.router.navigate(["perfiles"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.perfil = new Perfil()
            PerfilForm.markFormControlsAsPristine(this.form)
            PerfilForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
