import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Reporte, Reportes, ReporteView } from "@puntaje/nebulosa/api-services"
import { AuthService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { ReportesBuscadorService } from "./reportes_buscador.service"

@Component({
    templateUrl: "reportes.component.html"
})
export class ReportesComponent implements OnInit, OnDestroy {
    reportes: Reporte[]
    show_params = ReporteView.viewParams
    tableButtonsArray: any
    sub: Subscription
    searchParams: any = {}
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        private reportesService: Reportes,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected reportesBuscadorService: ReportesBuscadorService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.show_params.created_at.tableVisible = false
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            resolve: {
                function: this.showModalResolve.bind(this),
                text: "Resolver",
                callModal: true,
                glyphicon: "glyphicon-ok",
                permiso: "Reportes#update"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.sub = this.reportesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll = (page: number, count: number) => {
        let params = {
            reporte: {
                estado_reporte_id: 1,
                ...this.searchParams.reporte
            },
            page,
            per: count,
            sort: [{ field: "created_at", criteria: "desc" }]
        }

        return this.reportesService.where(params).then((reportes: Reporte[], total: number) => {
            this.reportes = reportes
            this.loadingLayout.ready()

            return total
        })
    }

    view(reporte: Reporte) {
        this.router.navigate(["reportes/" + reporte.id])
    }

    resolve(reporte: Reporte) {
        reporte.estado_reporte_id = 2 //Resuelto
        this.reportesService.update(reporte.id, reporte).then((response: Reporte) => {
            this.router.navigate(["reportes/resueltos"])
        })
    }

    delete(id: number) {
        this.router.navigate(["reportes/" + id + "/delete"])
    }

    showModalResolve(reporte: Reporte) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea marcar el reporte como resuelto?")
        this.simpleModalService.setModalCallback(() => this.resolve(reporte))
    }

    showModalDelete(reporte: Reporte) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar el reporte?")
        this.simpleModalService.setModalCallback(() => this.delete(reporte.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
