<!-- <nav class="navbar navbar-default" *ngIf = 'displayNavbar'>
	<button *ngIf='displayBtnAll' (click)="all()" class="btn btn-sm btn-default pull-left">
		<span class="glyphicon glyphicon-list" aria-hidden="true"></span> Lotes
	</button>

	<button *ngIf='displayBtnNew' (click)="new()" class="btn btn-sm btn-default pull-right">
		<span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span> Agregar lote
	</button>

	<button *ngIf='displayBtnEdit' (click)="edit('lote.id')" class="btn btn-sm btn-default pull-right margin-right">
		<span class="glyphicon glyphicon-pencil" aria-hidden="true"></span> Editar lote
	</button>


</nav> -->

<toolnavbar resourceName="lote" [resource]="lote" [displayButtons]="displayButtons" [displayNavbar]="displayNavbar">
    <!-- <a *ngIf='displayBtnMine' [routerLink]="urlMisPreguntas" class="btn btn-sm btn-default margin-right pull-left">
		<span class="glyphicon glyphicon-star" aria-hidden="true"></span> Mis Preguntas
	</a>
	<a [routerLink]="['/preguntas/buscar']" class="btn btn-sm btn-default pull-left">
		<span class="glyphicon glyphicon-search" aria-hidden="true"></span> Búsqueda
	</a> -->
</toolnavbar>
