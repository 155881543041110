<loading-layout #loadingLayout [opaque]="true">
    <div class="row">
        <div class="col-md-8">
            <div class="certificacionBox" [class.certificado]="isReady">
                <ng-container *ngIf="isReady">
                    <ng-container *ngIf="certificacionRecursos.length > 0">
                        <div class="certificacionWrap" *ngFor="let certificacionRecurso of certificacionRecursos">
                            <ogr-icon name="pro" class="iconoCertificacion certificado"></ogr-icon>
                            <p>
                                Certificado -
                                <span *ngIf="certificacionRecurso.curriculum">
                                    Curriculum: {{ certificacionRecurso.curriculum.curriculum }}
                                </span>
                                <span class="autor" *ngIf="certificacionRecurso.usuario">
                                    Por
                                    <b>{{ certificacionRecurso.usuario.persona }}</b>
                                    el {{ certificacionRecurso.created_at | date: "dd-MM-yyyy HH:mm" }}
                                </span>
                            </p>
                            <button
                                *permiso="'CertificacionRecursos#destroy'"
                                class="btn btn-default"
                                (click)="quitarCertificacion(certificacionRecurso)"
                            >
                                <fa name="times" class="icono"></fa>
                                Quitar certificación
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="certificacionRecursos.length == 0">
                        <p>
                            <fa name="ban" class="iconoCertificacion noCertificado"></fa>
                            Recurso no certificado.
                        </p>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Curriculum</label>
                <select [(ngModel)]="selectedCurriculumId" name="" id="" class="form-control">
                    <option [ngValue]="undefined">General</option>
                    <option *ngFor="let curriculum of curriculums" [ngValue]="curriculum.id">
                        {{ curriculum.curriculum }}
                    </option>
                </select>
            </div>
            <button *permiso="'CertificacionRecursos#create'" class="btn btn-default" (click)="certificar()">
                <fa name="check" class="icono"></fa>
                Certificar
            </button>
        </div>
    </div>
</loading-layout>
