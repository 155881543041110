import { IParserHandler } from "./IParserHandler"
import { Convertor } from "./Convertor"
import { ElementStart, ElementEnd } from "./MyElement"
import { NoItemException } from "./FatalErrorException"

import { java } from "j4ts"

import StringWriter = java.io.StringWriter

import IOException = java.io.IOException

/**
 * Handles events sent from the {@link Parser Parser} class.
 * Calls appropriate methods from the {@link Convertor Convertor} class.
 */
export class ParserHandler implements IParserHandler {
    /**
     * Convertor.
     */
    private _conv: Convertor

    /**
     * Cstr.
     * @param outputFile output LaTeX file
     * @throws FatalErrorException fatal error (ie. output file can't be closed) occurs
     */
    constructor(outputFile: StringWriter) {
        Object.defineProperty(this, "__interfaces", { configurable: true, value: ["util.html2latex.IParserHandler"] })
        this._conv = new Convertor(outputFile)
    }

    /**
     * Called when a start element is reached in the input document.
     * Calls {@link Convertor#commonElementStart(ElementStart) commonElementStart()}
     * for non-special elements and specials methods for the elements requiring
     * special care (ie. {@link Convertor#tableRowStart(ElementStart) tableRowStart()}
     * for <code>&lt;table&gt;</table>)
     * @param element start element reached
     */
    public startElement(element: ElementStart) {
        try {
            var name: string = element.getElementName()
            if (name === "a") this._conv.anchorStart(element)
            else if (name === "tr") this._conv.tableRowStart(element)
            else if (name === "td") this._conv.tableCellStart(element)
            else if (name === "th") this._conv.tableCellStart(element)
            else if (name === "meta") this._conv.metaStart(element)
            else if (name === "body") this._conv.bodyStart(element)
            else if (name === "font") this._conv.fontStart(element)
            else if (name === "img") this._conv.imgStart(element)
            else if (name === "table") this._conv.tableStart(element)
            else this._conv.commonElementStart(element)
            this._conv.cssStyleStart(element)
        } catch (e) {
            if (e != null && e instanceof Error) console.error("Can't write into output file")
            else if (e != null && e instanceof NoItemException) console.info(e.toString())
        }
    }

    /**
     * Called when an end element is reached in the input document.
     * Calls {@link Convertor#commonElementEnd(ElementEnd, ElementStart) commonElementEnd()}
     * for non-special elements and specials methods for the elements requiring
     * special care (ie. {@link Convertor#tableRowEnd(ElementEnd, ElementStart) tableRowEnd()}
     * for <code>&lt;/table&gt;</table>)
     * @param element end element reached
     * @param elementStart corresponding start element
     */
    public endElement(element: ElementEnd, elementStart: ElementStart) {
        try {
            var name: string = element.getElementName()
            this._conv.cssStyleEnd(elementStart)
            if (name === "a") this._conv.anchorEnd(element, elementStart)
            else if (name === "tr") this._conv.tableRowEnd(element, elementStart)
            else if (name === "th") this._conv.tableCellEnd(element, elementStart)
            else if (name === "td") this._conv.tableCellEnd(element, elementStart)
            else if (name === "table") this._conv.tableEnd(element, elementStart)
            else if (name === "body") this._conv.bodyEnd(element, elementStart)
            else if (name === "font") this._conv.fontEnd(element, elementStart)
            else this._conv.commonElementEnd(element, elementStart)
        } catch (e) {
            if (e != null && e instanceof Error) console.error("Can't write into output file")
            else if (e != null && e instanceof NoItemException) console.info(e.toString())
        }
    }

    /**
     * Called when the text content of an element is read.
     * Calls {@link Convertor#characters(String) characters()} method
     * of the {@link Convertor Convertor} class.
     * @param content ie. &quot;foo&quot; for the &quot;&lt;b&gt;foo&lt;/b&gt;&quot;
     */
    public characters(content: string) {
        try {
            this._conv.characters(content)
        } catch (e) {
            console.error("Can't write into output file.")
        }
    }

    /**
     * Called when the comment is reached in input document.
     * Calls {@link Convertor#comment(String) comment()} method
     * of the {@link Convertor Convertor} class.
     * @param comment ie. &quot;foo&quot; for the &quot;&lt;!--&gt;foo&lt;/--&gt;&quot;
     */
    public comment(comment: string) {
        try {
            this._conv.comment(comment)
        } catch (e) {
            console.error("Can't write into output file.")
        }
    }

    /**
     * Called when the whole input document is read.
     * Calls {@link Convertor#destroy() destroy()} method
     * of the {@link Convertor Convertor} class.
     */
    public endDocument() {
        this._conv.destroy()
    }
}
