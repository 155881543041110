<logged-layout>
    <logged-layout-toolnavbar>
        <lotes-toolnavbar [displayButtons]="'new'"></lotes-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Mis Lotes</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="lotes"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
