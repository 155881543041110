<table
    #resizingTable
    class="table table-striped table-bordered"
    (window:resize)="onResize($event)"
    [class.smallTable]="showSmallTable"
>
    <thead>
        <tr class="showBig">
            <th *ngIf="selectable"></th>
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <th *ngIf="show_params[key] && show_params[key].tableVisible">{{ show_params[key].label }}</th>
            </ng-template>
            <th>Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let content of contentArray; let i = index; trackBy: id" class="showBig">
            <td *ngIf="selectable">
                <input *ngIf="!hideSelect(content)" type="checkbox" (click)="addUp(content?.id)" [checked]="isChecked(content?.id)">
            </td>
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <td *ngIf="show_params[key] && show_params[key].tableVisible">
                    <model-table-cell [params]="show_params[key]" [value]="content" [key]="key"></model-table-cell>
                </td>
            </ng-template>
            <td>
                <table-with-actions-buttons
                    [resourceName]="resourceName"
                    [tableButtonsArray]="tableButtonsArray"
                    [functionParameter]="content"
                ></table-with-actions-buttons>
            </td>
        </tr>

        <tr *ngFor="let content of contentArray; let i = index; trackBy: id" class="showSmall">
            <td>
                <div class="clearfix">
                    <table-with-actions-buttons
                        [resourceName]="resourceName"
                        [tableButtonsArray]="tableButtonsArray"
                        [functionParameter]="content"
                        [enableSmallButtons]="true"
                    ></table-with-actions-buttons>
                </div>
                <div class="sub_table">
                    <ng-template ngFor let-key [ngForOf]="show_params | keys">
                        <div class="sub_row">
                            <div *ngIf="show_params[key].tableVisible" class="smallTableLabel sub_col">
                                {{ show_params[key].label }}
                            </div>
                            <div *ngIf="show_params[key].tableVisible" class="sub_col">
                                <model-table-cell
                                    [params]="show_params[key]"
                                    [value]="content"
                                    [key]="key"
                                ></model-table-cell>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </td>
        </tr>
    </tbody>
</table>
