import { RouterModule, Routes } from "@angular/router"
import { PreguntaGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PreguntaComponent } from "./pregunta.component"
import { PreguntasComponent } from "./preguntas.component"
import { PreguntasDeleteComponent } from "./preguntas.delete.component"
import { PreguntasEditComponent } from "./preguntas.edit.component"
import { PreguntasNewComponent } from "./preguntas.new.component"
import { PreguntasBuscarComponent } from "./preguntas_buscar.component"
import { PreguntasUsuarioComponent } from "./preguntas_usuario.component"
import { PreguntasListComponent } from "./preguntas-list/preguntas-list.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { EditarMasivamenteComponent } from "./editar-masivamente/editar-masivamente.component"

export const routes: Routes = [
    {
        path: "preguntas",
        component: PreguntasListComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#index" }
    },
    /*  { path: 'preguntas', component: PreguntasComponent, canActivate: [ AuthGuard, PermisosGuard ], data: {permiso: 'Preguntas#index'} }, */
    {
        path: "preguntas/new",
        component: PreguntasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#create" }
    },
    {
        path: "preguntas/buscar",
        component: PreguntasBuscarComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#index" }
    },
    {
        path: "preguntas/:id",
        component: PreguntaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#show" }
    },
    {
        path: "preguntas/:id/edit",
        component: PreguntasEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#update" }
    },
    {
        path: "preguntas/:id/delete",
        component: PreguntasDeleteComponent,
        canActivate: [AuthGuard],
        data: { permiso: "Preguntas#destroy" }
    },
    {
        path: "mis_preguntas",
        component: PreguntasUsuarioComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#index" }
    },
    {
        path: "editar_preguntas_masivamente",
        component: EditarMasivamenteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Preguntas#index" }
    }
    //{ path: 'personas/:id/delete', component: PersonasDeleteComponent }
]
export const preguntasProviders = [
    //AuthGuard
]

export const routing = RouterModule.forChild(routes)
