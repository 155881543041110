<logged-layout>
    <logged-layout-toolnavbar>
        <plataforma-toolnavbar [displayButtons]="'new'"></plataforma-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Plataformas</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="plataformas"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
        </loading-layout>
        <paginator [getList]="showAll.bind(this)" [useQueryParams]="true"></paginator>
    </logged-layout-contenido>
</logged-layout>
