<logged-layout>
    <logged-layout-toolnavbar>
        <materiales-toolnavbar [displayButtons]="'new,search'"></materiales-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Materiales</logged-layout-titulo>
    <logged-layout-contenido>
        <materiales-buscador></materiales-buscador>
        <loading-layout #loadingLayout>
            <materiales [materiales]="materiales"></materiales>
            <paginator [getList]="showAll" [useQueryParams]="true"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
