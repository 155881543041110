<logged-layout>
    <logged-layout-toolnavbar>
        <ayudas-toolnavbar [displayButtons]="'new,search'"></ayudas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Ayudas</logged-layout-titulo>
    <logged-layout-contenido>
        <ayudas-buscador></ayudas-buscador>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="ayudas"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll" [useQueryParams]="true"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
