import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ClasificacionTipo, ClasificacionTipoForm, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion_tipos.form.component.html"
})
export class ClasificacionTiposEditComponent implements OnInit, OnDestroy {
    oClasificacionTipo: ClasificacionTipo = new ClasificacionTipo()
    clasificacionTipo: ClasificacionTipo = new ClasificacionTipo()
    params = ClasificacionTipoForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar tipo de clasificación"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private clasificacionTiposService: ClasificacionTipos,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = ClasificacionTipoForm.getForm(this.clasificacionTipo)
        this.sub = this.route.params.subscribe(params => {
            this.clasificacionTiposService.find(+params["id"]).then((response: ClasificacionTipo) => {
                this.clasificacionTipo = response
                this.oClasificacionTipo = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        ClasificacionTipoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.clasificacionTiposService.update(this.clasificacionTipo.id, this.clasificacionTipo).then(response => {
                this.router.navigate(["clasificacion_tipos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.clasificacionTipo = this.oClasificacionTipo.clone()
            ClasificacionTipoForm.markFormControlsAsPristine(this.form)
            ClasificacionTipoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
