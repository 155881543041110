<div class="row">
    <div class="col-md-4 col-sm-6">
        <div class="form-group">
            <label [attr.for]="'id'">Id</label>
            <input
                name="id"
                class="form-control"
                type="text"
                pattern="[0-9]+"
                [(ngModel)]="pregunta.id"
                placeholder="ID de la pregunta"
            />
        </div>
        <div class="form-group">
            <label [attr.for]="'taxativo'">Enunciado</label>
            <input
                name="taxativo"
                class="form-control"
                type="text"
                [(ngModel)]="taxativo.taxativo"
                placeholder="Texto a buscar..."
            />
        </div>
        <div class="form-group">
            <label [attr.for]="'asignatura'">Asignatura</label>
            <select class="form-control" name="asignatura" [(ngModel)]="pregunta.asignatura_id">
                <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                <option *ngFor="let asignatura of asignaturas" [value]="asignatura.id">
                    {{ asignatura.asignatura }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label [attr.for]="'grupo_pregunta_id'">Grupo Pregunta Id</label>
            <input
                name="grupo_pregunta_id"
                class="form-control"
                type="number"
                [(ngModel)]="grupoPregunta.id"
                placeholder="ID Grupo Pregunta"
            />
        </div>
        <div class="form-group">
            <label [attr.for]="'texto'">Texto Grupo Pregunta</label>
            <input
                name="texto"
                class="form-control"
                type="text"
                [(ngModel)]="grupoPregunta.texto"
                placeholder="Texto a buscar..."
            />
        </div>
    </div>
    <div class="col-md-4 col-sm-6">
        <div class="form-group lista-resultados-box">
            <label [attr.for]="'lote'">Lote</label>
            <input
                name="lote"
                class="form-control"
                type="text"
                [formControl]="buscadorLote"
                placeholder="ID del Lote"
            />
            <ul *ngIf="showLotes" class="lista-resultados-auto" #ulLotes>
                <li *ngFor="let l of lotes" (click)="clickLote(l)">{{ l.lote }}</li>
            </ul>
        </div>
        <div class="form-group lista-resultados-box">
            <label [attr.for]="'profesor'">Profesor</label>
            <input
                name="profesor"
                class="form-control"
                type="text"
                [formControl]="buscadorProfesor"
                placeholder="Escriba y seleccione..."
            />
            <ul *ngIf="showProfesores" class="lista-resultados-auto" #ulProfesores>
                <li *ngFor="let p of profesores" (click)="clickProfesor(p)">{{ p }}</li>
            </ul>
        </div>
        <div class="form-group">
            <label [attr.for]="'numero_preguntas'">Número Alternativas</label>
            <input
                type="number"
                class="form-control"
                [(ngModel)]="pregunta.numero_alternativas"
                placeholder="Número de alternativas"
            />
        </div>
        <div class="form-group">
            <div class="checkbox">
                <label [attr.for]="'visible'">
                    <input id="visible" name="visible" type="checkbox" [(ngModel)]="pregunta.visible" />
                    <span>Visible</span>
                </label>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-6">
        <div class="form-group lista-resultados-box">
            <label [attr.for]="'clasificaciones'">Clasificaciones</label>
            <input
                name="clasificaciones"
                class="form-control"
                type="text"
                [formControl]="buscadorClasificaciones"
                placeholder="Escriba y seleccione..."
            />
            <ul *ngIf="showClasificaciones" class="lista-resultados-auto" #ulClasificaciones>
                <li *ngFor="let c of clasificaciones" (click)="clickClasificacion(c)">
                    {{ c.full_nombre_jerarquico }}
                </li>
            </ul>
            <ul class="clasificacion-box">
                <li *ngFor="let c of clasificacionesPregunta">
                    <span (click)="deleteClasificacion(c)">x</span>
                    {{ c.full_nombre_jerarquico }}
                </li>
            </ul>
        </div>
        <div class="form-group lista-resultados-box">
            <label [attr.for]="'clasificaciones'">ID clasificación</label>
            <input
                name="clasificacion_id"
                class="form-control"
                type="text"
                [formControl]="buscadorClasificacionId"
                placeholder="Escriba el id de la clasificación"
            />
        </div>
    </div>
</div>
<div class="">
    <button class="btn btn-default btn-buscar" (click)="showPreguntas()">
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        &nbsp;Buscar
    </button>
    <button class="btn btn-default" *ngIf="exportarIdsButton" (click)="buscarPreguntas(0, 0, true)">
        <span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
        &nbsp;Exportar IDs
    </button>
</div>
