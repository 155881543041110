import { Injectable, Injector } from "@angular/core"
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { servicios } from "../../modules/policies/tabla-servicios"
import { BasePolicyService } from "../../modules/policies/base-policy.service"

@Injectable({
    providedIn: "root"
})
export class PermisosGuard implements CanActivate {
    constructor(protected injector: Injector) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let [resource, method] = next.data.permiso.split("#")
        let service, resourceName
        if (servicios[resource]) {
            service = this.injector.get(servicios[resource].policy)
        } else {
            service = this.injector.get(BasePolicyService)
            resourceName = resource
        }
        return service[method]({ resourceName, resourceId: next.params["id"] })
    }
}
