import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { Personas } from "./personas.service"

export class GrupoPreguntaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            texto: { label: "Texto", type: "ckeditor", visible: true, validations: [Validators.required] },
            imagen: { label: "Imagen", type: "text", visible: true },
            estado: { label: "Estado", type: "hidden", visible: false },
            visible: { label: "Visible", type: "checkbox", visible: true },
            texto_latex: { label: "Texto Latex", type: "textarea", visible: true, validations: [Validators.required] },
            profesor_id: { label: "Profesor", type: "select", visible: true, options: { class: Personas } },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
