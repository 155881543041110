import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Usuario, Usuarios, UsuarioView } from "@puntaje/nebulosa/api-services"
import { AuthService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { UsuariosBuscadorService } from "./usuarios_buscador.service"

@Component({
    templateUrl: "usuarios.component.html"
})
export class UsuariosComponent implements OnInit {
    usuarios: Usuario[]
    show_params = UsuarioView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    sub: Subscription
    searchParams: any

    constructor(
        private usuariosService: Usuarios,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected usuariosBuscadorService: UsuariosBuscadorService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.sub = this.usuariosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll = (page: number, per: number) => {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            ...this.searchParams,
            page: page,
            per: per
        }

        return this.usuariosService.where(params).then((usuarios: Usuario[], total: number) => {
            this.usuarios = usuarios
            this.loadingLayout.ready()

            return total
        })
    }

    view(usuario: Usuario) {
        this.router.navigate(["usuarios/" + usuario.id])
    }

    edit(usuario: Usuario) {
        this.router.navigate(["usuarios/" + usuario.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["usuarios/" + id + "/delete"])
    }

    showModalDelete(usuario: Usuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar al usuario '" + usuario["nombre_completo"] + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(usuario.id))
    }
}
