<h3>Preguntas del lote</h3>
<ul class="preguntas-list">
    <li *ngFor="let lote_pregunta of lote_preguntas; let i = index; trackBy: id">
        <div class="">
            <a [routerLink]="['/preguntas', lote_pregunta.pregunta_id]">Pregunta #{{ lote_pregunta.pregunta_id }}</a>
            <pregunta-small [pregunta]="lote_pregunta.pregunta"></pregunta-small>
            <div
                class="clasificaciones"
                *ngIf="lote_pregunta.pregunta.clasificaciones && lote_pregunta.pregunta.clasificaciones.length > 0"
            >
                <ng-container *ngFor="let c of lote_pregunta.pregunta.clasificaciones">
                    <button
                        class="clasificacion with-popover"
                        popover="{{ c.descripcion }}"
                        triggers="focus"
                        *ngIf="c.descripcion && c.descripcion.length > 0"
                    >
                        {{ c.clasificacion }}&nbsp;
                        <fa name="plus-square-o" class="plus-info"></fa>
                    </button>
                    <span class="clasificacion" *ngIf="!(c.descripcion && c.descripcion.length > 0)">
                        {{ c.clasificacion }}
                    </span>
                </ng-container>
            </div>
        </div>
    </li>
</ul>
