import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Reporte, Reportes, ReporteView } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "reportes_resueltos.component.html"
})
export class ReportesResueltosComponent implements OnInit {
    reportes: Reporte[]
    show_params = ReporteView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private reportesService: Reportes,
        private router: Router,
        private simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.show_params.created_at.tableVisible = false
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" }
        }
        this.router.routerState.root.queryParams.subscribe(params => {
            var page = +params["page"]
            var count = +params["count"]
            if (isNaN(page)) {
                page = 1
            }
            if (isNaN(count)) {
                count = 20
            }
            this.showAll(page, count)
        })
    }

    showAll = (page: number = 1, count: number = 20) => {
        return this.reportesService
            .where({ reporte: { estado_reporte_id: 2 }, page: page, per: count, order: "desc" })
            .then((response: Reporte[], total: number) => {
                this.reportes = response
                this.loadingLayout.ready()

                return total
            })
    }

    view(reporte: Reporte) {
        this.router.navigate(["reportes/" + reporte.id])
    }
}
