import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { ReporteRazonComponent } from "./reporte_razon.component"
import { ReporteRazonesComponent } from "./reporte_razones.component"
import { ReporteRazonesDeleteComponent } from "./reporte_razones.delete.component"
import { ReporteRazonesEditComponent } from "./reporte_razones.edit.component"
import { ReporteRazonesNewComponent } from "./reporte_razones.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const reporteRazonesRoutes: Routes = [
    {
        path: "reporte_razones",
        component: ReporteRazonesComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteRazones#index" }
    },
    {
        path: "reporte_razones/new",
        component: ReporteRazonesNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteRazones#create" }
    },
    {
        path: "reporte_razones/:id",
        component: ReporteRazonComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteRazones#show" }
    },
    {
        path: "reporte_razones/:id/edit",
        component: ReporteRazonesEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteRazones#update" }
    },
    {
        path: "reporte_razones/:id/delete",
        component: ReporteRazonesDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ReporteRazones#destroy" }
    }
]

export const reporteRazonesRouting = RouterModule.forChild(reporteRazonesRoutes)
