<nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-header">
            <a routerLink="home" class="navbar-brand">Centro de recursos</a>
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
        <ng-template [ngIf]="authService.isLoggedIn()">
            <ul class="nav navbar-top-links navbar-right">
                <li class="dropdown user-circle">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="glyphicon glyphicon-user"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-user user-menu">
                        <li>
                            <a (click)="logout()">
                                <i class="glyphicon glyphicon-log-out"></i>
                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="nav navbar-brand navbar-right">
                {{ authService.getUserData().persona.nombre }} {{ authService.getUserData().persona.apellido_paterno }}
            </div>
        </ng-template>
    </div>
</nav>
