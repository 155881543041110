<div class="list" *ngIf="usuariosSliced.length > 0">
    <div class="user-row" *ngFor="let usuario of usuariosSliced">
        <fa name="user" class="user-icon"></fa>
        <div class="usuario-wrap">
            <p>
                <a routerLink="/usuarios/{{ usuario.id }}">{{ usuario.persona }}</a>
            </p>
            <p class="perfil">{{ usuario.perfiles[0].perfil }}</p>
        </div>
        <div class="fecha-wrap">
            <p>
                <span class="fecha-label">
                    <fa name="clock-o" class="icono"></fa>
                    Válido hasta:
                </span>
                <span class="value" *ngIf="usuario.fecha_expiracion">
                    {{ usuario.fecha_expiracion | date: "dd/MM/yyyy HH:mm" }}
                </span>
                <span class="value" *ngIf="!usuario.fecha_expiracion">-</span>
            </p>
        </div>
        <div class="acciones">
            <button (click)="ver(usuario)" class="btn btn-default btn-xs">
                <fa name="search" class="icono"></fa>
                Ver
            </button>
            <button
                *permiso="'UsuarioUsuarios#destroy'"
                (click)="showModalDesasociar(usuario)"
                class="btn btn-default btn-xs"
            >
                <fa name="times" class="icono"></fa>
                Desasociar
            </button>
        </div>
    </div>
</div>
<paginator class="paginator" [getList]="setData" [per]="5"></paginator>
<p *ngIf="usuariosSliced.length == 0" class="not-found">Sin usuarios asociados.</p>
