<logged-layout>
    <logged-layout-toolnavbar>
        <plataforma-toolnavbar
            *ngIf="plataforma"
            [plataforma]="plataforma"
            [displayButtons]="'all new edit'"
        ></plataforma-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Plataforma</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="plataforma"
                    [key]="key"
                ></model-simple-view>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
