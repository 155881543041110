import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { ClasificacionTipoComponent } from "./clasificacion_tipo.component"
import { ClasificacionTiposComponent } from "./clasificacion_tipos.component"
import { ClasificacionTiposDeleteComponent } from "./clasificacion_tipos.delete.component"
import { ClasificacionTiposEditComponent } from "./clasificacion_tipos.edit.component"
import { ClasificacionTiposNewComponent } from "./clasificacion_tipos.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const ClasificacionTiposRoutes: Routes = [
    {
        path: "clasificacion_tipos",
        component: ClasificacionTiposComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipos#index" }
    },
    {
        path: "clasificacion_tipos/new",
        component: ClasificacionTiposNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipos#create" }
    },
    {
        path: "clasificacion_tipos/:id",
        component: ClasificacionTipoComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipos#show" }
    },
    {
        path: "clasificacion_tipos/:id/edit",
        component: ClasificacionTiposEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipos#update" }
    },
    {
        path: "clasificacion_tipos/:id/delete",
        component: ClasificacionTiposDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "ClasificacionTipos#destroy" }
    }
]

export const ClasificacionTiposRouting = RouterModule.forChild(ClasificacionTiposRoutes)
