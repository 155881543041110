import { Component, OnInit, ViewChild, Input } from "@angular/core"
import {
    Pregunta,
    Preguntas,
    Usuario,
    UsuarioRecursoTipos,
    GrupoPreguntas,
    GrupoPregunta,
    Lotes,
    Lote,
    PlanEstudios,
    PlanEstudio,
    Material,
    Materiales
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "recursos-asociados",
    templateUrl: "./recursos-asociados.component.html",
    styleUrls: ["./recursos-asociados.component.scss"]
})
export class RecursosAsociadosComponent implements OnInit {
    preguntas: Pregunta[]
    grupo_preguntas: GrupoPregunta[]
    lotes: Lote[]
    planEstudios: PlanEstudio[]
    materiales: Material[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() usuario: Usuario
    @Input() recurso: any
    clasificaciones = []

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(
        private preguntasService: Preguntas,
        private usuarioRecursoTiposServices: UsuarioRecursoTipos,
        private grupo_preguntasService: GrupoPreguntas,
        private lotesService: Lotes,
        private planEstudiosService: PlanEstudios,
        private materialesService: Materiales
    ) {}

    ngOnInit() {
        this.getClasificaciones()
    }

    grupoEliminado(event) {
        this.loadingLayout.standby()
        this.paginator.changePage(1)
        this.paginator.reload()
    }

    showAll(page: number, per: number) {
        return this.preguntasService
            .where({ page: page, per: per, usuario_id: this.usuario.id, methods: "cantidad_reportes" })
            .then((preguntas: Pregunta[], total: number) => {
                this.preguntas = preguntas
                this.loadingLayout.ready()
                return total
            })
    }

    showAllGrupoPreguntas(page: number, per: number) {
        return this.grupo_preguntasService
            .where({ page: page, per: per, usuario_id: this.usuario.id })
            .then((grupo_preguntas: GrupoPregunta[], total: number) => {
                this.grupo_preguntas = grupo_preguntas
                this.loadingLayout.ready()
                return total
            })
    }

    showAllLote(page: number, per: number) {
        return this.lotesService
            .where({ page: page, per: per, usuario_id: this.usuario.id })
            .then((lotes: Lote[], total: number) => {
                this.lotes = lotes
                this.loadingLayout.ready()
                return total
            })
    }

    showAllPlanEstudio(page: number, per: number) {
        return this.planEstudiosService
            .where({ page: page, per: per, usuario_id: this.usuario.id })
            .then((response: PlanEstudio[], total: number) => {
                this.planEstudios = response
                this.loadingLayout.ready()
                return total
            })
    }

    showAllMaterial(page: number, per: number) {
        let params = {
            usuario_id: this.usuario.id,
            page: page,
            per: per,
            methods: "cantidad_reportes"
        }

        return this.materialesService.where(params).then((materiales: Material[], total: number) => {
            this.materiales = materiales
            this.loadingLayout.ready()
            return total
        })
    }

    async getClasificaciones() {
        let params = {
            usuario_recurso_tipo: {
                usuario_id: this.usuario.id,
                tipo_recurso: this.recurso
            },

            render_options: {
                include: {
                    criterio_asignaciones: {
                        include: {
                            criterio: null
                        }
                    }
                }
            }
        }
        this.usuarioRecursoTiposServices.enableIgnoreModel()
        this.clasificaciones = await this.usuarioRecursoTiposServices.where(params)
        this.usuarioRecursoTiposServices.disableIgnoreModel()
    }
}
