import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    Material,
    Materiales,
    MaterialView,
    RevisionRecurso,
    RevisionRecursos,
    Plataforma,
    Plataformas
} from "@puntaje/nebulosa/api-services"
import { PdfView, YoutubeVideoView, AuthService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "material.component.html",
    styleUrls: ["material.component.scss"]
})
export class MaterialComponent implements OnInit, OnDestroy {
    material: Material
    show_params = MaterialView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    showMarcarComoRevisada: boolean
    showDesmarcarComoRevisada: boolean
    plataformas: Plataforma[]
    estadistica: any[]
    total: any

    constructor(
        private materialesService: Materiales,
        private router: Router,
        private authService: AuthService,
        private revisionRecursosService: RevisionRecursos,
        private route: ActivatedRoute,
        private plataformasService: Plataformas
    ) {}

    getVisualizer() {
        if (this.material.material_tipo_id == 1) return { label: "Previsualizar", type: PdfView, tableVisible: true }
        else return { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.loadMaterial(+params["id"])
        })
    }

    loadMaterial(materialId) {
        this.loadingLayout.standby()

        const params = {
            revision_recurso: {
                usuario_id: [null, this.authService.getUserData().id]
            },
            include: "[revision_recursos,persona_externa:plataforma]"
        }

        this.materialesService.find(materialId, params).then((response: Material) => {
            this.material = response
            this.showMarcarComoRevisada =
                this.material.revision_recursos.length == 0 ||
                new Date(this.material.revision_recursos[0].created_at).getTime() <
                    new Date(this.material.created_at).getTime()
            this.showDesmarcarComoRevisada = !this.showMarcarComoRevisada

            this.plataformasService.where({ without_old: 1 }).then((plataformas: Plataforma[]) => {
                this.plataformas = plataformas
                this.materialesService.getEstadisticasTiempoMaterial(this.material.id).then(estadisticas => {
                    this.estadistica = estadisticas as any
                    this.total = this.sumTotal(this.estadistica)
                    this.loadingLayout.ready()
                })
            })

            this.loadingLayout.ready()
        })
    }

    marcarComoRevisada() {
        this.loadingLayout.standby()
        const revisionRecurso = new RevisionRecurso()
        revisionRecurso.usuario_id = +this.authService.getUserData().id
        revisionRecurso.recurso_type = "Material"
        revisionRecurso.recurso_id = this.material.id

        this.revisionRecursosService.save(revisionRecurso).then(rr => {
            this.loadMaterial(this.material.id)
        })
    }

    desmarcarComoRevisada() {
        this.loadingLayout.standby()
        this.revisionRecursosService.remove(this.material.revision_recursos[0].id).then(() => {
            this.loadMaterial(this.material.id)
        })
    }

    sumTotal(estadisticas) {
        let total_vista = 0
        let tiempo_acumulado = 0
        let total_like = 0
        let total_dislike = 0
        Object.keys(estadisticas).forEach(e => {
            if (estadisticas[e]) {
                total_vista = total_vista + estadisticas[e].total_vista
                tiempo_acumulado = tiempo_acumulado + estadisticas[e].tiempo_acumulado
                total_like = total_like + estadisticas[e].total_like
                total_dislike = total_dislike + estadisticas[e].total_dislike
            }
        })

        return {
            total_vista: total_vista,
            tiempo_acumulado: tiempo_acumulado,
            total_like: total_like,
            total_dislike: total_dislike
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
