import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    TipoInstrumentoPlataforma,
    TipoInstrumentoPlataformas,
    TipoInstrumentoPlataformaView
} from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"

@Component({
    selector: "tipo-instrumento-plataformas",

    templateUrl: "tipo_instrumento_plataformas.component.html"
})
export class TipoInstrumentoPlataformasComponent implements OnInit {
    // labels = PreguntaForm.formParams
    show_params = TipoInstrumentoPlataformaView.viewParams
    @Input() tipoInstrumentoPlataformas: TipoInstrumentoPlataforma[] = []
    tableButtonsArray: any
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Output() onDelete = new EventEmitter()

    constructor(
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        private router: Router,
        protected authService: AuthService,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", callModal: false, glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    view(tipoInstrumentoPlataforma: TipoInstrumentoPlataforma) {
        this.router.navigate(["tipo_instrumento_plataformas/" + tipoInstrumentoPlataforma.id])
    }

    edit(tipoInstrumentoPlataforma: TipoInstrumentoPlataforma) {
        this.router.navigate(["tipo_instrumento_plataformas/" + tipoInstrumentoPlataforma.id + "/edit"])
    }

    id(index, value) {
        return value.id
    }

    delete(id: number) {
        this.tipoInstrumentoPlataformasService.remove(id).then(response => {
            this.onDelete.emit()
        })
    }

    showModalDelete(tipoInstrumentoPlataforma: TipoInstrumentoPlataforma) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar este tipo instrumento plataforma?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(tipoInstrumentoPlataforma.id))
    }
}
