import { Component, OnInit, ViewChild } from "@angular/core"
import { Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "app-preguntas-list",
    templateUrl: "./preguntas-list.component.html",
    styleUrls: ["./preguntas-list.component.scss"]
})
export class PreguntasListComponent implements OnInit {
    preguntas: Pregunta[] = []
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private preguntasService: Preguntas) {}

    ngOnInit() {}

    showAll(page: number, per: number) {
        const params = {
            page: page,
            per: per,
            methods: "cantidad_reportes",
            include: "[certificacion_recursos]"
        }
        return this.preguntasService.where(params).then((preguntas: Pregunta[], total: number) => {
            this.preguntas = preguntas
            this.loadingLayout.ready()
            return total
        })
    }
}
