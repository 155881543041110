<div class="embed-plataformas">
    <div class="row">
        <div class="col-md-7">
            <div class="selector-plataformas">
                <h4>
                    <span class="glyphicon glyphicon-search title-icon" aria-hidden="true"></span>
                    &nbsp;Seleccionar Plataforma(s)
                </h4>
                <select class="form-control" [(ngModel)]="plataforma_actual" required>
                    <option value="" disabled selected hidden>-- Seleccione Plataforma --</option>
                    <option *ngFor="let plataforma of plataformas" [ngValue]="plataforma.id">
                        {{ plataforma.plataforma }}
                    </option>
                </select>
                <div class="clearfix buttons-container">
                    <button
                        [disabled]="disabled"
                        (click)="addPlataforma()"
                        type="button"
                        class="btn btn-default pull-right btn-sm"
                    >
                        Agregar Plataforma
                    </button>
                    <button
                        [disabled]="disabled"
                        (click)="addAll()"
                        type="button"
                        class="btn btn-default pull-right btn-sm"
                    >
                        Agregar Todas
                    </button>
                    <button
                        [disabled]="disabled"
                        (click)="removeAll()"
                        type="button"
                        class="btn btn-default pull-right btn-sm"
                    >
                        Remover Todas
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="lista-plataformas-container">
                <h4>
                    <span class="glyphicon glyphicon-list title-icon" aria-hidden="true"></span>
                    &nbsp;Listado de plataformas
                </h4>
                <ul class="lista-plataformas">
                    <div *ngFor="let plataforma of plataformas">
                        <li *ngIf="plataformas_seleccionadas.indexOf(plataforma.id) > -1">
                            {{ plataforma.plataforma }}
                            <button
                                [disabled]="disabled"
                                (click)="removePlataforma(plataforma.id)"
                                type="button"
                                class="btn btn-default btn-sm"
                            >
                                <span class="glyphicon glyphicon-remove"></span>
                            </button>
                        </li>
                    </div>
                </ul>
                <div *ngIf="plataformas_seleccionadas.length == 0" class="empty-list">
                    No hay plataformas seleccionadas.
                </div>
            </div>
        </div>
    </div>
</div>
