import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Materiales } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    template: ""
})
export class MaterialesDeleteComponent implements OnInit, OnDestroy {
    private sub: Subscription

    constructor(private materialesService: Materiales, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.materialesService.remove(+params["id"]).then(response => {
                this.router.navigate(["materiales"])
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
