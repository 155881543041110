import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { GrupoPreguntaComponent } from "./grupo_pregunta.component"
import { GrupoPreguntasDeleteComponent } from "./grupo_preguntas.delete.component"
import { GrupoPreguntasEditComponent } from "./grupo_preguntas.edit.component"
import { GrupoPreguntasNewComponent } from "./grupo_preguntas.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"
import { GrupoPreguntasListComponent } from "./grupo-preguntas-list/grupo-preguntas-list.component"

export const grupo_preguntasRoutes: Routes = [
    {
        path: "grupo_preguntas",
        component: GrupoPreguntasListComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GrupoPreguntas#index" }
    },
    {
        path: "grupo_preguntas/new",
        component: GrupoPreguntasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GrupoPreguntas#create" }
    },
    {
        path: "grupo_preguntas/:id",
        component: GrupoPreguntaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GrupoPreguntas#show" }
    },
    {
        path: "grupo_preguntas/:id/edit",
        component: GrupoPreguntasEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GrupoPreguntas#update" }
    },
    {
        path: "grupo_preguntas/:id/delete",
        component: GrupoPreguntasDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "GrupoPreguntas#destroy" }
    }
]

export const grupoPreguntasRouting = RouterModule.forChild(grupo_preguntasRoutes)
