import { BaseForm } from "@puntaje/shared/core"
import { Clasificaciones } from "./clasificaciones.service"
import { Validators } from "@angular/forms"

export class ClasificacionClasificacionForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            clasificacion_id: { label: "Clasificación", type: "hidden", visible: false },
            clasificacion_ref_id: {
                label: "Clasificación",
                type: "number",
                visible: true,
                validations: [Validators.min(1)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false }
        }
    }
}
