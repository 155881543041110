import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    GrupoPregunta,
    GrupoPreguntas,
    GrupoPreguntaView,
    Pregunta,
    Preguntas,
    RevisionRecursos,
    RevisionRecurso
} from "@puntaje/nebulosa/api-services"
import { PdfView, AuthService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { GrupoPreguntaPreviewLatexComponent } from "./grupo-pregunta-preview-latex/grupo-pregunta-preview-latex.component"

@Component({
    templateUrl: "grupo_pregunta.component.html",
    styleUrls: ["grupo_pregunta.component.scss"]
})
export class GrupoPreguntaComponent implements OnInit, OnDestroy {
    grupo_pregunta: GrupoPregunta
    private sub: Subscription
    show_params = GrupoPreguntaView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutPreguntas") loadingLayoutPreguntas: LoadingLayoutComponent
    preguntas: Pregunta[]
    paramsPreviewLatex: any
    showMarcarComoRevisada: boolean
    showDesmarcarComoRevisada: boolean
    

    constructor(
        private grupo_preguntasService: GrupoPreguntas,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private revisionRecursosService: RevisionRecursos,
        private preguntasService: Preguntas
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.loadGrupoPregunta(+params["id"])
        })
    }

    

    loadGrupoPregunta(grupoPreguntaId) {
        this.loadingLayout.standby()
        const params = {
            revision_recurso: {
                usuario_id: [null, this.authService.getUserData().id]
            },
            include: "[revision_recursos]"
        }

        this.grupo_preguntasService.find(grupoPreguntaId, params).then((response: GrupoPregunta) => {
            this.grupo_pregunta = response
            this.showMarcarComoRevisada =
                this.grupo_pregunta.revision_recursos.length == 0 ||
                new Date(this.grupo_pregunta.revision_recursos[0].created_at).getTime() <
                    new Date(this.grupo_pregunta.created_at).getTime()
            this.showDesmarcarComoRevisada = !this.showMarcarComoRevisada

            this.loadPreguntas()
            this.loadingLayout.ready()
            this.updatePreviewLatex()
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    marcarComoRevisada() {
        this.loadingLayout.standby()
        const revisionRecurso = new RevisionRecurso()
        revisionRecurso.usuario_id = +this.authService.getUserData().id
        revisionRecurso.recurso_type = "GrupoPregunta"
        revisionRecurso.recurso_id = this.grupo_pregunta.id

        this.revisionRecursosService.save(revisionRecurso).then(rr => {
            this.loadGrupoPregunta(this.grupo_pregunta.id)
        })
    }

    desmarcarComoRevisada() {
        this.loadingLayout.standby()
        this.revisionRecursosService.remove(this.grupo_pregunta.revision_recursos[0].id).then(() => {
            this.loadGrupoPregunta(this.grupo_pregunta.id)
        })
    }

    loadPreguntas() {
        this.preguntasService
            .where({ pregunta: { grupo_pregunta_id: this.grupo_pregunta.id } })
            .then((response: Pregunta[]) => {
                this.preguntas = response
                this.loadingLayoutPreguntas.ready()
            })
    }

    updatePreviewLatex() {
        const params: any = {
            grupo_pregunta: {
                ...this.grupo_pregunta
            }
        }

        this.paramsPreviewLatex = params
    }
}
