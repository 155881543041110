import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"

export class TaxativoForm extends BaseForm {
    public static get formParams(): any {
        return {
            taxativo: { label: "Taxativo", type: "ckeditor", visible: true, validations: [Validators.required] },
            taxativo_latex: { label: "Taxativo Latex", type: "textarea", visible: true },
            imagen: { label: "Imagen", type: "text", visible: true }
        }
    }
}
