<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-tipo-toolnavbar [displayButtons]="display_buttons_options"></clasificacion-tipo-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Formulario tipo de clasificación" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-md-8 col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            *ngFor="let key of params | keys"
                            [form]="form"
                            [params]="params[key]"
                            [(value)]="clasificacionTipo[key]"
                            [key]="key"
                        ></form-input>
                        <button (click)="save()" type="button" class="btn btn-default">
                            Guardar
                        </button>
                        <button (click)="clear()" type="button" class="btn btn-default">
                            Borrar
                        </button>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
