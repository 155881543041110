import { Component, OnInit, ViewChild } from "@angular/core"
import { Subscription } from "rxjs"
import { GeneradorInstrumento, GeneradorInstrumentos, GeneradorInstrumentoView } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { Router } from "@angular/router"
import { SimpleModalService } from "../../../layouts/simple_modal/simple_modal.service"
import { AuthService } from "angularx-social-login"
import { GeneradorInstrumentosBuscadorService } from "../generador_instrumentos_buscador.service"

@Component({
    selector: "app-generador-instrumentos",
    templateUrl: "./generador-instrumentos.component.html",
    styleUrls: ["./generador-instrumentos.component.scss"]
})
export class GeneradorInstrumentosComponent implements OnInit {
    sub: Subscription
    searchParams: any
    generadores: GeneradorInstrumento[] = []
    show_params = GeneradorInstrumentoView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        private generadorInstrumentosService: GeneradorInstrumentos,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected generadorInstrumentosBuscadorService: GeneradorInstrumentosBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            copy: {
                function: this.copy.bind(this),
                text: "Copiar",
                glyphicon: "glyphicon-duplicate",
                permiso: "GeneradorInstrumentos#create"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            asociar_generador_diagnostico: {
                function: generadorInstrumento => {
                    this.router.navigate([
                        `generador_instrumentos/${generadorInstrumento.id}/asociar_generador_diagnostico`
                    ])
                },
                text: "Asociar generador de diagnóstico",
                glyphicon: "glyphicon-tag",
                permiso: "GeneradorInstrumentoConversiones#create"
            }
        }
        this.sub = this.generadorInstrumentosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            ...this.searchParams,
            page: page,
            per: per,
            include: "[tipo_instrumento,asignatura_plataforma:[asignatura,plataforma]]"
        }
        this.generadorInstrumentosService.disableIgnoreModel()
        return this.generadorInstrumentosService
            .where(params)
            .then((generadores: GeneradorInstrumento[], total: number) => {
                this.generadorInstrumentosService.enableIgnoreModel()
                this.generadores = generadores
                this.loadingLayout.ready()
                return total
            })
    }

    view(generador: GeneradorInstrumento) {
        this.router.navigate(["generador_instrumentos/" + generador.id])
    }

    edit(generador: GeneradorInstrumento) {
        this.router.navigate(["generador_instrumentos/" + generador.id + "/edit"])
    }

    copy(generador: GeneradorInstrumento) {
        this.router.navigate(["generador_instrumentos/" + generador.id + "/copy"])
    }

    delete(id: number) {
        this.router.navigate(["generador_instrumentos/" + id + "/delete"])
    }

    showModalDelete(generador: GeneradorInstrumento) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar el generador '" + generador.generador_instrumento + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(generador.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
