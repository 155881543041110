import { Component, OnInit } from "@angular/core"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { GeneradorInstrumentoBaseForm } from "../generador-instrumento-base-form"

@Component({
    selector: "app-generador-instrumentos-new",
    templateUrl: "../generador-instrumentos-form.component.html",
    styleUrls: ["./generador-instrumentos-new.component.scss"]
})
export class GeneradorInstrumentosNewComponent extends GeneradorInstrumentoBaseForm implements OnInit {
    display_buttons_options = "all"

    ngOnInit() {
        this.setUpForm(new GeneradorInstrumento())
    }

    back() {
        this.router.navigate(["generador_instrumentos"])
    }

    apiPromise(generador) {
        return this.generadorInstrumentosService.save(generador)
    }
}
