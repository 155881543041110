import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Persona, PersonaForm, Personas } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "personas.form.component.html"
})
export class PersonasNewComponent implements OnInit {
    persona: Persona = new Persona()
    params = PersonaForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar persona"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private personasService: Personas, private router: Router) {}

    ngOnInit() {
        this.form = PersonaForm.getForm(this.persona)
        this.loadingLayout.ready()
    }

    save() {
        PersonaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.personasService.save(this.persona).then((persona: Persona) => {
                this.router.navigate(["personas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.persona = new Persona()
            PersonaForm.markFormControlsAsPristine(this.form)
            PersonaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
