import { Component, OnInit, Input } from "@angular/core"
import { BaseModel } from "@puntaje/shared/core"
import { RevisionRecurso, RevisionRecursos } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "revisiones-recurso",
    templateUrl: "./revisiones-recurso.component.html",
    styleUrls: ["./revisiones-recurso.component.scss"]
})
export class RevisionesRecursoComponent implements OnInit {
    @Input() recurso: BaseModel
    @Input() recursoTipo: string
    revisionRecursos: RevisionRecurso[]

    constructor(protected revisionRecursosService: RevisionRecursos) {}

    ngOnInit() {
        const revParams = {
            revision_recurso: {
                recurso_type: this.recursoTipo,
                recurso_id: this.recurso.id
            },
            include: "[usuario:persona]"
        }

        this.revisionRecursosService.where(revParams).then(revisionRecursos => {
            this.revisionRecursos = revisionRecursos
        })
    }
}
