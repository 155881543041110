import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { AsignaturaComponent } from "./asignatura.component"
import { AsignaturasComponent } from "./asignaturas.component"
import { AsignaturasDeleteComponent } from "./asignaturas.delete.component"
import { AsignaturasEditComponent } from "./asignaturas.edit.component"
import { AsignaturasNewComponent } from "./asignaturas.new.component"
import { asignaturasRouting } from "./asignaturas.routing"
import { AsignaturasToolNavbarComponent } from "./asignaturas.toolnavbar.component"
import { PoliciesModule } from "../policies/policies.module"
import { EmbedAsignaturasComponent } from "./embed-asignaturas/embed-asignaturas.component"

@NgModule({
    imports: [
        PoliciesModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        LayoutsModule,
        asignaturasRouting,
        ModelViewModule,
        PaginatorModule,
        NebuModule
    ],
    declarations: [
        AsignaturasComponent,
        AsignaturaComponent,
        AsignaturasNewComponent,
        AsignaturasEditComponent,
        AsignaturasDeleteComponent,
        AsignaturasToolNavbarComponent,
        EmbedAsignaturasComponent
    ],
    exports: [EmbedAsignaturasComponent]
})
export class AsignaturasModule {}
