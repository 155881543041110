import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Persona, Personas, PersonaView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { PersonasBuscadorService } from "./personas-buscador/personas_buscador.service"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "personas.component.html"
})
export class PersonasComponent implements OnInit {
    personas: Persona[]
    show_params = PersonaView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    busquedaPersonasSub: Subscription
    searchParams: any

    constructor(
        private personasService: Personas,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected personasBuscadorService: PersonasBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.busquedaPersonasSub = this.personasBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            ...this.searchParams,
            page: page,
            per: per
        }

        return this.personasService.where(params).then((personas: Persona[], total: number) => {
            this.personas = personas
            this.loadingLayout.ready()

            return total
        })
    }

    view(persona: Persona) {
        this.router.navigate(["personas/" + persona.id])
    }

    edit(persona: Persona) {
        this.router.navigate(["personas/" + persona.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["personas/" + id + "/delete"])
    }

    showModalDelete(persona: Persona) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar a " + persona.nombre + " " + persona.apellido_paterno + "?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(persona.id))
    }

    ngOnDestroy() {
        this.busquedaPersonasSub.unsubscribe()
    }
}
