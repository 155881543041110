import { BaseForm } from "@puntaje/shared/core"

export class AlternativaForm extends BaseForm {
    public static get formParams(): any {
        const possibleNumberOfAlts = 10
        const altsArr = new Array(possibleNumberOfAlts).fill(0)

        return {
            letra: { label: "Letra", type: "text", visible: true },
            alternativa: { label: "Alternativa", type: "ckeditor", visible: true },
            correcta: { label: "Correcta", type: "checkbox", visible: true },
            ocultable: { label: "Ocultable", type: "checkbox", visible: true },
            orden_ocultar: {
                label: "Orden Ocultar",
                type: "select",
                visible: true,
                options: altsArr.map((_, i) => ({
                    id: i,
                    toString: () => i
                }))
            },
            imagen: { label: "Imagen", type: "text", visible: true },
            explicacion_distractor: { label: "Explicación Distractor", type: "textarea", visible: true },
            alternativa_latex: { label: "Alternativa Latex", type: "textarea", visible: true },
            orden: { label: "Orden", type: "hidden", visible: true },
            pista: { label: "Pista", type: "textarea", visible: true }
        }
    }
}
