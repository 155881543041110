/** @format */

import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { DragulaModule } from "ng2-dragula"
import { PopoverModule } from "ngx-bootstrap"
import { FilterModule, FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { CertificacionRecursosModule } from "../certificacion_recursos/certificacion_recursos.module"
import { ClasificacionesModule } from "../clasificaciones/clasificaciones.module"
import { GrupoPreguntasModule } from "../grupo_preguntas/grupo_preguntas.module"
import { ReportesModule } from "../reportes/reportes.module"
import { RevisionesRecursoModule } from "../revisiones-recurso/revisiones-recurso.module"
import { RespuestaConstruidaComponent } from "./partials/respuesta-construida/respuesta-construida.component"
import { PreguntaComponent } from "./pregunta.component"
import { PreguntaSmallComponent } from "./pregunta.small.component"
import { PreguntasListComponent } from "./preguntas-list/preguntas-list.component"
import { PreguntasComponent } from "./preguntas.component"
import { PreguntasDeleteComponent } from "./preguntas.delete.component"
import { PreguntasEditComponent } from "./preguntas.edit.component"
import { PreguntasNewComponent } from "./preguntas.new.component"
import { routing } from "./preguntas.routing"
import { PreguntasToolNavbarComponent } from "./preguntas.toolnavbar.component"
import { PreguntasBuscarComponent } from "./preguntas_buscar.component"
import { PreguntasFilterComponent } from "./preguntas_filter.component"
import { PreguntasUsuarioComponent } from "./preguntas_usuario.component"
import { AsignaturasModule } from "../asignaturas/asignaturas.module"
import { PlataformasModule } from "../plataformas/plataformas.module"
import { EditarMasivamenteComponent } from "./editar-masivamente/editar-masivamente.component"
import { ListaDesplegableComponent } from "./partials/lista-desplegable/lista-desplegable.component"
import { PreguntaPreviewLatexComponent } from "./pregunta-preview-latex/pregunta-preview-latex.component"
import { PreguntaVariacionModalComponent } from "./pregunta_variacion_modal.component"
import { PoliciesModule } from "../policies/policies.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        FilterModule,
        PaginatorModule,
        GrupoPreguntasModule,
        DragulaModule,
        ClasificacionesModule,
        ReportesModule,
        Angular2FontawesomeModule,
        PopoverModule,
        NebuModule,
        PoliciesModule,
        CertificacionRecursosModule,
        routing,
        RevisionesRecursoModule,
        AsignaturasModule,
        PlataformasModule
    ],
    declarations: [
        PreguntaComponent,
        PreguntasComponent,
        PreguntasNewComponent,
        PreguntasEditComponent,
        PreguntasDeleteComponent,
        PreguntasToolNavbarComponent,
        PreguntaSmallComponent,
        PreguntasUsuarioComponent,
        PreguntasBuscarComponent,
        PreguntasFilterComponent,
        PreguntasListComponent,
        RespuestaConstruidaComponent,
        EditarMasivamenteComponent,
        ListaDesplegableComponent,
        PreguntaPreviewLatexComponent,
        PreguntaVariacionModalComponent
    ],
    exports: [PreguntaSmallComponent, PreguntasFilterComponent, PreguntasComponent]
})
export class PreguntasModule {}
