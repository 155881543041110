<ul class="nav nav-sidebar navbar-collapse collapse" aria-expanded="false">
    <li *permiso="'MonitorRecursos#index'">
        <a routerLink="/monitor_recursos" [routerLinkActive]="['active']">Monitor Recursos</a>
    </li>
    <li *permiso="'Asignaturas#index'">
        <a routerLink="/asignaturas" [routerLinkActive]="['active']">Asignaturas</a>
    </li>
    <li *permiso="'Lotes#index'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuLotesPreguntas">
            Lotes de preguntas
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuLotesPreguntas" class="collapse submenu">
            <li>
                <a routerLink="/mis_lotes" [routerLinkActive]="['active']">Mis Lotes</a>
            </li>
            <li>
                <a routerLink="/lotes" [routerLinkActive]="['active']">Lista de Lotes</a>
            </li>
        </ul>
    </li>
    <li *permiso="'Materiales#index'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuMateriales">
            Materiales
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuMateriales" class="collapse submenu">
            <li>
                <a routerLink="/mis_materiales" [routerLinkActive]="['active']">Mis Materiales</a>
            </li>
            <li>
                <a routerLink="/materiales" [routerLinkActive]="['active']">Lista de Materiales</a>
            </li>
            <li>
                <a routerLink="/editar_materiales_masivamente" [routerLinkActive]="['active']">Editar Masivamente</a>
            </li>
        </ul>
    </li>
    <!-- <li>
        <a routerLink="/ayudas">Material de Ayuda</a>
    </li> -->
    <li *permiso="'Perfiles#index'">
        <a routerLink="/perfiles" [routerLinkActive]="['active']">Perfiles</a>
    </li>
    <li *permiso="'Personas#index'">
        <a routerLink="/personas" [routerLinkActive]="['active']">Personas</a>
    </li>
    <li *permiso="'Plataformas#index'">
        <a routerLink="/plataformas" [routerLinkActive]="['active']">Plataformas</a>
    </li>
    <li *permiso="'PlanEstudios#index'">
        <a routerLink="/plan_estudios" [routerLinkActive]="['active']">Planes de Estudio</a>
    </li>
    <li *permiso="'Preguntas#index'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuPreguntas">
            Preguntas
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuPreguntas" class="collapse submenu">
            <li>
                <a routerLink="/mis_preguntas" [routerLinkActive]="['active']">Mis Preguntas</a>
            </li>
            <li>
                <a routerLink="/preguntas" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                    Listado de Preguntas
                </a>
            </li>
            <li>
                <a
                    routerLink="/preguntas/buscar"
                    [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{ exact: true }"
                >
                    Buscar Preguntas
                </a>
            </li>
            <li>
                <a routerLink="/editar_preguntas_masivamente" [routerLinkActive]="['active']">Editar Masivamente</a>
            </li>
        </ul>
    </li>
    <li *permiso="'GrupoPreguntas#index'">
        <a routerLink="/grupo_preguntas" [routerLinkActive]="['active']">Grupos de Preguntas</a>
    </li>
    <li *permiso="'RosettaStone#index'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuRosetta">
            Piedra Rosetta
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuRosetta" class="collapse submenu">
            <li>
                <a routerLink="/piedra_rosetta" [routerLinkActive]="['active']">Preguntas y Materiales</a>
            </li>
        </ul>
    </li>
    <li *permiso="'GeneradorInstrumentos#index'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuGeneradorInstrumentos">
            Generadores Instrumento
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuGeneradorInstrumentos" class="collapse submenu">
            <li>
                <a routerLink="/generador_instrumentos" [routerLinkActive]="['active']">Generador Instrumentos</a>
            </li>
        </ul>
    </li>
    <li *permiso="'ReporteRazones#index'">
        <a routerLink="/reporte_razones" [routerLinkActive]="['active']">Razones de reportes</a>
    </li>
    <li *permiso="'ReporteSubRazones#index'">
        <a routerLink="/reporte_sub_razones" [routerLinkActive]="['active']">Sub Razones de reportes</a>
    </li>
    <li [routerLinkActive]="['active']" *permiso="'Reportes#index'">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuReporte">
            Reportes
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuReporte" class="collapse submenu">
            <li>
                <a routerLink="/reportes/agrupados" [routerLinkActive]="['active']">Por Recurso</a>
            </li>
            <li>
                <a routerLink="/reportes" [routerLinkActive]="['active']">Pendientes</a>
            </li>
            <li>
                <a routerLink="/reportes/resueltos" [routerLinkActive]="['active']">Resueltos</a>
            </li>
        </ul>
    </li>
    <li *permiso="'Usuarios#indexGeneral'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuUsuario">
            Usuarios
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuUsuario" class="collapse submenu">
            <li *permiso="'Usuarios#index'">
                <a routerLink="/usuarios" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
                    Lista de Usuarios
                </a>
            </li>
            <li *permiso="'Usuarios#indexAsociados'">
                <a
                    routerLink="/usuarios/asociados"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLinkActive]="['active']"
                >
                    Mis usuarios asociados
                </a>
            </li>
            <li *permiso="'Usuarios#index'">
                <a
                    routerLink="/usuarios/sub_administradores"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLinkActive]="['active']"
                >
                    Sub Administradores
                </a>
            </li>
        </ul>
    </li>
    <li *permiso="'Clasificaciones#index'">
        <a routerLink="/clasificaciones" [routerLinkActive]="['active']">Clasificaciones</a>
    </li>
    <li *permiso="'ClasificacionTipos#index'">
        <a routerLink="/clasificacion_tipos" [routerLinkActive]="['active']">Tipos de Clasificación</a>
    </li>
    <li *permiso="'Usuarios#indexGeneral'" [routerLinkActive]="['active']">
        <a href="javascript:;" data-toggle="collapse" data-target="#submenuAlias">
            Tipos de Instrumento
            <span class="glyphicon glyphicon-menu-down submenu-plus" aria-hidden="true"></span>
        </a>
        <ul id="submenuAlias" class="collapse submenu">
            <li *permiso="'Usuarios#index'">
                <a
                    routerLink="/tipo_instrumento_plataformas"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLinkActive]="['active']"
                >
                    Modificar Alias
                </a>
            </li>
        </ul>
    </li>
    <li *permiso="'Ayudas#index'">
        <a routerLink="/ayudas" [routerLinkActive]="['active']">Ayudas</a>
    </li>
</ul>
