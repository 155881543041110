import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Asignatura, Asignaturas, AsignaturaView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "asignaturas.component.html"
})
export class AsignaturasComponent implements OnInit {
    asignaturas: Asignatura[]
    show_params = AsignaturaView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private asignaturasService: Asignaturas,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    keys(asignatura: Asignatura): Array<string> {
        return Object.keys(asignatura)
    }

    showAll(page: number, per: number) {
        return this.asignaturasService
            .where({ page: page, per: per })
            .then((asignaturas: Asignatura[], total: number) => {
                this.asignaturas = asignaturas
                this.loadingLayout.ready()
                return total
            })
    }

    view(asignatura: Asignatura) {
        this.router.navigate(["asignaturas/" + asignatura.id])
    }

    edit(asignatura: Asignatura) {
        this.router.navigate(["asignaturas/" + asignatura.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["asignaturas/" + id + "/delete"])
    }

    showModalDelete(asignatura: Asignatura) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar la asignatura '" + asignatura.asignatura + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(asignatura.id))
    }
}
