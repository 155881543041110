import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Ayuda, Ayudas, AyudaView } from "@puntaje/nebulosa/api-services"
import { PdfView, YoutubeVideoView } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "ayuda.component.html",
    styleUrls: ["ayuda.component.scss"]
})
export class AyudaComponent implements OnInit, OnDestroy {
    ayuda: Ayuda
    show_params = AyudaView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private ayudasService: Ayudas, private router: Router, private route: ActivatedRoute) {}

    getVisualizer() {
        if (this.ayuda.material_tipo_id == 1) return { label: "Previsualizar", type: PdfView, tableVisible: true }
        else return { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.ayudasService.find(+params["id"]).then((response: Ayuda) => {
                this.ayuda = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
