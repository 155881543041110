<button class="btn buttonAsociarRecursos" (click)="changeView.emit(recurso.recurso)">
    <fa name="arrow-left" class="icon"></fa>
    Ver {{ recurso.descripcion }} asociad{{ recurso.g }}s
</button>
<h3>Asociar {{ recurso.descripcion }}</h3>

<loading-layout #loadingLayout [opaque]="true">
    <div class="ids-bar">
        <input class="form-control" name="ids" type="text" placeholder="IDS: 1, 2, 3..." [(ngModel)]="ids" />
        <button type="button" class="btn btn-default" (click)="asociarIds()">
            <fa name="plus-circle" class="icono"></fa>
            Asociar recurso
        </button>
    </div>
    <p *ngIf="showSuccessFeedback" class="alert alert-success">
        <fa name="check" class="icono"></fa>
        Los siguientes recursos se han asociado correctamente:&nbsp;{{ successIds }}
    </p>
    <p *ngIf="showExistentesFeedback" class="alert alert-danger">
        <fa name="ban" class="icono"></fa>
        Los siguientes recursos no pudieron ser asociados (ya fueron asignados previamente):&nbsp;{{ existentesIds }}
    </p>
    <p *ngIf="showNoAExistenteFeedback" class="alert alert-danger">
        <fa name="ban" class="icono"></fa>
        Los siguientes recursos no pudieron ser asociados (no existen):&nbsp;{{ noExistentesIds }}
    </p>
    <p *ngIf="showNoAsignableFeedback" class="alert alert-danger">
        <fa name="ban" class="icono"></fa>
        Los siguientes recursos no pudieron ser asociados (usted no tiene acceso a los recursos):&nbsp;{{
            noAsignableIds
        }}
    </p>
</loading-layout>
