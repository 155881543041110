import { Component, Input } from "@angular/core"
import { Loading } from "@puntaje/shared/core"

/*#################################

Template simple que carga un loader por defecto mientras su variable 'loading' sea verdadera, y luego carga el contenido
Se usa en conjunto con la clase 'Loading' (que se encuentra en shared/templates), osea que quien llame este template debería extender de la
clase 'Loading'.

#################################*/

@Component({
    templateUrl: "loading_layout.component.html",
    selector: "loading-layout",
    styleUrls: ["loading_layout.component.scss"]
})
export class LoadingLayoutComponent extends Loading {
    @Input() loading: boolean
    @Input() loaderType: string = "nebu"
    @Input() opaque: boolean = false
    @Input() noMarginY: boolean = false

    constructor() {
        super(true)
    }
}
