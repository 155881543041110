<loading-layout #loadingLayout>
    <h4>Editar preguntas del lote</h4>
    <div class="clearfix extra-edit-options-container">
        <button
            class="extra-edit-btn btn btn-default"
            (click)="saveAllLotePreguntas()"
            [class.pending-save]="pendingOrderChange"
        >
            <span class="glyphicon glyphicon-floppy-disk" aria-hidden="true"></span>
            &nbsp;Guardar orden
        </button>
        <button class="extra-edit-btn btn btn-default" (click)="normalizeOrden()">
            <span class="glyphicon glyphicon-sort-by-order" aria-hidden="true"></span>
            &nbsp;Normalizar orden
        </button>
    </div>
    <ul class="preguntas-list">
        <li *ngFor="let lote_pregunta of lote_preguntas; let i = index; trackBy: id">
            <div class="pregunta-row">
                <div class="pregunta-cell orden-cell">
                    <button
                        class="btn btn-default btn-sm orden-btn-dec"
                        (click)="decreaseOrden(i)"
                        [disabled]="!enableOrderChange || i == 0"
                        [class.invisible-btn]="i == 0"
                    >
                        <span class="glyphicon glyphicon-arrow-up" aria-hidden="true"></span>
                    </button>
                    <div *ngIf="currentEditOrderIndex != i" class="orden-value">
                        {{ lote_pregunta.orden }}
                        <button class="btn-edit" (click)="setEditOrden(i)" [disabled]="!enableOrderChange">
                            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div *ngIf="currentEditOrderIndex == i" class="orden-input">
                        <input
                            name="orden"
                            type="number"
                            class="form-control"
                            type="text"
                            [(ngModel)]="editOrderValue"
                            placeholder=""
                            (keyup.enter)="confirmEditOrden(i)"
                        />
                        <button class="btn-cancel" (click)="clearEditOrden()">
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        </button>
                        <button class="btn-edit" (click)="confirmEditOrden(i)" [disabled]="!enableOrderChange">
                            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                        </button>
                    </div>
                    <button
                        class="btn btn-default btn-sm orden-btn-inc"
                        (click)="increaseOrden(i)"
                        [disabled]="!enableOrderChange || i == lote_preguntas.length - 1"
                        [class.invisible-btn]="i == lote_preguntas.length - 1"
                    >
                        <span class="glyphicon glyphicon-arrow-down" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="all-width pregunta-cell">
                    <a [routerLink]="['/preguntas', lote_pregunta.pregunta_id]" target="_blank">
                        Pregunta id: #{{ lote_pregunta.pregunta_id }}
                    </a>
                    <pregunta-small [pregunta]="lote_pregunta.pregunta"></pregunta-small>
                    <div
                        class="clasificaciones"
                        *ngIf="
                            lote_pregunta.pregunta &&
                            lote_pregunta.pregunta.clasificaciones &&
                            lote_pregunta.pregunta.clasificaciones.length > 0
                        "
                    >
                        <ng-container *ngFor="let c of lote_pregunta.pregunta.clasificaciones">
                            <button
                                class="clasificacion with-popover"
                                popover="{{ c.descripcion }}"
                                triggers="focus"
                                *ngIf="c.descripcion && c.descripcion.length > 0"
                            >
                                {{ c.clasificacion }}&nbsp;
                                <fa name="plus-square-o" class="plus-info"></fa>
                            </button>
                            <span class="clasificacion" *ngIf="!(c.descripcion && c.descripcion.length > 0)">
                                {{ c.clasificacion }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="pregunta-cell small-padding">
                    <button
                        (click)="view(lote_pregunta.pregunta.id)"
                        class="btn btn-xs btn-default small-margin-bot action-btn"
                    >
                        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                        Ver pregunta
                    </button>
                    <button
                        class="btn btn-xs btn-default small-margin-bot action-btn"
                        data-toggle="modal"
                        data-target="#simpleModal"
                        (click)="showModalDelete(lote_pregunta.id)"
                    >
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        Sacar del lote
                    </button>
                </div>
            </div>
        </li>
    </ul>
</loading-layout>
