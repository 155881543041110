import { BaseForm } from "@puntaje/shared/core"
import { Personas } from "./personas.service"

export class AsignaturaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            asignatura: { label: "Asignatura", type: "text", visible: true },
            abreviacion: { label: "Abreviación", type: "text", visible: true },
            encargado_id: { label: "Encargado", type: "select", visible: true, options: { class: Personas } },
            codigo_estilo: { label: "Código de estilo", type: "text", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false }
        }
    }
}
