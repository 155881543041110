<logged-layout>
    <logged-layout-toolnavbar>
        <reporte-razon-toolnavbar [displayButtons]="'new'"></reporte-razon-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Razones de Reporte</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="reporteRazones"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll" [useQueryParams]="true"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
