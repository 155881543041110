import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { GrupoPreguntaComponent } from "./grupo_pregunta.component"
import { GrupoPreguntaPreviewComponent } from "./grupo_pregunta.preview.component"
import { GrupoPreguntasComponent } from "./grupo_preguntas.component"
import { GrupoPreguntasDeleteComponent } from "./grupo_preguntas.delete.component"
import { GrupoPreguntasEditComponent } from "./grupo_preguntas.edit.component"
import { GrupoPreguntasNewComponent } from "./grupo_preguntas.new.component"
import { grupoPreguntasRouting } from "./grupo_preguntas.routing"
import { GrupoPreguntasToolNavbarComponent } from "./grupo_preguntas.toolnavbar.component"
import { GrupoPreguntaPreguntaComponent } from "./grupo_pregunta_pregunta.component"
import { CertificacionRecursosModule } from "../certificacion_recursos/certificacion_recursos.module"
import { RevisionesRecursoModule } from "../revisiones-recurso/revisiones-recurso.module"
import { GrupoPreguntasListComponent } from "./grupo-preguntas-list/grupo-preguntas-list.component"
import { GrupoPreguntaPreviewLatexComponent } from "./grupo-pregunta-preview-latex/grupo-pregunta-preview-latex.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        grupoPreguntasRouting,
        ModelViewModule,
        PaginatorModule,
        NebuModule,
        CertificacionRecursosModule,
        RevisionesRecursoModule
    ],
    declarations: [
        GrupoPreguntasComponent,
        GrupoPreguntaComponent,
        GrupoPreguntasNewComponent,
        GrupoPreguntasEditComponent,
        GrupoPreguntasDeleteComponent,
        GrupoPreguntasToolNavbarComponent,
        GrupoPreguntaPreviewComponent,
        GrupoPreguntaPreguntaComponent,
        GrupoPreguntasListComponent,
        GrupoPreguntaPreviewLatexComponent
    ],
    exports: [GrupoPreguntaPreviewComponent, GrupoPreguntasComponent, GrupoPreguntaPreviewLatexComponent]
})
export class GrupoPreguntasModule {}
