<logged-layout>
    <logged-layout-toolnavbar>
        <reporte-razon-toolnavbar
            *ngIf="reporteRazon"
            [reporteRazon]="reporteRazon"
            [displayButtons]="'all new edit'"
        ></reporte-razon-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Razón de Reporte</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="reporteRazon"
                    [key]="key"
                ></model-simple-view>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
