<logged-layout>
    <logged-layout-toolnavbar>
        <usuario-toolnavbar [usuario]="usuario" [displayButtons]="'all new edit'"></usuario-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Usuario</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="usuario"
                    [key]="key"
                ></model-simple-view>
            </div>
            <br />
            <tab-recursos-asociados [usuario]="usuario"></tab-recursos-asociados>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
