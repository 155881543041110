import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { AuthGuard, FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { ClasificacionComponent } from "./clasificacion.component"
import { ClasificacionesComponent } from "./clasificaciones.component"
import { ClasificacionesDeleteComponent } from "./clasificaciones.delete.component"
import { ClasificacionesEditComponent } from "./clasificaciones.edit.component"
import { ClasificacionesNewComponent } from "./clasificaciones.new.component"
import { clasificacionesRouting } from "./clasificaciones.routing"
import { ClasificacionesToolNavbarComponent } from "./clasificaciones.toolnavbar.component"
import { ClasificacionesBuscadorComponent } from "./clasificaciones_buscador.component"
import { ClasificacionesBuscadorService } from "./clasificaciones_buscador.service"
import { EmbedClasificacionesComponent } from "./embed_clasificaciones.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        PaginatorModule,
        clasificacionesRouting,
        ModelViewModule,
        NebuModule
    ],
    declarations: [
        ClasificacionesComponent,
        ClasificacionComponent,
        ClasificacionesNewComponent,
        ClasificacionesEditComponent,
        ClasificacionesDeleteComponent,
        ClasificacionesToolNavbarComponent,
        EmbedClasificacionesComponent,
        ClasificacionesBuscadorComponent
    ],
    exports: [EmbedClasificacionesComponent],
    providers: [AuthGuard]
})
export class ClasificacionesModule {
    static forRoot(): ModuleWithProviders<ClasificacionesModule> {
        return {
            ngModule: ClasificacionesModule,
            providers: [ClasificacionesBuscadorService]
        }
    }
}
