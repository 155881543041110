import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { AsignaturaComponent } from "./asignatura.component"
import { AsignaturasComponent } from "./asignaturas.component"
import { AsignaturasDeleteComponent } from "./asignaturas.delete.component"
import { AsignaturasEditComponent } from "./asignaturas.edit.component"
import { AsignaturasNewComponent } from "./asignaturas.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const asignaturasRoutes: Routes = [
    {
        path: "asignaturas",
        component: AsignaturasComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Asignaturas#index" }
    },
    {
        path: "asignaturas/new",
        component: AsignaturasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Asignaturas#create" }
    },
    {
        path: "asignaturas/:id",
        component: AsignaturaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Asignaturas#show" }
    },
    {
        path: "asignaturas/:id/edit",
        component: AsignaturasEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Asignaturas#update" }
    },
    {
        path: "asignaturas/:id/delete",
        component: AsignaturasDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Asignaturas#destroy" }
    }
]

export const asignaturasRouting = RouterModule.forChild(asignaturasRoutes)
