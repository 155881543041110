import { Component, Input } from "@angular/core"

/*#################################

Componente tipo loader o spinner

#################################*/

@Component({
    templateUrl: "loader.component.html",
    selector: "loader",
    styleUrls: ["loader.css"]
})
export class LoaderComponent {
    //Especifica el tipo de loader. Tipos disponibles:
    //default
    //default-small
    //nebu
    //nebu-small
    @Input() loaderType: string = ""
}
