import { Validators } from "@angular/forms"
import { BaseForm, Validations } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { MaterialTipos } from "./material_tipos.service"
import { Personas } from "./personas.service"

export class MaterialForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            profesor: {
                label: "Profesor",
                type: "autocomplete",
                visible: true,
                options: { class: Personas },
                validations: [Validators.required]
            },
            material_tipo_id: {
                label: "Tipo de Material",
                type: "select",
                visible: true,
                options: { class: MaterialTipos },
                validations: [Validators.required]
            },
            material: { label: "Nombre material", type: "text", visible: true, validations: [Validators.required] },
            visible: { label: "Visible", type: "checkbox", visible: true },
            contenido: { label: "Contenido", type: "ckeditor", visible: true },
            comentarios: { label: "Comentarios", type: "textarea", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            asignatura_id: {
                label: "Asignatura principal",
                type: "select",
                visible: true,
                options: { class: Asignaturas },
                validations: [Validators.required]
            },
            file: { label: "Archivo", type: "file", visible: true, validations: [Validations.validateFile] },
            url: {
                label: "Enlace a video",
                type: "text",
                visible: true,
                validations: [Validations.validateYoutubeURI]
            },
            thumbnail_file: {
                label: "Thumbnail",
                type: "file",
                visible: true,
                validations: [Validations.validateImage]
            }
        }
    }
}
