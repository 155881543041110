import { CommonModule } from "@angular/common"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, UtilModule, PaginatorModule } from "@puntaje/shared/core"
import { ClasificacionTipoAliasComponent } from "./clasificacion_tipo_alias.component"
import { ClasificacionTipoAliasesComponent } from "./clasificacion_tipo_aliases.component"
import { ClasificacionTipoAliasesDeleteComponent } from "./clasificacion_tipo_aliases.delete.component"
import { ClasificacionTipoAliasesEditComponent } from "./clasificacion_tipo_aliases.edit.component"
import { ClasificacionTipoAliasesNewComponent } from "./clasificacion_tipo_aliases.new.component"
import { ClasificacionTipoAliasesRouting } from "./clasificacion_tipo_aliases.routing"
import { ClasificacionTipoAliasesToolNavbarComponent } from "./clasificacion_tipo_aliases.toolnavbar.component"
import { ClasificacionTipoAliasesBuscadorService } from "./clasificacion-tipo-aliases-buscador.service"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ClasificacionTipoAliasesRouting,
        ModelViewModule,
        PaginatorModule,
        NebuModule
    ],
    declarations: [
        ClasificacionTipoAliasesComponent,
        ClasificacionTipoAliasComponent,
        ClasificacionTipoAliasesNewComponent,
        ClasificacionTipoAliasesEditComponent,
        ClasificacionTipoAliasesDeleteComponent,
        ClasificacionTipoAliasesToolNavbarComponent
    ],
    exports: []
})
export class ClasificacionTipoAliasesModule {
    static forRoot(): ModuleWithProviders<ClasificacionTipoAliasesModule> {
        return {
            ngModule: ClasificacionTipoAliasesModule,
            providers: [ClasificacionTipoAliasesBuscadorService]
        }
    }
}
