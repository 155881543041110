import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Clasificacion } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"
import { ClasificacionesBuscadorService } from "./clasificaciones_buscador.service"

@Component({
    templateUrl: "clasificaciones.toolnavbar.component.html",
    selector: "clasificacion-toolnavbar"
})
export class ClasificacionesToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() clasificacion: Clasificacion
    @Input() displayButtons: string

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected clasificacionesBuscadorService: ClasificacionesBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/clasificaciones"
        this.urlNew = "/clasificaciones/new"
        if (this.clasificacion != null) {
            this.urlEdit = "/clasificaciones/" + this.clasificacion.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    edit() {
        if (this.clasificacion != null) {
            this.urlEdit = "/clasificaciones/" + this.clasificacion.id + "/edit"
        }
        super.edit()
    }

    search() {
        this.clasificacionesBuscadorService.openSearch.next()
    }
}
