import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import {
    AsignaturaPlataforma,
    Plataforma,
    Plataformas,
    AsignaturaPlataformas,
    Asignaturas,
    Asignatura,
    TipoInstrumento,
    TipoInstrumentos
} from "@puntaje/nebulosa/api-services"
import { UntypedFormControl } from "@angular/forms"
import { GeneradorInstrumentosBuscadorService } from "../generador_instrumentos_buscador.service"

@Component({
    selector: "generador-instrumentos-buscador",
    templateUrl: "./generador-instrumentos-buscador.component.html",
    styleUrls: ["./generador-instrumentos-buscador.component.scss"]
})
export class GeneradorInstrumentosBuscadorComponent implements OnInit {
    sub: Subscription
    open: boolean = false

    plataformas: Plataforma[]
    asignaturas: Asignatura[] = []
    tipoInstrumentos: TipoInstrumento[] = []

    buscadorTipoClasificaciones: UntypedFormControl = new UntypedFormControl()

    searchParams: any = { generador_instrumento: { like: {} }, asignatura_plataforma: {}, tipo_instrumento: {} }

    showClasificacionTipos: boolean = false

    constructor(
        protected generadorInstrumentosBuscadorService: GeneradorInstrumentosBuscadorService,
        protected plataformasService: Plataformas,
        protected asignaturasService: Asignaturas,
        protected tipoInstrumentosService: TipoInstrumentos
    ) {}

    ngOnInit() {
        this.sub = this.generadorInstrumentosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.plataformasService
            .where({
                sort_by: "plataforma",
                order: "asc"
            })
            .then((plataformas: Plataforma[]) => {
                this.plataformas = plataformas
            })

        this.tipoInstrumentosService.where({ sort_by: "tipo_instrumento", order: "asc" }).then(tipoInstrumentos => {
            this.tipoInstrumentos = tipoInstrumentos
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
            if (field == "plataforma_id") delete obj["asignatura_id"]
        }
    }

    search() {
        this.generadorInstrumentosBuscadorService.search(this.searchParams)
    }

    onSelectedPlataforma(plataformaId) {
        if (!plataformaId) return
        this.asignaturas = []
        this.asignaturasService.where({ plataforma_id: plataformaId }).then(aps => {
            this.asignaturas = aps
        })
    }
}
