<div>
    <input type="checkbox" (change)="checkboxChanged()" [checked]="value" />
    <label (click)="toggleHijas()">
        {{ clasificacion.clasificacion }}
        <span *ngIf="clasificacion.clasificaciones_hijas && !showHijas" class="glyphicon glyphicon-chevron-down"></span>
        <span *ngIf="clasificacion.clasificaciones_hijas && showHijas" class="glyphicon glyphicon-chevron-up"></span>
    </label>
    <div [ngClass]="{ showHijas: show, hidden: !showHijas }" *ngIf="clasificacion.clasificaciones_hijas">
        <monitor-clasificacion
            *ngFor="let hija of clasificacion.clasificaciones_hijas"
            [clasificacion]="hija"
            [subject]="subjectParent"
            (hijaChecked)="hijaCheckedListener($event)"
        ></monitor-clasificacion>
    </div>
</div>
