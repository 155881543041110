import { Component, OnInit } from "@angular/core"
import { ArchivoForm, Archivo, Preguntas } from "@puntaje/nebulosa/api-services"
import { Subject } from "rxjs"
import { UntypedFormGroup } from "@angular/forms"
import { S3Service } from "@puntaje/shared/core"

@Component({
    selector: "app-editar-masivamente",
    templateUrl: "./editar-masivamente.component.html",
    styleUrls: ["./editar-masivamente.component.scss"]
})
export class EditarMasivamenteComponent implements OnInit {
    xhr_upload: Subject<XMLHttpRequest> = new Subject<XMLHttpRequest>()
    archivo: Archivo = new Archivo()
    saving: boolean = false
    form: UntypedFormGroup
    response: any
    params = ArchivoForm.formParams

    constructor(public s3Service: S3Service, public preguntasServices: Preguntas) {}

    ngOnInit(): void {
        this.form = ArchivoForm.getForm(this.archivo)
    }

    clear() {
        setTimeout(() => {
            this.archivo = new Archivo()
            ArchivoForm.markFormControlsAsPristine(this.form)
            ArchivoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    save() {
        this.saving = true
        ArchivoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.preguntasServices.editarMasivamente().uploadFile(
                "file",
                this.archivo.file,
                this.xhr_upload,
                response => {
                    this.response = JSON.parse(response)
                    this.saving = false
                },
                900000
            )
        } else {
            this.saving = false
        }
    }
}
