<logged-layout>
    <logged-layout-toolnavbar>
        <asignaturas-toolnavbar [displayButtons]="'new'"></asignaturas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        <ng-container>Asignaturas</ng-container>
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <table-with-actions
                [contentArray]="asignaturas"
                [showParams]="show_params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
