<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-toolnavbar [displayButtons]="display_buttons_options"></clasificacion-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Formulario clasificaciones" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-md-8 col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            [form]="form"
                            [params]="params['clasificacion']"
                            [(value)]="clasificacion['clasificacion']"
                            key="clasificacion"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['descripcion']"
                            [(value)]="clasificacion['descripcion']"
                            key="descripcion"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['clasificacion_tipo_id']"
                            [(value)]="clasificacion['clasificacion_tipo_id']"
                            key="clasificacion_tipo_id"
                            (change)="filtrarTipo()"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['asignatura_id']"
                            [(value)]="clasificacion['asignatura_id']"
                            key="asignatura_id"
                            (change)="filtrarTipo()"
                        ></form-input>
                        <form-input
                            [(form)]="form"
                            [params]="params['medible']"
                            [(value)]="clasificacion['medible']"
                            [key]="'medible'"
                        ></form-input>
                        <form-input
                            [(form)]="form"
                            [params]="params['priorizado']"
                            [(value)]="clasificacion['priorizado']"
                            [key]="'priorizado'"
                        ></form-input>
                        <form-input
                            [(form)]="form"
                            [params]="params['nota_ia']"
                            [(value)]="clasificacion['nota_ia']"
                            [key]="'nota_ia'"
                        ></form-input>
                        <h2>Clasificaciones Asociadas</h2>
                        <div class="col-md-12">
                            <ng-template ngFor let-clasificacion [ngForOf]="clasificacion.clasificaciones">
                                <div class="col-md-8">
                                    {{ clasificacion.clasificacion }}
                                </div>
                                <div class="col-md-4">
                                    <button
                                        class="btn btn-default"
                                        type="button"
                                        (click)="quitarClasificacion(clasificacion)"
                                        value=""
                                    >
                                        Quitar
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                        <form-input
                            [form]="form"
                            [params]="params['clasificacion_clasificaciones']"
                            [(value)]="clasificacion['clasificacion_clasificaciones']"
                            key="clasificacion_clasificaciones"
                        ></form-input>

                        <button (click)="clasificacion.AgregarClasificacion()" type="button" class="btn btn-default">
                            Agregar Clasificación
                        </button>
                        <button (click)="save()" type="button" class="btn btn-default">Guardar</button>
                        <button (click)="clear()" type="button" class="btn btn-default">Borrar</button>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
