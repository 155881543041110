import { Validators } from "@angular/forms"
import { BaseForm, Validations } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { ContestableForm } from "./contestables.form"
import { GrupoPreguntas } from "./grupo_preguntas.service"
import { Personas } from "./personas.service"
import { TaxativoForm } from "./taxativos.form"
import { Preguntas } from "./preguntas.service"

export class PreguntaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            asignatura_id: {
                label: "Asignatura principal",
                type: "select",
                visible: true,
                options: { class: Asignaturas },
                validations: [Validators.required]
            },
            grupo_pregunta_id: {
                label: "Grupo Pregunta",
                type: "select",
                options: { class: GrupoPreguntas, params: { order: { by: "id", sort: "desc" }, only_id: 1 } },
                visible: true
            },
            taxativos: { type: "models", class: TaxativoForm },
            contestables: { type: "models", class: ContestableForm },
            solucion: { label: "Solución", type: "ckeditor", visible: true, validations: [Validators.required] },
            solucion_video: { label: "Solución Video", type: "textarea", visible: true },
            solucion_latex: { label: "Solución Latex", type: "textarea", visible: true },
            estado: { label: "Estado", type: "checkbox", visible: true },
            visible: { label: "Visible", type: "checkbox", visible: true },
            presencial: { label: "Presencial", type: "checkbox", visible: true },
            comentarios: { label: "Comentarios", type: "textarea", visible: true },
            profesor: {
                label: "Autor",
                type: "autocomplete",
                visible: true,
                options: { class: Personas },
                validations: [Validators.required]
            },
            encargado_reporte: {
                label: "Encargado Reporte",
                type: "autocomplete",
                visible: true,
                options: { class: Personas }
            },
            autor: { label: "Org.", type: "autocomplete", visible: true, options: { class: Personas } },
            pregunta_ref_id: {
                label: "Dependencia Pregunta ID",
                type: "number",
                visible: true,
                validations: [],
                serviceValidators: [{ class: Preguntas, validator: Validations.validatePreguntaIdorEmpty }]
            }
        }
    }
}
