import { Component } from "@angular/core"

/*#################################

Menú a la izquierda del sitio
Links disponibles deberían depender de los permisos del usuario

#################################*/

@Component({
    templateUrl: "sidemenu.component.html",
    selector: "sidemenu",
    styleUrls: ["sidemenu.css"]
})
export class SidemenuComponent {}
