<logged-layout>
    <logged-layout-toolnavbar>
        <preguntas-toolnavbar [displayButtons]="'new mine'"></preguntas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Preguntas</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="preguntas">
                <preguntas [preguntas]="preguntas"></preguntas>
            </div>
        </loading-layout>
        <paginator [getList]="showAll.bind(this)" [useQueryParams]="true"></paginator>
    </logged-layout-contenido>
</logged-layout>
