<logged-layout>
    <logged-layout-toolnavbar>
        <grupo_pregunta-toolnavbar [displayButtons]="'new'"></grupo_pregunta-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Grupos de Preguntas</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="grupo_preguntas">
                <grupo_preguntas [contentArray]="grupo_preguntas"></grupo_preguntas>
            </div>
            <paginator [getList]="showAll.bind(this)"></paginator>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
