import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { Personas } from "./personas.service"

export class AsignaturaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Asignaturas, key: "id" } },
            asignatura: { label: "Asignatura", type: TextView, tableVisible: true },
            abreviacion: { label: "Abreviación", type: TextView, tableVisible: true },
            nombre_encargado: {
                label: "Encargado",
                type: LinkView,
                tableVisible: true,
                options: { class: Personas, key: "encargado_id" }
            },
            codigo_estilo: { label: "Código de estilo", type: TextView, tableVisible: false },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
