import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Clasificacion, Clasificaciones, ClasificacionForm } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificaciones.form.component.html"
})
export class ClasificacionesEditComponent implements OnInit, OnDestroy {
    oClasificacion: Clasificacion = new Clasificacion()
    clasificacion: Clasificacion = new Clasificacion()
    params = ClasificacionForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar clasificación"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private clasificacionesService: Clasificaciones,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = ClasificacionForm.getForm(this.clasificacion)
        this.sub = this.route.params.subscribe(params => {
            ;(this.clasificacionesService as any)
                .find(+params["id"], { with_clasificaciones: true })
                .then((clasificacion: Clasificacion) => {
                    this.clasificacion = clasificacion
                    this.oClasificacion = clasificacion.clone()
                    this.loadingLayout.ready()
                    this.cdr.detectChanges()
                })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        this.clasificacion.clasificacion_clasificaciones = this.clasificacion.clasificacion_clasificaciones.filter(
            cc => !!cc.clasificacion_ref_id
        )

        ClasificacionForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.clasificacionesService.update(this.clasificacion.id, this.clasificacion).then(response => {
                this.router.navigate(["clasificaciones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.clasificacion = this.oClasificacion.clone()
            ClasificacionForm.markFormControlsAsPristine(this.form)
            ClasificacionForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    filtrarTipo() {
        this.clasificacion.LimpiarClasificaciones()
    }

    quitarClasificacion(clasificacion) {
        if (this.clasificacion["destroy_asociados"]) this.clasificacion["destroy_asociados"].push(clasificacion.id)
        else this.clasificacion["destroy_asociados"] = [clasificacion.id]
        var index = this.clasificacion.clasificaciones.indexOf(clasificacion)
        var el = this.clasificacion.clasificaciones.splice(index, 1)
    }
}
