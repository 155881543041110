import { Injectable } from "@angular/core"
import { PermisosService } from "./permisos.service"
import { BaseModel } from "@puntaje/shared/core"

export interface Opts {
    resourceId?: number
    resource?: BaseModel
    resourceName?: string
    onlyPermisos?: boolean
}

@Injectable({
    providedIn: "root"
})
export class BasePolicyService {
    resourceName: string

    constructor(protected permisosService: PermisosService) {}

    testPermiso(permiso: string) {
        return this.permisosService.permisos.includes(permiso)
    }

    getResourceName(resourceName?: string) {
        return resourceName ? resourceName : this.resourceName
    }

    index({ resourceName }: Opts): boolean | Promise<boolean> {
        return this.testPermiso(`${this.getResourceName(resourceName)}_index`)
    }

    update({ resourceName }: Opts): boolean | Promise<boolean> {
        return this.testPermiso(`${this.getResourceName(resourceName)}_update`)
    }

    show({ resourceName }: Opts): boolean | Promise<boolean> {
        return this.testPermiso(`${this.getResourceName(resourceName)}_show`)
    }

    create({ resourceName }: Opts): boolean | Promise<boolean> {
        return this.testPermiso(`${this.getResourceName(resourceName)}_create`)
    }

    destroy({ resourceName }: Opts): boolean | Promise<boolean> {
        return this.testPermiso(`${this.getResourceName(resourceName)}_destroy`)
    }
}
