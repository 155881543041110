import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Lotes } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    template: ""
})
export class LotesDeleteComponent implements OnInit, OnDestroy {
    private sub: Subscription

    constructor(private lotesService: Lotes, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.lotesService.remove(+params["id"]).then(response => {
                this.router.navigate(["lotes"])
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
