import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import {
    Asignatura,
    Asignaturas,
    Clasificacion,
    Clasificaciones,
    ClasificacionTipo,
    ClasificacionTipos
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { ClasificacionesBuscadorService } from "./clasificaciones_buscador.service"
import { filter, distinctUntilChanged, debounceTime } from "rxjs/operators"

@Component({
    selector: "clasificaciones-buscador",
    templateUrl: "clasificaciones_buscador.component.html",
    styleUrls: ["clasificaciones_buscador.component.scss"]
})
export class ClasificacionesBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false

    asignaturas: Asignatura[]
    clasificacionTipos: ClasificacionTipo[]

    buscadorTipoClasificaciones: UntypedFormControl = new UntypedFormControl()

    searchParams: any = { clasificacion: { like: {} } }
    clasificacionTipoMaterial: ClasificacionTipo[] = []

    showClasificacionTipos: boolean = false

    constructor(
        protected clasificacionesBuscadorService: ClasificacionesBuscadorService,
        protected clasificacionTiposService: ClasificacionTipos,
        protected asignaturasService: Asignaturas
    ) {}

    ngOnInit() {
        this.sub = this.clasificacionesBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.asignaturasService.where().then((asignaturas: Asignatura[]) => {
            this.asignaturas = asignaturas
        })

        this.clasificacionTiposService.where().then((clasificacionTipos: ClasificacionTipo[]) => {
            this.clasificacionTipos = clasificacionTipos
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    search() {
        this.clasificacionesBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
