import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import {
    TipoInstrumentoPlataforma,
    TipoInstrumentoPlataformas,
    TipoInstrumentoPlataformaView
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "tipo_instrumento_plataforma.component.html"
})
export class TipoInstrumentoPlataformaComponent implements OnInit, OnDestroy {
    tipoInstrumentoPlataforma: TipoInstrumentoPlataforma
    show_params = TipoInstrumentoPlataformaView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            let cta_params = {
                ...params,
                render_options: {
                    include: {
                        tipo_instrumento: null,
                        plataforma: null
                    }
                }
            }
            this.tipoInstrumentoPlataformasService
                .find(+cta_params["id"], cta_params)
                .then((response: TipoInstrumentoPlataforma) => {
                    this.tipoInstrumentoPlataforma = response
                    this.loadingLayout.ready()
                })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
