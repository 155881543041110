import { Component, Injector } from "@angular/core"
import { BaseModel } from "../api/base.model"
import { View } from "./view.component"

@Component({
    selector: "linkordownloadview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>
            <a *ngIf="check(value)" [attr.href]="fun(value)" target="_blank" download>{{ download }}</a>
            <a *ngIf="!check(value) && fun(value)" [attr.href]="fun(value)" target="_blank">{{ view }}</a>
            <p *ngIf="!fun(value)">No disponible</p>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class LinkOrDownloadView extends View {
    download: string = "Descargar"
    view: string = "Ver"
    fun: (m: BaseModel) => string
    check: (m: BaseModel) => boolean

    constructor(private injector: Injector) {
        super()
    }

    ngOnInit() {
        if (this.options !== undefined && this.options.class !== undefined) {
            let service = this.injector.get(this.options.class)
            this.fun = service.getDownloadLink
            this.check = service.isFileLink
        }
    }
}
