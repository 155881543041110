import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { AyudasBuscadorService } from "./ayudas_buscador.service"

@Component({
    selector: "ayudas-buscador",
    templateUrl: "ayudas_buscador.component.html",
    styleUrls: ["ayudas_buscador.component.scss"]
})
export class AyudasBuscadorComponent implements OnInit, OnDestroy {
    sub: Subscription
    open: boolean = false

    searchParams: any = { ayuda: { like: {} } }

    constructor(protected ayudasBuscadorService: AyudasBuscadorService) {}

    ngOnInit() {
        this.sub = this.ayudasBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    deleteIfUndefined(obj: any, field: string) {
        if (!obj[field] && typeof obj[field] != "boolean" && typeof obj[field] != "number") {
            delete obj[field]
        }
    }

    search() {
        delete this.searchParams.clasificacion
        delete this.searchParams.and_clasificacion
        this.ayudasBuscadorService.search(this.searchParams)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
