<logged-layout>
    <logged-layout-titulo>Buscar Preguntas</logged-layout-titulo>
    <logged-layout-contenido>
        <preguntas-filter
            #preguntasFilter
            [exportarIdsButton]="true"
            (preguntasChange)="onPreguntasChange($event)"
            (showPreguntasChange)="onShowPreguntasChange($event)"
            (isSearchingChange)="onIsSearchingChange($event)"
        ></preguntas-filter>
        <div class="lista-resultados" *ngIf="showPreguntas">
            <h4>
                <span class="glyphicon glyphicon-search"></span>
                &nbsp;Resultados
            </h4>
            <loading-layout #loadingLayout>
                <div *ngFor="let p of preguntas" class="resultado-pregunta">
                    <div class="pregunta-box">
                        <a [routerLink]="['/preguntas', p.id]" class="titulo">Pregunta #{{ p.id }}</a>
                        <grupo-pregunta-preview
                            *ngIf="p.grupo_pregunta?.texto"
                            [grupoPregunta]="p.grupo_pregunta"
                        ></grupo-pregunta-preview>
                        <pregunta-small [pregunta]="p"></pregunta-small>
                    </div>
                </div>
                <p class="not-found" *ngIf="!(preguntas && preguntas.length > 0)">
                    No se encontraron resultados con esos criterios de búsqueda.
                </p>
            </loading-layout>
            <paginator [getList]="preguntasFilter.buscarPreguntas.bind(preguntasFilter)"></paginator>
        </div>
    </logged-layout-contenido>
</logged-layout>
