<form *ngIf="open">
    <div class="flex-container">
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Nombre"
                (keyup.enter)="search()"
                name="nombre"
                type="text"
                [(ngModel)]="searchParams.persona.like.nombre"
                (ngModelChange)="deleteIfUndefined(searchParams.persona.like, 'nombre')"
            />
        </div>
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Apellido paterno"
                (keyup.enter)="search()"
                name="apellido_paterno"
                type="text"
                [(ngModel)]="searchParams.persona.like.apellido_paterno"
                (ngModelChange)="deleteIfUndefined(searchParams.persona.like, 'apellido_paterno')"
            />
        </div>
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Apellido materno"
                (keyup.enter)="search()"
                name="apellido_materno"
                type="text"
                [(ngModel)]="searchParams.persona.like.apellido_materno"
                (ngModelChange)="deleteIfUndefined(searchParams.persona.like, 'apellido_materno')"
            />
        </div>
        <div class="form-group">
            <input
                class="form-control"
                placeholder="Email"
                (keyup.enter)="search()"
                name="email"
                type="text"
                [(ngModel)]="searchParams.persona.email"
                (ngModelChange)="deleteIfUndefined(searchParams.persona, 'email')"
            />
        </div>
        <button type="button" class="btn btn-default" (click)="search()">
            <fa name="search"></fa>
            &nbsp;Buscar
        </button>
    </div>
</form>
