import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Ayuda, AyudaForm, AyudaPlataforma, Ayudas, TipoMaterial } from "@puntaje/nebulosa/api-services"
import { S3, S3Service } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "ayudas.form.component.html",
    styleUrls: ["ayudas.form.component.scss"]
})
export class AyudasEditComponent implements OnInit, OnDestroy {
    params = AyudaForm.formParams
    oAyuda: Ayuda = new Ayuda()
    ayuda = new Ayuda()
    s3_model = new S3()
    form: UntypedFormGroup
    logged_layout_title = "Editar ayuda"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    policy: String
    s3signature: String
    file: File
    tipoMaterial = TipoMaterial
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    plataformas_seleccionadas: number[]
    enablePreview: boolean = true
    saving: boolean = false

    constructor(
        private ayudaService: Ayudas,
        private router: Router,
        private route: ActivatedRoute,
        public s3Service: S3Service,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = AyudaForm.getForm(this.ayuda)
        this.sub = this.route.params.subscribe(params => {
            this.ayudaService.find(+params["id"]).then((ayuda: Ayuda) => {
                this.ayuda = ayuda
                this.plataformas_seleccionadas = ayuda.plataformas.map(x => x.id)
                this.oAyuda = ayuda.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        AyudaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.saving = true
            if (this.ayuda.material_tipo_id == 1) {
                this.s3Service.where(this.ayuda.getS3Params()).then((policies: S3[]) => {
                    let policy = policies as any as S3 // claudio wn ocupa la cosa bien, no hagas mierdaaa
                    if (policy["Content-Type"] === undefined) {
                        this.updateAyuda()
                    } else {
                        this.ayuda["url"] = policy["key"]
                        this.s3Service.uploadToS3(
                            policy,
                            this.ayuda.file,
                            this.ayudaService.tableName,
                            this.updateAyuda
                        )
                    }
                })
            } else {
                this.updateAyuda()
            }
        }
    }

    checkTipoAyuda() {
        let tipo = this.form.controls["material_tipo_id"].value
        if (tipo !== "" && tipo !== undefined) {
            return tipo
        }
        return ""
    }

    clear() {
        this.enablePreview = false
        setTimeout(() => {
            this.ayuda = this.oAyuda.clone()
            AyudaForm.markFormControlsAsPristine(this.form)
            AyudaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
            this.enablePreview = true
        }, 150)
    }

    updateAyuda = () => {
        this.ayudaService.update(this.ayuda.id, this.ayuda).then(response => {
            this.saving = false
            this.router.navigate(["ayudas/" + this.ayuda.id])
            //this.persona = response.json();
        })
    }

    actualizarPlataformas(event) {
        this.ayuda.ayuda_plataformas = []
        if (event && Array.isArray(event) && event.length > 0) {
            let to_destroy = this.oAyuda.ayuda_plataformas.filter(mp => event.indexOf(mp.plataforma_id) < 0)
            let to_add = event.filter(x => !this.oAyuda.ayuda_plataformas.find(v => v.plataforma_id === x))

            to_destroy.forEach(mp => {
                var new_mp = new AyudaPlataforma()
                new_mp["_destroy"] = true
                new_mp["id"] = mp.id
                this.ayuda.ayuda_plataformas.push(new_mp)
            })

            to_add.forEach(p => {
                var new_mp = new AyudaPlataforma()
                new_mp["plataforma_id"] = p
                this.ayuda.ayuda_plataformas.push(new_mp)
            })
        }
    }

    reloadPreview() {
        this.enablePreview = false
        setTimeout(() => {
            this.enablePreview = true
        }, 150)
    }

    updateTipoAyuda() {}

    isSaving() {
        return this.saving
    }
}
