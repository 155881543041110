import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar } from "@puntaje/shared/core"

@Component({
    templateUrl: "asignaturas.toolnavbar.component.html",
    selector: "asignaturas-toolnavbar"
})
export class AsignaturasToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() asignatura: Asignatura
    @Input() displayButtons: string

    constructor(router: Router, route: ActivatedRoute, protected authService: AuthService) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/asignaturas"
        this.urlNew = "/asignaturas/new"
        if (this.asignatura != null) {
            this.urlEdit = "/asignaturas/" + this.asignatura.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    edit() {
        if (this.asignatura != null) {
            this.urlEdit = "/asignaturas/" + this.asignatura.id + "/edit"
        }
        super.edit()
    }
}
