<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-6">
            <div class="main">
                <h4>
                    <span class="glyphicon glyphicon-search title-icon" aria-hidden="true"></span>
                    &nbsp;Seleccionar Asignatura(s)
                </h4>
                <select class="form-control" *ngIf="asignaturas" [(ngModel)]="selectedAsignatura">
                    <option [ngValue]="undefined" disabled selected hidden>-- Seleccione Asignatura --</option>
                    <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura">
                        {{ asignatura.asignatura }}
                    </option>
                </select>

                <div class="clearfix buttons-container">
                    <button
                        [disabled]="disabled"
                        (click)="add()"
                        type="button"
                        class="btn btn-default pull-right btn-sm"
                    >
                        Agregar Asignatura
                    </button>
                    <button
                        [disabled]="disabled"
                        (click)="addAll()"
                        type="button"
                        class="btn btn-default pull-right btn-sm"
                    >
                        Agregar Todas
                    </button>
                    <button
                        [disabled]="disabled"
                        (click)="removeAll()"
                        type="button"
                        class="btn btn-default pull-right btn-sm"
                    >
                        Remover Todas
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <h4>
                <span class="glyphicon glyphicon-list title-icon" aria-hidden="true"></span>
                &nbsp;Listado de asignaturas
            </h4>
            <ul class="lista-asignaturas">
                <div *ngFor="let asignatura of selectedAsignaturas">
                    <li>
                        {{ asignatura.asignatura }}
                        <button
                            [disabled]="disabled"
                            (click)="remove(asignatura)"
                            type="button"
                            class="btn btn-default btn-sm"
                        >
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </li>
                </div>
            </ul>
            <div *ngIf="selectedAsignaturas.length == 0" class="empty-list">
                No hay asignaturas seleccionadas.
            </div>
        </div>
    </div>
</loading-layout>
