<logged-layout>
    <!-- <logged-layout-toolnavbar>
        <clasificacion-tipo-alias-toolnavbar
            [displayButtons]="display_buttons_options"
        ></clasificacion-tipo-alias-toolnavbar>
    </logged-layout-toolnavbar> -->
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Formulario alias de tipo de instrumento por plataforma" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-md-8 col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            [form]="form"
                            [params]="params['alias']"
                            [(value)]="tipoInstrumentoPlataforma['alias']"
                            key="alias"
                        ></form-input>

                        <form-input
                            *ngIf="!tipoInstrumentoId && displayTipoEdit"
                            [form]="form"
                            [params]="params['tipo_instrumento']"
                            [(value)]="tipoInstrumentoPlataforma['tipo_instrumento']"
                            key="'tipo_instrumento'"
                        ></form-input>

                        <form-input
                            *ngIf="!plataformaId && displayTipoEdit"
                            [form]="form"
                            [params]="params['plataforma_id']"
                            [(value)]="tipoInstrumentoPlataforma['plataforma_id']"
                            key="'plataforma_id'"
                        ></form-input>

                        <button (click)="save()" type="button" class="btn btn-default">
                            Guardar
                        </button>
                        <button (click)="clear()" type="button" class="btn btn-default">
                            Deshacer
                        </button>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
