import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core"
import { Materiales, Material } from "@puntaje/nebulosa/api-services"
import { MaterialesBuscadorService } from "../materiales_buscador.service"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "app-materiales-list",
    templateUrl: "./materiales-list.component.html",
    styleUrls: ["./materiales-list.component.scss"]
})
export class MaterialesListComponent implements OnInit, OnDestroy {
    sub: Subscription
    searchParams: any
    materiales: Material[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        private materialesService: Materiales,
        protected materialesBuscadorService: MaterialesBuscadorService
    ) {}

    ngOnInit() {
        this.sub = this.materialesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number, per: number) => {
        let params = {
            ...this.searchParams,
            page: page,
            per: per,
            methods: "cantidad_reportes"
        }

        return this.materialesService.where(params).then((materiales: Material[], total: number) => {
            this.materiales = materiales
            this.loadingLayout.ready()
            return total
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
