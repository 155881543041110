<logged-layout>
    <logged-layout-toolnavbar>
        <clasificacion-tipo-alias-toolnavbar
            [displayButtons]="display_buttons_options"
        ></clasificacion-tipo-alias-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>
        {{ logged_layout_title | easyplaceholder: "Formulario alias de tipo de clasificación" }}
    </logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-md-8 col-sm-12">
                    <form novalidate [formGroup]="form">
                        <form-input
                            [form]="form"
                            [params]="params['alias']"
                            [(value)]="clasificacionTipoAlias['alias']"
                            key="alias"
                        ></form-input>

                        <form-input
                            *ngIf="!clasificacionTipoId && displayTipoEdit"
                            [form]="form"
                            [params]="params['clasificacion_tipo_id']"
                            [(value)]="clasificacionTipoAlias['clasificacion_tipo_id']"
                            key="'clasificacion_tipo_id'"
                        ></form-input>

                        <div class="form-group" *ngIf="plataformas">
                            <label>Plataforma</label>
                            <select (input)="plataformaSelected($any($event).target.value)" class="form-control">
                                <option value="">Seleccione Plataforma</option>
                                <option
                                    [selected]="plataforma.id == plataformaId"
                                    [value]="plataforma.id"
                                    *ngFor="let plataforma of plataformas"
                                >
                                    {{ plataforma.plataforma }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group" *ngIf="asignaturaPlataformas">
                            <label>Asignatura</label>
                            <select
                                (input)="asignaturaPlataformaSelected($any($event).target.value)"
                                class="form-control"
                            >
                                <option
                                    [selected]="ap.id == asignaturaPlataformaId"
                                    [value]="ap.id"
                                    *ngFor="let ap of asignaturaPlataformas"
                                >
                                    {{ ap.asignatura.asignatura }}
                                </option>
                            </select>
                        </div>

                        <button (click)="save()" type="button" class="btn btn-default">
                            Guardar
                        </button>
                        <button (click)="clear()" type="button" class="btn btn-default">
                            Deshacer
                        </button>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
