<loading-layout #loadingLayout>
    <div class="row">
        <button class="btn buttonAsociarRecursos" (click)="goList(recurso.recurso)">
            <fa name="arrow-left" class="icon"></fa>
            Ver {{ recurso.descripcion }} asociad{{ recurso.g }}s
        </button>
        <h3>Asociar Clasificaciones</h3>
    </div>

    <ng-container *ngIf="seleccionandoGrupo">
        <div class="row">
            <div class="col-md-12">
                <h4>Seleccione un grupo</h4>
                <div class="clasificacion-criterios">
                    <div
                        *ngFor="let grupo of grupos; let i = index"
                        class="clasificaciones"
                        (click)="seleccionarGrupo(grupo)"
                    >
                        <div class="options">
                            <div>Grupo {{ i + 1 }}</div>
                        </div>
                        <div *ngFor="let ca of grupo.criterio_asignaciones">
                            <div class="clasificacion">
                                {{ ca.criterio.nombre() }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!seleccionandoGrupo">
        <div class="row">
            <div class="col-md-12">
                <button type="button" (click)="reset()" class="btn btn-default pull-right">
                    Deshacer Cambios
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div *ngFor="let a of arreglo" class="search-col">
                    <h4>
                        <span class="glyphicon glyphicon-search title-icon" aria-hidden="true"></span>
                        &nbsp;Seleccionar Clasificación
                    </h4>
                    <div class="form-group">
                        <label>Clasificación tipo</label>
                        <select
                            class="form-control"
                            [(ngModel)]="a.clasificacionTipo"
                            [disabled]="!!grupoSeleccionado"
                            (change)="getClasificaciones(a)"
                        >
                            <option [ngValue]="undefined">-- Seleccione Clasificación tipo --</option>
                            <option
                                *ngFor="let clasificacionTipo of a.clasificacionTipos"
                                [ngValue]="clasificacionTipo"
                            >
                                {{ clasificacionTipo.clasificacion_tipo }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="asignatura_id">Asignatura</label>
                        <select
                            id="asignatura_id"
                            [(ngModel)]="a.asignatura"
                            class="form-control"
                            [disabled]="grupoSeleccionado && a.asignaturas.length == 1"
                            (change)="getClasificaciones(a)"
                        >
                            <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                            <option *ngFor="let option of a.asignaturas" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group" *ngIf="a.clasificaciones.length > 0">
                        <label>Clasificación</label>
                        <select
                            [(ngModel)]="a.clasificacion"
                            class="form-control"
                            [disabled]="grupoSeleccionado && a.clasificaciones.length == 1"
                        >
                            <option [ngValue]="undefined">-- Seleccione Clasificación --</option>
                            <option *ngFor="let clasificacion of a.clasificaciones" [ngValue]="clasificacion">
                                {{ clasificacion.clasificacion }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="clearfix">
                    <button (click)="agregar()" type="button" class="btn btn-default pull-right">
                        Agregar
                    </button>
                </div>
                <p *ngIf="showFailureFeedback" class="feedback failure">
                    <fa name="exclamation" class="icono"></fa>
                    {{ mensaje }}
                </p>
            </div>
            <div class="col-md-5">
                <h4>
                    <span class="glyphicon glyphicon-list title-icon" aria-hidden="true"></span>
                    &nbsp;Clasificación Tipo
                </h4>
                <div *ngIf="clasificacionTipoAgregadas.length == 0" class="empty-list">
                    No hay clasificaciones seleccionadas.
                </div>
                <ul class="lista-clasificaciones">
                    <li *ngFor="let clasificacionTipo of clasificacionTipoAgregadas">
                        <p>
                            <b>{{ clasificacionTipo.id }} - {{ clasificacionTipo.clasificacion_tipo }}</b>
                            <!--  <span [innerHTML]="clasificacion.descripcion | trustedhtml"></span> -->
                        </p>
                        <button
                            *ngIf="!grupoSeleccionado"
                            (click)="quitar(clasificacionTipo.id, 'clasificacionTipo')"
                            type="button"
                            class="btn btn-default btn-sm"
                        >
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </li>
                </ul>

                <h4>
                    <span class="glyphicon glyphicon-list title-icon" aria-hidden="true"></span>
                    &nbsp;Asignatura
                </h4>
                <div *ngIf="asignaturasAgregadas.length == 0" class="empty-list">
                    No hay asignaturas seleccionadas.
                </div>
                <ul class="lista-clasificaciones">
                    <li *ngFor="let asignatura of asignaturasAgregadas">
                        <p>
                            <b>{{ asignatura.id }} - {{ asignatura.asignatura }}</b>
                            <!--  <span [innerHTML]="clasificacion.descripcion | trustedhtml"></span> -->
                        </p>
                        <button
                            *ngIf="!grupoSeleccionado"
                            (click)="quitar(asignatura.id, 'asignatura')"
                            type="button"
                            class="btn btn-default btn-sm"
                        >
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </li>
                </ul>

                <h4>
                    <span class="glyphicon glyphicon-list title-icon" aria-hidden="true"></span>
                    &nbsp;Clasificaciones
                </h4>
                <div *ngIf="clasificacionAgregadas.length == 0" class="empty-list">
                    No hay clasificación seleccionadas.
                </div>
                <ul class="lista-clasificaciones">
                    <li *ngFor="let clasificacion of clasificacionAgregadas">
                        <p>
                            <b>{{ clasificacion.id }} - {{ clasificacion.clasificacion }}</b>
                            <!--  <span [innerHTML]="clasificacion.descripcion | trustedhtml"></span> -->
                        </p>
                        <button
                            *ngIf="!grupoSeleccionado"
                            (click)="quitar(clasificacion.id, 'clasificacion')"
                            type="button"
                            class="btn btn-default btn-sm"
                        >
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div
            class="clearfix"
            *ngIf="
                clasificacionTipoAgregadas.length > 0 ||
                asignaturasAgregadas.length > 0 ||
                clasificacionAgregadas.length > 0
            "
        >
            <button type="button" (click)="asociarClasificaciones()" class="btn buttonAsociarRecursos pull-right">
                <fa name="plus-circle" class="icon"></fa>
                Asociar Clasificaciones
            </button>
        </div>
    </ng-container>
</loading-layout>
