import { Component, OnInit, ViewChild } from "@angular/core"
import { GeneradorInstrumento, GeneradorInstrumentoView, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { Router, ActivatedRoute } from "@angular/router"

@Component({
    selector: "app-generador-instrumento",
    templateUrl: "./generador-instrumento.component.html",
    styleUrls: ["./generador-instrumento.component.scss"]
})
export class GeneradorInstrumentoComponent implements OnInit {
    generador: GeneradorInstrumento
    show_params = GeneradorInstrumentoView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private generadoresService: GeneradorInstrumentos,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.generadoresService
                .find(+params["id"], {
                    include:
                        "[" +
                        "asignatura_plataforma:[asignatura,plataforma]," +
                        "generador_instrumento_categorias:categoria:clasificacion_tipo," +
                        "generador_instrumento_clasificaciones:[clasificacion_mencion,clasificacion_suficiencia,clasificacion:clasificacion_tipo]," +
                        "generador_instrumento_instrucciones:[clasificacion,instruccion]," +
                        "generador_instrumento_filtro_clasificaciones:clasificacion:clasificacion_tipo," +
                        "generador_instrumento_filtro_clasificacion_tipos:[clasificacion_tipo,clasificacion_tipo_hija]" +
                        "]"
                })
                .then((generador: GeneradorInstrumento) => {
                    this.generador = generador
                    this.loadingLayout.ready()
                })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
