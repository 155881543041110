import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Plataformas } from "./plataformas.service"

export class PlataformaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            plataforma: {
                label: "Plataforma",
                type: LinkView,
                tableVisible: true,
                options: { class: Plataformas, key: "id" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
