import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Lote, Lotes, LoteView } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { AuthService } from "@puntaje/shared/core"

@Component({
    templateUrl: "lotes_usuario.component.html"
})
export class LotesUsuarioComponent implements OnInit {
    lotes: Lote[]
    show_params = LoteView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private lotesService: Lotes,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", callModal: false, glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    keys(lote: Lote): Array<string> {
        return Object.keys(lote)
    }

    showAll(page: number, per: number) {
        let params = {
            page: page,
            per: per,
            lote: {
                propietario_id: this.authService.getUserData().id
            }
        }
        return this.lotesService.where(params).then((lotes: Lote[], total: number) => {
            this.lotes = lotes
            console.log(lotes)
            this.loadingLayout.ready()
            return total
        })
    }

    view(lote: Lote) {
        this.router.navigate(["lotes/" + lote.id])
    }

    edit(lote: Lote) {
        this.router.navigate(["lotes/" + lote.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["lotes/" + id + "/delete"])
    }

    showModalDelete(lote: Lote) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar el lote '" + lote.lote + "'?")
        this.simpleModalService.setModalCallback(() => this.delete(lote.id))
    }
}
