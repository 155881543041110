import { RouterModule, Routes } from "@angular/router"
import { AdminGuard } from "@puntaje/nebulosa/api-services"
import { AuthGuard } from "@puntaje/shared/core"
import { PersonaComponent } from "./persona.component"
import { PersonasComponent } from "./personas.component"
import { PersonasDeleteComponent } from "./personas.delete.component"
import { PersonasEditComponent } from "./personas.edit.component"
import { PersonasNewComponent } from "./personas.new.component"
import { PermisosGuard } from "../../shared/guards/permisos.guard"

export const personasRoutes: Routes = [
    {
        path: "personas",
        component: PersonasComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Personas#index" }
    },
    {
        path: "personas/new",
        component: PersonasNewComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Personas#create" }
    },
    {
        path: "personas/:id",
        component: PersonaComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Personas#show" }
    },
    {
        path: "personas/:id/edit",
        component: PersonasEditComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Personas#update" }
    },
    {
        path: "personas/:id/delete",
        component: PersonasDeleteComponent,
        canActivate: [AuthGuard, PermisosGuard],
        data: { permiso: "Personas#destroy" }
    }
]

export const personasRouting = RouterModule.forChild(personasRoutes)
