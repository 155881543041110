import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, PaginatorModule } from "@puntaje/shared/core"
import { MonitorClasificacionComponent } from "./monitor_clasificacion.component"
import { MonitorClasificacionesComponent } from "./monitor_clasificaciones.component"
import { MonitorPreguntasComponent } from "./monitor_preguntas.component"
import { MonitorRecursosComponent } from "./monitor_recursos.component"
import { monitorRecursosRouting } from "./monitor_recursos.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PaginatorModule,
        FormModule,
        LayoutsModule,
        Angular2FontawesomeModule,
        NebuModule,
        monitorRecursosRouting
    ],
    declarations: [
        MonitorRecursosComponent,
        MonitorPreguntasComponent,
        MonitorClasificacionesComponent,
        MonitorClasificacionComponent
    ],
    exports: [],
    providers: []
})
export class MonitorRecursosModule {}
