import { Component, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Pregunta } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "preguntas-buscar",
    templateUrl: "preguntas_buscar.component.html",
    styleUrls: ["preguntas_buscar.component.scss"]
})
export class PreguntasBuscarComponent {
    preguntas: Pregunta[]
    showPreguntas: boolean
    isSearching: boolean
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    onPreguntasChange(preguntas: Pregunta[]) {
        this.preguntas = preguntas
    }

    onShowPreguntasChange(show: boolean) {
        this.showPreguntas = show
    }

    onIsSearchingChange(search: boolean) {
        this.isSearching = search
        if (this.isSearching && this.loadingLayout) this.loadingLayout.standby()
        else if (this.loadingLayout) this.loadingLayout.ready()
    }
}
