import {
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
    ElementRef,
    SimpleChanges,
    KeyValueDiffer,
    KeyValueDiffers,
    HostListener,
    Renderer2,
    ChangeDetectorRef
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { GrupoPregunta, GrupoPreguntas, GrupoPreguntaView } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "grupo-pregunta-preview",
    templateUrl: "grupo_pregunta.preview.component.html",
    styleUrls: ["grupo_pregunta.preview.component.scss"]
})
export class GrupoPreguntaPreviewComponent implements OnInit, OnChanges {
    show_params = GrupoPreguntaView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input("grupoPregunta") grupoPregunta: GrupoPregunta
    @Input("contenidoPreguntaId") grupoPreguntaId: number
    @Input() disableLinkOnCorner: boolean = false

    texto: string

    grupoPreguntaDiffer: KeyValueDiffer<string, any>

    constructor(private grupoPreguntasService: GrupoPreguntas) {}

    ngOnInit() {
        if (this.grupoPreguntaId != undefined) {
            this.getGrupoPregunta()
        }

        if (this.grupoPregunta) {
            this.loadingLayout.ready()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.grupoPreguntaId != undefined) {
            this.loadingLayout.standby()

            this.getGrupoPregunta()
        }
    }

    getGrupoPregunta() {
        this.grupoPreguntasService.find(this.grupoPreguntaId).then((response: GrupoPregunta) => {
            this.grupoPregunta = response
            this.loadingLayout.ready()
        })
    }
}
