import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from "@angular/core"
import { Asignaturas, Asignatura } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "embed-asignaturas",
    templateUrl: "./embed-asignaturas.component.html",
    styleUrls: ["./embed-asignaturas.component.scss"]
})
export class EmbedAsignaturasComponent implements OnInit {
    selectedAsignatura: Asignatura
    asignaturas: Asignatura[]
    selectedAsignaturas: Asignatura[] = []
    @Input() initialAsignaturaIds: number[] = []
    @Input() disabled = false
    @Output() onUpdateAsignaturas = new EventEmitter<number[]>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(protected asignaturasService: Asignaturas) {}

    ngOnInit() {
        this.asignaturasService.where().then(asignaturas => {
            this.asignaturas = asignaturas
            if (this.initialAsignaturaIds && this.initialAsignaturaIds.length > 0) {
                this.selectedAsignaturas = this.asignaturas.filter(a => this.initialAsignaturaIds.includes(a.id))
            }
            this.loadingLayout.ready()
        })
    }

    add() {
        if (!this.selectedAsignaturas.includes(this.selectedAsignatura)) {
            this.selectedAsignaturas.push(this.selectedAsignatura)
            this.emit()
        }
    }

    addAll() {
        this.selectedAsignaturas = this.asignaturas
        this.emit()
    }

    removeAll() {
        this.selectedAsignaturas = []
        this.emit()
    }

    remove(asignatura: Asignatura) {
        this.selectedAsignaturas = this.selectedAsignaturas.filter(a => a.id != asignatura.id)
        this.emit()
    }

    emit() {
        this.onUpdateAsignaturas.emit(this.selectedAsignaturas.map(a => a.id))
    }
}
