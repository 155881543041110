import { Component, Input, OnInit, SimpleChange, SimpleChanges, ViewChild } from "@angular/core"
import { GrupoPreguntas } from "@puntaje/nebulosa/api-services"
import { PdfView } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "grupo-pregunta-preview-latex",
    templateUrl: "./grupo-pregunta-preview-latex.component.html",
    styleUrls: ["./grupo-pregunta-preview-latex.component.scss"]
})
export class GrupoPreguntaPreviewLatexComponent implements OnInit {
    _paramsGrupoPregunta: any
    get paramsGrupoPregunta(): any {
        return this._paramsGrupoPregunta
    }
    @Input() set paramsGrupoPregunta(value: any) {
        if (value) {
            this._paramsGrupoPregunta = value
            this.update()
        }
    }

    pdfParams = { label: "Previsualización de Latex", type: PdfView }
    showPreviewErrorMessage = false
    previewErrorMessage: string = "PDF no pudo ser generado."
    pdfLatexBinaryData: Blob

    @ViewChild("pdfLoadingLayout", { static: true }) pdfLoadingLayout: LoadingLayoutComponent

    constructor(protected grupoPreguntasService: GrupoPreguntas) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["paramsGrupoPregunta"] &&
            changes["paramsGrupoPregunta"].currentValue !== null &&
            changes["paramsGrupoPregunta"].currentValue !== undefined
        ) {
            this.update()
        }
    }

    update() {
        if (!this.paramsGrupoPregunta.grupo_pregunta) {
            return
        }

        this.pdfLatexBinaryData = undefined
        this.showPreviewErrorMessage = false
        this.pdfLoadingLayout.standby()

        this.grupoPreguntasService.previewLatexBlob(this.paramsGrupoPregunta).then(response => {
            if (response.error) {
                this.showPreviewErrorMessage = true
            } else {
                this.pdfLatexBinaryData = new Blob([response.blob], { type: "application/pdf" })
                this.showPreviewErrorMessage = false
            }
            this.pdfLoadingLayout.ready()
        })
    }
}
