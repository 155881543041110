import { Component, Input, OnInit, Injector } from "@angular/core"
import { UntypedFormArray, FormGroup } from "@angular/forms"
import {
    Alternativa,
    AlternativaForm,
    Contestable,
    ContestableForm,
    ContestableTipo,
    Pregunta,
    PreguntaForm
} from "@puntaje/nebulosa/api-services"

@Component({
    selector: "app-lista-desplegable",
    templateUrl: "./lista-desplegable.component.html"
})
export class ListaDesplegableComponent implements OnInit {
    @Input()
    editMode: boolean = false
    @Input()
    alternativasParams: any
    @Input()
    contestablesForms: UntypedFormArray
    @Input()
    contestableTipos: ContestableTipo[]
    @Input() nuevoContestable: (args: any) => void

    @Input()
    pregunta: Pregunta
    load: boolean = false

    constructor(protected injector: Injector) {}

    ngOnInit(): void {
        //para esperar antes que se intente cargar CKeditor
        setTimeout(() => {
            this.load = true
        }, 1000)
    }

    public addNewContestable() {
        const tipo = this.contestableTipos.find(c => c.contestable_tipo === "Lista Desplegable")
        this.nuevoContestable(tipo)
    }
    public addAlternativa(index: number) {
        if (this.pregunta.contestables[index].alternativas.length != 10) {
            const alternativaControl = AlternativaForm.getForm(new Alternativa())
            this.contestablesForms.controls[index]["controls"].alternativas.push(alternativaControl)
            this.pregunta.contestables[index].agregarAlternativa()
        }
    }
}
