import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import {
    ClasificacionTipoAlias,
    ClasificacionTipoAliases,
    ClasificacionTipoAliasView
} from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "clasificacion_tipo_alias.component.html"
})
export class ClasificacionTipoAliasComponent implements OnInit, OnDestroy {
    clasificacionTipoAlias: ClasificacionTipoAlias
    show_params = ClasificacionTipoAliasView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            let cta_params = {
                ...params,
                render_options: {
                    include: {
                        clasificacion_tipo: null,
                        asignatura_plataforma: {
                            include: {
                                asignatura: null,
                                plataforma: null
                            }
                        }
                    }
                }
            }
            this.clasificacionTipoAliasesService
                .find(+cta_params["id"], cta_params)
                .then((response: ClasificacionTipoAlias) => {
                    this.clasificacionTipoAlias = response
                    this.loadingLayout.ready()
                })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
