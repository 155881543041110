import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Plataforma, Plataformas, PlataformaView } from "@puntaje/nebulosa/api-services"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plataformas.component.html"
})
export class PlataformasComponent implements OnInit {
    plataformas: Plataforma[]
    show_params = PlataformaView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private plataformasService: Plataformas,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.router.routerState.root.queryParams.subscribe(params => {
            var page = +params["page"]
            var per = +params["per"]
            if (isNaN(page)) {
                page = 1
            }
            if (isNaN(per)) {
                per = 10
            }
            this.showAll(page, per)
        })
    }

    canShow(obj: any) {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }

    showAll(page: number = 1, per: number = 10) {
        this.loadingLayout.standby()

        return this.plataformasService.where({ page: page, per: per }).then((response: Plataforma[], total) => {
            this.plataformas = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(plataforma: Plataforma) {
        this.router.navigate(["plataformas/" + plataforma.id])
    }

    edit(plataforma: Plataforma) {
        this.router.navigate(["plataformas/" + plataforma.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["plataformas/" + id + "/delete"])
    }

    showModalDelete(plataforma: Plataforma) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar la plataforma '" + plataforma.plataforma + "'?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(plataforma.id))
    }
}
