import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { PermisosGuard } from "./permisos.guard"

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [PermisosGuard]
})
export class GuardsModule {}
