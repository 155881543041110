import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Persona, Personas, PersonaView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "persona.component.html"
})
export class PersonaComponent implements OnInit, OnDestroy {
    persona: Persona
    private sub: Subscription
    show_params = PersonaView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private personasService: Personas, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.personasService.find(+params["id"]).then((persona: Persona) => {
                this.persona = persona
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
