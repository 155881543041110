import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { ReporteSubRazon, ReporteSubRazones, ReporteSubRazonForm } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "reporte_sub_razones.form.component.html"
})
export class ReporteSubRazonesNewComponent implements OnInit {
    reporteSubRazon: ReporteSubRazon = new ReporteSubRazon()
    params = ReporteSubRazonForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar razón de reporte"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private reporteSubRazonesService: ReporteSubRazones, private router: Router) {}

    ngOnInit() {
        this.form = ReporteSubRazonForm.getForm(this.reporteSubRazon)
        this.loadingLayout.ready()
    }

    save() {
        ReporteSubRazonForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.reporteSubRazonesService.save(this.reporteSubRazon).then(response => {
                this.router.navigate(["reporte_sub_razones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.reporteSubRazon = new ReporteSubRazon()
            ReporteSubRazonForm.markFormControlsAsPristine(this.form)
            ReporteSubRazonForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
