<logged-layout>
    <logged-layout-toolnavbar>
        <ayudas-toolnavbar [displayButtons]="display_buttons_options"></ayudas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>{{ logged_layout_title | easyplaceholder: "Formulario ayudas" }}</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading" class="row">
                <div class="col-sm-12">
                    <form novalidate [formGroup]="form">
                        <div class="">
                            <button (click)="clear()" type="button" class="btn btn-default pull-right">
                                {{ clear_button_text | easyplaceholder: "Borrar" }}
                            </button>
                        </div>

                        <ul class="nav nav-tabs">
                            <li class="active">
                                <a href="#tab1" role="tab" data-toggle="tab">
                                    <span class="tab-number">1</span>
                                    <span class="tab_text">Clasificación</span>
                                </a>
                            </li>
                            <li>
                                <a href="#tab2" role="tab" data-toggle="tab">
                                    <span class="tab-number">2</span>
                                    <span class="tab_text">Contenido</span>
                                </a>
                            </li>
                            <li>
                                <a href="#tab3" role="tab" data-toggle="tab" (click)="reloadPreview()">
                                    <span class="tab-number">3</span>
                                    <span class="tab_text">Vista previa</span>
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <!-- Clasificación -->
                            <div id="tab1" class="active tab-pane clearfix">
                                <label class="label-visibilidad">Visibilidad del material</label>
                                <form-input
                                    [form]="form"
                                    [params]="params['visible']"
                                    [(value)]="ayuda['visible']"
                                    [key]="'visible'"
                                    class="input-visibilidad"
                                ></form-input>
                                <h4 _ngcontent-c19="">Lista de Plataformas donde el material está habilitado</h4>
                                <div>
                                    <embed-plataformas
                                        [plataformas_seleccionadas]="plataformas_seleccionadas || []"
                                        (emit_clasificaciones)="actualizarPlataformas($event)"
                                    ></embed-plataformas>
                                </div>
                            </div>
                            <div id="tab2" class="tab-pane clearfix">
                                <form-input
                                    [form]="form"
                                    [params]="params['material_tipo_id']"
                                    [(value)]="ayuda['material_tipo_id']"
                                    [key]="'material_tipo_id'"
                                    [disabled]="!(ayuda.id === undefined)"
                                    (valueChange)="updateTipoAyuda()"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['ayuda']"
                                    [(value)]="ayuda['ayuda']"
                                    [key]="'ayuda'"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['target_app']"
                                    [(value)]="ayuda['target_app']"
                                    [key]="'target_app'"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['url']"
                                    [(value)]="ayuda['url']"
                                    [key]="'url'"
                                    [hidden]="
                                        !(
                                            checkTipoAyuda() == tipoMaterial.VIDEO ||
                                            checkTipoAyuda() == tipoMaterial.STREAMING
                                        )
                                    "
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['file']"
                                    [(value)]="ayuda['file']"
                                    [key]="'file'"
                                    [xhr_upload]="s3Service.xhr_upload"
                                    [hidden]="!(checkTipoAyuda() == tipoMaterial.FILE)"
                                ></form-input>

                                <div *ngIf="ayuda.material_tipo_id == 4" class="contenido-warning">
                                    <div class="contenido-warning-content">
                                        <div class="contenido-warning-icon"><fa [name]="'warning'"></fa></div>
                                        <div class="contenido-warning-message">
                                            <p>
                                                El editor de contenido convierte texto normal a html por defecto. Para
                                                pegar contenido que ya está en formato html, apretar primero el botón
                                                "Source" del editor (arriba a la derecha en el editor) y luego pegar.
                                                Luego volver a apretar "Source" para volver al modo por defecto.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <form-input
                                    [form]="form"
                                    [params]="params['contenido']"
                                    [(value)]="ayuda['contenido']"
                                    [key]="'contenido'"
                                    *ngIf="ayuda.material_tipo_id == 4"
                                ></form-input>

                                <form-input
                                    [form]="form"
                                    [params]="params['comentarios']"
                                    [(value)]="ayuda['comentarios']"
                                    [key]="'comentarios'"
                                ></form-input>
                            </div>

                            <div id="tab3" class="tab-pane clearfix">
                                <h4>Vista previa</h4>
                                <ayuda-preview [ayuda]="ayuda" #previewAyuda *ngIf="enablePreview"></ayuda-preview>
                                <br />
                                <div class="clearfix">
                                    <div *ngIf="isSaving()">Guardando...</div>
                                    <button
                                        [disabled]="isSaving()"
                                        (click)="save()"
                                        type="button"
                                        class="btn btn-default pull-right"
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
