import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { PlanEstudioComponent } from "./plan_estudio.component"
import { PlanEstudiosComponent } from "./plan_estudios.component"
import { PlanEstudiosDeleteComponent } from "./plan_estudios.delete.component"
import { PlanEstudiosEditComponent } from "./plan_estudios.edit.component"
import { PlanEstudiosNewComponent } from "./plan_estudios.new.component"
import { planEstudiosRouting } from "./plan_estudios.routing"
import { PlanEstudiosSesionesComponent } from "./plan_estudios.sesiones.component"
import { PlanEstudiosToolNavbarComponent } from "./plan_estudios.toolnavbar.component"
import { PlanEstudiosListComponent } from "./plan-estudios-list/plan-estudios-list.component"
import { CertificacionRecursosModule } from "../certificacion_recursos/certificacion_recursos.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        planEstudiosRouting,
        ModelViewModule,
        PaginatorModule,
        NebuModule,
        CertificacionRecursosModule
    ],
    declarations: [
        PlanEstudiosComponent,
        PlanEstudioComponent,
        PlanEstudiosNewComponent,
        PlanEstudiosEditComponent,
        PlanEstudiosDeleteComponent,
        PlanEstudiosToolNavbarComponent,
        PlanEstudiosSesionesComponent,
        PlanEstudiosListComponent
    ],
    exports: [PlanEstudiosComponent]
})
export class PlanEstudiosModule {}
