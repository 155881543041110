import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Lote } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "lotes.toolnavbar.component.html",
    selector: "lotes-toolnavbar"
})
export class LotesToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() lote: Lote
    @Input() displayButtons: string

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/lotes"
        this.urlNew = "/lotes/new"
        if (this.lote != null) {
            this.urlEdit = "/lotes/" + this.lote.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        super.checkDisplayButtons()

        if (this.lote) {
            let usuario = this.authService.getUserData()
            if (
                usuario.persona_id != this.lote.propietario_id &&
                !this.sessionService.getPerfiles().includes("Super Administrador")
            )
                this.displayBtnEdit = false
        }
    }

    edit() {
        if (this.lote != null) {
            this.urlEdit = "/lotes/" + this.lote.id + "/edit"
        }
        super.edit()
    }
}
