import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Lote, Lotes, LoteView } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "lote.component.html"
})
export class LoteComponent implements OnInit, OnDestroy {
    lote: Lote
    show_params = LoteView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private lotesService: Lotes, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.lotesService.find(+params["id"]).then((lote: Lote) => {
                this.lote = lote
                this.loadingLayout.ready()
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    keys(lote: Lote): Array<string> {
        return Object.keys(lote)
    }
}
