import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { ReporteRazonComponent } from "./reporte_razon.component"
import { ReporteRazonesComponent } from "./reporte_razones.component"
import { ReporteRazonesDeleteComponent } from "./reporte_razones.delete.component"
import { ReporteRazonesEditComponent } from "./reporte_razones.edit.component"
import { ReporteRazonesNewComponent } from "./reporte_razones.new.component"
import { reporteRazonesRouting } from "./reporte_razones.routing"
import { ReporteRazonesToolNavbarComponent } from "./reporte_razones.toolnavbar.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        PaginatorModule,
        NebuModule,
        reporteRazonesRouting
    ],
    declarations: [
        ReporteRazonesComponent,
        ReporteRazonComponent,
        ReporteRazonesNewComponent,
        ReporteRazonesEditComponent,
        ReporteRazonesDeleteComponent,
        ReporteRazonesToolNavbarComponent
    ],
    exports: []
})
export class ReporteRazonesModule {}
