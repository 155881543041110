import { Component, EventEmitter, Input } from "@angular/core"
import { Pregunta, Preguntas, VariacionPrompt } from "@puntaje/nebulosa/api-services"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"

@Component({
    selector: "pregunta-variacion-modal",
    templateUrl: "pregunta_variacion_modal.component.html",
    styleUrls: ["pregunta_variacion_modal.component.scss"]
})
export class PreguntaVariacionModalComponent {
    @Input() preguntaId: number
    @Input() preguntaOriginal: Pregunta
    @Input() openVariacionModal: EventEmitter<any> = new EventEmitter<any>()

    cantidadVariaciones = 1
    notas = ""
    variaciones: Pregunta[]
    variacionesGeneradas = false
    generandoVariaciones = false
    eliminandoVariacion = false
    groupedPrompts: { [key: string]: VariacionPrompt[] } = {}
    promptId: number = null
    grupoPreguntaId: number = null

    constructor(private preguntasService: Preguntas, protected sms: SimpleModalService) {}

    ngOnInit() {
        this.preguntasService.preguntaVariacionPrompts().then(prompts => {
            const groupedPrompts = prompts.reduce((acc, prompt) => {
                if (!acc[prompt.generador_instrumento]) {
                    acc[prompt.generador_instrumento] = []
                }
                acc[prompt.generador_instrumento].push(prompt)
                return acc
            }, {})
            this.groupedPrompts = groupedPrompts
            this.promptId = prompts[0].id
        })
    }

    generarVariaciones() {
        this.generandoVariaciones = true
        this.preguntasService
            .generarVariaciones(
                this.preguntaId,
                this.promptId,
                this.cantidadVariaciones,
                this.grupoPreguntaId,
                this.notas
            )
            .then(variaciones => {
                this.generandoVariaciones = false
                this.variaciones = variaciones
                this.variacionesGeneradas = true
            })
    }

    confirmarEliminarVariacion(index: number) {
        this.sms.setModalHeader("Eliminando Variación")
        this.sms.setModalStringContent("Esta seguro de eliminar la variación?")
        this.sms.setModalCallback(() => this.eliminarVariacion(index))
        this.sms.showSimpleModal()
    }

    eliminarVariacion(index: number) {
        this.sms.closeSimpleModal()
        this.eliminandoVariacion = true
        this.preguntasService.remove(this.variaciones[index].id).then(() => {
            this.variaciones.splice(index, 1)
            this.eliminandoVariacion = false
        })
    }
}
