import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Lote, LoteForm, Lotes } from "@puntaje/nebulosa/api-services"

@Component({
    templateUrl: "lotes.form.component.html"
})
export class LotesNewComponent implements OnInit {
    lote: Lote = new Lote()
    params = LoteForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar lote"
    display_buttons_options = "all"
    lote_preguntas_options: any
    loading = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private lotesService: Lotes, private router: Router) {}

    ngOnInit() {
        this.form = LoteForm.getForm(this.lote)
        this.loadingLayout.ready()
    }

    save() {
        LoteForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.lotesService.save(this.lote).then(response => {
                this.router.navigate(["lotes/" + response.id])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.lote = new Lote()
            LoteForm.markFormControlsAsPristine(this.form)
            LoteForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    loteUpdated() {}

    loteUpdating(val) {}
}
