import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TipoInstrumentoPlataforma } from "@puntaje/nebulosa/api-services"
import { TipoInstrumentoPlataformasBuscadorService } from "./tipo_instrumento_plataformas_buscador.service"

@Component({
    templateUrl: "tipo_instrumento_plataformas.toolnavbar.component.html",
    selector: "tipo-instrumento-plataformas-toolnavbar"
})
export class TipoInstrumentoPlataformasToolNavbarComponent implements OnInit {
    @Input() tipoInstrumentoPlataforma: TipoInstrumentoPlataforma
    @Input() displayButtons: string

    displayBtnMine: boolean

    urlMisPreguntas: string
    displayNavbar: boolean

    constructor(
        protected router: Router,
        protected tipoInstrumentoPlataformasBuscadorService: TipoInstrumentoPlataformasBuscadorService
    ) {}

    ngOnInit() {
        this.displayNavbar = this.displayBtnMine
    }

    search() {
        this.tipoInstrumentoPlataformasBuscadorService.openSearch.next()
    }
}
