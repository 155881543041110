import { Component } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { PermisosService } from "../../../modules/policies/permisos.service"
import { config } from "../../../../config/config"

@Component({
    templateUrl: "login.component.html",
    styleUrls: ["login.component.scss"]
})
export class LoginComponent {
    email: string
    password: string
    form: UntypedFormGroup
    invalidLogin: boolean = false
    config: typeof config
    usuarioDeshabilitado: boolean = false

    constructor(
        protected permisosService: PermisosService,
        formBuilder: UntypedFormBuilder,
        public authService: AuthService,
        public router: Router
    ) {
        var EMAIL_REGEXP =
            "^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$"
        this.form = formBuilder.group({
            email: ["", [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
            password: ["", Validators.required]
        })

        this.form.valueChanges.subscribe(data => {
            this.invalidLogin = false
            this.usuarioDeshabilitado = false
        })
    }

    login() {
        if (this.form.valid) {
            this.authService
                .login(this.email, this.password, false)
                .then(response => {
                    this.permisosService.setPermisosToStorage(response.permisos)
                    this.permisosService.setPermisos(response.permisos)

                    // Get the redirect URL from our auth service
                    // If no redirect has been set, use the default
                    let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/home"
                    // Redirect the user
                    this.router.navigate([redirect])
                })
                .catch(response => {
                    if (response && response.error.error.user_authentication[0] == "invalid credentials") {
                        this.invalidLogin = true
                    } else if (response && response.error.error.user_authentication[0] == "deshabilitado") {
                        this.usuarioDeshabilitado = true
                    } else {
                        throw new Error("Error " + response.status)
                    }
                })
        }
    }

    checkPassword() {
        return this.form.controls["password"].hasError("required") && this.form.controls["password"].touched
    }

    checkEmail() {
        return (
            (this.form.controls["email"].hasError("required") || this.form.controls["email"].hasError("pattern")) &&
            this.form.controls["email"].touched
        )
    }

    logout() {
        this.authService.logout()
    }
}
