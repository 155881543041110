import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Pregunta, PreguntaForm, Preguntas, PreguntaView } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "preguntas",

    templateUrl: "preguntas.component.html"
})
export class PreguntasComponent implements OnInit {
    labels = PreguntaForm.formParams
    show_params = PreguntaView.viewParams
    @Input() preguntas: Pregunta[] = []
    tableButtonsArray: any
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(private preguntasService: Preguntas, private router: Router, protected authService: AuthService) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", callModal: false, glyphicon: "glyphicon-pencil" }
        }
    }

    view(pregunta: Pregunta) {
        this.router.navigate(["preguntas/" + pregunta.id])
    }

    edit(pregunta: Pregunta) {
        this.router.navigate(["preguntas/" + pregunta.id + "/edit"])
    }

    id(index, value) {
        return value.id
    }
}
