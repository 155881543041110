import { BaseForm } from "@puntaje/shared/core"
import { PreguntaForm } from "./preguntas.form"

export class LotePreguntaForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            lote_id: { label: "Lote id", type: "text", visible: true },
            pregunta_id: { label: "Pregunta id", type: "text", visible: true },
            orden: { label: "Orden", type: "text", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            pregunta: { type: "model", class: PreguntaForm }
        }
    }
}
