<logged-layout>
    <logged-layout-toolnavbar>
        <asignaturas-toolnavbar
            *ngIf="asignatura"
            [asignatura]="asignatura"
            [displayButtons]="'all new edit'"
        ></asignaturas-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>Asignatura</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <model-simple-view
                    *ngFor="let key of show_params | keys"
                    [params]="show_params[key]"
                    [value]="asignatura"
                    [key]="key"
                ></model-simple-view>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
