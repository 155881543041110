import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { PlanEstudio } from "@puntaje/nebulosa/api-services"
import { AuthService, ToolNavbar, SessionService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan_estudios.toolnavbar.component.html",
    selector: "plan-estudio-toolnavbar"
})
export class PlanEstudiosToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() planEstudio: PlanEstudio
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSesiones: boolean = false
    displayBtnNewSesion: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/plan_estudios"
        this.urlNew = "/plan_estudios/new"
        if (this.planEstudio != null) {
            this.urlEdit = "/plan_estudios/" + this.planEstudio.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()

        this.displayBtnNew = this.displayBtnNew && this.sessionService.getPerfiles().includes("Super Administrador")
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnSesiones = this.displayButtons.indexOf("sesiones") !== -1
            this.displayBtnNewSesion = this.displayButtons.indexOf("n_sesion") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.planEstudio != null) {
            this.urlEdit = "/plan_estudios/" + this.planEstudio.id + "/edit"
        }
        super.edit()
    }

    sesiones() {
        if (this.planEstudio != null) {
            let url = "/plan_estudios/" + this.planEstudio.id + "/sesiones"
            this.router.navigate([url])
        }
    }
}
