import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { LoginComponent } from "./login/login.component"
import { RegistroComponent } from "./registro/registro.component"
import { UsuarioComponent } from "./usuario.component"
import { UsuariosComponent } from "./usuarios.component"
import { UsuariosDeleteComponent } from "./usuarios.delete.component"
import { UsuariosEditComponent } from "./usuarios.edit.component"
import { UsuariosNewComponent } from "./usuarios.new.component"
import { routing } from "./usuarios.routing"
import { UsuariosToolNavbarComponent } from "./usuarios.toolnavbar.component"
import { UsuariosBuscadorComponent } from "./usuarios_buscador.component"
import { UsuariosBuscadorService } from "./usuarios_buscador.service"
import { SubAdministradoresComponent } from "./sub-administradores/sub-administradores.component"
import { SubAdministradorRowComponent } from "./sub-administrador-row/sub-administrador-row.component"
import { UsuariosSubAdministradorComponent } from "./usuarios-sub-administrador/usuarios-sub-administrador.component"
import { MisUsuariosAsociadosComponent } from "./mis-usuarios-asociados/mis-usuarios-asociados.component"
import { PoliciesModule } from "../../modules/policies/policies.module"

import { UsuarioRecursosModule } from "../usuario_recursos/usuario_recursos.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        LayoutsModule,
        ModelViewModule,
        PaginatorModule,
        NebuModule,
        routing,
        Angular2FontawesomeModule,
        PoliciesModule,
        UsuarioRecursosModule
    ],
    declarations: [
        LoginComponent,
        UsuariosComponent,
        UsuariosNewComponent,
        UsuarioComponent,
        UsuariosEditComponent,
        UsuariosDeleteComponent,
        RegistroComponent,
        UsuariosToolNavbarComponent,
        SubAdministradoresComponent,
        SubAdministradorRowComponent,
        UsuariosSubAdministradorComponent,
        UsuariosBuscadorComponent,
        MisUsuariosAsociadosComponent
    ],
    exports: [LoginComponent],
    providers: []
})
export class UsuariosModule {
    static forRoot(): ModuleWithProviders<UsuariosModule> {
        return {
            ngModule: UsuariosModule,
            providers: [UsuariosBuscadorService]
        }
    }
}
