<toolnavbar
    resourceName="generador_instrumento"
    [resource]="generador"
    [displayButtons]="displayButtons"
    [displayNavbar]="displayNavbar"
>
    <button *ngIf="displayBtnSearch" (click)="search()" class="btn btn-sm btn-default pull-left">
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        Buscar
    </button>
</toolnavbar>
