import { Component } from "@angular/core"
import { Router } from "@angular/router"

@Component({
    selector: "quick-search-showcasebox",
    templateUrl: "quick_search_showcasebox.component.html",
    styleUrls: ["quick_search_showcasebox.component.scss"]
})
export class QuickSearchShowcaseBoxComponent {
    targetPregunta: number
    targetLote: number
    targetMaterial: number
    targetPlanEstudio: number

    constructor(private router: Router) {}

    searchPregunta() {
        this.router.navigate(["/preguntas/" + this.targetPregunta])
    }

    editPregunta() {
        this.router.navigate(["/preguntas/" + this.targetPregunta + "/edit"])
    }

    searchLote() {
        this.router.navigate(["/lotes/" + this.targetLote])
    }

    editLote() {
        this.router.navigate(["/lotes/" + this.targetLote + "/edit"])
    }

    searchMaterial() {
        this.router.navigate(["/materiales/" + this.targetMaterial])
    }

    editMaterial() {
        this.router.navigate(["/materiales/" + this.targetMaterial + "/edit"])
    }

    searchPlanEstudio() {
        this.router.navigate(["/plan_estudios/" + this.targetPlanEstudio])
    }

    editPlanEstudio() {
        this.router.navigate(["/plan_estudios/" + this.targetPlanEstudio + "/edit"])
    }

    clearPregunta() {
        this.targetPregunta = null
    }

    clearLote() {
        this.targetLote = null
    }

    clearMaterial() {
        this.targetMaterial = null
    }

    clearPlanEstudio() {
        this.targetMaterial = null
    }
}
