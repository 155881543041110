import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { Plataforma, Plataformas } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "embed-plataformas",
    templateUrl: "embed_plataformas.component.html",
    styleUrls: ["embed_plataformas.component.css"]
})
export class EmbedPlataformasComponent implements OnInit, OnDestroy {
    @Input() plataformas_seleccionadas: number[]
    @Input() disabled = false

    plataformas: Plataforma[] = []
    plataforma_actual: number
    @Output() emit_clasificaciones: EventEmitter<number[]> = new EventEmitter<number[]>()

    constructor(private plataformasService: Plataformas) {}

    ngOnInit() {
        this.plataformasService.where().then((response: Plataforma[]) => {
            this.plataformas = response
        })
    }

    addPlataforma() {
        if (
            this.plataforma_actual !== undefined &&
            !(this.plataformas_seleccionadas.indexOf(this.plataforma_actual) > -1)
        ) {
            this.plataformas_seleccionadas.push(this.plataforma_actual)
        }
        this.emit_clasificaciones.emit(this.plataformas_seleccionadas)
    }

    addAll() {
        let ids = this.plataformas.map(plataforma => plataforma.id)
        this.plataformas_seleccionadas = ids
        this.emit_clasificaciones.emit(this.plataformas_seleccionadas)
    }

    removePlataforma(id) {
        let index = this.plataformas_seleccionadas.indexOf(id)
        this.plataformas_seleccionadas.splice(index, 1)
        this.emit_clasificaciones.emit(this.plataformas_seleccionadas)
    }

    removeAll() {
        this.plataformas_seleccionadas = []
        this.emit_clasificaciones.emit(this.plataformas_seleccionadas)
    }

    ngOnDestroy() {
        this.plataformas = []
        this.plataformas_seleccionadas = []
    }
}
