import { BooleanView, DateView, LinkOrDownloadView, TextView } from "@puntaje/shared/core"
import { Ayudas } from "./ayudas.service"
import { MaterialTipos } from "./material_tipos.service"

export class AyudaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            material_tipo_nombre: {
                label: "Tipo de Ayuda",
                type: TextView,
                tableVisible: true,
                options: { class: MaterialTipos, key: "material_tipo_id" }
            },
            ayuda: { label: "Nombre ayuda", type: TextView, tableVisible: true },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            target_app: { label: "Target App", type: TextView, tableVisible: true },
            comentarios: { label: "Comentarios", type: TextView, tableVisible: true },
            download: { label: "Link", type: LinkOrDownloadView, tableVisible: true, options: { class: Ayudas } },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
