import { Component, OnInit, ViewChild } from "@angular/core"
import { Usuarios, Usuario } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"

@Component({
    selector: "sub-administradores",
    templateUrl: "./sub-administradores.component.html",
    styleUrls: ["./sub-administradores.component.scss"]
})
export class SubAdministradoresComponent implements OnInit {
    usuarios: Usuario[]
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected usuariosService: Usuarios) {}

    ngOnInit() {
        const params = {
            perfil: {
                perfil: "Sub Administrador"
            },
            include: "[perfiles,persona,usuarios_hijos:[perfiles,persona]]"
        }

        this.usuariosService.where(params).then(usuarios => {
            this.usuarios = usuarios
            this.loadingLayout.ready()
        })
    }
}
