// import { urls } from "config/urls.prod";

// export const environment = {
//   production: true,
//   baseUrl: urls.baseUrl,
//   nebuUrl: urls.baseUrl,
//   achievementsUrl: urls.achievementsUrl
// };

import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.baseUrl,
        achievements: urls.achievementsUrl
    },
    facebook: {
        appId: null,
        apiVersion: "v2.10"
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.baseUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: null,
    facebookApiVersion: "v2.10"
}
