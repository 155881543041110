import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { SimpleModalService } from "../../layouts/simple_modal/simple_modal.service"
import { Ayuda, Ayudas, AyudaView } from "@puntaje/nebulosa/api-services"
import { AuthService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { AyudasBuscadorService } from "./ayudas_buscador.service"

@Component({
    templateUrl: "ayudas.component.html"
})
export class AyudasComponent implements OnInit, OnDestroy {
    ayudas: Ayuda[]
    show_params = AyudaView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    sub: Subscription
    searchParams: any

    constructor(
        private ayudasService: Ayudas,
        private router: Router,
        private simpleModalService: SimpleModalService,
        protected authService: AuthService,
        protected ayudasBuscadorService: AyudasBuscadorService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.sub = this.ayudasBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number, per: number) => {
        let params = {
            ...this.searchParams,
            page: page,
            per: per
        }

        return this.ayudasService.where(params).then((ayudas: Ayuda[], total: number) => {
            this.ayudas = ayudas
            this.loadingLayout.ready()
            return total
        })
    }

    view(ayuda: Ayuda) {
        this.router.navigate(["ayudas/" + ayuda.id])
    }

    edit(ayuda: Ayuda) {
        this.router.navigate(["ayudas/" + ayuda.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["ayudas/" + id + "/delete"])
    }

    canShow(ayuda: Ayuda) {
        let perfiles = this.sessionService.getPerfiles()
        return (
            perfiles.includes("Super Administrador") ||
            (perfiles.includes("Docente") && this.authService.getUserData().id == ayuda.profesor_id)
        )
    }

    showModalDelete(ayuda: Ayuda) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta ayuda?")
        this.simpleModalService.setModalCallback(() => this.delete(ayuda.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
