import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Reporte, ReporteForm, Reportes } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "reportes.form.component.html"
})
export class ReportesEditComponent implements OnInit, OnDestroy {
    oReporte: Reporte = new Reporte()
    reporte: Reporte = new Reporte()
    params = ReporteForm.formParams
    form: UntypedFormGroup
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    logged_layout_title = "Editar reporte"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        private reportesService: Reportes,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = ReporteForm.getForm(this.reporte)
        this.sub = this.route.params.subscribe(params => {
            this.reportesService.find(+params["id"]).then((response: Reporte) => {
                this.reporte = response
                this.oReporte = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        ReporteForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.reportesService.update(this.reporte.id, this.reporte).then(response => {
                this.router.navigate(["reportes"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.reporte = this.oReporte.clone()
            ReporteForm.markFormControlsAsPristine(this.form)
            ReporteForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
