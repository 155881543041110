import { AsignaturasPolicyService } from "./asignaturas-policy.service"
import { PreguntasPolicyService } from "./preguntas-policy.service"
import {
    Asignaturas,
    Preguntas,
    Lotes,
    Materiales,
    GrupoPreguntas,
    PlanEstudios,
    Usuarios,
    GeneradorInstrumentos,
    Ayudas
} from "@puntaje/nebulosa/api-services"
import { LotesPolicyService } from "./lotes-policy.service"
import { MaterialesPolicyService } from "./materiales-policy.service"
import { GrupoPreguntasPolicyService } from "./grupo-preguntas-policy.service"
import { PlanEstudiosPolicyService } from "./plan-estudios-policy.service"
import { UsuariosPolicyService } from "./usuarios-policy.service"
import { GeneradorInstrumentosPolicyService } from "./generador-instrumentos-policy.service"
import { AyudasPolicyService } from "./ayudas-policy.service"

export const servicios: { [model: string]: any } = {
    Asignaturas: {
        policy: AsignaturasPolicyService,
        api: Asignaturas
    },
    Ayudas: {
        policy: AyudasPolicyService,
        api: Ayudas
    },
    Preguntas: {
        policy: PreguntasPolicyService,
        api: Preguntas
    },
    Lotes: {
        policy: LotesPolicyService,
        api: Lotes
    },
    Materiales: {
        policy: MaterialesPolicyService,
        api: Materiales
    },
    GrupoPreguntas: {
        policy: GrupoPreguntasPolicyService,
        api: GrupoPreguntas
    },
    PlanEstudios: {
        policy: PlanEstudiosPolicyService,
        api: PlanEstudios
    },
    Usuarios: {
        policy: UsuariosPolicyService,
        api: Usuarios
    },
    GeneradorInstrumentos: {
        policy: GeneradorInstrumentosPolicyService,
        api: GeneradorInstrumentos
    }
}
