import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LayoutsModule } from "../../layouts/layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { FormModule, ModelViewModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { CommonModule } from "@angular/common"
import { RevisionesRecursoComponent } from "./revisiones-recurso.component"

@NgModule({
    declarations: [RevisionesRecursoComponent],
    imports: [
        CommonModule,
        NebuModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        Angular2FontawesomeModule,
        LayoutsModule,
        ModelViewModule,
        PaginatorModule,
        UtilModule
    ],
    exports: [RevisionesRecursoComponent]
})
export class RevisionesRecursoModule {}
