<logged-layout>
    <logged-layout-toolnavbar>
        <lotes-toolnavbar [displayButtons]="display_buttons_options"></lotes-toolnavbar>
    </logged-layout-toolnavbar>
    <logged-layout-titulo>{{ logged_layout_title | easyplaceholder: "Formulario lotes" }}</logged-layout-titulo>
    <logged-layout-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <ul *ngIf="lote_preguntas_options" class="nav nav-tabs opciones_lote_preguntas">
                    <li class="active"><a href="#tab1" role="tab" data-toggle="tab">Información del lote</a></li>
                    <li *ngIf="lote_preguntas_options">
                        <a href="#tab2" role="tab" data-toggle="tab">Editar preguntas</a>
                    </li>
                    <li *ngIf="lote_preguntas_options">
                        <a href="#tab3" role="tab" data-toggle="tab">Agregar preguntas</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div id="tab1" class="active tab-pane">
                        <div class="row">
                            <div class="col-md-8 col-sm-12">
                                <form novalidate [formGroup]="form">
                                    <form-input
                                        [form]="form"
                                        [params]="params['lote']"
                                        [(value)]="lote['lote']"
                                        [key]="'lote'"
                                    ></form-input>
                                    <form-input
                                        [form]="form"
                                        [params]="params['asignatura_id']"
                                        [(value)]="lote['asignatura_id']"
                                        [key]="'asignatura_id'"
                                    ></form-input>
                                    <form-input
                                        [form]="form"
                                        [params]="params['estado']"
                                        [(value)]="lote['estado']"
                                        [key]="'estado'"
                                    ></form-input>
                                    <form-input
                                        [form]="form"
                                        [params]="params['visible']"
                                        [(value)]="lote['visible']"
                                        [key]="'visible'"
                                    ></form-input>
                                    <div class="btn-container">
                                        <button (click)="save()" type="button" class="btn btn-default">
                                            Guardar
                                        </button>
                                        <button (click)="clear()" type="button" class="btn btn-default">
                                            Borrar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <ng-template [ngIf]="lote_preguntas_options">
                        <div id="tab2" class="tab-pane">
                            <edit-lote-preguntas
                                *ngIf="!loading"
                                [lote_preguntas]="lote.lote_preguntas"
                                (loteUpdated)="loteUpdated()"
                                (loteUpdating)="loteUpdating($event)"
                                [lote]="lote"
                            ></edit-lote-preguntas>
                        </div>
                        <div id="tab3" class="tab-pane">
                            <add-lote-preguntas
                                *ngIf="!loading"
                                [lote_preguntas]="lote.lote_preguntas"
                                [lote_id]="lote.id"
                                (loteUpdated)="loteUpdated()"
                                (loteUpdating)="loteUpdating($event)"
                            ></add-lote-preguntas>
                        </div>
                    </ng-template>
                </div>
            </div>
        </loading-layout>
    </logged-layout-contenido>
</logged-layout>
