<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>Recurso</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.tabla"
                (ngModelChange)="deleteIfUndefined('tabla')"
                name="tabla"
            >
                <option [ngValue]="undefined">-- Seleccione Tipo --</option>
                <option *ngFor="let tabla of tablas" [ngValue]="tabla">{{ tabla }}</option>
            </select>
        </div>

        <div class="form-group">
            <label>ID Recurso</label>
            <input
                type="text"
                [(ngModel)]="searchParams.reporte.entidad_id"
                (ngModelChange)="deleteIfUndefined('entidad_id')"
                class="form-control"
                name="entidad_id"
            />
        </div>
        <div *ngIf="withAsignatura" class="form-group">
            <label>Asignatura</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.asignatura_id"
                (ngModelChange)="deleteIfUndefined('asignatura_id')"
                name="asignatura_id"
            >
                <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura.id">
                    {{ asignatura.asignatura }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label>Plataforma</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.plataforma_id"
                (ngModelChange)="deleteIfUndefined('plataforma_id')"
                name="plataforma_id"
            >
                <option [ngValue]="undefined">-- Seleccione Plataforma --</option>
                <option *ngFor="let plataforma of plataformas" [ngValue]="plataforma.id">
                    {{ plataforma.plataforma }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label>Estado Reporte</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.estado_reporte_id"
                (ngModelChange)="deleteIfUndefined('estado_reporte_id')"
                name="estado_reporte_id"
            >
                <option [ngValue]="undefined">-- Seleccione Estado Reporte --</option>
                <option *ngFor="let estadoReporte of estadoReportes" [ngValue]="estadoReporte.id">
                    {{ estadoReporte.estado }}
                </option>
            </select>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group">
            <label>Reporte Razon</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.reporte_razon_id"
                (ngModelChange)="deleteIfUndefined('reporte_razon_id'); buscarReporteSubRazones($event)"
                name="reporte_razon_id"
            >
                <option [ngValue]="undefined">-- Seleccione Reporte Razón --</option>
                <option *ngFor="let reporteRazon of reporteRazones" [ngValue]="reporteRazon.id">
                    {{ reporteRazon.tabla }} / {{ reporteRazon.razon }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label>Reporte Sub Razon</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.reporte_sub_razon_id"
                (ngModelChange)="deleteIfUndefined('reporte_sub_razon_id')"
                name="reporte_sub_razon_id"
            >
                <option [ngValue]="undefined">-- Seleccione Reporte Sub Razón --</option>
                <option *ngFor="let reporteSubRazon of reporteSubRazones" [ngValue]="reporteSubRazon.id">
                    {{ reporteSubRazon.sub_reporte }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label>Rol</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.reporte.usuario_reporte_rol_rol"
                (ngModelChange)="deleteIfUndefined('usuario_reporte_rol_rol')"
                name="usuario_reporte_rol_rol"
            >
                <option [ngValue]="undefined">-- Seleccione Rol --</option>
                <option *ngFor="let rol of roles" [ngValue]="rol">{{ rol }}</option>
            </select>
        </div>
    </div>
    <div class="col-md-12">
        <a class="btn btn_style btn-default" (click)="search()">Buscar</a>
    </div>
</form>
