import { Component, ViewChild, Input, OnInit } from "@angular/core"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { CertificacionRecurso, CertificacionRecursos, Curriculum, Curriculums } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "certificacion-recurso",
    templateUrl: "certificacion-recurso.component.html",
    styleUrls: ["certificacion-recurso.component.scss"]
})
export class CertificacionRecursoComponent implements OnInit {
    @Input() tipoRecurso: string
    @Input() recursoId: number
    certificacionRecursos: CertificacionRecurso[]
    curriculums: Curriculum[]
    selectedCurriculumId: number
    isReady: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    lockActions: boolean = false

    constructor(
        protected certificacionRecursosService: CertificacionRecursos,

        protected authService: AuthService,

        protected curriculumsService: Curriculums
    ) {}

    ngOnInit() {
        this.curriculumsService.where().then(curriculums => {
            this.curriculums = curriculums
            this.loadCertificado()
        })
    }

    loadCertificado() {
        this.certificacionRecursos = []
        if (this.tipoRecurso && this.recursoId) {
            this.isReady = false
            this.lockActions = true
            const params = {
                certificacion_recurso: {
                    recurso_id: this.recursoId,
                    recurso_type: this.tipoRecurso
                },
                include: "[curriculum,usuario:persona]"
            }
            this.certificacionRecursosService.where(params).then((crs: CertificacionRecurso[]) => {
                this.certificacionRecursos = crs
                this.isReady = true
                this.lockActions = false
                this.loadingLayout.ready()
            })
        }
    }

    certificar() {
        if (this.lockActions) return
        if (this.tipoRecurso && this.recursoId) {
            this.loadingLayout.standby()
            this.lockActions = true
            const certificacion = new CertificacionRecurso()
            certificacion.curriculum_id = this.selectedCurriculumId
            certificacion.recurso_id = this.recursoId
            certificacion.recurso_type = this.tipoRecurso
            certificacion.usuario_id = this.authService.getUserData().id
            this.certificacionRecursosService.save(certificacion).then((cr: CertificacionRecurso) => {
                this.loadCertificado()
            })
        }
    }

    quitarCertificacion(certificacionRecurso: CertificacionRecurso) {
        if (this.lockActions) return
        if (certificacionRecurso.usuario_id == this.authService.getUserData().id) {
            this.loadingLayout.standby()
            this.lockActions = true
            this.certificacionRecursosService.remove(certificacionRecurso.id).then(() => {
                this.loadCertificado()
            })
        }
    }
}
