import { DateView, LinkView, TextView, BooleanView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { ClasificacionTipoAliases } from "./clasificacion_tipo_aliases.service"
import { ClasificacionTipoAlias } from "./clasificacion_tipo_aliases.model"
import { ClasificacionTipos } from "./clasificacion_tipos.service"
import { ClasificacionTipo } from "./clasificacion_tipos.model"
import { TipoInstrumentos } from "./tipo_instrumentos.service"
import { Plataformas } from "./plataformas.service"
import { TipoInstrumentoPlataformas } from "./tipo_instrumento_plataformas.service"
import { TipoInstrumentoPlataforma } from "./tipo_instrumento_plataformas.model"

export class TipoInstrumentoPlataformaView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            alias: {
                label: "Alias de Tipo de Instrumento",
                type: LinkView,
                tableVisible: true,
                options: { class: TipoInstrumentoPlataformas, key: "id" }
            },
            tipo_instrumento: {
                label: "Tipo de Instrumento",
                type: LinkView,
                tableVisible: true,
                options: { class: TipoInstrumentos, key: "tipo_instrumento_id" }
            },
            plataforma: {
                label: "Plataforma",
                type: TextView,
                tableVisible: true,
                options: { class: Plataformas, key: "plataforma_id" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
