<div class="buttonContainer" [class.smallButtons]="enableSmallButtons">
    <ng-template ngFor let-key [ngForOf]="tableButtonsArray | keys">
        <ng-template [permiso]="buttonsOptions[key]?.permiso" [resource]="functionParameter">
            <button *ngIf="tableButtonsArray[key].showIf(functionParameter)" 
                (click)="tableButtonsArray[key].function(functionParameter)"
                class="btn btn-xs btn-default table-btn"
                [attr.data-toggle]="tableButtonsArray[key].callModal ? 'modal' : null"
                [attr.data-target]="tableButtonsArray[key].callModal ? '#simpleModal' : null"
            >
                <span
                    *ngIf="tableButtonsArray[key].glyphicon"
                    class="glyphicon small_btn "
                    [ngClass]="tableButtonsArray[key].glyphicon"
                    aria-hidden="true"
                ></span>
                <span class="btn_text">{{ tableButtonsArray[key].text }}</span>
            </button>
        </ng-template>
    </ng-template>
</div>
