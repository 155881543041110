import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core"
import { PaginatorComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "../../../layouts/loading_layout/loading_layout.component"
import { Usuario } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "tab-recursos-asociados",
    templateUrl: "./tab-recursos-asociados.component.html",
    styleUrls: ["./tab-recursos-asociados.component.scss"]
})
export class TabRecursosAsociadosComponent implements OnInit {
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    showAsociarRecursos: any = {}
    showRecursosAsociados: any = {}
    showAsociarClasificaciones: any = {}
    recurso
    @Input() usuario: Usuario

    constructor() {}

    ngOnInit() {
        this.reset("Pregunta")
    }

    goAsociar(recurso, g, descripcion) {
        this.recurso = {
            recurso: recurso,
            g: g,
            descripcion: descripcion
        }
        this.showAsociarRecursos[recurso] = true
        this.showRecursosAsociados[recurso] = false
    }

    reset(recursoAct) {
        this.showAsociarRecursos = {
            Pregunta: false,
            GrupoPregunta: false,
            Lote: false,
            PlanEstudio: false,
            Material: false
        }

        this.showRecursosAsociados = {
            Pregunta: false,
            GrupoPregunta: false,
            Lote: false,
            PlanEstudio: false,
            Material: false
        }

        this.showAsociarClasificaciones = {
            Pregunta: false,
            GrupoPregunta: false,
            Lote: false,
            Material: false
        }

        this.showRecursosAsociados[recursoAct] = true
    }

    goToShowRecursosAsociados(event) {
        this.showAsociarRecursos[event] = false
        this.showRecursosAsociados[event] = true
        this.showAsociarClasificaciones[event] = false
    }

    goAsociarClasificaciones(recurso, g, descripcion) {
        this.recurso = {
            recurso: recurso,
            g: g,
            descripcion: descripcion
        }
        this.showAsociarRecursos[recurso] = false
        this.showRecursosAsociados[recurso] = false
        this.showAsociarClasificaciones[recurso] = true
    }
}
