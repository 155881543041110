import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "../../layouts/loading_layout/loading_layout.component"
import { Lote, LoteForm, Lotes } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"
import { LotePreguntasAddComponent } from "./lote_preguntas.add.component"
import { LotePreguntasEditComponent } from "./lote_preguntas.edit.component"

@Component({
    templateUrl: "lotes.form.component.html",
    styleUrls: ["lotes.form.component.css"]
})
export class LotesEditComponent implements OnInit, OnDestroy {
    oLote: Lote = new Lote()
    lote: Lote = new Lote()
    private sub: Subscription
    form: UntypedFormGroup
    loteId: number
    params = LoteForm.formParams
    logged_layout_title = "Editar lote"
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    lote_preguntas_options = true
    loading = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(LotePreguntasAddComponent) lotePreguntasAddChild: LotePreguntasAddComponent
    @ViewChild(LotePreguntasEditComponent) lotePreguntasEditChild: LotePreguntasEditComponent

    constructor(
        private lotesService: Lotes,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = LoteForm.getForm(this.lote)
        this.sub = this.route.params.subscribe(params => {
            this.loteId = +params["id"]
            this.lotesService.find(this.loteId).then((lote: Lote) => {
                this.lote = lote
                this.oLote = lote.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    save() {
        LoteForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.lotesService.update(this.lote.id, this.lote).then((lote: Lote) => {
                this.router.navigate(["/lotes/" + this.lote.id])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.lote = this.oLote.clone()
            LoteForm.markFormControlsAsPristine(this.form)
            LoteForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    loteUpdated() {
        //this.sub.unsubscribe();
        //this.form = this.lote.form;
        //this.sub = this.route.params.subscribe(params => {
        this.lotesService.find(this.loteId).then((lote: Lote) => {
            this.lote = lote
            this.oLote = lote.clone()
            //this.lote.form = this.form;
            this.lotePreguntasEditChild.loadingLayout.ready()
            this.lotePreguntasAddChild.showAll()
        })
        //});
    }

    loteUpdating(val) {}
}
